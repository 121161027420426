import React, { Component } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Redirect } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row, InputGroup, Form
} from "reactstrap";
import axios from "axios";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { showToast } from "pages/UI/ui-toast";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
class PublicAPISettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "",
            redirect: null,
            id: "",
            name: '',
            parameters: [''],
            domain_URL: '',
            publicKeys: '',
            leads_settings_data: ''
        };
    }
    async getDetailLeadSettings() {
        const res = await axios.get(baseURL + "/api/lead-general-setting/1", config);
        const data = res.data.data
        this.setState({ leads_settings_data: data })
        if (data.domains.length != 0) {
            const domainNames = data?.domains.map(domain => domain.domain_name);
            this.setState({ parameters: domainNames })
        }
    }
    async getAPIkeys() {
        const res = await axios.get(baseURL + "/api/app-keys-generate", config);
        const status = res.data.status
        if (status) {
            this.getDetailLeadSettings();
            showToast("Success", "The new APIs generated successfully.", "success");
        } else {
            return console.error("Keys are not generated ", res.data.message);
        }
    }
    componentDidMount() {
        this.getDetailLeadSettings();
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const parametersArray = this.state.parameters;
        if (!parametersArray.length) {
            let missingFields = '';
            if (!parametersArray.length) {
                missingFields += '<li>Domains</li>';
            }
            showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
            return false;
        }
        axios.post(baseURL + "/api/lead-general-setting", {
            "domains": parametersArray
        }, config)
            .then((response) => {
                showToast("Success", "The APIs setting updated successfully.", "success");
                // this.setState({ redirect: "/campaign/list" });
            })
            .catch((error) => {
                showToast("Failed", error.response.data.message, "error")
            });
    }

    getDateFormateMethod = (myDate) => {
        var today = myDate
        const dd = today.getDate().toString()
        const mm = today.getMonth() + 1
        const yyyy = today.getFullYear()
        const fromate_date = (today = yyyy + "-" + mm + "-" + dd)
        return fromate_date
    }
    showToast(title, message, toastType) {
        //position class
        var positionClass = "toast-top-right";
        toastr.options = {
            positionClass: positionClass,
            timeOut: this.state.timeOut,
            extendedTimeOut: this.state.extendedTimeOut,
            showEasing: this.state.showEasing,
            hideEasing: this.state.hideEasing,
            showMethod: this.state.showMethod,
            hideMethod: this.state.hideMethod,
            showDuration: this.state.showDuration,
            hideDuration: this.state.hideDuration,
        };

        // setTimeout(() => toastr.success(`Settings updated `), 300)
        //Toaster Types
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
    }
    handleParameterChange = (index, value) => {
        const parameters = [...this.state.parameters];
        parameters[index] = value;
        this.setState({ parameters });
    };

    addDomainParameter = () => {
        this.setState(prevState => ({
            parameters: [...prevState.parameters, ''] // Adding an empty string as a new parameter
        }));
    };

    removeParameter = index => {
        const parameters = [...this.state.parameters];
        parameters.splice(index, 1);
        this.setState({ parameters });
    };
    generateKeys = () => {
        this.getAPIkeys();
        // Comment for after use if needed.
        // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        // const keyLength = 64;

        // const generateRandomKey = () => {
        //     let key = '';
        //     for (let i = 0; i < keyLength; i++) {
        //         const randomIndex = Math.floor(Math.random() * characters.length);
        //         key += characters[randomIndex];
        //     }
        //     return key;
        // };

        // const secretKey = generateRandomKey();
        // const apiKey = generateRandomKey();
        // document.getElementById('secKey').value = secretKey;
        // document.getElementById('apiKey').value = apiKey;
        // const publicKeys = {
        //     secretKey: secretKey,
        //     apiKey: apiKey
        // };
        // this.setState({ publicKeys: publicKeys })
        // console.log(publicKeys); // Log the keys for demonstration
    };

    render() {
        //meta title
        document.title = "Public APIs Settings";
        const { selectedStatus, parameters, domain_URL, leads_settings_data } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        const { selectedGroup } = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Lead Settings" breadcrumbItem="Public APIs Settings" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Form onSubmit={this.handleSubmit}>
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="secKey">
                                                            Secret Key
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="secKey"
                                                                name="secKey"
                                                                readOnly
                                                                defaultValue={leads_settings_data?.secret_key}
                                                                placeholder="Secret Key"
                                                                type="text"
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="apiKey">
                                                            API Key
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="apiKey"
                                                                name="apiKey"
                                                                defaultValue={leads_settings_data?.app_key}
                                                                readOnly
                                                                placeholder="API Key"
                                                                type="text"
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>

                                                <Col md="12">
                                                    <div className="mb-3 row d-flex select2-container">
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-10">
                                                            <div className="float-end">
                                                                <Button color="primary" className="mx-2" onClick={this.generateKeys}>
                                                                    Generate Keys
                                                                </Button>
                                                                <Button color="primary" className="" onClick={this.addDomainParameter}>
                                                                    Add Domain
                                                                </Button>.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md="12">
                                                    <Card>
                                                        <CardTitle className="mb-2">Domains*</CardTitle>
                                                        <CardBody className="border border-primary">
                                                            {parameters.map((value, index) => (
                                                                <FormGroup key={index} className="mb-3 row d-flex">
                                                                    <Label className="col-md-1 col-form-label">Domain</Label>
                                                                    <div className="col-md-8">
                                                                        <Input
                                                                            type="text"
                                                                            value={value}
                                                                            onChange={e => this.handleParameterChange(index, e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <Button color="danger" onClick={() => this.removeParameter(index)}>Remove</Button>
                                                                    </div>
                                                                </FormGroup>
                                                            ))}

                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Button color="success" type="submit" className="float-end">
                                                Update
                                            </Button>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default PublicAPISettings;
