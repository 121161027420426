import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../../../../UI/ui-toast'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../../../../../components/Common/Breadcrumb";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: `${token}`
    }
};
import constant from '../../../../../../constants.json';
import EmailBody from "pages/Templates/Email/EmailBody";
import { subject_variable_list, get_default_email_template } from "../../../../api/templatesApi"
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
class ClonePaymentReminderEmailTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "",
            optionGroupProjects: [],
            redirect: null,
            status_templates: constant.status_templates,
            statusSelected: -1,
            htmlData: null,
            org_id: null,
            variable_list: null,
            emailJSON: null,
        };
    }

    async getOptionsProjects() {
        const res = await axios.get(baseURL + "/api/project-list", config);
        const data = res.data.data;
        const options = data.map(d => ({
            "value": d.id,
            "label": d.name,
            "org_id": d.organisation_id
        }))
        this.setState({ optionGroupProjects: options })
    }
    componentDidMount() {
        Promise.all([get_default_email_template(localStorage.getItem("current_sample_id"))]).then((values) => {
            const subjectField = document.getElementById("subject");
            subjectField.value = values[0].subject
        });
        Promise.all([subject_variable_list()]).then((values) => {
            const variable_list = Object.entries(values[0]).map(([label, value]) => (label));
            this.setState({ variable_list: variable_list })
            console.log(variable_list);
        });
        this.getOptionsProjects()
    }
    checkValidation = () => {
        if (this.state.projectSelected != 1) {
            this.setState({ projectSelected: 0 })
        }
        if (this.state.projectLogoSelected != 1) {
            this.setState({ projectLogoSelected: 0 })
        }
        if (this.state.statusSelected != 1) {
            this.setState({ statusSelected: 0 })
        }
    }
    handleSelectGroupProject = selectedGroupProject => {
        this.setState({ selectedGroupProject })
        this.setState({ org_id: selectedGroupProject.org_id })
        this.setState({ projectSelected: 1 })
    }
    handleSelectedStatusTemplate = selectedStatusTemplate => {
        this.setState({ statusSelected: 1 })
        this.setState({ selectedStatusTemplate })
    }
    handleChildData = (emailJSON, html) => {
        this.setState({ htmlData: html })
        this.setState({ emailJSON: emailJSON })
    };
    appendVariableToSubject = (variable) => {
        const subjectField = document.getElementById("subject");
        const currentValue = subjectField.value;
        const updatedValue = currentValue + " " + variable;
        subjectField.value = updatedValue;
    };
    render() {
        //meta title
        const { variable_list } = this.state;
        const variableButtons = variable_list?.map((variable, index) => (
            <Button
                key={index}
                type="button"
                className="m-1 btn-sm bg-primary"
                onClick={() => this.appendVariableToSubject(variable)}
            >
                {variable}
            </Button>
        ));
        document.title = "Clone Template";
        const { validation } = this.state;
        const { selectedGroupProject } = this.state;
        const { selectedStatusTemplate } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        const subjectField = document.getElementById("subject");
        const subjectValue = subjectField?.value
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Payment Reminder Email" breadcrumbItem="Clone Template" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                name: (this.state && this.state.name) || "",
                                                address: (this.state && this.state.address) || "",
                                                phone: (this.state && this.state.phone) || "",
                                                mobile: (this.state && this.state.mobile) || "",
                                                email: (this.state && this.state.email) || "",
                                                website: (this.state && this.state.website) || "",
                                                company_reg_no: (this.state && this.state.company_reg_no) || "",
                                                subject: (this.state && this.state.subject) || subjectValue,
                                            }}
                                            validationSchema={Yup.object().shape({
                                                name: Yup.string().required(
                                                    "Template name is required!"
                                                ),
                                                address: Yup.string().required(
                                                    "Address is required!"
                                                ),
                                                phone: Yup.string().required(
                                                    "Phone number is required!"
                                                ),
                                                mobile: Yup.string().required(
                                                    "Mobile number is required!"
                                                ),
                                                email: Yup.string().required(
                                                    "Email is required!"
                                                ),
                                                website: Yup.string().required(
                                                    "Website is required!"
                                                ),
                                                // company_reg_no: Yup.string().required(
                                                //     "Company Registration Number is required!"
                                                // ),
                                                subject: Yup.string().required(
                                                    "Subject is required!"
                                                ),
                                            })}
                                            onSubmit={values => {
                                                const subjectField = document.getElementById("subject");
                                                axios.post(baseURL + "/api/email-templates", {
                                                    'template_name': values.name,
                                                    'default_template_name': window.localStorage.getItem("current_sample_template_name"),
                                                    'subject': subjectField.value,
                                                    'body': this.state.htmlData,
                                                    'bodyjson': this.state.emailJSON,
                                                    'organisation_id': this.state.org_id,
                                                    'address': values.address,
                                                    'telephone': values.phone,
                                                    'mobile': values.mobile,
                                                    'email': values.email,
                                                    'website': values.website,
                                                    'company_reg_no': '-',
                                                    // 'company_reg_no': values.company_reg_no,
                                                    'project_id': selectedGroupProject.value,
                                                    'status': selectedStatusTemplate.value,
                                                    'email_type': 'Payment Reminder Template'
                                                }, config)
                                                    .then((response) => {
                                                        showToast("Success", "The template has been cloned successfully.", "success");
                                                        this.setState({ redirect: "/template/email/financial/payment-reminder-email/clone-list" });
                                                    })
                                                    .catch((error) => {
                                                        showToast("Failed", "Template Not Cloned Successfully", "error")
                                                    });
                                            }}
                                        >
                                            {({ errors, status, touched }) => (
                                                <Form className="needs-validation">
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="name">
                                                                    Template Name*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="name"
                                                                        placeholder="Template Name"
                                                                        type="text"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.name && touched.name
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="name"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="selectProject">Projects</Label>
                                                                <div className="col-md-10">
                                                                    <Select
                                                                        name="selectProject"
                                                                        value={selectedGroupProject}
                                                                        onChange={this.handleSelectGroupProject}
                                                                        options={this.state.optionGroupProjects}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                    {this.state.projectSelected == 0 ? <>
                                                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Project is not selected, please select first</p>
                                                                    </> : <></>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        {/* <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="company_reg_no">
                                                                    Company Reg No.*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="company_reg_no"
                                                                        placeholder="Company Registration No."
                                                                        type="text"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.company_reg_no && touched.company_reg_no
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="company_reg_no"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col> */}
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="address">
                                                                    Address*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="address"
                                                                        placeholder="Address"
                                                                        type="text"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.address && touched.address
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="address"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="phone">
                                                                    Phone*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="phone"
                                                                        placeholder="Phone"
                                                                        type="tell"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.phone && touched.phone
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="phone"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="mobile">
                                                                    Mobile*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="mobile"
                                                                        placeholder="Mobile"
                                                                        type="tell"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.mobile && touched.mobile
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="mobile"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="email">
                                                                    Email*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="email"
                                                                        placeholder="Email"
                                                                        type="email"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.email && touched.email
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="email"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="website">
                                                                    Website*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="website"
                                                                        placeholder="Website"
                                                                        type="text"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.website && touched.website
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="website"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="statusTemplate">Status*</Label>
                                                                <div className="col-md-10">
                                                                    <Select
                                                                        value={selectedStatusTemplate}
                                                                        onChange={this.handleSelectedStatusTemplate}
                                                                        options={this.state.status_templates}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                    {this.state.statusSelected == 0 ? <>
                                                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Status is not selected, please select first</p>
                                                                    </> : <></>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="subject">
                                                                    Subject*
                                                                </Label>
                                                                <div className="col-md-6">
                                                                    <Field
                                                                        id="subject"
                                                                        name="subject"
                                                                        placeholder="Subject"
                                                                        type="text"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.subject && touched.subject
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="subject"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <h4>Variables List</h4>
                                                                    {variableButtons}
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="emailBody">Email Body*</Label>
                                                                <div className="col-md-12">
                                                                    <EmailBody sendDataToParent={this.handleChildData} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Button color="primary" onClick={this.checkValidation} type="submit" className="float-end" >
                                                        Clone Template
                                                    </Button>
                                                </Form>
                                            )}
                                        </Formik>
                                        <br />
                                        <br />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default ClonePaymentReminderEmailTemplate;