import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../UI/ui-toast'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get_current_orginasation_user, get_current_user, get_current_propertyappz_user } from '../api/profilesApi';
// import { get_current_user } from '../api/profilesApi';
const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

import constant from '../../../constants.json';
const baseURL_propertyAppz = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

class EditProfiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      optionGroupRole: [],
      u_first_name: '',
      u_last_name: '',
      u_middle_name: '',
      u_mobile_number: '',
      u_telephone: '',
      u_email: '',
      u_title: '',
      u_status: '',
      u_role: '',
      u_picture: null,
      u_picture_show: null,
      redirect: null,
      currentUserInfo: null,
      titlesArray: constant.titles_list,
      statusArray: [
        {
          "value": '1',
          "label": 'Active'
        },
        {
          "value": '0',
          "label": 'Inactive'
        }
      ],
      id: "",
      name: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }
  handleChange(event) {
  }

  componentDidMount() {
    this.getProfileData();
  }

  getProfileData(){
    if(localStorage.getItem('adminUserLoginFlag')){
      Promise.all([get_current_user()]).then((values) => {
        this.setState({ currentUserInfo: values[0] });
        this.setState({ u_first_name: values[0].first_name });
        this.setState({ u_last_name: values[0].last_name });
        this.setState({ u_middle_name: values[0].middle_name });
        this.setState({ u_mobile_number: values[0].mobile });
        this.setState({ u_telephone: values[0].telephone });
        this.setState({ u_email: values[0].email });
        this.setState({ u_title: values[0].title });
        this.setState({ u_status: values[0].status });
        this.setState({ u_role: values[0].roles[0].name });
        this.setState({ u_picture_show: values[0].picture });
        secureLocalStorage.setItem("headerPlatformUserData", values[0]);
      });
    }
    else if(localStorage.getItem('propertyAppzLoginFlag')){
      Promise.all([get_current_propertyappz_user()]).then((values) => {
        this.setState({ currentUserInfo: values[0] });
        this.setState({ u_first_name: values[0].first_name });
        this.setState({ u_last_name: values[0].last_name });
        this.setState({ u_middle_name: values[0].middle_name });
        this.setState({ u_mobile_number: values[0].mobile });
        this.setState({ u_telephone: values[0].telephone });
        this.setState({ u_email: values[0].email });
        this.setState({ u_title: values[0].title });
        this.setState({ u_status: values[0].status });
        this.setState({ u_role: values[0].roles[0].name });
        this.setState({ u_picture_show: values[0].picture });
        secureLocalStorage.setItem("headerPropertyAppzUserData", values[0]);
      });
    }
    else{
      Promise.all([get_current_orginasation_user()]).then((values) => {
        this.setState({ currentUserInfo: values[0] });
        this.setState({ u_first_name: values[0].first_name });
        this.setState({ u_last_name: values[0].last_name });
        this.setState({ u_middle_name: values[0].middle_name });
        this.setState({ u_mobile_number: values[0].mobile });
        this.setState({ u_telephone: values[0].telephone });
        this.setState({ u_email: values[0].email });
        this.setState({ u_title: values[0].title });
        this.setState({ u_status: values[0].status });
        this.setState({ u_role: values[0].roles[0].name });
        this.setState({ u_picture_show: values[0].picture });
        secureLocalStorage.setItem("headerOrganisationUserData", values[0]);
      });
    }
  }

  handleSelectedUserTitle = selectedUserTitle => {
    this.setState({ u_title: selectedUserTitle.value });
    this.setState({ selectedUserTitle })
  }
  handleImageChangePicture = (e) => {
    if (e.target.files && e.target.files[0]) {
      this.setState({ u_picture: e.target.files[0] });
    }
  };
  handleSubmit(e) {
    e.preventDefault();
    var endpoint_url = "/api/orginasation-profile-update";
    var org_id = '';
    var URL;
    if(localStorage.getItem('adminUserLoginFlag')){
      var endpoint_url = "/api/platform-user-profile-update";
      URL = baseURL;
    }
    else if(localStorage.getItem('propertyAppzLoginFlag')){
      var endpoint_url = "/api/propertyappz-user-profile-update";
      URL = baseURL_propertyAppz;
    }
    else{
      org_id = localStorage.getItem("organisation_id");
      URL = baseURL;
    }
    
    if (!this.state.u_first_name || !this.state.u_last_name) {
      let missingFields = '';
      if (!this.state.u_first_name) {
        missingFields += '<li>First Name</li>';
      }
      if (!this.state.u_last_name) {
        missingFields += '<li>Last Name</li>';
      }
      showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
      return false;
    }
      axios.post(URL + endpoint_url, {

        "title": this.state.u_title,
        "first_name": e.target.firstName.value,
        "middle_name": e.target.middleName.value,
        "last_name": e.target.lastName.value,
        "telephone": e.target.telephone.value,
        "mobile": e.target.mobileNumber.value,
        "role": this.state.u_role,
        "email": this.state.u_email,
        "organisation_id": org_id,
        "picture": this.state.u_picture,
        "status": this.state.u_status
      }, config)
      .then((response) => {
        showToast("Success", "Your profile has been updated successfully.", "success");
        this.getProfileData();
        // this.setState({ redirect: "/dashboard" });
        // this.setState({ redirect: "/organisation/users/list" });
        setTimeout(() => {
          window.location.replace('/dashboard');
        }, 3000);
      })
      .catch((error) => {
        showToast("Failed", "Profile Not Edited Successfully", "error");
      });
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }

  render() {
    //meta title
    document.title = "Profile";
    const { validation } = this.state;
    const { selectedUserTitle } = this.state;
    const { selectedGroupRole } = this.state;
    const { selectedUserStatus } = this.state;
     if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
     }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Dashboard" breadcrumbItem="Edit Profile" parentLink="/dashboard" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik
                    >
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation" onSubmit={this.handleSubmit}>
                        <Row>
                        <Col md="12">
                            <div className="mb-3 row d-flex select2-container">
                              <Label className="col-md-2 col-form-label" htmlFor="selectCountry">Role </Label>
                              <div className="col-md-10 mt-2">
                                {this.state.u_role}
                              </div>
                            </div>
                          </Col>
                          <Col md="12">
                            <div className="mb-3 row d-flex select2-container">
                              <Label className="col-md-2 col-form-label" htmlFor="userTitle">Select title</Label>
                              <div className="col-md-10">
                                <Select
                                  onChange={this.handleSelectedUserTitle}
                                  options={this.state.titlesArray}
                                  // value={this.state.c_title}
                                  value={{ label: this.state.u_title, value: 0 }}
                                  classNamePrefix="select2-selection"
                                />
                              </div>
                            </div>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="firstName">
                                First Name*
                              </Label>
                              <div className="col-md-10">
                                <Field
                                  name="firstName"
                                  placeholder="First Name"
                                  type="text"
                                  value={this.state.u_first_name}
                                  onChange={(e) => this.setState({ u_first_name: e.target.value })}
                                  className={
                                  "form-control"
                                }
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="middleName">
                                Middle Name
                              </Label>
                              <div className="col-md-10">
                                <Field
                                  name="middleName"
                                  placeholder="Middle Name"
                                  type="text"
                                  value={this.state.u_middle_name}
                                  onChange={(e) => this.setState({ u_middle_name: e.target.value })}
                                  className={
                                    "form-control"
                                  }
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="lastName">
                                Last Name*
                              </Label>
                              <div className="col-md-10">
                                <Field
                                  name="lastName"
                                  placeholder="Last Name"
                                  type="text"
                                  onChange={(e) => this.setState({ u_last_name: e.target.value })}
                                  value={this.state.u_last_name}
                                  className={
                                    "form-control"
                                  }
                                  />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="telephone">
                                Telephone
                              </Label>
                              <div className="col-md-10">
                                <Field
                                  name="telephone"
                                  placeholder="Telephone"
                                  type="text"
                                  onChange={(e) => this.setState({ u_telephone: e.target.value })}
                                  value={this.state.u_telephone}
                                  className={
                                    "form-control"
                                  }
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="mobileNumber">
                                Mobile Number
                              </Label>
                              <div className="col-md-10">
                                <Field
                                  name="mobileNumber"
                                  placeholder="Mobile Number"
                                  type="text"
                                  onChange={(e) => this.setState({ u_mobile_number: e.target.value })}
                                  value={this.state.u_mobile_number}
                                  className={
                                    "form-control"
                                  }
                                />

                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="email">
                                 Email
                              </Label>
                              <div className="col-md-10 mt-2">
                                {this.state.u_email}
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <div className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="picture">
                                Picture
                              </Label>
                              <div className="col-md-10">
                                <Input className="form-control form-control" type="file" id="inputPicture" accept="image/*"
                                  onChange={this.handleImageChangePicture} />
                              </div>
                            </div>
                          </Col>
                          {this.state.u_picture_show == null ?

                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="picture">
                                  Existing Picture
                                </Label>
                                <div className="col-md-10 mt-2">
                                  No Picture Exists
                                </div>
                              </div>
                            </Col>
                            :
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="picture">
                                  Existing Picture
                                </Label>
                                <div className="col-md-10">
                                  <img className="rounded me-2" alt="200x200" width="200" src={this.state.u_picture_show} data-holder-rendered="true" />
                                </div>
                              </div>
                            </Col>
                          }
                        </Row>
                        <Button color="primary" type="submit" className="float-end" >
                          Update Profile
                        </Button>
                      </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default EditProfiles;
