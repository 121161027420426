import axios from "axios";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
export const get_all_campaigns = async () => {
    let response = await axios.get(baseURL + "/api/campaigns", config);
    return response.data.data;
}
export const get_single_campaign = async (campaign_id) => {
    let response = await axios.get(baseURL + "/api/campaigns/" + campaign_id, config);
    return response.data.data;
}
export const update_campaign = async (campaign_id, payload) => {
    let response = await axios.patch(baseURL + "/api/campaigns/" + campaign_id, payload, config);
    return response.data.data;
}
export const delete_campaign = async (campaign_id) => {
    let response = await axios.delete(baseURL + "/api/campaigns/" + campaign_id, config);
    return response.data.data;
}
export const get_all_campaigns_logs = async () => {
    let response = await axios.get(baseURL + "/api/all-campaign-activity-log", config);
    return response.data.data;
}
export const get_all_single_campaign_logs = async (campagin_id) => {
    let response = await axios.get(baseURL + "/api/campaign-activity-log/" + campagin_id, config);
    return response.data.data;
}