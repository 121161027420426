import PropTypes from "prop-types";
import React, { Component } from "react";
import "react-drawer/lib/react-drawer.css";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import megamenuImg from "../../assets/images/new-megamenu-img.jpg";
import logo from "../../assets/images/logo.svg";
//i18n
import { withTranslation } from "react-i18next";
// Redux Store
import { toggleRightSidebar } from "../../store/actions";
import { fetchLogo } from '../../store/logo/actions';
import propertyAppzWhite from "../../assets/images/Property-Appz-White-Logo-v1.png";
import propertyAppzDark from "../../assets/images/Property-Appz-Logo-v1.0.png";
var personalData = null;
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      open: false,
      position: "right",
      logo: '',
      icon: '',
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }

  componentDidMount() {
    this.props.fetchLogo();
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    var { logoDark } = this.props;
    var { logoWhite } = this.props;

    if (logoDark === undefined || logoDark == null) {
      logoDark = propertyAppzDark;
    }

    if (logoWhite === undefined || logoWhite == null) {
      logoWhite = propertyAppzWhite;
    }

    if (localStorage.getItem('propertyAppzLoginFlag')) {
      logoDark = propertyAppzDark;
      logoWhite = propertyAppzWhite;
    }
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/dashboard" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    {/* <img src={logoDark} alt="" height="17" /> */}
                    {/* <img src={this.state.logo?this.state.logo:logoDark} alt="" height="17" /> */}
                    <img src={logoDark} alt="" height="35" />
                  </span>
                </Link>

                <Link to="/dashboard" className="logo logo-light">
                  <span className="logo-sm">
                    {/* <img src={logoLightSvg} alt="" height="22" /> */}
                  </span>
                  <span className="logo-lg">
                    {/* <img src={logoLightPng} alt="" height="35" /> */}
                    <img src={logoWhite} alt="" height="35" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                onClick={this.toggleMenu}
                className="btn btn-sm px-3 font-size-16 header-item"
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>

              <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={this.props.t("Search") + "..."}
                  />
                  <span className="bx bx-search-alt"></span>
                </div>
              </form>
              {localStorage.getItem("adminUserLoginFlag") || localStorage.getItem("propertyAppzLoginFlag")  ? <></> : <>
                <Dropdown
                  className="dropdown-mega d-none d-lg-block ms-2"
                  isOpen={this.state.megaMenuDrp}
                  toggle={() => {
                    this.setState({ megaMenuDrp: !this.state.megaMenuDrp });
                  }}
                >
                  <DropdownToggle className="btn header-item" caret tag="button">
                    {" "}
                    {this.props.t("Quick Access")}{" "}
                    <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-megamenu">
                    <Row>
                      <Col sm={8}>
                        <Row>
                          <Col md={3}>
                            <h5 className="font-size-14 mt-0">
                              {this.props.t("Properties")}
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/properties/add">{this.props.t("Add Property")}</Link>
                              </li>
                              <li>
                                <Link to="/properties/list">
                                  {/* <span className="badge rounded-pill text-bg-success float-end" key="t-new">New</span> */}
                                  {this.props.t("List Properties")}
                                </Link>
                              </li>
                            </ul>
                          </Col>

                          <Col md={3}>
                            <h5 className="font-size-14 mt-0">
                              {this.props.t("Sales")}
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/sales/add">{this.props.t("Add Sale")}</Link>
                              </li>
                              <li>
                                <Link to="/sales/list">
                                  {this.props.t("List Sales")}
                                </Link>
                              </li>
                            </ul>
                          </Col>

                          <Col md={3}>
                            <h5 className="font-size-14 mt-0">
                              {this.props.t("Reports")}
                            </h5>
                            <ul className="list-unstyled megamenu-list">
                              <li>
                                <Link to="/report/sales">
                                  {this.props.t("Sales Report")}
                                </Link>
                              </li>
                              <li>
                                <Link to="/report/payments">
                                  {this.props.t("Payments Report")}
                                </Link>
                              </li>
                              <li>
                                <Link to="/report/overdue-payments">
                                  {this.props.t("Overdue Report")}
                                </Link>
                              </li>
                              <li>
                                <Link to="/reports/projects">
                                  {this.props.t("Projects Report")}
                                </Link>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm={4}>
                        <Row>
                          <Col sm={5}></Col>
                          {/* <Col sm={6}>
                          <h5 className="font-size-14 mt-0">
                            {this.props.t("UI Components")}
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="#">{this.props.t("Lightbox")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Range Slider")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Sweet Alert")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Rating")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Forms")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Tables")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Charts")}</Link>
                            </li>
                          </ul>
                        </Col> */}

                          <Col sm={6}>
                            <div>
                              <img
                                src={megamenuImg}
                                alt=""
                                className="img-fluid mx-auto d-block"
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </DropdownMenu>
                </Dropdown>
              </>
              }
            </div>
            <div className="d-flex">
              <div className="dropdown d-inline-block d-lg-none ms-2">
                <button
                  onClick={() => {
                    this.setState({ isSearch: !this.state.isSearch });
                  }}
                  type="button"
                  className="btn header-item noti-icon"
                  id="page-header-search-dropdown"
                >
                  <i className="mdi mdi-magnify"></i>
                </button>
                <div
                  className={
                    this.state.isSearch
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  }
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              {/* <LanguageDropdown /> */}

              {localStorage.getItem("adminUserLoginFlag") || localStorage.getItem("propertyAppzLoginFlag") ? <></> : <>
                <Dropdown
                  className="d-none d-lg-inline-block ms-1"
                  isOpen={this.state.socialDrp}
                  toggle={() => {
                    this.setState({ socialDrp: !this.state.socialDrp });
                  }}
                >
                  <DropdownToggle
                    className="btn header-item noti-icon"
                    tag="button"
                  >
                    <i className="bx bx-customize"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                    <div className="px-lg-2">
                      <Row className="no-gutters">
                        <Col>
                          <Link className="dropdown-icon-item" to="/projects/list">
                            {/* <img src={github} alt="Github" /> */}
                            <i className="bx bx-building-house" style={{ fontSize: "2em" }} />
                            <span>Projects</span>
                          </Link>
                        </Col>
                        <Col>
                          <Link className="dropdown-icon-item" to="/properties/list">
                            {/* <img src={bitbucket} alt="bitbucket" /> */}
                            <i className="bx bx-home" style={{ fontSize: "2em" }} />
                            <span>Properties</span>
                          </Link>
                        </Col>
                        <Col>
                          <Link className="dropdown-icon-item" to="/customers/list">
                            {/* <img src={dribbble} alt="dribbble" /> */}
                            <i className="bx bx-user" style={{ fontSize: "2em" }} />
                            <span>Customers</span>
                          </Link>
                        </Col>
                      </Row>

                      <Row className="no-gutters">
                        <Col>
                          <Link className="dropdown-icon-item" to="/salesagents/list">
                            {/* <img src={dropbox} alt="dropbox" /> */}
                            <i className="bx bx-user" style={{ fontSize: "2em" }} />
                            <span>Sales Agents</span>
                          </Link>
                        </Col>
                        <Col>
                          <Link className="dropdown-icon-item" to="/sales/list">
                            {/* <img src={mail_chimp} alt="mail_chimp" /> */}
                            <i className="bx bx-pound" style={{ fontSize: "2em" }} />
                            <span>Sales</span>
                          </Link>
                        </Col>
                        <Col>
                          <Link className="dropdown-icon-item" to="/documents/list">
                            {/* <img src={slack} alt="slack" /> */}
                            <i className="bx bx-file" style={{ fontSize: "2em" }} />
                            <span>Documents</span>
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              </>
              }
              {/* <NotificationDropdown /> */}
              <ProfileMenu />

              <div className="dropdown d-inline-block">
                <button
                  onClick={() => {
                    this.toggleRightbar();
                  }}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle"
                >
                  <i className="bx bx-cog bx-spin"></i>
                </button>
              </div>
              <div className="dropdown d-none d-lg-inline-block ms-1">
                <button
                  type="button"
                  onClick={this.toggleFullscreen}
                  className="btn header-item noti-icon"
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen"></i>
                </button>
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  t: PropTypes.any,
  toggleMenuCallback: PropTypes.any,
  showRightSidebar: PropTypes.any,
  toggleRightSidebar: PropTypes.func,
  fetchLogo: PropTypes.func,
  logoUrl: PropTypes.shape({
    logo: PropTypes.string,
    icon: PropTypes.string,
    white_logo: PropTypes.string
  })
};

const mapStateToProps = (state) => {
  // console.log(state.Logo.logo.logoUrl);
  const { layoutType, showRightSidebar } = state.Layout;
  return {
    layoutType,
    showRightSidebar,
    logoDark: state.Logo.logo.logoUrl?.logo,
    logoWhite: state.Logo.logo.logoUrl?.white_logo,
  };
};

export default connect(mapStateToProps, { toggleRightSidebar, fetchLogo })(withTranslation()(Header));
