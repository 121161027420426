import React, { Component } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Redirect } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row, InputGroup, Form
} from "reactstrap";
import axios from "axios";
import Flatpickr from 'react-flatpickr'
import * as Yup from "yup";
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { showToast } from "pages/UI/ui-toast";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
// const baseURL = secureLocalStorage.getItem("baseURL");
import { get_single_tenant, update_tenant } from '../api/tenantApi';
class EditTenant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "",
            redirect: null,
            optionGroup: [],
            id: "",
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            spaces_folder: '',
            domains: [{ "api": '', "frontend": '' }],
            ip_addresses: [''],
            loading: false,
            doc_encryption_variable: ''
        };
    }
    componentDidMount() {
        Promise.all([get_single_tenant(window.localStorage.getItem("tenant_id"))])
            .then((values) => {
                const tenant = values[0];
                const domains = tenant.domain_name.map((apiDomain, index) => ({
                    api: apiDomain,
                    frontend: tenant.frontend_domain[index] || ''
                }));

                this.setState({
                    id: tenant.id,
                    name: tenant.name,
                    // email: tenant.email,
                    doc_encryption_variable: tenant.doc_encryption_variable,
                    spaces_folder: tenant.name,
                    domains: domains,
                    ip_addresses: tenant.ip_address,
                });
            });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true }); // Set loading to true
        const { id, name, email, spaces_folder, doc_encryption_variable } = this.state;

        let missingFields = '';
        if (!name) missingFields += '<li>Name</li>';
        // if (!email) missingFields += '<li>Email</li>';
        // if (!spaces_folder) missingFields += '<li>Spaces Folder</li>';
        if (!doc_encryption_variable) missingFields += '<li>Document Encryption Variable</li>';

        if (missingFields) {
            showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
            this.setState({ loading: false }); // Set loading to false if validation fails
            return false;
        }
        const payload = {
            name,
            // email,
            spaces_folder: name,
            doc_encryption_variable,
            domain_name: this.state.domains.map(domain => domain.api),
            ip_address: this.state.ip_addresses,
            frontend_domain: this.state.domains.map(domain => domain.frontend)
        };

        axios.patch(baseURL + "/api/tenant/" + id, payload, config)
            .then((response) => {
                showToast("Success", "The tenant updated successfully.", "success");
                this.setState({ redirect: "/propertyappz/tenants/list", loading: false }); // Set loading to false on success
            })
            .catch((error) => {
                showToast("Failed", error.response.data.message, "error");
                this.setState({ loading: false }); // Set loading to false on error
            });
    }
    showToast(title, message, toastType) {
        //position class
        var positionClass = "toast-top-right";
        toastr.options = {
            positionClass: positionClass,
            timeOut: this.state.timeOut,
            extendedTimeOut: this.state.extendedTimeOut,
            showEasing: this.state.showEasing,
            hideEasing: this.state.hideEasing,
            showMethod: this.state.showMethod,
            hideMethod: this.state.hideMethod,
            showDuration: this.state.showDuration,
            hideDuration: this.state.hideDuration,
        };

        // setTimeout(() => toastr.success(`Settings updated `), 300)
        //Toaster Types
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }
    handleFrontendChange = (index, value) => {
        const domains = [...this.state.domains];
        domains[index].frontend = value;
        this.setState({ domains });
    }

    handleApiChange = (index, value) => {
        const domains = [...this.state.domains];
        domains[index].api = value;
        this.setState({ domains });
    }

    addDomain = () => {
        this.setState(prevState => ({
            domains: [...prevState.domains, { frontend: '', api: '' }]
        }));
    }

    removeDomain = index => {
        const domains = [...this.state.domains];
        domains.splice(index, 1);
        this.setState({ domains });
    }

    handleIPChange = (index, value) => {
        const ip_addresses = [...this.state.ip_addresses];
        ip_addresses[index] = value;
        this.setState({ ip_addresses });
    };

    addIP = () => {
        this.setState(prevState => ({
            ip_addresses: [...prevState.ip_addresses, '']
        }));
    };

    removeIP = index => {
        const ip_addresses = [...this.state.ip_addresses];
        ip_addresses.splice(index, 1);
        this.setState({ ip_addresses });
    };

    render() {
        //meta title
        document.title = "Add Tenant";
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Tenants" breadcrumbItem="Edit Tenant" parentLink="/propertyappz/tenants/list" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Form onSubmit={this.handleSubmit}>
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="name">
                                                            Name*
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="name"
                                                                name="name"
                                                                placeholder="Name"
                                                                type="text"
                                                                className={"form-control"}
                                                                value={this.state.name}
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="doc_encryption_variable">
                                                            Document Encryption Variable*
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="doc_encryption_variable"
                                                                name="doc_encryption_variable"
                                                                placeholder="Document Encryption Variable"
                                                                type="text"
                                                                className={"form-control"}
                                                                value={this.state.doc_encryption_variable}
                                                                onChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="spaces_folder">
                                                            Spaces Folder*
                                                        </Label>
                                                        <div className="col-md-10">
                                                        <Label className="col-md-12 col-form-label" htmlFor="spaces_folder">
                                                            {this.state.name}
                                                        </Label>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <Card>
                                                        <CardTitle className="mb-2">Domains*</CardTitle>
                                                        <CardBody className="border border-primary">
                                                            {this.state.domains.map((domain, index) => (
                                                                <div key={index}>
                                                                    <FormGroup className="mb-3 row d-flex">
                                                                        <Label className="col-md-2 col-form-label">Frontend Domain {index + 1}</Label>
                                                                        <div className="col-md-8">
                                                                            <Input
                                                                                type="text"
                                                                                value={domain.frontend}
                                                                                onChange={e => this.handleFrontendChange(index, e.target.value)}
                                                                            />
                                                                        </div>
                                                                        <div className="col-md-2">
                                                                            <Button color="danger" onClick={() => this.removeDomain(index)}>Remove</Button>
                                                                        </div>
                                                                    </FormGroup>
                                                                    <FormGroup className="mb-3 row d-flex">
                                                                        <Label className="col-md-2 col-form-label">API Domain {index + 1}</Label>
                                                                        <div className="col-md-8">
                                                                            <Input
                                                                                type="text"
                                                                                value={domain.api}
                                                                                onChange={e => this.handleApiChange(index, e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                </div>
                                                            ))}
                                                            <Button color="primary" onClick={this.addDomain}>Add Domain</Button>
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <Card>
                                                        <CardTitle className="mb-2">IP Addresses*</CardTitle>
                                                        <CardBody className="border border-primary">
                                                            {this.state.ip_addresses.map((ip, index) => (
                                                                <FormGroup key={index} className="mb-3 row d-flex">
                                                                    <Label className="col-md-2 col-form-label">IP Address {index + 1}</Label>
                                                                    <div className="col-md-8">
                                                                        <Input
                                                                            type="text"
                                                                            value={ip}
                                                                            onChange={e => this.handleIPChange(index, e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <Button color="danger" onClick={() => this.removeIP(index)}>Remove</Button>
                                                                    </div>
                                                                </FormGroup>
                                                            ))}
                                                            <Button color="primary" onClick={this.addIP}>Add IP Address</Button>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Button color="success" type="submit" className="float-end" disabled={this.state.loading}>
                                                {this.state.loading ? 'Loading...' : 'Update'}
                                            </Button>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default EditTenant;
