import axios from "axios";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
// const baseURL = secureLocalStorage.getItem("baseURL");
export const get_all_tenants = async () => {
    let response = await axios.get(baseURL + "/api/tenant", config);
    return response.data.data;
}
export const get_single_tenant = async (tenant_id) => {
    let response = await axios.get(baseURL + "/api/tenant/" + tenant_id, config);
    return response.data.data;
}
export const update_tenant = async (tenant_id, payload) => {
    let response = await axios.patch(baseURL + "/api/tenant/" + tenant_id, payload, config);
    return response.data.data;
}
export const delete_tenant = async (tenant_id) => {
    let response = await axios.delete(baseURL + "/api/tenant/" + tenant_id, config);
    return response.data.data;
}
export const get_all_tenants_logs = async () => {
    let response = await axios.get(baseURL + "/api/tenant-activity-log", config);
    return response.data.data;
}
export const get_all_single_tenant_logs = async (tenant_id) => {
    let response = await axios.get(baseURL + "/api/tenant-activity-log/" + tenant_id, config);
    return response.data.data;
}