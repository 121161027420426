import axios from "axios";

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

export const get_all_document_types = async () => {
    let response = await axios.get(baseURL + "/api/document-type", config);
    return response.data.data;
}
export const update_document_types = async (document_type_id,name) => {
    let response = await axios.patch(baseURL + "/api/document-type/"+document_type_id,{
        "document_type_name": name,
      }, config);
    return response.data.data;
}
export const delete_document_type = async (document_type_id) => {
    let response = await axios.delete(baseURL + "/api/document-type/"+document_type_id, config);
    return response.data.data;
}