import React from "react";
import { useFieldArray } from "react-hook-form";

let blockCount = 1;
export default function BlockArray({ nestIndex, control, register }) {
    const { fields, remove, append, swap } = useFieldArray({
        control,
        name: `phases.${nestIndex}.blocks`
    });

    return (
        <div>
            {fields.map((item, index) => {
                const isEditable = item.has_block_property
                return (
                    <div key={item.id} style={{ marginLeft: 20 }}>
                        <label
                            htmlFor="example-text-input"
                            className="col-md-2 col-form-label"
                        >
                            Block
                        </label>
                        <input placeholder="Enter Block" className="form-control"
                            {...register(`phases.${nestIndex}.blocks.${index}.name`, {
                            })}
                            style={{ marginRight: "25px" }}
                            disabled={isEditable}
                        />
                        <input placeholder="Enter Block" className="form-control" type="hidden" value={1}
                            {...register(`phases.${nestIndex}.blocks-order.${index}.name`, {
                            })}
                            style={{ marginRight: "25px" }}
                        />
                        <button type="button" color="danger" disabled={isEditable}
                            className="btn btn-danger mt-2 mb-2" onClick={() => remove(index)}>
                            Delete Block
                        </button>
                        {index + 1 >= fields.length ?
                            <>
                                {index + 1 == fields.length ? <>
                                    {index >= 1 ?
                                        <button color="primary"
                                            className="btn btn-primary ms-1 mt-2 mb-2" type="button" onClick={() => swap(index - 1, index)}>
                                            Up
                                        </button>
                                        :
                                        <></>
                                    }


                                </>
                                    :
                                    <>
                                        <button color="primary"
                                            className="btn btn-primary ms-1 mt-2 mb-2" type="button" onClick={() => swap(index, index + 1)}>
                                            Down
                                        </button>
                                    </>
                                }
                            </>
                            :
                            <>
                                {index >= 1 ?
                                    <button color="primary"
                                        className="btn btn-primary ms-1 mt-2 mb-2" type="button" onClick={() => swap(index - 1, index)}>
                                        Up
                                    </button>
                                    :
                                    <></>
                                }

                                <button color="primary"
                                    className="btn btn-primary ms-1 mt-2 mb-2" type="button" onClick={() => swap(index, index + 1)}>
                                    Down
                                </button>
                            </>
                        }
                    </div>
                );
            })}

            <button
                type="button"
                color="success"
                className="btn btn-success ms-3"
                onClick={() => {
                    append({ name: "", id: 0 });
                    blockCount++;
                }}
            >
                Add Block
            </button>

            <hr />
        </div>
    );
};
