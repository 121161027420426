import React, { Component } from "react"
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import Features from "./Features"
import { update_project_feature } from '../../api/projectApi';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
const defaultValues = {
    project_id: '',
    features: []
};

function EditProjectFeature() {
    const {
        control,
        register,
        handleSubmit,
        getValues,
        errors,
        reset,
        setValue
    } = useForm({
        defaultValues
    });
    const onSubmit = (data) => {
            var myData = []
            for (var i = 0; i < data.features.length; i++) {
                // console.log(data.features[i]?.feature_name);
                if (data.features[i].feature != undefined) {
                    myData.push({ "feature_id": data.features[i].feature.feature_id, "percentage_value": data.features[i].percentage_value, "id": data.features[i].id })
                } else {
                    myData.push({ "feature_id": data.features[i].feature_id, "percentage_value": data.features[i].percentage_value, "id": data.features[i].id })
                }
            }
            var project_id = localStorage.getItem('current_project_id')
            console.log(myData);
            var toastTitle = "Failed";
            var toastMessage = "Please fill the required fields.";
            var toastType = "error";
            for (var i = 0; i < myData.length; i++) {
                if (myData[i].feature_id == '' || myData[i].percentage_value == '') {
                    showToast(toastTitle, toastMessage, toastType);
                    return false;
                }
            }
            Promise.all([update_project_feature(myData, project_id)]).then((values) => {
                var toastTitle = "Success";
                var toastMessage = "Feature Updated Successfully";
                var toastType = "success";
                showToast(toastTitle, toastMessage, toastType);
                console.log(values[0]);
                handleClick();
            }).catch((error) => {
                var toastTitle = "Failed";
                var toastMessage = "Not Updated";
                var toastType = "error";
                showToast(toastTitle, toastMessage, toastType);
                console.log(error);
            });
    }
    function showToast(title, message, toastType) {
        //position class
        var positionClass = "toast-top-right";
        toastr.options = {
            positionClass: positionClass,
            timeOut: 5000,
            extendedTimeOut: 1000,
            showEasing: "swing",
            hideEasing: "linear",
            showMethod: "fadeIn",
            hideMethod: "fadeOut",
            showDuration: 300,
            hideDuration: 1000,
        };

        // setTimeout(() => toastr.success(`Settings updated `), 300)
        //Toaster Types
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
    }
    let history = useHistory();

    function handleClick() {
        // history.push("/projects/currencies?section=6");
        history.push("/projects/edit-currencies");
        // history.push("/projects/add?section=1");
    }
    return (
        <Row>
            <Col xs="12">
                <Card>
                    <CardBody style={{ minHeight: "450px", paddingTop: "100px" }}>
                        <h5>Project Features</h5>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <Features
                                {...{ control, register, defaultValues, getValues, setValue, errors }}
                            />
                            <button style={{ float: "right" }} onClick={handleSubmit(onSubmit)} color="primary"
                                className="btn btn-primary">Next</button>
                                <br/><br/><br/><br/>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row >

    )
}


export default EditProjectFeature
