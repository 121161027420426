import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { send_email_controller } from "helpers/custom_helper";
import { showToast } from "pages/UI/ui-toast";
class SendEmailModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    handleSendEmail = async (id, flag, payload) => {
        Promise.all([send_email_controller(id, flag, payload)])
            .then((values) => {
                showToast("Success", "Email sent successfully.", "success");
                this.props.onCloseClick();
            }).catch((error) => {
                showToast("Failed", "Email has not been sent successfully. " + error.response.data.message, "error");
            });
    }
    render() {
        return (
            <React.Fragment>
                <Modal size="sm" isOpen={this.props.show} toggle={this.props.onCloseClick} centered={true}>
                    <div className="modal-content">
                        <ModalBody className="px-4 py-5 text-center">
                            <button type="button" onClick={this.props.onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
                            <div className="avatar-sm mb-4 mx-auto">
                                <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                                    <i className="mdi mdi-send"></i>
                                </div>
                            </div>
                            <p className="text-muted font-size-16 mb-4">Do you want to proceed with the email now?</p>

                            <div className="hstack gap-2 justify-content-center mb-0">
                                <button type="button" className="btn btn-success" onClick={() => this.handleSendEmail(this.props.id, this.props.flag, this.props.payload)}>Yes, Send</button>
                                <button type="button" className="btn btn-danger" onClick={this.props.onCloseClick}>No, Cancel</button>
                            </div>
                        </ModalBody>
                    </div>
                </Modal>

            </React.Fragment>
        );
    }
}
SendEmailModal.propTypes = {
    onCloseClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    show: PropTypes.any,
};
export default SendEmailModal;
