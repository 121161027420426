import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import axios from "axios";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
//common
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
class AddLogType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "",
            redirect: null
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeValidation = this.onChangeValidation.bind(this);
    }
    handleSubmit(e) {
        e.preventDefault();
        const modifiedV = { ...this.state.validation };
        if (stateV === "") {
            modifiedV["stateV"] = false;
        } else {
            modifiedV["stateV"] = true;
        }
        this.setState({ validation: modifiedV });
    }
    //for change tooltip display propery
    onChangeValidation(fieldName, value) {
        const modifiedV = { ...this.state.validation };
        if (value !== "") {
            modifiedV[fieldName] = true;
        } else {
            modifiedV[fieldName] = false;
        }
        this.setState({ validation: modifiedV });
    }
    showToast(title, message, toastType) {
        //position class
        var positionClass = "toast-top-right";
        toastr.options = {
            positionClass: positionClass,
            timeOut: this.state.timeOut,
            extendedTimeOut: this.state.extendedTimeOut,
            showEasing: this.state.showEasing,
            hideEasing: this.state.hideEasing,
            showMethod: this.state.showMethod,
            hideMethod: this.state.hideMethod,
            showDuration: this.state.showDuration,
            hideDuration: this.state.hideDuration,
        };
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
    }
    render() {
        //meta title
        document.title = "Add Log Type";
        const { validation } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Log Types" breadcrumbItem="Add Log Type" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                logTypeName: (this.state && this.state.logTypeName) || "",
                                            }}
                                            validationSchema={Yup.object().shape({
                                                logTypeName: Yup.string().required(
                                                    "Please Enter Your Log Type Name"
                                                ),
                                            })}
                                            onSubmit={values => {
                                                axios.post(baseURL + "/api/log-types", {
                                                    "name": values.logTypeName,
                                                }, config)
                                                    .then((response) => {
                                                        var toastTitle = "Success";
                                                        var toastMessage = "The log type has been added successfully.";
                                                        var toastType = "success";
                                                        this.showToast(toastTitle, toastMessage, toastType);
                                                        this.setState({ redirect: "/catalogue/log-type/list" });
                                                    })
                                                    .catch((error) => {
                                                        var toastTitle = "Failed";
                                                        var toastMessage = "Log Type Not Added";
                                                        var toastType = "error";
                                                        this.showToast(toastTitle, toastMessage, toastType);
                                                    });
                                            }}
                                        >
                                            {({ errors, status, touched }) => (
                                                <Form className="needs-validation">
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="logTypeName">
                                                                    Log Type Name
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="logTypeName"
                                                                        placeholder="Log Type Name"
                                                                        type="text"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.logTypeName && touched.logTypeName
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="logTypeName"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Button color="primary" type="submit" className="float-end">
                                                        Add Log Type
                                                    </Button>
                                                </Form>
                                            )}
                                        </Formik>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}
export default AddLogType;