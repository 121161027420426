// remove old import of secureLocalStorage "react-secure-storage";
import { sale_document_mail, send_payment_summary_email_against_sale, send_statement_of_account_email_against_sale } from '../pages/Sales/api/salesApi';
import { send_all_overdue_payments_emails, send_selected_overdue_payments_emails } from '../pages/Reports/api/reportsApi';
import { payment_invoice_mailm, payment_confirm_mail } from '../pages/Payments/api/paymentsApi'
import axios from "axios";
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
};
const baseURL_propertyAppz = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
export function get_user_permissions(key) {
    var userData = secureLocalStorage.getItem("userData")
    return userData?.data[key];
}
export function get_all_permissions() {
    var userData = secureLocalStorage.getItem("userData")
    return userData?.data;
}
export const send_email_controller = async (id, flag, payload) => {
    var response;
    switch (flag) {
        case "email_welcome_pack":
            response = await sale_document_mail(id);
            return response;
        case "Payment Summary":
            response = await send_payment_summary_email_against_sale(id);
            return response;
        case "Statement Of Accounts":
            response = await send_statement_of_account_email_against_sale(id);
            return response;
        case "Send All Overdue Payment Email":
            response = await send_all_overdue_payments_emails(payload);
            return response;
        case "Send Selected Overdue Payment Email":
            response = await send_selected_overdue_payments_emails(payload);
            return response;
        case "Send Invoice Email":
            response = await payment_invoice_mail(id);
            return response;
        case "Send Receipt Email":
            response = await payment_confirm_mail(id);
            return response;
        default:
            break;
    }
}
export const getBaseURL = async (current_url) => {
    try {
        const res = await axios.post(`${baseURL_propertyAppz}/api/get-base-url`, {
            url: current_url
        }, config);

        if (res.status === 200) {
            console.log(res.data.data);
            const current_base_url = res.data.data.base_url;
            secureLocalStorage.setItem('baseURL', current_base_url);
        }
    } catch (error) {
        console.error('Error fetching base URL:', error);
    }
};