import React, { Component } from "react"
import {
  Card,
  CardBody,
  Label, Input, Button,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { showToast } from '../../UI/ui-toast';
import Select from "react-select";

const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

export default class AddCustomerForm extends Component {
  constructor(props) {
    const {getOptionsCustomers,tog_customer_modal} = props;
    super(props);
    this.state = {
      validation: "",
      optionGroupCountry: [],
      optionGroupCity: [],
      optionGroupNationalities: [],
      optionGroupIdentity: [],
      c_picture: null,
      redirect: null,
      titlesArray: constant.titles_list,
      id: "",
      name: '',
      c_title: '',
      c_first_name: '',
      c_middle_name: '',
      c_last_name: '',
      c_identity_number: '',
      c_father_name: '',
      c_husband_name: '',
      c_address_line_1: '',
      c_address_line_2: '',
      c_telephone_home: '',
      c_telephone_office: '',
      c_mobile_number: '',
      c_postcode: '',
      c_primary_email: '',
      c_secondary_email: '',
      c_country_id: '',
      c_city_id: '',
      c_identity_type: '',
      c_nationality: '',
      countrySelected: -1,
      citySelected: -1,
      titleSelected: -1,
    };
    this.handleCustomerSubmit = this.handleCustomerSubmit.bind(this);
    //this.onChangeValidation = this.onChangeValidation.bind(this);
  }

  async getOptionsCountry() {
    const res = await axios.get(baseURL + "/api/countries-listing", config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.title
    }))
    this.setState({ optionGroupCountry: options });
    this.setState({ optionGroupCity: [] });
  }
  async getOptionsCity(country_id) {
    const res = await axios.get(baseURL + "/api/country-cities/" + country_id, config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.title
    }))
    this.setState({ optionGroupCity: options })
  }
  async getOptionsNationalities() {
    const res = await axios.get(baseURL + "/api/nationalities", config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.name
    }))
    this.setState({ optionGroupNationalities: options })
  }
  async getOptionsIdentity() {
    const res = await axios.get(baseURL + "/api/identity-types", config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d,
      "label": d
    }))
    this.setState({ optionGroupIdentity: options })
  }

  componentDidMount() {
    console.log(this.props);
    this.getOptionsCountry()
    this.getOptionsNationalities()
    this.getOptionsIdentity()
  }

  checkValidation = () => {
    if (this.state.citySelected != 1) {
      this.setState({ citySelected: 0 })
    }
    if (this.state.countrySelected != 1) {
      this.setState({ countrySelected: 0 })
    }
    if (this.state.titleSelected != 1) {
      this.setState({ titleSelected: 0 })
    }
  }
  /*handleSelectedCustomerTitle = selectedCustomerTitle => {
    this.setState({ selectedCustomerTitle })
    console.log(selectedCustomerTitle);
  }*/

  handleSelectedCustomerTitle = selectedCustomerTitle => {
    this.setState({titleSelected:1})
    this.setState({ c_title: selectedCustomerTitle.value });
    this.setState({ selectedCustomerTitle })
    console.log(selectedCustomerTitle);
  }

  handleSelectGroupCountry = selectedGroupCountry => {
    this.setState({countrySelected:1})
    this.setState({ c_country_id: selectedGroupCountry.value });
    this.setState({ selectedGroupCountry })
    this.getOptionsCity(selectedGroupCountry.value)
    console.log(selectedGroupCountry);
  }
  handleSelectGroupCity = selectedGroupCity => {
    this.setState({citySelected:1})
    this.setState({ c_city_id: selectedGroupCity.value });
    this.setState({ selectedGroupCity })
    console.log(selectedGroupCity);
  }
  handleSelectGroupNationalities = selectedGroupNationalities => {
    this.setState({ c_nationality: selectedGroupNationalities.label });
    this.setState({ selectedGroupNationalities })
    console.log(selectedGroupNationalities);
  }
  handleSelectGroupIdentity = selectedGroupIdentity => {
    this.setState({ c_identity_type: selectedGroupIdentity.label });
    this.setState({ selectedGroupIdentity })
    console.log(selectedGroupIdentity);
  }

  submitAddCustomer(){
    var currentFrm = document.getElementById('frmAddCustomer');

    currentFrm.submit();
  }


  handleCustomerSubmit(e) {
    e.preventDefault();
    var validationCheck = 1;
    console.log(e.target.chkCustomerForm.value);
    if(this.state.c_title == "" || e.target.customerFirstName.value == "" || e.target.customerLastName.value == "" 
      || e.target.addresslineOne.value == "" || e.target.mobileNumber.value == "" || e.target.postcode.value == ""
      || e.target.primaryEmail.value == "" || this.state.c_country_id == "" || this.state.c_city_id == ""){
      e.target.customerFirstName.focus();
      e.target.customerLastName.focus();
      e.target.addresslineOne.focus();
      e.target.mobileNumber.focus();
      e.target.postcode.focus();
      e.target.primaryEmail.focus();
      validationCheck = 0;
    }
    
    console.log(this.state.c_country_id);
    console.log(this.state.c_city_id);
    
    if(validationCheck == 1){
      document.getElementById('chkCustomerForm').value = 1;
    }
    if(e.target.chkCustomerForm == undefined){
      return false;
    }
    console.log(e.target.chkCustomerForm.value);
    axios.post(baseURL + "/api/customers/", {
      "title": this.state.c_title,
      "first_name": e.target.customerFirstName.value,
      "middle_name": e.target.customerMiddleName.value,
      "last_name": e.target.customerLastName.value,
      "identity_type": this.state.c_identity_type,
      "identity_number": e.target.identityNumber.value,
      "father_name": e.target.fatherName.value,
      "husband_name": e.target.husbandName.value,
      "nationality": this.state.c_nationality,
      "organisation_id": window.localStorage.getItem("organisation_id"),
      "address_line_1": e.target.addresslineOne.value,
      "address_line_2": e.target.addresslineTwo.value,
      "country_id": this.state.c_country_id,
      "city_id": this.state.c_city_id,
      // "city_title": selectedGroupCity.label,
      // "country_title": selectedGroupCountry.label,
      "postcode": e.target.postcode.value,
      "telephone_home": e.target.phoneHome.value,
      "telephone_office": e.target.phoneOffice.value,
      "mobile_number": e.target.mobileNumber.value,
      "primary_email": e.target.primaryEmail.value,
      "secondary_email": e.target.secondaryEmail.value,
      "picture_path": this.state.c_picture
    }, config)
    .then((response) => {
      showToast("Failed", "The customer has been added successfully.", "success");
      // this.setState({ redirect: "/customers/list" });
      // localStorage.removeItem("current_customer");
      // window.location.replace('/customers/list');
      console.log(response);
      console.log(this.props);
      // this.props.getOptionsCustomers;
      // this.props.tog_customer_modal;
    })
    .catch((error) => {
      console.log(error);
      var errorsObj = error.response.data.errors;
      var errorMsg = "";
      if(errorsObj != undefined){
        if(Object.keys(errorsObj).length > 0){
          Object.keys(errorsObj).forEach(eKey => {
            console.log(errorsObj[eKey]);
            errorMsg = errorMsg+'<br />&#10003; ' + errorsObj[eKey];
          });
        }
      }
      else{
        var errorMsg = errorMsg+'<br />&#10003; ' + error.response.data.message;
      }
      showToast("Failed", "Customer Not Added." + (errorMsg==""?'':errorMsg), "error");
    });

  }

  render() {

    const { validation } = this.state;
    const { selectedCustomerTitle } = this.state;
    const { selectedGroupCountry } = this.state;
    const { selectedGroupNationalities } = this.state;
    const { selectedGroupIdentity } = this.state;
    const { selectedGroupCity } = this.state;

    return (
      <Card>
        <CardBody>
        <Formik
          enableReinitialize={true}
          initialValues={{
            customerFirstName: (this.state && this.state.c_first_name) || "",
            customerLastName: (this.state && this.state.c_last_name) || "",
            addresslineOne: (this.state && this.state.c_address_line_1) || "",
            mobileNumber: (this.state && this.state.c_mobile_number) || "",
            postcode: (this.state && this.state.c_postcode) || "",
            primaryEmail: (this.state && this.state.c_primary_email) || "",
          }}
          validationSchema={Yup.object().shape({
            customerFirstName: Yup.string().required(
              "Customer first name is required!"
            ),
            customerLastName: Yup.string().required(
              "Customer last name is required!"
            ),

            addresslineOne: Yup.string().required(
              "Please Enter Your Address Line 1"
            ),
            mobileNumber: Yup.string().required(
              "Please Enter Your Mobile Number"
            ),
            postcode: Yup.string().required("Please Enter Your postcode"),
            primaryEmail: Yup.string().required("Please Enter Your Email"),
          })}
          // onSubmit={values => {
          //   console.log(values);
          //   axios.post(baseURL + "/api/customers", {

          //     "title": selectedCustomerTitle.value,
          //     "first_name": values.customerFirstName,
          //     "middle_name": values.customerMiddleName,
          //     "last_name": values.customerLastName,
          //     "identity_type": selectedGroupIdentity.label,
          //     "identity_number": values.identityNumber,
          //     "father_name": values.fatherName,
          //     "husband_name": values.husbandName,
          //     "nationality": selectedGroupNationalities.label,
          //     "organisation_id": window.localStorage.getItem("organisation_id"),
          //     "address_line_1": values.addresslineOne,
          //     "address_line_2": values.addresslineTwo,
          //     "city_id": selectedGroupCity.value,
          //     "city_title": selectedGroupCity.label,
          //     "country_id": selectedGroupCountry.value,
          //     "country_title": selectedGroupCountry.label,
          //     "postcode": values.postcode,
          //     "telephone_home": values.phoneHome,
          //     "telephone_office": values.phoneOffice,
          //     "mobile_number": values.mobileNumber,
          //     "primary_email": values.primaryEmail,
          //     "secondary_email": values.secondaryEmail,
          //     "picture_path": this.state.c_picture
          //   }, config)
          //     .then((response) => {
          //       showToast("Customer", "The customer has been added successfully.", "success");
          //       //this.setState({ redirect: "/customers/list" });
          //       console.log(response);
          //       this.getOptionsCustomers();
          //       this.tog_standard;
          //     })
          //     .catch((error) => {
          //       showToast("Customer", "Customer Not Added.", "error")
          //       console.log(error);
          //     });
          // }}
        >
        {({ errors, status, touched }) => (
        <Form className="needs-validation" onSubmit={this.handleCustomerSubmit} id="frmAddCustomer">
          <Field
            id="chkCustomerForm"
            name="chkCustomerForm"
            value="0"
            type="hidden"
          />
          <div className="mb-3 row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Title*
            </label>
            <div className="col-md-10">
              <Select
                value={selectedCustomerTitle}
                onChange={this.handleSelectedCustomerTitle}
                options={this.state.titlesArray}
                classNamePrefix="select2-selection"
              
              />
                    {this.state.titleSelected == 0 ? <>
                      <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Title is not selected, please select first</p>
                    </> : <></>
                    }
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="customerFirstName"
              className="col-md-2 col-form-label"
            >
              First Name*
            </label>
            <div className="col-md-10">
              <Field
                name="customerFirstName"
                placeholder="First Name"
                value={this.state.c_first_name}
                type="text"
                onChange={(e) => this.setState({ c_first_name: e.target.value })}
                className={
                  "form-control" +
                  (errors.customerFirstName && touched.customerFirstName
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                name="customerFirstName"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="customerMiddleName"
              className="col-md-2 col-form-label">
              Middle Name
            </label>
            <div className="col-md-10">
              <Field
                name="customerMiddleName"
                placeholder="Middle Name"
                type="text"
                className={"form-control"}
                value={this.state.c_middle_name}
                onChange={(e) => this.setState({ c_middle_name: e.target.value })}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="customerLastName"
              className="col-md-2 col-form-label">
              Last Name*
            </label>
            <div className="col-md-10">
              <Field
                name="customerLastName"
                placeholder="Last Name"
                type="text"
                onChange={(e) => this.setState({ c_last_name: e.target.value })}
                value={this.state.c_last_name}
                className={
                  "form-control" +
                  (errors.customerLastName && touched.customerLastName
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                name="customerLastName"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label className="col-md-2 col-form-label" htmlFor="selectIdentity">Identity Type</label>
            <div className="col-md-10">
              <Select
                value={selectedGroupIdentity}
                onChange={this.handleSelectGroupIdentity}
                options={this.state.optionGroupIdentity}
                classNamePrefix="select2-selection"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="identityNumber"
              className="col-md-2 col-form-label">
              Identity Number
            </label>
            <div className="col-md-10">
              <Field
                name="identityNumber"
                placeholder="Identity Number"
                type="text"
                className={"form-control"}
                onChange={(e) => this.setState({ c_identity_number: e.target.value })}
                value={this.state.c_identity_number}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="fatherName"
              className="col-md-2 col-form-label"
            >
              Father Name 
            </label>
            <div className="col-md-10">
              <Field
                name="fatherName"
                placeholder="Father Name"
                type="text"
                className={"form-control"}
                onChange={(e) => this.setState({ c_father_name: e.target.value })}
                value={this.state.c_father_name}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="husbandName"
              className="col-md-2 col-form-label"
            >
              Husband Name 
            </label>
            <div className="col-md-10">
              <Field
                name="husbandName"
                placeholder="Husband Name"
                type="text"
                className={"form-control"}
                onChange={(e) => this.setState({ c_husband_name: e.target.value })}
                value={this.state.c_husband_name}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label"
            >
              Nationality 
            </label>
            <div className="col-md-10">
              <Select
                value={selectedGroupNationalities}
                onChange={this.handleSelectGroupNationalities}
                options={this.state.optionGroupNationalities}
                classNamePrefix="select2-selection"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="addresslineOne"
              className="col-md-2 col-form-label">
              Address Line 1*
            </label>
            <div className="col-md-10">
              <Field
                name="addresslineOne"
                placeholder="Address Line 1"
                type="text"
                onChange={(e) => this.setState({ c_address_line_1: e.target.value })}
                value={this.state.c_address_line_1}
                className={
                  "form-control" +
                  (errors.addresslineOne && touched.addresslineOne
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                name="addresslineOne"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="addresslineTwo"
              className="col-md-2 col-form-label">
              Address Line 2
            </label>
            <div className="col-md-10">
              <Field
                name="addresslineTwo"
                placeholder="Address Line 2"
                type="text"
                className={"form-control"}
                onChange={(e) => this.setState({ c_address_line_2: e.target.value })}
                value={this.state.c_address_line_2}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="selectCountry"
              className="col-md-2 col-form-label"
            >
              Country Select*
            </label>
            <div className="col-md-10">
              <Select
                value={selectedGroupCountry}
                onChange={this.handleSelectGroupCountry}
                options={this.state.optionGroupCountry}
                classNamePrefix="select2-selection"
              />
                    {this.state.countrySelected == 0 ? <>
                      <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Country is not selected, please select first</p>
                    </> : <></>
                    }
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="selectCity"
              className="col-md-2 col-form-label"
            >
              City Select*
            </label>
            <div className="col-md-10">
              <Select
                value={selectedGroupCity}
                onChange={this.handleSelectGroupCity}
                options={this.state.optionGroupCity}
                classNamePrefix="select2-selection"
              />
                    {this.state.citySelected == 0 ? <>
                      <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> City is not selected, please select first</p>
                    </> : <></>
                    }
            </div>
          </div>
          <div className="mb-3 row">
            <Label className="col-md-2 col-form-label" htmlFor="postcode">Postcode*</Label>
            <div className="col-md-10">
              <Field
                name="postcode"
                placeholder="Postcode"
                type="text"
                onChange={(e) => this.setState({ c_postcode: e.target.value })}
                value={this.state.c_postcode}
                className={
                  "form-control" +
                  (errors.postcode && touched.postcode
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                name="postcode"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="phoneHome"
              className="col-md-2 col-form-label"
            >
              Phone Home
            </label>
            <div className="col-md-10">
              <Field
                name="phoneHome"
                placeholder="Phone Home"
                type="text"
                className={"form-control"}
                onChange={(e) => this.setState({ c_telephone_home: e.target.value })}
                value={this.state.c_telephone_home}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="phoneOffice"
              className="col-md-2 col-form-label"
            >
              Phone Office
            </label>
            <div className="col-md-10">
              <Field
                name="phoneOffice"
                placeholder="Phone Office"
                type="text"
                className={"form-control"}
                onChange={(e) => this.setState({ c_telephone_office: e.target.value })}
                value={this.state.c_telephone_office}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="mobileNumber"
              className="col-md-2 col-form-label"
            >
              Mobile Number*
            </label>
            <div className="col-md-10">
              <Field
                name="mobileNumber"
                placeholder="Mobile Number"
                type="text"
                onChange={(e) => this.setState({ c_mobile_number: e.target.value })}
                value={this.state.c_mobile_number}
                className={
                  "form-control" +
                  (errors.mobileNumber && touched.mobileNumber
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                name="mobileNumber"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="primaryEmail" className="col-md-2 col-form-label">Primary Email*</label>
            <div className="col-md-10">
              <Field
                name="primaryEmail"
                placeholder="Primary Email"
                type="text"
                onChange={(e) => this.setState({ c_primary_email: e.target.value })}
                value={this.state.c_primary_email}
                className={
                  "form-control" +
                  (errors.primaryEmail && touched.primaryEmail
                    ? " is-invalid"
                    : "")
                }
              />
              <ErrorMessage
                name="primaryEmail"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="secondaryEmail"
              className="col-md-2 col-form-label"
            >
              Secondary Email
            </label>
            <div className="col-md-10">
              <Field
                name="secondaryEmail"
                placeholder="Secondary Email"
                type="text"
                className={"form-control"}
                onChange={(e) => this.setState({ c_secondary_email: e.target.value })}
                value={this.state.c_secondary_email}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <Label className="col-md-2 col-form-label" htmlFor="picture">Picture</Label>
            <div className="col-md-10">
              <Input className="form-control form-control" type="file" id="inputPicture" accept="image/*" onChange={this.handleImageChangePicture} />
            </div>
          </div>
          <div className="mb-3 row">
            <div className="col-md-4"></div>
            <div className="col-md-8 text-end">
              <Button color="primary" className="btn btn-primary" onClick={this.checkValidation} type="submit">Add Customer</Button>
            </div>
          </div>
          </Form>
          )}
          </Formik>
        </CardBody>
      </Card>
    )
  }
}
