import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../../../../UI/ui-toast'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../../../../../components/Common/Breadcrumb";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `${token}`
    }
};
import constant from '../../../../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
class CloneInvoiceTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "",
            optionGroupProjects: [],
            redirect: null,
            projectSelected: -1,
            project_logo: null,
            company_logo: null,
            status_templates: constant.status_templates,
            statusSelected: -1,
            projectLogoSelected: -1,
            templatePrimaryColor: null,
            templatePrimaryColorSelected: -1,
            templateSecondaryColor: null,
            templateSecondaryColorSelected: -1,
        };
    }

    async getOptionsProjects() {
        const res = await axios.get(baseURL + "/api/project-list", config);
        const data = res.data.data;
        console.log(data);

        const options = data.map(d => ({
            "value": d.id,
            "label": d.name
        }))
        this.setState({ optionGroupProjects: options })
    }
    componentDidMount() {
        this.getOptionsProjects()
    }
    checkValidation = () => {
        if (this.state.projectSelected != 1) {
            this.setState({ projectSelected: 0 })
        }
        if (this.state.projectLogoSelected != 1) {
            this.setState({ projectLogoSelected: 0 })
        }
        if (this.state.statusSelected != 1) {
            this.setState({ statusSelected: 0 })
        }
        if (this.state.templatePrimaryColorSelected != 1) {
            this.setState({ templatePrimaryColorSelected: 0 })
        }
        if (this.state.templateSecondaryColorSelected != 1) {
            this.setState({ templateSecondaryColorSelected: 0 })
        }
    }
    componentDidUpdate() {
    }

    handleSelectGroupProject = selectedGroupProject => {
        this.setState({ selectedGroupProject })
        this.setState({ projectSelected: 1 })
    }
    handleProjectLogo = (e) => {
        if (e.target.files && e.target.files[0]) {
            console.log(e.target.files[0]);
            this.setState({ project_logo: e.target.files[0] });
            this.setState({ projectLogoSelected: 1 })
        }
    };
    handleCompanyLogo = (e) => {
        if (e.target.files && e.target.files[0]) {
            console.log(e.target.files[0]);
            this.setState({ company_logo: e.target.files[0] });
        }
    };
    handleSelectedStatusTemplate = selectedStatusTemplate => {
        this.setState({ statusSelected: 1 })
        this.setState({ selectedStatusTemplate })
    }
    render() {
        //meta title
        document.title = "Clone Template";
        const { validation } = this.state;
        const { selectedGroupProject } = this.state;
        const { selectedStatusTemplate } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Invoice" breadcrumbItem="Clone Template" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                name: (this.state && this.state.name) || "",
                                                address: (this.state && this.state.address) || "",
                                                phone: (this.state && this.state.phone) || "",
                                                mobile: (this.state && this.state.mobile) || "",
                                                email: (this.state && this.state.email) || "",
                                                website: (this.state && this.state.website) || "",
                                                company_reg_no: (this.state && this.state.company_reg_no) || "",
                                            }}
                                            validationSchema={Yup.object().shape({
                                                name: Yup.string().required(
                                                    "Template name is required!"
                                                ),
                                                address: Yup.string().required(
                                                    "Address is required!"
                                                ),
                                                phone: Yup.string().required(
                                                    "Phone number is required!"
                                                ),
                                                mobile: Yup.string().required(
                                                    "Mobile number is required!"
                                                ),
                                                email: Yup.string().required(
                                                    "Email is required!"
                                                ),
                                                website: Yup.string().required(
                                                    "Website is required!"
                                                ),
                                                company_reg_no: Yup.string().required(
                                                    "Company Registration Number is required!"
                                                ),
                                            })}
                                            onSubmit={values => {
                                                if (!this.state.templatePrimaryColor) {
                                                    let missingFields = '';
                                                    if (!this.state.templatePrimaryColor) {
                                                        missingFields += '<li>Primary Colour</li>';
                                                    }
                                                    showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
                                                    return false;
                                                }
                                                axios.post(baseURL + "/api/document-templates", {
                                                    'template_name': values.name,
                                                    'default_template_name': window.localStorage.getItem("current_sample_template_name"),
                                                    'project_logo': this.state.project_logo,
                                                    'company_logo': this.state.company_logo,
                                                    'address': values.address,
                                                    'phone': values.phone,
                                                    'mobile': values.mobile,
                                                    'email': values.email,
                                                    'website': values.website,
                                                    'primary_color': this.state.templatePrimaryColor,
                                                    'secondary_color': this.state.templateSecondaryColor,
                                                    'company_reg_no': values.company_reg_no,
                                                    'project_id': selectedGroupProject.value,
                                                    'status': selectedStatusTemplate.value,
                                                    'doc_sub_type': 'Invoices',
                                                }, config)
                                                    .then((response) => {
                                                        showToast("Success", "The template has been cloned successfully.", "success");
                                                        this.setState({ redirect: "/template/documents/financials/invoice/clone-list" });
                                                        console.log(response);
                                                    })
                                                    .catch((error) => {
                                                        showToast("Failed", "Template Not Cloned Successfully", "error")
                                                        console.log(error);
                                                    });
                                            }}
                                        >
                                            {({ errors, status, touched }) => (
                                                <Form className="needs-validation">
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="name">
                                                                    Template Name*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="name"
                                                                        placeholder="Template Name"
                                                                        type="text"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.name && touched.name
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="name"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="selectProject">Projects</Label>
                                                                <div className="col-md-10">
                                                                    <Select
                                                                        name="selectProject"
                                                                        value={selectedGroupProject}
                                                                        onChange={this.handleSelectGroupProject}
                                                                        options={this.state.optionGroupProjects}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                    {this.state.projectSelected == 0 ? <>
                                                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Project is not selected, please select first</p>
                                                                    </> : <></>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="project_logo">
                                                                    Project Logo*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Input className="form-control form-control" type="file" accept="image/*"
                                                                        onChange={this.handleProjectLogo} />
                                                                    {this.state.projectLogoSelected == 0 ? <>
                                                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Project Logo is not selected, please select first</p>
                                                                    </> : <></>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="company_logo">
                                                                    Company Logo
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Input className="form-control form-control" type="file" accept="image/*"
                                                                        onChange={this.handleCompanyLogo} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="company_reg_no">
                                                                Footer Details*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="company_reg_no"
                                                                        placeholder="Footer Details"
                                                                        type="text"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.company_reg_no && touched.company_reg_no
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="company_reg_no"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="address">
                                                                    Address*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="address"
                                                                        placeholder="Address"
                                                                        type="text"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.address && touched.address
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="address"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="phone">
                                                                    Phone*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="phone"
                                                                        placeholder="Phone"
                                                                        type="tell"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.phone && touched.phone
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="phone"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="mobile">
                                                                    Mobile*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="mobile"
                                                                        placeholder="Mobile"
                                                                        type="tell"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.mobile && touched.mobile
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="mobile"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="email">
                                                                    Email*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="email"
                                                                        placeholder="Email"
                                                                        type="email"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.email && touched.email
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="email"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="website">
                                                                    Website*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="website"
                                                                        placeholder="Website"
                                                                        type="text"
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.website && touched.website
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="website"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="color">
                                                                    Primary Colour*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="color"
                                                                        placeholder="Add Hexa"
                                                                        type="text"
                                                                        value={this.state.templatePrimaryColor}
                                                                        onChange={(e) => this.setState({ templatePrimaryColor: e.target.value, templatePrimaryColorSelected: 1 })}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                    {this.state.templatePrimaryColorSelected == 0 ? <>
                                                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Color is not selected, please select first</p>
                                                                    </> : <></>
                                                                    }
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        {this.state.templatePrimaryColor ? <>
                                                            <Col md="12">
                                                                <FormGroup className="mb-3 row d-flex">
                                                                    <Label className="col-md-2 col-form-label">

                                                                    </Label>
                                                                    <div className="col-md-10">
                                                                        <div style={{ backgroundColor: this.state.templatePrimaryColor, height: "40px", width: "40px" }}></div>
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                        </> : <></>}
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="color">
                                                                    Secondary Colour
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="color"
                                                                        placeholder="Add Hexa"
                                                                        type="text"
                                                                        value={this.state.templateSecondaryColor}
                                                                        onChange={(e) => this.setState({ templateSecondaryColor: e.target.value, templateSecondaryColorSelected: 1 })}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                    {/* {this.state.templateSecondaryColorSelected == 0 ? <>
                                                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Color is not selected, please select first</p>
                                                                    </> : <></>
                                                                    } */}
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        {this.state.templateSecondaryColor ? <>
                                                            <Col md="12">
                                                                <FormGroup className="mb-3 row d-flex">
                                                                    <Label className="col-md-2 col-form-label">

                                                                    </Label>
                                                                    <div className="col-md-10">
                                                                        <div style={{ backgroundColor: this.state.templateSecondaryColor, height: "40px", width: "40px" }}></div>
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                        </> : <></>}
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="statusTemplate">Status*</Label>
                                                                <div className="col-md-10">
                                                                    <Select
                                                                        value={selectedStatusTemplate}
                                                                        onChange={this.handleSelectedStatusTemplate}
                                                                        options={this.state.status_templates}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                    {this.state.statusSelected == 0 ? <>
                                                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Status is not selected, please select first</p>
                                                                    </> : <></>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Button color="primary" onClick={this.checkValidation} type="submit" className="float-end" >
                                                        Clone Template
                                                    </Button>
                                                </Form>
                                            )}
                                        </Formik>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default CloneInvoiceTemplate;
