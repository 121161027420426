import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../UI/ui-toast'

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get_current_salesagents } from '../api/salesAgentsApi';

import constant from '../../../constants.json';

const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

class ViewSalesAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      redirect: null,
      full_name: '',
      agent_title: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      telephone: '',
      mobile: '',
      email: '',
      picture: null,
      show_picture: null,
      id: "",
      name: '',
      current_salesagent_id: '',
      pictureSelected: -1,
    };

  }

  componentDidMount() {
    //const current_salesagent = window.localStorage.getItem("current_salesagent");
    Promise.all([get_current_salesagents(localStorage.getItem("current_salesagent"))]).then((values) => {
      this.setState({ full_name: values[0].full_name });
      this.setState({ agent_title: values[0].title });
      this.setState({ first_name: values[0].first_name });
      this.setState({ middle_name: values[0].middle_name });
      this.setState({ last_name: values[0].last_name });
      this.setState({ telephone: values[0].telephone });
      this.setState({ mobile: values[0].mobile });
      this.setState({ email: values[0].email });
      this.setState({ show_picture: values[0].picture });
      this.setState({ current_salesagent_id: localStorage.getItem("current_salesagent") });
      if(this.state.show_picture!=null){
        this.setState({pictureSelected:1})
      }
    });

  }

  render() {
    //meta title
    document.title = "View Sales Agent";
    const { validation } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Sales Agent" breadcrumbItem="View Sales Agent" parentLink="/salesagents/list" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                      <Row>
                        <Col md="12">
                          <div className="mb-3 row d-flex">
                            <Label className="col-md-2 col-form-label" htmlFor="agentTitle">Name:</Label>
                            <div className="mt-2 col-md-10">{this.state.full_name}</div>
                          </div>
                        </Col>
                        <Col md="12">
                          <div className="mb-3 row d-flex">
                            <Label className="col-md-2 col-form-label" htmlFor="telephone">Telephone</Label>
                            <div className="mt-2 col-md-10">{this.state.telephone}</div>
                          </div>
                        </Col>
                        <Col md="12">
                          <div className="mb-3 row d-flex">
                            <Label className="col-md-2 col-form-label" htmlFor="mobileNumber">Mobile Number</Label>
                            <div className="mt-2 col-md-10">{this.state.mobile}</div>
                          </div>
                        </Col>
                        <Col md="12">
                          <div className="mb-3 row d-flex">
                            <Label className="col-md-2 col-form-label" htmlFor="email">Email</Label>
                            <div className="mt-2 col-md-10">{this.state.email}</div>
                          </div>
                        </Col>
                        <Col md="12">
                          <div className="mb-3 row d-flex">
                            <Label className="col-md-2 col-form-label" htmlFor="picture">
                              Picture
                            </Label>
                            <div className="col-md-10">
                              {this.state.show_picture == null ?
                                <>No Picture Exists</>
                              :
                                <img className="rounded me-2" alt="200x200" width="150" src={this.state.show_picture} data-holder-rendered="true" />
                              }
                            </div>
                          </div>
                        </Col>
                      </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default ViewSalesAgent;
