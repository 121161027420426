import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Col, Container, Row, Alert, Label } from "reactstrap";
import { Route, Redirect } from "react-router-dom"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { showToast } from '../../UI/ui-toast';
// remove old import of secureLocalStorage "react-secure-storage";

// import images
import logodark from "../../../assets/images/logo-dark.png";
import logolight from "../../../assets/images/logo-light.png";
import propertyAppzWhite from "../../../assets/images/Property-Appz-White-Logo-v1.png";
import propertyAppzDark from "../../../assets/images/Property-Appz-Logo-v1.0.png";
import CarouselPage from "../child.components/CarouselPage";
import { getBaseURL } from "helpers/custom_helper";
const config = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
import { fetchLogo } from '../../../store/logo/actions';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
class LoginAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      redirect: null
    };
    this.t_show.bind(this)
  }
  t_show = () => {
    this.setState(prevState => ({
      show: !prevState.show,
    }))
  }

  async getAdminData(user_id) {
    let baseURL = secureLocalStorage.getItem("baseURL");
    const res = await axios.get(baseURL + "/api/platform-user-assign-permission/" + user_id, config);
    if (res.status === 200) {
      const data = res.data.data
      secureLocalStorage.setItem("userData", { data });
      // this.setState({ redirect: "/dashboard" });
      window.location.replace('/');
    }
  }
  async getOrganisationData(token) {
    let baseURL = secureLocalStorage.getItem("baseURL");
    const res = await axios.get(baseURL + "/api/organisation/show", config);
    const data = res.data.data;
    localStorage.setItem("organisation_id", data.id);
  }

  componentDidMount() {
    let current_url = window.location.href;
    getBaseURL(current_url)
    this.props.fetchLogo();
    // Organisation-User login flag
    localStorage.removeItem('organisationUserLoginFlag');
    // Admin-User/Platform-User login flag
    localStorage.removeItem('adminUserLoginFlag');
    localStorage.removeItem('token');
    localStorage.removeItem('organisation_id');
    secureLocalStorage.removeItem("userData");
    secureLocalStorage.removeItem('rememberProprtyAppzUsername')
    secureLocalStorage.removeItem('rememberOrgUsername')
    localStorage.removeItem("propertyAppzLoginFlag");
    secureLocalStorage.removeItem("personalData");
    secureLocalStorage.removeItem("headerPropertyAppzUserData");
    secureLocalStorage.removeItem("headerPlatformUserData");
    secureLocalStorage.removeItem("headerOrganisationUserData");
  }

  redirectToAuthPath() {

  }

  render() {
    var { logoDark } = this.props;
    var { logoWhite } = this.props;

    if (logoDark === undefined || logoDark == null) {
      logoDark = propertyAppzDark;
    }

    if (logoWhite === undefined || logoWhite == null) {
      logoWhite = propertyAppzWhite;
    }
    //meta title
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    document.title = "Login | PropertyAppz";
    return (
      <React.Fragment>
        <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <CarouselPage />

              <Col xl={3}>
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5">
                        <Link to="#" className="d-block auth-logo">
                          <img
                            src={logoDark ? logoDark : propertyAppzDark}
                            alt=""
                            height="40"
                            className="auth-logo-dark"
                          />
                          <img
                            src={logoWhite ? logoWhite : propertyAppzWhite}
                            alt=""
                            height="40"
                            className="auth-logo-light"
                          />
                        </Link>
                      </div>

                      <div className="my-auto">
                        <div>
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p className="text-muted">
                            Sign in to continue to PropertyAppz.
                          </p>
                        </div>

                        <div className="mt-4">
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              username: (this.state && this.state.username) || secureLocalStorage.getItem('rememberAdminUsername'),
                              password:
                                (this.state && this.state.password) || "",
                            }}
                            validationSchema={Yup.object().shape({
                              username: Yup.string().required(
                                "Please Enter Your Username"
                              ).nullable(),
                              password: Yup.string().required(
                                "Please Enter Valid Password"
                              ),
                            })}
                            onSubmit={values => {
                              var remCheck = document.getElementById('remember-check').checked;
                              let baseURL = secureLocalStorage.getItem("baseURL");
                              axios.post(baseURL + "/api/platformuserlogin", {

                                "email": values.username,
                                "password": values.password
                              }, config)
                                .then((response) => {
                                  if (remCheck) {
                                    secureLocalStorage.setItem('rememberAdminUsername', values.username)
                                  }
                                  let token = response.data.data.token;
                                  let personalData = response.data.data.user;
                                  showToast("Success", "Hurray! Let's proceed to great things.", "success");
                                  // Admin-User/Platform-User login flag
                                  localStorage.setItem("adminUserLoginFlag", true);
                                  localStorage.setItem("token", 'Bearer ' + token);
                                  // window.location.replace('/dashboard');
                                  // this.setState({ redirect: "/dashboard" });
                                  config.headers.Authorization = 'Bearer ' + token;
                                  secureLocalStorage.setItem("personalData", { personalData });
                                  secureLocalStorage.setItem("headerPlatformUserData", personalData);
                                  this.getOrganisationData('Bearer ' + token);
                                  this.getAdminData(response.data.data.user.id);
                                })
                                .catch((error) => {
                                  showToast("Failed", error.response.data.message, "error");
                                });
                            }}
                          >
                            {({ errors, status, touched }) => (
                              <Form className="form-horizontal">
                                {this.props.error && this.props.error ? (
                                  <Alert color="danger">
                                    {this.props.error}
                                  </Alert>
                                ) : null}

                                <div className="mb-3">
                                  <Label for="username" className="form-label">
                                    Username
                                  </Label>
                                  <Field
                                    name="username"
                                    placeholder="Enter username"
                                    type="username"
                                    className={
                                      "form-control" +
                                      (errors.username && touched.username
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="username"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="mb-3">
                                  <Label for="password" className="form-label">
                                    Password
                                  </Label>
                                  <div className="input-group auth-pass-inputgroup">
                                    <Field
                                      name="password"
                                      placeholder="Enter Password"
                                      type={this.state.show ? "text" : "password"}
                                      autoComplete="true"
                                      className={
                                        "form-control" +
                                        (errors.password && touched.password
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <button onClick={this.t_show} className="btn btn-light " type="button" id="password-addon">
                                      <i className="mdi mdi-eye-outline"></i></button>
                                    <ErrorMessage
                                      name="password"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="remember-check"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="remember-check"
                                  >
                                    Remember me
                                  </label>
                                </div>

                                <div className="mt-3 d-grid">
                                  <button
                                    className="btn btn-primary btn-block"
                                    type="submit"
                                  >
                                    {" "}
                                    Log In{" "}
                                  </button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>

                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()} <a href='https://propertyappz.com/' target='_blank' rel="noreferrer">Property Appz</a> + <a href='https://pentadmedia.com/' target='_blank' rel="noreferrer">Pentad Media</a>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

LoginAdmin.propTypes = {
  error: PropTypes.any,
  fetchLogo: PropTypes.func,
  logoUrl: PropTypes.shape({
    logo: PropTypes.string,
    icon: PropTypes.string,
    white_logo: PropTypes.string
  })
};

const mapStateToProps = (state) => {
  return {
    logoDark: state.Logo.logo.logoUrl?.logo,
    logoWhite: state.Logo.logo.logoUrl?.white_logo,
  };
};

export default connect(mapStateToProps, { fetchLogo })(withTranslation()(LoginAdmin));
