import axios from "axios";
// import { Link, useHistory } from "react-router-dom";  
import { withRouter } from 'react-router-dom'   

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

export const get_all_saleagents = async () => {
    let response = await axios.get(baseURL + "/api/sales_agents", config);
    return response.data.data;
}

export const delete_salesagents = async (salesagent_id) => {
    let response = await axios.delete(baseURL + "/api/sales_agents/"+salesagent_id, config);
    return response.data.data;
}

export const get_current_salesagents = async (salesagent_id) => {
    let current_salesagent_response = await axios.get(baseURL + "/api/sales_agents/"+salesagent_id, config);
    return current_salesagent_response.data.data;
}
export const get_salesagent_log = async (salesagent_id) => {
    let response = await axios.get(baseURL + "/api/sale-agent-activity-log/"+salesagent_id, config);
    return response.data.data;
}
export const get_all_salesagents_logs = async () => {
    let response = await axios.get(baseURL + "/api/all-sale-agent-activity-log", config);
    return response.data.data;
}
export const get_all_deleted_sales_agents = async () => {
    let response = await axios.get(baseURL + "/api/get-deleted-saleAgent", config);
    return response.data.data;
}
export const restore_sales_agents = async (salesagent_id) => {
    let response = await axios.post(baseURL + "/api/restore-the-saleAgent/"+salesagent_id,{}, config);
    return response.data.data;
}
