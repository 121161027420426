// action types
export const FETCH_LOGO = 'FETCH_LOGO';
export const FETCH_LOGO_SUCCESS = 'FETCH_LOGO_SUCCESS';
export const FETCH_LOGO_FAILURE = 'FETCH_LOGO_FAILURE';

// action creators
export const fetchLogo = () => ({
  type: FETCH_LOGO
});

export const fetchLogoSuccess = (logoUrl) => ({
  type: FETCH_LOGO_SUCCESS,
  payload: logoUrl
});

export const fetchLogoFailure = (error) => ({
  type: FETCH_LOGO_FAILURE,
  payload: error
});
