import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../UI/ui-toast'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get_current_property } from '../api/propertyApi';
import { get_features_against_project, get_project_sizes, get_project_phases_blocks, get_blocks_against_phases, get_project_floors } from "../../Projects/api/projectApi"
const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

class EditProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      property_area_size: '',
      optionGroupAreaUnit: constant.area_unit_titles,
      optionGroupProjects: [],
      optionGroupSizes: [],
      size_id: '',
      size_name: '',
      floor_id: '',
      floor_name: '',
      phase_id: '',
      phase_name: '',
      block_id: '',
      property_id: '',
      block_name: '',
      property_type_id: '',
      property_type_name: '',
      project_name: '',
      project_id: '',
      unique_reference: '',
      area_unit: '',
      property_features: [],
      optionGroupPhases: [],
      optionGroupBlocks: [],
      optionGroupFloors: [],
      optionGroupFeatures: [],
      optionGroupPropertyTypes: [],
      prices: [],
      percentage_value_against_price: '',
      pricesArray: [],
      totalFeaturesPrices: [],
      currencies: [],
      features: [],
      has_phases: 0,
      has_blocks: 0,
      has_floors: 0,
      has_sizes: 0,
      redirect: null,
      titlesArray: [
        {
          "value": 'Mr',
          "label": 'Mr'
        },
        {
          "value": 'Mrs',
          "label": 'Mrs'
        }
      ],
      id: "",
      name: '',
      property_status: '',
      disabled: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }
  handleChange(event) {
  }
  async getOptionsTypes() {
    const res = await axios.get(baseURL + "/api/property-type-listing", config);
    const data = res.data.data;
    console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.type_name
    }))
    this.setState({ optionGroupPropertyTypes: options })
  }
  async getProjectDetail(project_id) {
    const res = await axios.get(baseURL + "/api/project-details/" + project_id, config);
    const data = res.data.data;
    console.log(data);
    this.setState({ has_sizes: data.has_sizes })
    this.setState({ has_phases: data.has_phases })
    this.setState({ has_blocks: data.has_blocks })
    this.setState({ has_floors: data.has_floors })
    if (data.has_sizes) {
      Promise.all([get_project_sizes(project_id)]).then((values) => {
        console.log(values[0]);
        this.getOptionsSizes(values[0]);
      });
    }
    if (data.has_phases) {
      Promise.all([get_project_phases_blocks(project_id)]).then((values) => {
        console.log(values[0]);
        this.getOptionsPhases(values[0]);
      });
    }
    if (data.has_floors) {
      Promise.all([get_project_floors(project_id)]).then((values) => {
        console.log(values[0]);
        this.getOptionsFloors(values[0]);
      });
    }
    const currencies = await axios.get(baseURL + "/api/project-currencies/" + project_id, config);
    const currenciesData = currencies.data.data;
    console.log(currenciesData);
    this.setState({ currencies: currenciesData })
    const { pricesArray } = this.state;
    console.log(this.state.prices);
    for (var i = 0; i < currenciesData.length; i++) {
      pricesArray[i] = { "price": 0, "currency_id": currenciesData[i].currency_id };
      this.setState({ pricesArray });
      // this.setResponsePrices(currenciesData[i].currency_id)

    }
    // console.log(this.state.pricesArray);
    // const listItems = currenciesData?.map((d) =>
    //     <li key={d.currency_id}>{d.currency_name}</li>
    // );
  }
  getOptionsFloors(floors) {
    const options = floors.map(d => ({
      "value": d.id,
      "label": d.name
    }))
    this.setState({ optionGroupFloors: options })
  }
  getOptionsSizes(sizes) {
    const options = sizes.map(d => ({
      "value": d.id,
      "label": d.name
    }))
    this.setState({ optionGroupSizes: options })
  }
  getOptionsPhases(phases) {
    const options = phases.map(d => ({
      "value": d.id,
      "label": d.name
    }))
    this.setState({ optionGroupPhases: options })
  }
  getBlocksAgainstPhases(phase_id) {
    Promise.all([get_blocks_against_phases(phase_id)]).then((values) => {
      console.log(values[0]);
      let data = values[0];
      const options = data.map(d => ({
        "value": d.id,
        "label": d.name
      }))
      this.setState({ optionGroupBlocks: options })
    });

  }
  getOptionsFeatures(project_id) {
    Promise.all([get_features_against_project(project_id)]).then((values) => {
      console.log(values[0]);
      const options = values[0].map(d => ({
        "value": d.feature_id,
        "label": d.feature_name,
        "id": d.id,
        "percentage_value": d.percentage_value,
        "feature_name": d.feature_name
      }))
      this.setState({ optionGroupFeatures: options })
    });


  }
  componentDidMount() {
    Promise.all([get_current_property(localStorage.getItem("current_property"))]).then((values) => {
      console.log(values[0]);
      this.getOptionsFeatures(values[0].project_id)
      this.getProjectDetail(values[0].project_id)
      this.setState({ prices: values[0].prices })
      this.setState({ property_id: values[0].id })
      this.setState({ project_id: values[0].project_id })
      this.setState({ project_name: values[0].project_name })
      this.setState({ size_name: values[0].size_name })
      this.setState({ size_id: values[0].size_id })
      this.setState({ block_name: values[0].block_name })
      this.setState({ block_id: values[0].block_id })
      this.setState({ phase_name: values[0].phase_name })
      this.setState({ phase_id: values[0].phase_id })
      this.setState({ floor_name: values[0].floor_name })
      this.setState({ floor_id: values[0].floor_id })
      this.setState({ property_type_id: values[0].type_id })
      this.setState({ property_type_name: values[0].type_name })
      this.setState({ unique_reference: values[0].unique_reference })
      this.setState({ property_status: values[0].status })
      this.setState({ area_unit: values[0].area_unit })
      this.setState({ property_area_size: values[0].area_size })

      // Map current features
      const options = values[0].property_feature.map(d => ({
        "value": d.feature_id,
        "label": d.feature_name,
        "id": d.id,
        "percentage_value": d.percentage_value,
        "feature_name": d.feature_name
      }))
      console.log(options);
      this.setState({ property_features: options })
      this.setState({ features: options })

      if (values[0].status == "Sold" || values[0].status == "Confirmed") {
        this.setState({ disabled: true })
      } else {
        this.setState({ disabled: false })
      }

    });
    setTimeout(() => {
      var currentPricesArray = this.state.prices;
      for (var i = 0; i < currentPricesArray.length; i++) {
        document.getElementById('price-' + i).value = currentPricesArray[i].price
        this.setResponsePrices(currentPricesArray[i].currency_id)
        document.getElementById('total-' + currentPricesArray[i].currency_id).value = currentPricesArray[i].with_feature_price
      }
    }, 5000);
    this.getOptionsTypes()
  }

  setResponsePrices = (currency_id) => {
    console.log("Response");
    var percentage = null;
    var total = 0;
    for (var i = 0; i < this.state.features.length; i++) {
      percentage = this.state.features[i].percentage_value
      for (var j = 0; j < this.state.currencies.length; j++) {
        console.log(this.state.prices[j].price);
        if (this.state.prices[j].currency_id == currency_id) {
          var actualCurrencyPrice = document.getElementById('price-' + j).value;
          percentage = (percentage * actualCurrencyPrice) / 100
          // document.getElementById('feature-' + i + '-' + currency_id).value = parseFloat(percentage);
          document.getElementById('feature-' + i + '-' + currency_id).value = parseFloat(percentage).toFixed(2);
          total += parseFloat(percentage);
          document.getElementById('feature_price-' + currency_id).value = (total + parseFloat(actualCurrencyPrice)).toFixed(2);
          document.getElementById('total-' + currency_id).value = (total + parseFloat(actualCurrencyPrice)).toFixed(2);
        }
      }
    }
  }

  // handleSelectGroupProject = selectedGroupProject => {
  //   this.setState({ selectedGroupProject })
  //   console.log(selectedGroupProject);
  //   this.getProjectDetail(selectedGroupProject.value)
  // }
  handleSelectGroupSizes = selectedGroupSizes => {
    this.setState({ selectedGroupSizes })
    this.setState({ size_name: selectedGroupSizes.label });
    this.setState({ size_id: selectedGroupSizes.value });
    console.log(selectedGroupSizes);
  }
  handleSelectGroupPhases = selectedGroupPhases => {
    this.setState({ selectedGroupPhases })
    this.setState({ phase_name: selectedGroupPhases.label });
    this.setState({ block_name: '' })
    this.setState({ block_id: '' })
    this.setState({ phase_id: selectedGroupPhases.value });
    console.log(selectedGroupPhases);
    if (this.state.has_blocks == 1) {
      this.getBlocksAgainstPhases(selectedGroupPhases.value)
    }
  }
  handleSelectGroupBlocks = selectedGroupBlocks => {
    this.setState({ selectedGroupBlocks })
    this.setState({ block_name: selectedGroupBlocks.label });
    this.setState({ block_id: selectedGroupBlocks.value });
    console.log(selectedGroupBlocks);
  }
  handleSelectGroupFloors = selectedGroupFloors => {
    this.setState({ selectedGroupFloors })
    this.setState({ floor_name: selectedGroupFloors.label });
    this.setState({ floor_id: selectedGroupFloors.value });
    console.log(selectedGroupFloors);
  }
  handleSelectGroupPropertyTypes = selectedGroupPropertyTypes => {
    this.setState({ selectedGroupPropertyTypes })
    this.setState({ property_type_name: selectedGroupPropertyTypes.label });
    this.setState({ property_type_id: selectedGroupPropertyTypes.value });
    console.log(selectedGroupPropertyTypes);
  }
  // handleSelectGroupFeatures = selectedGroupFeatures => {
  //   this.setState({ selectedGroupFeatures })
  //   console.log(this.state.property_features);
  //   var features = [];
  //   for (var i = 0; i < selectedGroupFeatures.length; i++) {
  //     if (this.state.property_features[i] == undefined && selectedGroupFeatures[i].value) {
  //       selectedGroupFeatures[i].feature_id = selectedGroupFeatures[i].value;
  //       features.push({ "feature_id": selectedGroupFeatures[i].feature_id, "id": 0 })
  //     } else {

  //       console.log(this.state.property_features[i].id == selectedGroupFeatures[i].value);
  //       selectedGroupFeatures[i].feature_id = selectedGroupFeatures[i].value;
  //       features.push({ "feature_id": selectedGroupFeatures[i].feature_id, "id": this.state.property_features[i].id })
  //     }

  //   }
  //   console.log(features);
  //   this.setState({ features: features })
  // }
  handleSelectGroupFeatures = selectedGroupFeatures => {
    console.log(selectedGroupFeatures);
    this.setState({ selectedGroupFeatures })
    var features = [];
    for (var i = 0; i < selectedGroupFeatures.length; i++) {
      if (this.state.property_features[i] == undefined && selectedGroupFeatures[i].value) {
        selectedGroupFeatures[i].feature_id = selectedGroupFeatures[i].value;
        features.push({ "id": 0, "feature_id": selectedGroupFeatures[i].feature_id, "feature_name": selectedGroupFeatures[i].label, "percentage_value": selectedGroupFeatures[i].percentage_value })
      } else {
        selectedGroupFeatures[i].feature_id = selectedGroupFeatures[i].value;
        features.push({ "id": selectedGroupFeatures[i].id, "feature_id": selectedGroupFeatures[i].feature_id, "feature_name": selectedGroupFeatures[i].label, "percentage_value": selectedGroupFeatures[i].percentage_value })
      }

    }
    console.log(features);
    this.setState({ features: features })
    setTimeout(() => {
      var currentPricesArray = this.state.prices;
      for (var i = 0; i < currentPricesArray.length; i++) {
        // document.getElementById('price-' + i).value = currentPricesArray[i].price
        this.setResponsePrices(currentPricesArray[i].currency_id)
      }
    }, 1000);

    if (features == '') {
      console.log('Empty');
      for (var j = 0; j < this.state.currencies.length; j++) {
        // console.log(this.state.prices[j].price);

        // percentage = (percentage * this.state.prices[j].price) / 100
        // document.getElementById('feature-' + i + '-' + currency_id).value = parseFloat(percentage);
        document.getElementById('feature_price-' + this.state.prices[j].currency_id).value = 0.00;
        document.getElementById('total-' + this.state.prices[j].currency_id).value = document.getElementById('price-' + j).value;
      }
    }
  }
  setPriceAgainstPercentageValue(currency_id) {
    var percentage = null;
    var total = 0;
    for (var i = 0; i < this.state.features.length; i++) {
      percentage = this.state.features[i].percentage_value
      for (var j = 0; j < this.state.currencies.length; j++) {
        // console.log(j);
        if (this.state.pricesArray[j].currency_id == currency_id) {
          document.getElementById('feature-' + i + '-' + currency_id).value = 0.00
          document.getElementById('feature_price-' + currency_id).value = 0.00
          document.getElementById('total-' + currency_id).value = 0.00
          percentage = (percentage * this.state.pricesArray[j].price) / 100
          document.getElementById('feature-' + i + '-' + currency_id).value = parseFloat(percentage).toFixed(2);
          total += parseFloat(percentage);
          document.getElementById('feature_price-' + currency_id).value = (total + parseFloat(this.state.pricesArray[j].price)).toFixed(2);
          document.getElementById('total-' + currency_id).value = (total + parseFloat(this.state.pricesArray[j].price)).toFixed(2);
        }
      }
    }
  }
  handlePriceChange = (e, index, currency_id) => {
    console.log(currency_id);
    const { pricesArray } = this.state;
    pricesArray[index] = { "price": e.target.value, "currency_id": currency_id };
    this.setState({ pricesArray });

    if (this.state.features == '') {
      console.log('Empty');
      for (var j = 0; j < this.state.currencies.length; j++) {
        document.getElementById('feature_price-' + this.state.prices[j].currency_id).value = 0.00;
        document.getElementById('total-' + this.state.prices[j].currency_id).value = document.getElementById('price-' + j).value;
      }
    }

    console.log(this.state.pricesArray);
    this.setPriceAgainstPercentageValue(currency_id)
  }
  handleSubmit(e) {

    e.preventDefault();
    // console.log(e.target.price.value)
    // console.log(e.target.unique_reference.value)
    // console.log(this.state.features)
    // console.log(this.state.property_type_id)
    // console.log(this.state.block_id)
    // console.log(this.state.phase_id)
    // console.log(this.state.floor_id)
    // console.log(this.state.size_id)

    var prices = []
    for (var j = 0; j < this.state.currencies.length; j++) {

      var total = document.getElementById('total-' + this.state.currencies[j].currency_id).value
      var actualPrice = document.getElementById('price-' + j).value
      // var with_feature_price = document.getElementById('feature-'+j+'-'+this.state.currencies[j].currency_id).value
      prices.push({ "id": this.state.prices[j].id, "with_feature_price": total, "price": actualPrice, "currency_id": this.state.currencies[j].currency_id })
    }
    console.log(prices);
    console.log(this.state.features);
    var features = [];
    for (var i = 0; i < this.state.features.length; i++) {
      if (this.state.features[i].value != undefined) {
        features.push({ "id": this.state.features[i].id, "feature_id": this.state.features[i].value, "feature_name": this.state.features[i].label, "percentage_value": this.state.features[i].percentage_value })
      } else {
        features.push({ "id": 0, "feature_id": this.state.features[i].feature_id, "feature_name": this.state.features[i].feature_name, "percentage_value": this.state.features[i].percentage_value })
      }
    }
    console.log(features);
    axios.patch(baseURL + "/api/properties/" + localStorage.getItem("current_property"), {
      "project_id": this.state.project_id,
      "floor_id": this.state.floor_id == undefined ? "" : this.state.floor_id,
      "type_id": this.state.property_type_id,
      "size_id": this.state.size_id,
      "phase_id": this.state.phase_id,
      "block_id": this.state.block_id,
      "features": features == [] ? this.state.features : features,
      "prices": prices,
      "unique_reference": e.target.unique_reference.value,
      "area_size": this.state.property_area_size,
      "area_unit": this.state.area_unit
    }, config)
      .then((response) => {
        showToast("Success", "The property has been updated successfully.", "success");
        this.setState({ redirect: "/properties/list" });
        localStorage.removeItem("current_property");
        // window.location.replace('/customers/list');
        console.log(response);
      })
      .catch((error) => {
        showToast("Failed", "Property Not Edited Successfully", "error");
        console.log(error);
      });
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }
  handleSelectGroupAreaUnit = selectedGroupAreaUnit => {
    this.setState({ selectedGroupAreaUnit })
    this.setState({ area_unit: selectedGroupAreaUnit.value })
  }
  handlePropertyAreaSize = (e) => {
    this.setState({ property_area_size: e.target.value })
  }
  render() {
    //meta title
    document.title = "Edit Property";
    const { validation } = this.state;
    const { selectedGroupSizes } = this.state;
    const { selectedGroupProject } = this.state;
    const { selectedGroupAreaUnit } = this.state;
    const { selectedGroupPhases } = this.state;
    const { selectedGroupBlocks } = this.state;
    const { selectedGroupFloors } = this.state;
    const { selectedGroupFeatures } = this.state;
    const { selectedGroupPropertyTypes } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Property" breadcrumbItem="Edit Property" parentLink="/properties/list" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{

                      }}
                      validationSchema={Yup.object().shape({

                      })}

                    >
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation" onSubmit={this.handleSubmit}>
                          <Row>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="projectName">Project</Label>
                                <div className="col-md-10">
                                  <Field
                                    name="projectName"
                                    value={this.state.project_name}
                                    // onChange={(e) => this.setState({ unique_reference: e.target.value })}
                                    type="text"
                                    disabled
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            {this.state.has_sizes == 0 ? <></> : <>
                              <Col md="12">
                                <div className="mb-3 row d-flex select2-container">
                                  <Label className="col-md-2 col-form-label" htmlFor="selectSizes">Size</Label>
                                  <div className="col-md-10">
                                    <Select
                                      name="selectSizes"
                                      isDisabled={this.state.disabled}
                                      // value={selectedGroupSizes}
                                      value={{ label: this.state.size_name, value: 0 }}
                                      onChange={this.handleSelectGroupSizes}
                                      options={this.state.optionGroupSizes}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>
                                </div>
                              </Col>
                            </>}
                            {this.state.has_phases == 0 ? <></> : <>
                              <Col md="12">
                                <div className="mb-3 row d-flex select2-container">
                                  <Label className="col-md-2 col-form-label" htmlFor="selectPhases">Phase</Label>
                                  <div className="col-md-10">
                                    <Select
                                      name="selectPhases"
                                      isDisabled={this.state.disabled}
                                      // value={selectedGroupPhases}
                                      value={{ label: this.state.phase_name, value: 0 }}
                                      onChange={this.handleSelectGroupPhases}
                                      options={this.state.optionGroupPhases}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>
                                </div>
                              </Col>
                            </>}
                            {this.state.has_blocks == 0 ? <></> : <>
                              <Col md="12">
                                <div className="mb-3 row d-flex select2-container">
                                  <Label className="col-md-2 col-form-label" htmlFor="selectBlocks">Block</Label>
                                  <div className="col-md-10">
                                    <Select
                                      name="selectBlocks"
                                      // value={selectedGroupBlocks}
                                      isDisabled={this.state.disabled}
                                      value={{ label: this.state.block_name, value: 0 }}
                                      onChange={this.handleSelectGroupBlocks}
                                      options={this.state.optionGroupBlocks}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>
                                </div>
                              </Col>
                            </>}
                            {this.state.has_floors == 0 ? <></> : <>
                              <Col md="12">
                                <div className="mb-3 row d-flex select2-container">
                                  <Label className="col-md-2 col-form-label" htmlFor="selectFloors">Floor</Label>
                                  <div className="col-md-10">
                                    <Select
                                      name="selectFloors"
                                      isDisabled={this.state.disabled}
                                      // value={selectedGroupFloors}
                                      value={{ label: this.state.floor_name, value: 0 }}
                                      onChange={this.handleSelectGroupFloors}
                                      options={this.state.optionGroupFloors}
                                      classNamePrefix="select2-selection"
                                    />
                                  </div>
                                </div>
                              </Col>
                            </>}
                            <Col md="6">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-4 col-form-label" htmlFor="selectArea">Area Unit</Label>
                                <div className="col-md-8">
                                  <Select
                                    name="selectArea"
                                    placeholder='Change Area'
                                    value={{ label: this.state.area_unit, value: 0 }}
                                    onChange={this.handleSelectGroupAreaUnit}
                                    options={this.state.optionGroupAreaUnit}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-4 col-form-label" htmlFor="areaSize">
                                  Area Size
                                </Label>
                                <div className="col-md-8">
                                  <Field
                                    name="areaSize"
                                    placeholder="Area Size"
                                    value={this.state.property_area_size}
                                    onChange={(e) => this.handlePropertyAreaSize(e)}
                                    type="text"
                                    className={
                                      "form-control"}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="unique_reference">Unique Reference</Label>
                                <div className="col-md-10">
                                  <Field
                                    name="unique_reference"
                                    placeholder="Unique Reference"
                                    required="required"
                                    disabled={this.state.disabled}
                                    value={this.state.unique_reference}
                                    onChange={(e) => this.setState({ unique_reference: e.target.value })}
                                    type="text"
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectFeatures">Property Features</Label>
                                <div className="col-md-10">
                                  <Select
                                    name="selectFeatures"
                                    isMulti={true}
                                    isDisabled={this.state.disabled}
                                    // value={this.state.property_features}
                                    value={selectedGroupFeatures ? selectedGroupFeatures : this.state.property_features}
                                    // value={{ label: this.state.optionGroupFeatures, value: 0 }}
                                    onChange={this.handleSelectGroupFeatures}
                                    options={this.state.optionGroupFeatures}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectType">Property Type</Label>
                                <div className="col-md-10">
                                  <Select
                                    name="selectType"
                                    isDisabled={this.state.disabled}
                                    // value={selectedGroupPropertyTypes}
                                    value={{ label: this.state.property_type_name, value: 0 }}
                                    onChange={this.handleSelectGroupPropertyTypes}
                                    options={this.state.optionGroupPropertyTypes}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="currency">Currency Prices</Label>
                                {this.state.currencies?.map((d, i) =>
                                  <div key={d.currency_id} className="col-md-12 d-flex mb-2" style={{ "marginLeft": "1.5rem" }}>
                                    <Label style={{ "marginLeft": "0.5rem" }} className="col-md-2 col-form-label" htmlFor="currency">{d.currency_name}</Label>
                                    <div className="col-md-2">
                                      <Field
                                        name={"price-" + i}
                                        id={"price-" + i}
                                        placeholder="0.00"
                                        disabled={this.state.disabled}
                                        // value={this.state.price?this.state.price:""}
                                        onChange={(e) => this.handlePriceChange(e, i, d.currency_id)}
                                        type="text"
                                        pattern="[0-9]+([\.,][0-9]+)?"
                                        style={{ textAlign: 'right' }}
                                        required="required"
                                        className={"form-control"}
                                        onBlur={(e) => {
                                          if (e.target.value.trim() === "") {
                                            e.target.value = ""; // Empty value stays empty
                                          } else {
                                            const formattedValue = parseFloat(e.target.value).toFixed(2);
                                            e.target.value = formattedValue;
                                          }
                                        }}
                                      />
                                    </div>
                                    <Label style={{ "marginLeft": "1.5rem" }} className="col-md-2 col-form-label" htmlFor="feature_price">Price with features</Label>
                                    <div className="col-md-2">
                                      <Field
                                        name={"feature_price-" + i}
                                        placeholder="0.00"
                                        id={"feature_price-" + d.currency_id}
                                        // value={this.state.price?this.state.price:""}
                                        // onChange={(e) => this.handlePriceChange(e, i, d.currency_id)}
                                        type="text"
                                        disabled
                                        pattern="[0-9]+([\.,][0-9]+)?"
                                        style={{ textAlign: 'right' }}
                                        required="required"
                                        className={"form-control"}
                                      />
                                    </div>
                                    <Label style={{ "marginLeft": "1.5rem" }} className="col-md-1 col-form-label" htmlFor="total">Total</Label>
                                    <div className="col-md-2">
                                      <Field
                                        name={"total-" + i}
                                        placeholder="0.00"
                                        id={"total-" + d.currency_id}
                                        disabled={this.state.disabled}
                                        // value={this.state.price?this.state.price:""}
                                        // onChange={(e) => this.handlePriceChange(e, i, d.currency_id)}
                                        type="text"
                                        pattern="[0-9]+([\.,][0-9]+)?"
                                        style={{ textAlign: 'right' }}
                                        required="required"
                                        className={"form-control"}
                                        onBlur={(e) => {
                                          if (e.target.value.trim() === "") {
                                            e.target.value = ""; // Empty value stays empty
                                          } else {
                                            const formattedValue = parseFloat(e.target.value).toFixed(2);
                                            e.target.value = formattedValue;
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                              {this.state.features?.map((d, i) =>
                                <div key={i}>
                                  <div className="d-flex">
                                    <Label className="col-md-2" style={{ "paddingTop": "50px", "marginTop": "20px" }}>{d.feature_name}: </Label>
                                    <Label className="col-md-2" style={{ "paddingTop": "50px", "marginTop": "20px" }}>{d.percentage_value}% </Label>
                                    {this.state.currencies?.map((c, j) =>
                                      <table key={j} className="table mt-0 pt-0">
                                        <thead>
                                          <tr key={j}>
                                            <th scope="col" key={j}>{c.currency_name}</th>
                                          </tr>
                                        </thead>
                                        <tbody>

                                          <tr key={j}>

                                            <td scope="row">

                                              <Field
                                                name={"feature-" + i + "-" + c.currency_id}
                                                id={"feature-" + i + "-" + c.currency_id}
                                                placeholder="0.00"
                                                currency_id={c.currency_id}
                                                disabled
                                                // value={this.state.percentage_value_against_price}
                                                // value={this.state.price?this.state.price:""}
                                                type="text"
                                                pattern="[0-9]+([\.,][0-9]+)?"
                                                style={{ textAlign: 'right' }}
                                                required="required"
                                                className={"form-control"}
                                              />
                                            </td>

                                          </tr>

                                        </tbody>
                                      </table>
                                    )}
                                  </div>
                                </div>
                              )}
                            </Col>
                          </Row>
                          {this.state.property_status == "Sold" ? <></> : <>
                            <Button color="primary" type="submit" className="float-end" >
                              Update Property
                            </Button>
                          </>}
                        </Form>
                      )}
                    </Formik>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default EditProperty;
