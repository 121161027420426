import React from "react"
import PropTypes from 'prop-types'
import { Route, Redirect } from "react-router-dom"
// remove old import of secureLocalStorage "react-secure-storage"
import secureLocalStorage from "react-secure-storage"
// import { get_user_permissions } from "../helpers/custom_helper"
import AccessDenied from "pages/Error/access_denied"
import PageNotFound from "pages/Error/PageNotFound"

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      var permissions = secureLocalStorage.getItem('userData')
      if (window.location.pathname == '/auth-lock-screen') {
      } else {
        secureLocalStorage.setItem('currentSession', window.location.pathname)
      }
      if (window.location.pathname == '/projects/add') {
        secureLocalStorage.setItem('currentSession', '/projects/add?section=1')
      }
      var currentPath = window.location.pathname;
      var checkPermission = false;

      /* ---------- Project Permissions for route ---------- */
      if ((currentPath == "/projects/list" || currentPath == "/project/view" || currentPath == "/deleted/projects/list") && permissions?.data['projects-view']) {
        checkPermission = true;
      }
      if ((currentPath == "/projects/add" || currentPath == "/projects/currencies" || currentPath == "/projects/sizes" || currentPath == "/projects/floors" || currentPath == "/projects/phases" || currentPath == "/projects/features") && permissions?.data['projects-create']) {
        checkPermission = true;
      }
      if ((currentPath == "/project/edit" || currentPath == "/project/edit-sizes" || currentPath == "/project/edit-phases-blocks" || currentPath == "/project/edit-floors" || currentPath == "/projects/edit-currencies" || currentPath == "/projects/edit-features") && permissions?.data['projects-edit']) {
        checkPermission = true;
      }
      if (currentPath == "/project/log" || currentPath == "/all/projects/logs" && permissions?.data['project-logs-view']) {
        checkPermission = true;
      }

      /* ---------- Property Permissions for route ---------- */
      if ((currentPath == "/properties/list" || currentPath == "/property/view" || currentPath == "/deleted/properties/list") && permissions?.data['properties-view']) {
        checkPermission = true;
      }
      if (currentPath == "/properties/add" && permissions?.data['properties-create']) {
        checkPermission = true;
      }
      if (currentPath == "/property/edit" && permissions?.data['properties-edit']) {
        checkPermission = true;
      }
      if (currentPath == "/project-report/properties/available/list" || currentPath == "/property/view" && permissions?.data['properties-view']) {
        checkPermission = true;
      }
      if (currentPath == "/project-report/properties/sold/list" || currentPath == "/property/view" && permissions?.data['properties-view']) {
        checkPermission = true;
      }
      if (currentPath == "/project-report/properties/total/list" || currentPath == "/property/view" && permissions?.data['properties-view']) {
        checkPermission = true;
      }
      if (currentPath == "/property/log" || currentPath == "/all/properties/logs" && permissions?.data['property-logs-view']) {
        checkPermission = true;
      }

      /* ---------- Payment Plan Permissions for route ---------- */
      if (currentPath == "/payment-plan/add" && permissions?.data['payment-plan-create']) {
        checkPermission = true;
      }
      if (currentPath == "/payment-plan/edit" && permissions?.data['payment-plan-edit']) {
        checkPermission = true;
      }
      if ((currentPath == "/payment-plan/list" || currentPath == "/payment-plan/view" || currentPath == "/deleted/payment-plans/list") && permissions?.data['payment-plan-view']) {
        checkPermission = true;
      }
      if ((currentPath == "/payment-plan/log" || currentPath == "/all/payment-plan/log") && permissions?.data['payment-plan-logs-view']) {
        checkPermission = true;
      }

      /* ---------- Customers & Nominees Permissions for route ---------- */
      if ((currentPath == "/customers/add" || currentPath == "/customer/nominee/add") && permissions?.data['customers-create']) {
        checkPermission = true;
      }
      if ((currentPath == "/customers/edit" || currentPath == "/customer/nominee/edit") && permissions?.data['customers-edit']) {
        checkPermission = true;
      }
      if ((currentPath == "/customers/list" || currentPath == "/customers/view" || currentPath == "/deleted/customers/list" || currentPath == "/customer/deleted/nominees/list" || currentPath == "/customer/nominees/list" || currentPath == "/customer/nominee/view") && permissions?.data['customers-view']) {
        checkPermission = true;
      }
      if (currentPath == "/customers/documents" && permissions?.data['identity-documents-view']) {
        checkPermission = true;
      }
      if ((currentPath == "/customer/log" || currentPath == "/customers/logs" || currentPath == "/customer/nominee/log") && permissions?.data['customer-logs-view']) {
        checkPermission = true;
      }
      if (currentPath == "/customer/documents_nominees/list" && permissions?.data['identity-documents-view']) {
        checkPermission = true;
      }
      if (currentPath == "/customers/nominee/documents" && permissions?.data['identity-documents-view']) {
        checkPermission = true;
      }
      if (currentPath == "/documents/identity" && permissions?.data['identity-documents-view']) {
        checkPermission = true;
      }

      /* ---------- Sales Agents Permissions for route ---------- */
      if ((currentPath == "/salesagents/list" || currentPath == "/salesagents/view" || currentPath == "/deleted/salesagents/list") && permissions?.data['sale-agents-view']) {
        checkPermission = true;
      }
      if (currentPath == "/salesagents/add" && permissions?.data['sale-agents-create']) {
        checkPermission = true;
      }
      if (currentPath == "/salesagents/edit" && permissions?.data['sale-agents-edit']) {
        checkPermission = true;
      }
      if ((currentPath == "/salesagents/log" || currentPath == "/all/salesagents/logs") && permissions?.data['sale-agent-logs-view']) {
        checkPermission = true;
      }

      /* ---------- Sales Permissions for route ---------- */
      if ((currentPath == "/sales/list" || currentPath == "/salepayments/list" || currentPath == "/sale/view" || currentPath == "/payment/view" || currentPath == "/cancelled/sales/list") && permissions?.data['sales-view']) {
        checkPermission = true;
      }
      if (currentPath == "/sales/add" && permissions?.data['sales-create']) {
        checkPermission = true;
      }
      if (currentPath == "/sales/edit" && permissions?.data['sales-edit']) {
        checkPermission = true;
      }
      if ((currentPath == "/sale/log" || currentPath == "/all/sales/logs") && permissions?.data['sale-logs-view']) {
        checkPermission = true;
      }
      if (currentPath == "/documents/sales" && permissions?.data['sale-documents-view']) {
        checkPermission = true;
      }
      if (currentPath == "/sale/documents" && permissions?.data['sale-documents-view']) {
        checkPermission = true;
      }

      /* ---------- Sales Payments Permissions for route ---------- */
      if ((currentPath == "/payments/log" || currentPath == "/payment/log") && permissions?.data['payments-logs-view']) {
        checkPermission = true;
      }
      if ((currentPath == "/salepayment/documents" ||
        currentPath == "/salepayment/documents/list" ||
        currentPath == "/payments/documents" ||
        currentPath == "/salepayment/receipt-documents")
        && permissions?.data['payment-documents-view']) {
        checkPermission = true;
      }
      // This route not using for now.
      // if (currentPath == "/receivepayment") {
      //   checkPermission = true;
      // }
      /* ---------- Other modules Permissions for route ---------- */
      if (currentPath == "/roles/list" && permissions?.data['orginasation-roles-view']) {
        checkPermission = true;
      }
      if (currentPath == "/roles/add" && permissions?.data['orginasation-roles-create']) {
        checkPermission = true;
      }
      if (currentPath == "/roles/edit" && permissions?.data['orginasation-roles-edit']) {
        checkPermission = true;
      }
      if (currentPath == "/organisation/users/list" && permissions?.data['users-view']) {
        checkPermission = true;
      }
      if (currentPath == "/organisation/users/add" && permissions?.data['users-create']) {
        checkPermission = true;
      }
      if (currentPath == "/organisation/user/edit" && permissions?.data['users-edit']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/cities/list" && (permissions?.data['cities-view'] || permissions?.data['platform-city-view'])) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/cities/add" && (permissions?.data['cities-create'] || permissions?.data['platform-city-create'])) {
        checkPermission = true;
      }
      // if(currentPath=="/setting/organisation/setup"){
      //   checkPermission=true; 
      // }

      if (currentPath == "/platformroles/add" && permissions?.data['roles-create']) {
        checkPermission = true;
      }
      if (currentPath == "/platformroles/list" && permissions?.data['roles-view']) {
        checkPermission = true;
      }
      if (currentPath == "/platformroles/edit" && permissions?.data['roles-edit']) {
        checkPermission = true;
      }
      if (currentPath == "/users/add" && permissions?.data['platform-users-create']) {
        checkPermission = true;
      }
      if (currentPath == "/users/list" && permissions?.data['platform-users-view']) {
        checkPermission = true;
      }
      if (currentPath == "/user/edit" && permissions?.data['platform-users-edit']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/project-type/add" && permissions?.data['project-types-create']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/project-type/list" && permissions?.data['project-types-view']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/property-type/add" && permissions?.data['property-types-create']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/property-type/list" && permissions?.data['property-types-view']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/property-feature/add" && permissions?.data['property-features-create']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/property-feature/list" && permissions?.data['property-features-view']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/currencies/add" && permissions?.data['currencies-create']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/currencies/list" && permissions?.data['currencies-view']) {
        checkPermission = true;
      }
      // if(currentPath=="/catalogue/document-types/add" && permissions?.data['document-type-create']){
      //   checkPermission=true; 
      // }
      if (currentPath == "/catalogue/document-types/list" && permissions?.data['document-type-view']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/country/add" && permissions?.data['countries-create']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/country/list" && permissions?.data['countries-view']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/payment-type/add" && permissions?.data['payment-type-create']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/payment-type/list" && permissions?.data['payment-type-view']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/log-type/add" && permissions?.data['log-types-create']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/log-type/list" && permissions?.data['log-types-view']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/campaign-type/add" && permissions?.data['campaign-types-create']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/campaign-type/list" && permissions?.data['campaign-types-view']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/lead-stage/add" && permissions?.data['lead-stages-create']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/lead-stage/list" && permissions?.data['lead-stages-view']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/lead-pipeline/add" && permissions?.data['lead-pipelines-create']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/lead-pipeline/list" && permissions?.data['lead-pipelines-view']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/lead-type/add" && permissions?.data['lead-types-create']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/lead-type/list" && permissions?.data['lead-types-view']) {
        checkPermission = true;
      }
      // Open Routes - Without permission Routes Start
      if (currentPath == "/campaign/utms") {
        checkPermission = true;
      }
      if (currentPath == "/campaign/add" && permissions?.data['campaigns-create']) {
        checkPermission = true;
      }
      if (currentPath == "/campaign/list" || currentPath == "/campaigns/logs" || currentPath == "/campaign/logs" && permissions?.data['campaigns-view']) {
        checkPermission = true;
      }
      if (currentPath == "/campaigns/campaign-list/edit" && permissions?.data['campaigns-edit']) {
        checkPermission = true;
      }
      if (currentPath == "/lead/add" && permissions?.data['leads-create']) {
        checkPermission = true;
      }
      if (currentPath == "/lead/list" && permissions?.data['leads-view']) {
        checkPermission = true;
      }
      if (currentPath == "/lead/activty-log" && permissions?.data['leads-view']) {
        checkPermission = true;
      }
      if (currentPath == "/lead/activties-logs" && permissions?.data['leads-view']) {
        checkPermission = true;
      }
      if (currentPath == "/lead/view" || currentPath == "/leads/logs" || currentPath == "/lead/logs" && permissions?.data['leads-view']) {
        checkPermission = true;
      }
      if (currentPath == "/leads-settings/public-api-settings" || currentPath == "/leads-settings/logs" && permissions?.data['lead-settings-view']) {
        checkPermission = true;
      }
      if (currentPath == "/propertyappz/users/add" && permissions?.data['propertyAppz-users-create']) {
        checkPermission = true;
      }
      if (currentPath == "/propertyappz/users/list" && permissions?.data['propertyAppz-users-view']) {
        checkPermission = true;
      }
      if (currentPath == "/propertyappz/user/logs" && permissions?.data['propertyAppz-users-view']) {
        checkPermission = true;
      }
      if (currentPath == "/propertyappz/users/logs" && permissions?.data['propertyAppz-users-view']) {
        checkPermission = true;
      }
      if (currentPath == "/propertyappz/users/edit" && permissions?.data['propertyAppz-users-edit']) {
        checkPermission = true;
      }
      if (currentPath == "/propertyappz/roles/add" && permissions?.data['roles-create']) {
        checkPermission = true;
      }
      if (currentPath == "/propertyappz/roles/list" && permissions?.data['roles-view']) {
        checkPermission = true;
      }
      if (currentPath == "/propertyappz/roles/edit" && permissions?.data['roles-edit']) {
        checkPermission = true;
      }
      if (currentPath == "/propertyappz/tenants/add" && permissions?.data['tenants-create']) {
        checkPermission = true;
      }
      if (currentPath == "/propertyappz/tenants/list" && permissions?.data['tenants-view']) {
        checkPermission = true;
      }
      if (currentPath == "/propertyappz/tenants/view" && permissions?.data['tenants-view']) {
        checkPermission = true;
      }
      if (currentPath == "/propertyappz/tenants/logs" && permissions?.data['tenants-view']) {
        checkPermission = true;
      }
      if (currentPath == "/propertyappz/tenant/logs" && permissions?.data['tenants-view']) {
        checkPermission = true;
      }
      if (currentPath == "/propertyappz/tenants/edit" && permissions?.data['tenants-edit']) {
        checkPermission = true;
      }
      if (currentPath == "/propertyappz/ip-management/update" && (permissions?.data['ip-management-view'] || permissions?.data['ip-management-edit'])) {
        checkPermission = true;
      }
      // Open Routes - Without permission Routes End
      if (currentPath == "/organisation-setup/setup" && permissions?.data['organisation-setup-view']) {
        checkPermission = true;
      }
      if (currentPath == "/integrations/add" && permissions?.data['integrations-create']) {
        checkPermission = true;
      }
      if (currentPath == "/integrations/list" && permissions?.data['integrations-view']) {
        checkPermission = true;
      }
      if (currentPath == "/integrations/edit" && permissions?.data['integrations-edit']) {
        checkPermission = true;
      }
      if (currentPath == "/setting/organisation/setup" && permissions?.data['organisation-view']) {
        checkPermission = true;
      }

      // Document Templates Permissions
      if ((currentPath == "/templates/documents-templates/sales-documents-tabs-view" ||
        currentPath == "/template/documents/sales/welcome-letter/samples-list" ||
        currentPath == "/template/documents/sales/welcome-letter/clone-list" ||
        currentPath == "/template/documents/sales/payment-summary/samples-list" ||
        currentPath == "/template/documents/sales/payment-summary/clone-list" ||
        currentPath == "/template/documents/sales/statement-accounts/samples-list" ||
        currentPath == "/template/documents/sales/statement-accounts/clone-list" ||
        currentPath == "/template/documents/sales/payment-schedule/samples-list" ||
        currentPath == "/template/documents/sales/payment-schedule/clone-list"
      )
        && permissions?.data['document-templates-view']) {
        checkPermission = true;
      }
      if ((currentPath == "/templates/documents-templates/financials-documents-tabs-view" ||
        currentPath == "/template/documents/financials/receipt/samples-list" ||
        currentPath == "/template/documents/financials/receipt/clone-list" ||
        currentPath == "/template/documents/financials/invoice/samples-list" ||
        currentPath == "/template/documents/financials/invoice/clone-list")
        && permissions?.data['document-templates-view']) {
        checkPermission = true;
      }
      if ((currentPath == "/template/documents/sales/welcome-letter/sample-clone" ||
        currentPath == "/template/documents/sales/payment-summary/sample-clone" ||
        currentPath == "/template/documents/sales/statement-accounts/sample-clone" ||
        currentPath == "/template/documents/sales/payment-schedule/sample-clone")
        && permissions?.data['document-templates-create']) {
        checkPermission = true;
      }
      if ((currentPath == "/template/documents/financials/receipt/sample-clone" ||
        currentPath == "/template/documents/financials/invoice/sample-clone")
        && permissions?.data['document-templates-create']) {
        checkPermission = true;
      }
      if ((currentPath == "/template/documents/sales/welcome-letter/clone-list/edit" ||
        currentPath == "/template/documents/sales/payment-summary/clone-list/edit" ||
        currentPath == "/template/documents/sales/statement-accounts/clone-list/edit" ||
        currentPath == "/template/documents/sales/payment-schedule/clone-list/edit")
        && permissions?.data['document-templates-edit']) {
        checkPermission = true;
      }
      if ((currentPath == "/template/documents/financials/receipt/clone-list/edit" ||
        currentPath == "/template/documents/financials/invoice/clone-list/edit")
        && permissions?.data['document-templates-edit']) {
        checkPermission = true;
      }
      if ((currentPath == "/sale-documents/log" ||
        currentPath == "/all/documents/payments/logs" ||
        currentPath == "/documents/payments/receipt/logs" ||
        currentPath == "/payments-documents/log" ||
        currentPath == "/all/documents/sales/logs" ||
        currentPath == "/all/documents/identity/logs" ||
        currentPath == "/nominee-documents/log" ||
        currentPath == "/customer-documents/log") && permissions?.data['documents-logs-view']) {
        checkPermission = true;
      }
      if ((currentPath == "/template/documents/logs" ||
        currentPath == "/template/documents/sales/welcome-letter/logs" ||
        currentPath == "/template/documents/sales/payment-summary/logs" ||
        currentPath == "/template/documents/sales/statement-accounts/logs" ||
        currentPath == "/template/documents/sales/payment-schedule/logs" ||
        currentPath == "/template/documents/financials/receipt/logs" ||
        currentPath == "/template/documents/financials/invoice/logs") && permissions?.data['document-templates-logs-view']) {
        checkPermission = true;
      }

      // Email Templates Sales Permissions
      if ((currentPath == "/templates/email-templates/sales-email-tabs-view" ||
        currentPath == "/template/email/sales/welcome-pack/samples-list" ||
        currentPath == "/template/email/sales/welcome-pack/clone-list" ||
        currentPath == "/template/email/sales/payment-summary/samples-list" ||
        currentPath == "/template/email/sales/payment-summary-email/clone-list" ||
        currentPath == "/template/email/sales/statement-account/samples-list" ||
        currentPath == "/template/email/sales/statement-account-email/clone-list")
        && permissions?.data['email-templates-view']
      ) {
        checkPermission = true;
      }
      if ((currentPath == "/template/email/sales/welcome-pack/sample-clone" ||
        currentPath == "/template/email/sales/payment-summary/sample-clone" ||
        currentPath == "/template/email/sales/statement-account/sample-clone")
        && permissions?.data['email-templates-create']
      ) {
        checkPermission = true;
      }
      if ((currentPath == "/template/email/sales/welcome-email/clone-list/edit" ||
        currentPath == "/template/email/sales/payment-summary-email/clone-list/edit" ||
        currentPath == "/template/email/sales/statement-account-email/clone-list/edit")
        && permissions?.data['email-templates-edit']
      ) {
        checkPermission = true;
      }

      // Email Templates Financials Permissions
      if ((currentPath == "/templates/email-templates/financial-email-tabs-view" ||
        currentPath == "/template/email/financial/invoice-email/samples-list" ||
        currentPath == "/template/email/financial/invoice-email/clone-list" ||
        currentPath == "/template/email/financial/receipt-email/samples-list" ||
        currentPath == "/template/email/financial/receipt-email/clone-list" ||
        currentPath == "/template/email/financial/payment-reminder-email/samples-list" ||
        currentPath == "/template/email/financial/payment-reminder-email/clone-list")
        && permissions?.data['email-templates-view']
      ) {
        checkPermission = true;
      }
      if ((currentPath == "/template/email/financial/invoice-email/sample-clone" ||
        currentPath == "/template/email/financial/receipt-email/sample-clone" ||
        currentPath == "/template/email/financial/payment-reminder-email/sample-clone")
        && permissions?.data['email-templates-create']
      ) {
        checkPermission = true;
      }
      if ((currentPath == "/template/email/financial/invoice-email/clone-list/edit" ||
        currentPath == "/template/email/financial/receipt-email/clone-list/edit" ||
        currentPath == "/template/email/financial/payment-reminder-email/clone-list/edit")
        && permissions?.data['email-templates-edit']
      ) {
        checkPermission = true;
      }
      if ((currentPath == "/template/email/logs" ||
        currentPath == "/template/email/sales/welcome-pack/logs" ||
        currentPath == "/template/email/financial/invoice-email/logs" ||
        currentPath == "/template/email/financial/receipt-email/logs" ||
        currentPath == "/template/email/sales/statement-account-email/logs" ||
        currentPath == "/template/email/financial/payment-reminder-email/logs" ||
        currentPath == "/template/email/sales/payment-summary-email/logs")
        && permissions?.data['email-templates-logs-view']) {
        checkPermission = true;
      }

      //  For open URLs
      // if (currentPath == "/reports/due-payments" || permissions?.data['payment-report']) {
      //   checkPermission = true;
      // }
      // if (currentPath == "/reports/projects" || permissions?.data['project-report']) {
      //   checkPermission = true;
      // }
      if (currentPath == "/reports/projects" && permissions?.data['project-reports-view']) {
        checkPermission = true;
      }
      if (currentPath == "/report/sales" && permissions?.data['sale-reports-view']) {
        checkPermission = true;
      }
      if (currentPath == "/report/payments" && permissions?.data['payment-reports-view']) {
        checkPermission = true;
      }
      if (currentPath == "/report/overdue-payments" && permissions?.data['overdue-reports-view']) {
        checkPermission = true;
      }
      if (currentPath == "/sales/receipts") {
        checkPermission = true;
      }
      // Permissions ORG bank module
      if (currentPath == "/organisation/bank/add" && permissions?.data['org-bank-details-create']) {
        checkPermission = true;
      }
      if (currentPath == "/organisation/bank/list" && permissions?.data['org-bank-details-view']) {
        checkPermission = true;
      }
      if (currentPath == "/organisation/bank/edit" && permissions?.data['org-bank-details-edit']) {
        checkPermission = true;
      }
      if ((currentPath == "/organisation/bank/logs" || currentPath == "/organisation/banks/logs") && permissions?.data['org-bank-details-view']) {
        checkPermission = true;
      }
      // Permissions Platform bank module
      if (currentPath == "/catalogue/bank/add" && permissions?.data['bank-details-create']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/bank/list" && permissions?.data['bank-details-view']) {
        checkPermission = true;
      }
      if (currentPath == "/catalogue/bank/edit" && permissions?.data['bank-details-edit']) {
        checkPermission = true;
      }
      if (currentPath == "/sales/receipt/view") {
        checkPermission = true;
      }
      if (currentPath == "/payment/receipt/view") {
        checkPermission = true;
      }
      if (currentPath == "/platform/dashboard") {
        checkPermission = true;
      }
      if (currentPath == "/dashboard") {
        checkPermission = true;
      }
      if (currentPath == "/login") {
        checkPermission = true;
      }
      if (currentPath == "/auth-lock-screen") {
        checkPermission = true;
      }
      if (currentPath == "/admin/login") {
        checkPermission = true;
      }
      if (currentPath == "/propertyappz/login") {
        checkPermission = true;
      }
      if (currentPath == "/logout") {
        checkPermission = true;
      }
      if (currentPath == "/user/profile") {
        checkPermission = true;
      }
      if (currentPath == "/user/settings") {
        checkPermission = true;
      }
      if (currentPath == "/") {
        checkPermission = true;
      }
      if (isAuthProtected && !localStorage.getItem("token")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
      //  else if(get_user_permissions('properties-view')){
      //  else if(!permissions.data['properties-view']){
      //   return (
      //     <Redirect
      //       to={{ pathname: "/404", state: { from: props.location } }}
      //     />
      //   )
      // }
      return (
        <>
          {props.match.path == '*' ? <>
            <PageNotFound />
          </> : <>
            <Layout>
              {checkPermission ? <>
                <Component {...props} />
              </> : <>
                <AccessDenied />
              </>}
            </Layout>
          </>}
        </>
      )
    }}
  />
)

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any
}

export default AppRoute
