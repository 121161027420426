import axios from "axios";

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
import constant from '../../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

export const get_all_cities = async () => {
    let response = await axios.get(baseURL + "/api/cities", config);
    return response.data.data;
}
export const update_city = async (city_id, name, country_id) => {
    let response = await axios.patch(baseURL + "/api/cities/" + city_id, {
        "title": name,
        "country_id": country_id
    }, config);
    return response.data.data;
}
export const delete_city = async (city_id) => {
    let response = await axios.delete(baseURL + "/api/cities/" + city_id, config);
    return response.data.data;
}
export const get_all_platform_cities = async () => {
    let response = await axios.get(baseURL + "/api/platform-cities", config);
    return response.data.data;
}
export const update_platform_city = async (city_id, name, country_id) => {
    let response = await axios.patch(baseURL + "/api/platform-cities/" + city_id, {
        "title": name,
        "country_id": country_id
    }, config);
    return response.data.data;
}
export const delete_platform_city = async (city_id) => {
    let response = await axios.delete(baseURL + "/api/platform-cities/" + city_id, config);
    return response.data.data;
}