import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../UI/ui-toast'

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

import constant from '../../../constants.json';
const baseURL_propertyAppz = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

class UserSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      optionGroupRole: [],
      c_picture: null,
      redirect: null,
      oldPassword: "",
      id: "",
      name: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    
  }

  componentDidMount() {
    this.setState({ oldPassword: "" });
  }

  
  handleSubmit(e) {
    e.preventDefault();
  }

  render() {
    //meta title
    document.title = "Settings";
    const { validation } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Dashboard" breadcrumbItem="Settings" parentLink="/dashboard" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        
                      }}
                      validationSchema={Yup.object().shape({
                        
                      })}
                      onSubmit={values => {
                        var passwordUpdateUrl;
                        var URL;
                        if(localStorage.getItem('adminUserLoginFlag')){
                          passwordUpdateUrl = "/api/platform-change-password";
                          URL = baseURL;
                        }
                        else if(localStorage.getItem('propertyAppzLoginFlag')){
                          passwordUpdateUrl = "/api/propertyappz-change-password";
                          URL = baseURL_propertyAppz;
                        }else{
                          passwordUpdateUrl = '/api/change-password';
                          URL = baseURL;  
                        }
                        axios.post(URL + passwordUpdateUrl, {
                          
                          "old_password": this.state.oldPassword,
                          "password": values.password,
                          "password_confirmation": values.password_confirm,
                          
                        }, config)
                          .then((response) => {
                            showToast("Success", "The password has been updated successfully.", "success");
                            
                            console.log(response);
                          })
                          .catch((error) => {
                            console.log(error);
                            var responseErrors = error.response.data.errors;
                            var errorMessage = "The password could not be updated successfully.";
                            if(responseErrors != undefined){
                              Object.entries(responseErrors).forEach(([key, value]) => 
                                {
                                  errorMessage = errorMessage + "<br />&#10003; " + value;
                                }
                              );
                            }
                            else{
                              errorMessage = errorMessage + "<br />&#10003; " + error.response.data.message;
                            }
                            showToast("Failed", errorMessage, "error");
                            console.log(error.response.data.errors);
                          });
                      }}
                    >
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation">
                          <Row>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="old_password">
                                  Old Password
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    id="old_password"
                                    name="old_password"
                                    placeholder="Old Password"
                                    value={this.state.oldPassword}
                                    onChange={(e) => this.setState({ oldPassword: e.target.value })}
                                    type="password"
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="password">
                                  Password
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="password"
                                    placeholder="Password"
                                    type="password"
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="password_confirm">
                                  Confirm Password
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="password_confirm"
                                    placeholder="Confirm Password"
                                    type="password"
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            
                          </Row>
                          <Button color="primary" type="submit" className="float-end" >
                            Update Password
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default UserSettings;
