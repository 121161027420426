import React, { Component } from "react";
import "toastr/build/toastr.min.css";
import { Redirect, Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row, InputGroup, Form
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { get_single_tenant } from '../api/tenantApi';
class ViewTenant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "",
            redirect: null,
            optionGroup: [],
            id: "",
            name: '',
            doc_encryption_variable: '',
            password: '',
            password_confirmation: '',
            spaces_folder: '',
            domains: [{ "api": '', "frontend": '' }],
            ip_addresses: [''],
        };
    }
    componentDidMount() {
        Promise.all([get_single_tenant(window.localStorage.getItem("tenant_id"))])
            .then((values) => {
                const tenant = values[0];
                const domains = tenant.domain_name.map((apiDomain, index) => ({
                    api: apiDomain,
                    frontend: tenant.frontend_domain[index] || ''
                }));

                this.setState({
                    id: tenant.id,
                    name: tenant.name,
                    doc_encryption_variable: tenant.doc_encryption_variable,
                    spaces_folder: tenant.spaces_folder,
                    domains: domains,
                    ip_addresses: tenant.ip_address,
                });
            });
    }

    render() {
        //meta title
        document.title = "Add Tenant";
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Tenants" breadcrumbItem="View Tenant" parentLink="/propertyappz/tenants/list" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Form>
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="name">
                                                            Name*
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="name"
                                                                name="name"
                                                                placeholder="Name"
                                                                type="text"
                                                                className={"form-control"}
                                                                value={this.state.name}
                                                                disabled
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="doc_encryption_variable">
                                                            Document Encryption Variable*
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="doc_encryption_variable"
                                                                name="doc_encryption_variable"
                                                                placeholder="doc_encryption_variable"
                                                                type="text"
                                                                className={"form-control"}
                                                                value={this.state.doc_encryption_variable}
                                                                disabled
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="spaces_folder">
                                                            Spaces Folder*
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="spaces_folder"
                                                                name="spaces_folder"
                                                                placeholder="Spaces Folder"
                                                                type="text"
                                                                className={"form-control"}
                                                                value={this.state.spaces_folder}
                                                                disabled
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <Card>
                                                        <CardTitle className="mb-2">Domains*</CardTitle>
                                                        <CardBody className="border border-primary">
                                                            {this.state.domains.map((domain, index) => (
                                                                <div key={index}>
                                                                    <FormGroup className="mb-3 row d-flex">
                                                                        <Label className="col-md-2 col-form-label">Frontend Domain {index + 1}</Label>
                                                                        <div className="col-md-8">
                                                                            <Input
                                                                                type="text"
                                                                                value={domain.frontend}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                    <FormGroup className="mb-3 row d-flex">
                                                                        <Label className="col-md-2 col-form-label">API Domain {index + 1}</Label>
                                                                        <div className="col-md-8">
                                                                            <Input
                                                                                type="text"
                                                                                value={domain.api}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                    </FormGroup>
                                                                </div>
                                                            ))}
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <Card>
                                                        <CardTitle className="mb-2">IP Addresses*</CardTitle>
                                                        <CardBody className="border border-primary">
                                                            {this.state.ip_addresses.map((ip, index) => (
                                                                <FormGroup key={index} className="mb-3 row d-flex">
                                                                    <Label className="col-md-2 col-form-label">IP Address {index + 1}</Label>
                                                                    <div className="col-md-8">
                                                                        <Input
                                                                            type="text"
                                                                            value={ip}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </FormGroup>
                                                            ))}
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Link to="/propertyappz/tenants/list" className="btn btn-primary">
                                                Back
                                            </Link>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ViewTenant;
