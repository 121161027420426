import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { Link, useHistory, Redirect } from "react-router-dom";
import { isEmpty, size } from "lodash";
import { showToast } from '../../../UI/ui-toast'

import DeleteModal from "../../../../components/Common/DeleteModal";
import moment from "moment";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { get_all_users_without_me, delete_user } from '../api/usersApi';

import default_avatar from "../../../../assets/images/avatar_01.png";

import { get_user_permissions } from "../../../../helpers/custom_helper";
var propertyappzUsersView, propertyappzUsersDelete, propertyappzUsersEdit, propertyappzUsersCreate;

class ListUsers extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 10,
            listData: [],
            listdata: "",
            viewmodal: false,
            modal: false,
            isOpen: false,
            selectDate: false,
            deleteModal: false,
            recordDeleteID: null,
            redirect: null
        };
        this.handleRecordClick = this.handleRecordClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleRecordClicks = this.handleRecordClicks.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.onClickActivityLog = this.onClickActivityLog.bind(this);
    }
    componentDidMount() {
        propertyappzUsersCreate = get_user_permissions('propertyAppz-users-create')
        propertyappzUsersView = get_user_permissions('propertyAppz-users-view')
        propertyappzUsersEdit = get_user_permissions('propertyAppz-users-edit')
        propertyappzUsersDelete = get_user_permissions('propertyAppz-users-delete')
        localStorage.removeItem("current_user");
        Promise.all([get_all_users_without_me()]).then((values) => {
            this.setState({ listData: values[0] });
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    };
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };
    onClickDelete = listdata => {
        this.setState({ listdata: listdata });
        this.setState({ deleteModal: true });
    };

    handleDeletejob = () => {
        const { listdata } = this.state;
        if (listdata.id !== undefined) {
            console.log(this.state.recordDeleteID);
            Promise.all([delete_user(this.state.recordDeleteID)]).then((values) => {
                showToast("Success", "The user " + listdata.full_name + " has been deleted successfully.", "success");
                Promise.all([get_all_users_without_me()]).then((values) => {
                    console.log(values[0]);
                    this.setState({ listData: values[0] });
                });
            }).catch((error) => {
                showToast("Failed", "User Not Deleted Successfully", "error");
            });
            this.setState({ deleteModal: false });
        }
    };
    handleRecordClicks = () => {
        this.setState({ listdata: "", isEdit: false });
        this.toggle();
    };

    handleRecordClick = arg => {
        const listdata = arg;
        window.localStorage.setItem("current_user", listdata.id);
        this.setState({ redirect: "/propertyappz/users/edit" });

    };
    onClickActivityLog = arg => {
        const listdata = arg;
        window.localStorage.setItem("user_id", listdata.id);
        this.setState({ redirect: "/propertyappz/user/logs" });
    }
    render() {

        const { deleteModal } = this.state;

        const listdata = this.state.listdata;

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        const columns = [
            {
                dataField: "picture",
                text: "Picture",
                sort: false,
                formatter: (cellContent, listdata) => (

                    <div>
                        <img src={listdata.picture == null ? default_avatar : listdata.picture} alt="" className="avatar-sm" />
                    </div>

                ),
            },
            {
                dataField: "full_name",
                text: "Name",
                sort: true,
            },
            {
                dataField: "email",
                text: "Email",
                sort: true,
            },
            {
                dataField: "role",
                text: "Role",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {listdata.roles[0].name}
                    </>

                ),
            },
            {
                dataField: "updated_name",
                text: "Updated By",
                sort: true,
            },
            {
                dataField: "updated_at",
                text: "Updated At",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {moment(listdata.updated_at).format('DD/MM/YYYY h:mma')}
                    </>

                ),
            },
            {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                formatter: (cellContent, listdata) => (
                    <>
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            {1 ? <>
                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-primary"
                                        onClick={() => this.onClickActivityLog(listdata)}
                                    >
                                        <i className="mdi mdi-file-document-outline" id="logtooltip" />
                                        <UncontrolledTooltip placement="top" target="logtooltip">
                                            Activity Log
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </> : <></>}
                            {propertyappzUsersEdit ? <>
                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-info"
                                        onClick={() => this.handleRecordClick(listdata)}
                                    >
                                        <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                        <UncontrolledTooltip placement="top" target="edittooltip">
                                            Edit
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </> : <></>}
                            {propertyappzUsersDelete ? <>
                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-danger"
                                        onClick={() => this.onClickDelete(listdata)}
                                    >
                                        <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                        <UncontrolledTooltip placement="top" target="deletetooltip">
                                            Delete
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </> : <></>}
                        </ul>
                    </>
                ),
            },
        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            // totalSize: productsData.length, // replace later with size(customers),
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;

        //meta title
        document.title = "List Users";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                console.log(row);
                this.setState({ recordDeleteID: row.id });

            }
        }
        return (
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeletejob}
                    record={`user`}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Users" breadcrumbItem="List Users" />

                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">List Users</h5>
                                                {propertyappzUsersCreate ? <>
                                                    <div className="flex-shrink-0">
                                                        <Link to="/propertyappz/users/add" className="btn btn-primary me-1">Add New User</Link>
                                                    </div>
                                                </> : null}
                                            </div>
                                        </CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.listData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.listData}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            selectRow={selectRow}
                                                                            rowEvents={rowEvents}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            ref={this.node}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                                <br />
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default ListUsers;
