import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";

import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get_current_organisation } from '../api/organisationApi';
const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    // 'Content-Type': 'application/json',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

import { get_user_permissions } from "../../../helpers/custom_helper"
var orgOrgEdit, orgOrgView;
import { fetchLogo } from '../../../store/logo/actions';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
class SetupOrgansation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      optionGroupCountry: [],
      optionGroupCity: [],
      redirect: null,
      org_id: '',
      org_name: '',
      org_phoneOne: '',
      org_phoneTwo: '',
      org_address_line_1: '',
      org_address_line_2: '',
      org_email: '',
      org_postcode: '',
      org_country_id: '',
      org_city_id: '',
      org_city_title: '',
      org_country_title: '',
      org_icon: '',
      org_logo: '',
      org_white_logo: '',
      org_icon_show: '',
      org_logo_show: '',
      org_white_logo_show: '',
      org_website: '',
      logoSelected: -1,
      whiteLogoSelected: -1,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }
  handleChange(event) {
  }

  async getOptionsCountry() {
    const res = await axios.get(baseURL + "/api/countries-listing", config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.title
    }))
    this.setState({ optionGroupCountry: options })
  }
  async getOptionsCity(country_id) {
    const res = await axios.get(baseURL + "/api/country-cities/" + country_id, config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.title
    }))
    this.setState({ optionGroupCity: options })
  }
  componentDidMount() {
    orgOrgEdit = get_user_permissions('organisation-edit');
    orgOrgView = get_user_permissions('organisation-view');

    Promise.all([get_current_organisation()]).then((values) => {
      var toastTitle = "Success";
      var toastMessage = "Organisation Record Fetched";
      var toastType = "success";
      this.showToast(toastTitle, toastMessage, toastType);
      this.setState({ org_id: values[0].id })
      this.setState({ org_name: values[0].name });
      this.setState({ org_phoneOne: values[0].phone_1 });
      this.setState({ org_phoneTwo: values[0].phone_2 });
      this.setState({ org_email: values[0].email });
      this.setState({ org_address_line_1: values[0].address_line_1 });
      this.setState({ org_address_line_2: values[0].address_line_2 });
      this.setState({ org_postcode: values[0].post_code });
      this.setState({ org_city_id: values[0].city_id });
      this.setState({ org_country_id: values[0].country_id });
      this.setState({ org_country_title: values[0].country_title });
      this.setState({ org_city_title: values[0].city_title });
      this.setState({ org_icon_show: values[0].icon });
      this.setState({ org_logo_show: values[0].logo });
      this.setState({ org_white_logo_show: values[0].white_logo });
      this.setState({ org_website: values[0].website });
      if (this.state.org_logo_show != null) {
        this.setState({ logoSelected: 1 })
      }
      if (this.state.org_white_logo_show != null) {
        this.setState({ whiteLogoSelected: 1 })
      }
    }).catch((error) => {
      var toastTitle = "Failed";
      var toastMessage = error.response.data.message;
      var toastType = "error";
      this.showToast(toastTitle, toastMessage, toastType);
      console.log(error);
    });
    this.getOptionsCountry()
  }
  checkValidation = () => {
    if (this.state.logoSelected != 1) {
      this.setState({ logoSelected: 0 })
    }
    if (this.state.org_logo_show != null) {
      this.setState({ logoSelected: 1 })
    }
    if (this.state.whiteLogoSelected != 1) {
      this.setState({ whiteLogoSelected: 0 })
    }
    if (this.state.org_white_logo_show != null) {
      this.setState({ whiteLogoSelected: 1 })
    }
  }
  handleSelectGroupCountry = selectedGroupCountry => {
    this.setState({ selectedGroupCountry })
    this.getOptionsCity(selectedGroupCountry.value)
    this.setState({ org_city_title: '' })
    this.setState({ org_country_title: selectedGroupCountry.label });
    this.setState({ org_country_id: selectedGroupCountry.value });
    console.log(selectedGroupCountry);
  }
  handleSelectGroupCity = selectedGroupCity => {
    this.setState({ org_city_title: selectedGroupCity.label });
    this.setState({ org_city_id: selectedGroupCity.value });
    this.setState({ selectedGroupCity })
    console.log(selectedGroupCity);
  }

  handleImageChangeLogo = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log('Logo');
      console.log(e.target.files[0]);
      this.setState({ org_logo: e.target.files[0] });
    }
  };
  handleImageChangeWhiteLogo = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log('White Logo');
      console.log(e.target.files[0]);
      this.setState({ org_white_logo: e.target.files[0] });
    }
  };
  handleImageChangeIcon = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log('Icon');
      console.log(e.target.files[0]);
      this.setState({ org_icon: e.target.files[0] });
    }
  };

  handleSubmit(e) {

    e.preventDefault();
    console.log(e.target.organisationName.value)
    console.log(e.target.addresslineOne.value)
    console.log(e.target.addresslineTwo.value)
    console.log(e.target.postcode.value)
    console.log(e.target.phoneOne.value)
    console.log(e.target.phoneTwo.value)
    console.log(e.target.email.value)
    console.log(this.state.org_country_id);
    console.log(this.state.org_city_id);
    console.log(this.state.org_website);

    if (!this.state.org_name || !this.state.org_address_line_1 || !this.state.org_phoneOne || !this.state.org_postcode || !this.state.org_email) {
      let missingFields = '';
      if (!this.state.org_name) {
        missingFields += '<li>Organisation Name</li>';
      }
      if (!this.state.org_address_line_1) {
        missingFields += '<li>Address Line 1</li>';
      }
      if (!this.state.org_phoneOne) {
        missingFields += '<li>Phone 1</li>';
      }
      if (!this.state.org_postcode) {
        missingFields += '<li>Postcode</li>';
      }
      if (!this.state.org_email) {
        missingFields += '<li>Email</li>';
      }
      this.showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
      return false;
    }

    if (this.state.logoSelected == 0) {
      this.showToast("Organisation", "Logo not selected, select first.", "error")
    }
    else if (this.state.whiteLogoSelected == 0) {
      this.showToast("Organisation", "White Logo not selected, select first.", "error")
    } else {
      axios.post(baseURL + "/api/organisation-update/" + this.state.org_id, {

        "name": e.target.organisationName.value,
        "address_line_1": e.target.addresslineOne.value,
        "address_line_2": e.target.addresslineTwo.value,
        "city_id": this.state.org_city_id,
        "country_id": this.state.org_country_id,
        "post_code": e.target.postcode.value,
        "phone_1": e.target.phoneOne.value,
        "phone_2": e.target.phoneTwo.value,
        "email": e.target.email.value,
        "website": e.target.website.value,
        "logo": this.state.org_logo,
        "icon": this.state.org_icon,
        "white_logo": this.state.org_white_logo
      }, config)
        .then((response) => {
          this.props.fetchLogo();
          var toastTitle = "Success";
          var toastMessage = "The organisation has been updated successfully.";
          var toastType = "success";
          this.showToast(toastTitle, toastMessage, toastType);
          // this.setState({ redirect: "/organisation-setup/setup" });
          Promise.all([get_current_organisation()]).then((values) => {
            var toastTitle = "Success";
            var toastMessage = "Organisation Record Fetched";
            var toastType = "success";
            this.showToast(toastTitle, toastMessage, toastType);
            this.setState({ org_id: values[0].id })
            this.setState({ org_name: values[0].name });
            this.setState({ org_phoneOne: values[0].phone_1 });
            this.setState({ org_phoneTwo: values[0].phone_2 });
            this.setState({ org_email: values[0].email });
            this.setState({ org_address_line_1: values[0].address_line_1 });
            this.setState({ org_address_line_2: values[0].address_line_2 });
            this.setState({ org_postcode: values[0].post_code });
            this.setState({ org_city_id: values[0].city_id });
            this.setState({ org_country_id: values[0].country_id });
            this.setState({ org_country_title: values[0].country_title });
            this.setState({ org_city_title: values[0].city_title });
            this.setState({ org_icon_show: values[0].icon });
            this.setState({ org_logo_show: values[0].logo });
            this.setState({ org_white_logo_show: values[0].white_logo });
            this.setState({ org_website: values[0].website });
            if (this.state.org_logo_show != null) {
              this.setState({ logoSelected: 1 })
            }
            if (this.state.org_white_logo_show != null) {
              this.setState({ whiteLogoSelected: 1 })
            }
          }).catch((error) => {
            var toastTitle = "Failed";
            var toastMessage = error.response.data.message;
            var toastType = "error";
            this.showToast(toastTitle, toastMessage, toastType);
            console.log(error);
          });
        })
        .catch((error) => {
          var toastTitle = "Failed";
          var toastMessage = "Organisation not Updated";
          var toastType = "error";
          this.showToast(toastTitle, toastMessage, toastType);
          console.log(error);
        });
    }
  }
  showToast(title, message, toastType) {
    //position class
    var positionClass = "toast-top-right";
    toastr.options = {
      positionClass: positionClass,
      timeOut: this.state.timeOut,
      extendedTimeOut: this.state.extendedTimeOut,
      showEasing: this.state.showEasing,
      hideEasing: this.state.hideEasing,
      showMethod: this.state.showMethod,
      hideMethod: this.state.hideMethod,
      showDuration: this.state.showDuration,
      hideDuration: this.state.hideDuration,
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }
  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }

  render() {
    //meta title
    document.title = "Edit Organisation";
    const { validation } = this.state;
    const { selectedGroupCountry } = this.state;
    const { selectedGroupCity } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Organisation" breadcrumbItem="Edit Organisation" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik>
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation" onSubmit={this.handleSubmit}>
                          <Row>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="organisationName">
                                  Organisation Name*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="organisationName"
                                    placeholder="Organisation Name"
                                    type="text"
                                    value={this.state.org_name}
                                    onChange={(e) => this.setState({ org_name: e.target.value })}
                                    className={
                                      "form-control" +
                                      (errors.organisationName && touched.organisationName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="organisationName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="addresslineOne">
                                  Address Line 1*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="addresslineOne"
                                    placeholder="Address Line 1"
                                    type="text"
                                    value={this.state.org_address_line_1}
                                    onChange={(e) => this.setState({ org_address_line_1: e.target.value })}
                                    className={
                                      "form-control" +
                                      (errors.addresslineOne && touched.addresslineOne
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="addresslineOne"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="addresslineTwo">
                                  Address Line 2
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="addresslineTwo"
                                    placeholder="Address Line 2"
                                    type="text"
                                    value={this.state.org_address_line_2}
                                    onChange={(e) => this.setState({ org_address_line_2: e.target.value })}
                                    className={
                                      "form-control" +
                                      (errors.addresslineTwo && touched.addresslineTwo
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="addresslineTwo"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectCountry">Country Select*</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={{ label: this.state.org_country_title, value: 0 }}
                                    onChange={this.handleSelectGroupCountry}
                                    options={this.state.optionGroupCountry}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectCity">City Select*</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={{ label: this.state.org_city_title, value: 0 }}
                                    onChange={this.handleSelectGroupCity}
                                    options={this.state.optionGroupCity}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </div>
                            </Col>

                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="postcode">Postcode*</Label>
                                <div className="col-md-10">
                                  <Field
                                    name="postcode"
                                    placeholder="Postcode"
                                    type="text"
                                    value={this.state.org_postcode}
                                    onChange={(e) => this.setState({ org_postcode: e.target.value })}
                                    className={
                                      "form-control" +
                                      (errors.postcode && touched.postcode
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="postcode"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="phoneOne">
                                  Phone 1*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="phoneOne"
                                    placeholder="Phone 1"
                                    type="text" value={this.state.org_phoneOne}
                                    onChange={(e) => this.setState({ org_phoneOne: e.target.value })}
                                    className={
                                      "form-control" +
                                      (errors.phoneOne && touched.phoneOne
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="phoneOne"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="phoneTwo">
                                  Phone 2
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="phoneTwo"
                                    placeholder="Phone 2"
                                    type="text"
                                    value={this.state.org_phoneTwo}
                                    onChange={(e) => this.setState({ org_phoneTwo: e.target.value })}
                                    className={
                                      "form-control" +
                                      (errors.phoneTwo && touched.phoneTwo
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="phoneTwo"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="email">
                                  Email*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="email"
                                    // readOnly
                                    type="text"
                                    value={this.state.org_email}
                                    onChange={(e) => this.setState({ org_email: e.target.value })}
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="website">
                                  Website
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="website"
                                    placeholder="Website"
                                    type="text"
                                    value={this.state.org_website}
                                    onChange={(e) => this.setState({ org_website: e.target.value })}
                                    className={
                                      "form-control" +
                                      (errors.website && touched.website
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="website"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="icon">
                                  Icon
                                </Label>
                                <div className="col-md-10">
                                  <Input className="form-control form-control" type="file" id="inputIcon" accept="image/*"
                                    onChange={this.handleImageChangeIcon} />
                                </div>
                              </div>
                            </Col>
                            {this.state.org_icon_show == null ?
                              <Col md="12">
                                <div className="mb-3 row d-flex">
                                  <Label className="col-md-2 col-form-label" htmlFor="icon">
                                    Existing Icon
                                  </Label>
                                  <div className="col-md-10 mt-2">
                                    No Icon Exists
                                  </div>
                                </div>
                              </Col>
                              :
                              <Col md="12">
                                <div className="mb-3 row d-flex">
                                  <Label className="col-md-2 col-form-label" htmlFor="icon">
                                    Existing Icon
                                  </Label>
                                  <div className="col-md-10">
                                    <img className="rounded me-2" alt="icon" width="50" src={this.state.org_icon_show} data-holder-rendered="true" />
                                  </div>
                                </div>
                              </Col>
                            }
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="logo">
                                  Logo*
                                </Label>
                                <div className="col-md-10">
                                  <Input className="form-control form-control" type="file" id="inputLogo" accept="image/*"
                                    onChange={this.handleImageChangeLogo} />
                                  {this.state.logoSelected == 0 ? <>
                                    <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Logo is not selected, please select first</p>
                                  </> : <></>
                                  }
                                </div>
                              </div>
                            </Col>
                            {this.state.org_logo_show == null ?
                              <Col md="12">
                                <div className="mb-3 row d-flex">
                                  <Label className="col-md-2 col-form-label" htmlFor="logo">
                                    Existing Logo
                                  </Label>
                                  <div className="col-md-10 mt-2">
                                    No Logo Exists
                                  </div>
                                </div>
                              </Col>
                              :
                              <Col md="12">
                                <div className="mb-3 row d-flex">
                                  <Label className="col-md-2 col-form-label" htmlFor="logo">
                                    Existing Logo
                                  </Label>
                                  <div className="col-md-10">
                                    <img className="rounded me-2" alt="logo" width="50" src={this.state.org_logo_show} data-holder-rendered="true" />
                                  </div>
                                </div>
                              </Col>
                            }
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="WhiteLogo">
                                  White Logo*
                                </Label>
                                <div className="col-md-10">
                                  <Input className="form-control form-control" type="file" id="WhiteLogo" accept="image/*"
                                    onChange={this.handleImageChangeWhiteLogo} />
                                  {this.state.whiteLogoSelected == 0 ? <>
                                    <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> White Logo is not selected, please select first</p>
                                  </> : <></>
                                  }
                                </div>
                              </div>
                            </Col>
                            {this.state.org_white_logo_show == null ?
                              <Col md="12">
                                <div className="mb-3 row d-flex">
                                  <Label className="col-md-2 col-form-label" htmlFor="WhiteLogo">
                                    Existing White Logo
                                  </Label>
                                  <div className="col-md-10 mt-2">
                                    No White Logo Exists
                                  </div>
                                </div>
                              </Col>
                              :
                              <Col md="12">
                                <div className="mb-3 row d-flex">
                                  <Label className="col-md-2 col-form-label" htmlFor="WhiteLogo">
                                    Existing White Logo
                                  </Label>
                                  <div className="col-md-10">
                                    <img className="rounded me-2" style={{ "backgroundColor": "#2a3042" }} alt="logo" width="50" src={this.state.org_white_logo_show} data-holder-rendered="true" />
                                  </div>
                                </div>
                              </Col>
                            }
                          </Row>
                          {orgOrgEdit ? <>
                            <Button color="primary" onClick={this.checkValidation} type="submit" className="float-end">
                              Update Organisation
                            </Button>
                          </> : <></>}
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
SetupOrgansation.propTypes = {
  fetchLogo: PropTypes.func,
  logoUrl: PropTypes.string
};
const mapStateToProps = (state) => {
  return {
    logoUrl: state.Logo.logo.logoUrl
  };
};

export default connect(mapStateToProps, { fetchLogo })(withTranslation()(SetupOrgansation));
