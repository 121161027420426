import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle, Button, Table, Label, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { isEmpty, size } from "lodash";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import moment from "moment";
import { payment_receipt_detail } from '../api/paymentsApi'
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { numberWithCommas } from "pages/UI/numeric-helper";
class PaymentReceiptView extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 500,
            listData: [],
            listdata: "",
            paymentDetail: "",
            recordDeleteID: null,
            redirect: null
        };
    }
    componentDidMount() {
        Promise.all([payment_receipt_detail(localStorage.getItem('current_payment_id'))]).then((values) => {
            console.log(values[0]);
            this.setState({ paymentDetail: values[0] })
            this.setState({ listData: values[0].SalesReceiptDetails });
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    };
    render() {
        const listdata = this.state.listData;
        const { paymentDetail, listData } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        const columns = [
            {
                dataField: "sales_receipt.receipt_date",
                text: "Receipt Date",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>{listdata.sales_receipt.receipt_date}</>
                ),
            },
            {
                dataField: "sales_receipt.received_amount",
                text: `${'Receipt Amount '} (${paymentDetail.currency_name ? paymentDetail.currency_name : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>{numberWithCommas(listdata.sales_receipt.received_amount)}</>
                ),
            },
            {
                dataField: "sales_receipt.mode_of_payment",
                text: 'Mode Of Payment',
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>{listdata.sales_receipt.mode_of_payment}</>
                ),
            },
            {
                dataField: "previously_paid",
                text: `${'Previously Paid '} (${paymentDetail.currency_name ? paymentDetail.currency_name : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>{numberWithCommas(listdata.previously_paid)}</>
                ),
            },
            {
                dataField: "adjusted",
                text: `${'Adjusted '} (${paymentDetail.currency_name ? paymentDetail.currency_name : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>{numberWithCommas(listdata.adjusted)}</>
                ),
            },
            {
                dataField: "status",
                text: `${'Status'}`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>{listdata.status}</>
                ),
            },
        ];


        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];
        const pageOptions = {
            sizePerPage: 500,
            // totalSize: productsData.length, // replace later with size(customers),
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;
        //meta title
        document.title = "Payment Receipt Details";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState({ recordDeleteID: row.id });
            }
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Payment" breadcrumbItem="Payment Receipt Details" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <div className="mb-5 d-flex align-items-center">
                                            <h6 className="mb-0 card-title flex-grow-1">Received Amount Details</h6>
                                        </div>
                                        <div className="row">
                                            <Col md="12">
                                                <FormGroup className="mb-3 row d-flex">
                                                    <Label className="col-md-2 col-form-label">
                                                        <strong>Due Date</strong>
                                                    </Label>
                                                    <div className="col-md-2">
                                                        <Label className="col-md-12 col-form-label">
                                                            {paymentDetail.due_date}
                                                        </Label>
                                                    </div>
                                                    <div className="col-md-4"></div>
                                                    <Label className="col-md-2 col-form-label">
                                                        <strong>Total Amount</strong>
                                                    </Label>
                                                    <div className="col-md-2">
                                                        <Label className="col-md-12 col-form-label">
                                                            <strong>{paymentDetail.currency_name}</strong> {" "} {numberWithCommas(paymentDetail.amount)}
                                                        </Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </div>
                                        <div className="row">
                                            <Col md="12">
                                                <FormGroup className="mb-3 row d-flex">
                                                    <Label className="col-md-2 col-form-label">
                                                        <strong>Payment Type</strong>
                                                    </Label>
                                                    <div className="col-md-2">
                                                        <Label className="col-md-12 col-form-label">
                                                            {paymentDetail.payment_types_name}
                                                        </Label>
                                                    </div>
                                                    <div className="col-md-4"></div>
                                                    <Label className="col-md-2 col-form-label">
                                                        <strong>Payment Status</strong>
                                                    </Label>
                                                    <div className="col-md-2">
                                                        <Label className="col-md-12 col-form-label">
                                                            {paymentDetail.status}
                                                        </Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <div className="mb-5 d-flex align-items-center">
                                            <h6 className="mb-0 card-title flex-grow-1">List Adjustment Payments</h6>
                                        </div>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.listData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.listData}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            // selectRow={selectRow}
                                                                            rowEvents={rowEvents}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            ref={this.node}
                                                                        />
                                                                    </div>
                                                                    <div className="table-responsive">
                                                                        <Table className="table mb-3 mt-0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th><strong>Total Received: </strong></th>
                                                                                    <td><strong>{paymentDetail.currency_name}{" "}{numberWithCommas(paymentDetail.paid)}</strong></td>
                                                                                    <th>{" "}</th>
                                                                                    <th>{" "}</th>
                                                                                    <th>{" "}</th>
                                                                                    <th><strong>Outstanding Amount: </strong></th>
                                                                                    <td><strong>{paymentDetail.currency_name}{" "}{numberWithCommas((paymentDetail.amount) - (paymentDetail.paid))}</strong></td>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default PaymentReceiptView;
