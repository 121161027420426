import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../../UI/ui-toast'

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};
import { get_all_identity_sub_types } from '../../api/customersApi'
import constant from '../../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
var newData = [];
class AddNominees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { document_sub_type_id: '', document_name: '', file: '' },
      tableData: [],
      optionsArray: [],
      validation: "",
      optionGroupCountry: [],
      optionGroupCity: [],
      optionGroupNationalities: [],
      optionGroupIdentity: [],
      c_picture: null,
      redirect: null,
      titlesArray: constant.titles_list,
      id: "",
      name: '',
      countrySelected: -1,
      citySelected: -1,
      titleSelected: -1,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }

  async getCountriesList() {
    const countriesResponse = await axios.get(baseURL + "/api/countries-list", config);
    console.log(countriesResponse.data);
    const countriesListData = countriesResponse.data.data
    
    console.log(countriesListData);

    const options = countriesListData.map(d => ({
      "value": d,
      "label": d
    }));

    console.log(options);
    this.setState({ optionGroupCountry: options })
  }

  async getOptionsCountry() {
    /*
      This function is replaced by getCountriesList()
     */
    // const res = await axios.get(baseURL + "/api/countries-listing", config);
    // const data = res.data.data
    // // console.log(data);

    // const options = data.map(d => ({
    //   "value": d.id,
    //   "label": d.title
    // }))
    // this.setState({ optionGroupCountry: options })
  }
  async getOptionsCity(country_id) {
    const res = await axios.get(baseURL + "/api/country-cities/" + country_id, config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.title
    }))
    this.setState({ optionGroupCity: options })
  }
  async getOptionsNationalities() {
    const res = await axios.get(baseURL + "/api/nationalities", config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.name
    }))
    this.setState({ optionGroupNationalities: options })
  }
  async getOptionsIdentity() {
    const res = await axios.get(baseURL + "/api/identity-types", config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d,
      "label": d
    }))
    this.setState({ optionGroupIdentity: options })
  }
  componentDidMount() {
    this.getCountriesList();
    // this.getOptionsCountry();
    this.getOptionsNationalities();
    this.getOptionsIdentity();
     Promise.all([get_all_identity_sub_types()]).then((values) => {
      const identityTypesOptions = values[0].map(d => ([d.name]))
      console.log(identityTypesOptions);
      this.setState({ optionsArray: identityTypesOptions })
    });
  }
  checkValidation = () => {
    if (this.state.citySelected != 1) {
      this.setState({ citySelected: 0 })
    }
    if (this.state.countrySelected != 1) {
      this.setState({ countrySelected: 0 })
    }
    if (this.state.titleSelected != 1) {
      this.setState({ titleSelected: 0 })
    }
  }
  handleSelectedNomineeTitle = selectedNomineeTitle => {
    this.setState({ titleSelected: 1 })
    this.setState({ selectedNomineeTitle })
    console.log(selectedNomineeTitle);
  }
  handleSelectGroupCountry = selectedGroupCountry => {
    this.setState({ countrySelected: 1 })
    this.setState({ selectedGroupCountry })
    // this.getOptionsCity(selectedGroupCountry.value)
    console.log(selectedGroupCountry);
  }
  handleSelectGroupCity = selectedGroupCity => {
    this.setState({ citySelected: 1 })
    this.setState({ selectedGroupCity })
    console.log(selectedGroupCity);
  }
  handleSelectGroupNationalities = selectedGroupNationalities => {
    this.setState({ selectedGroupNationalities })
    console.log(selectedGroupNationalities);
  }
  handleSelectGroupIdentity = selectedGroupIdentity => {
    this.setState({ selectedGroupIdentity })
    console.log(selectedGroupIdentity);
  }
  handleSelectGroupProjectTypes = selectedGroupProjectTypes => {
    /*this.setState({ selectedGroupProjectTypes })
    console.log(selectedGroupProjectTypes);*/
  }
  handleDocumentFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const fileName = file.name.toLowerCase();
      const fileExtension = fileName.split('.').pop();
  
      if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'pdf') {
        // Valid file format
        console.log('Picture');
        console.log(file);
        // this.setState({ c_picture: file });
        const { formData } = this.state;
        this.setState({ formData: { ...formData, file: file } });
      } else {
        // Invalid file format
        showToast("Failed", "Invalid file format. Please select a JPG, JPEG, PNG, or PDF file.", "error");
        console.log('Invalid file format. Please select a JPG, JPEG, PNG, or PDF file.');
        return false;
      }
    }
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    const { formData } = this.state;
    this.setState({ formData: { ...formData, [name]: value } });
  }
  handleChangeFileName = (index, event) => {
    const updatedTableData = [...this.state.tableData]
    updatedTableData[index].document_name = event.target.value;
    this.setState({ tableData: updatedTableData })
    console.log(updatedTableData);
  }
  handleChangeCategory = (index, event) => {
    // console.log(index);
    // console.log(event.target.value);
    const updatedTableData = [...this.state.tableData]
    updatedTableData[index].document_sub_type_id = event.target.value;
    this.setState({ tableData: updatedTableData })
    console.log(updatedTableData);
  }
  handleDelete = (index, data) => {
    console.log(index);
    // console.log(this.state.tableData[index]);

    var newTableData = this.state.tableData
    delete newTableData[index]
    console.log(newTableData);
    this.setState({ tableData: newTableData })

    var currentTableData = this.state.tableData;
    var newArray = [];
    // var newTableData = this.state.tableData;
    console.log(currentTableData);
    var counter = 0;
    currentTableData.forEach((rowData, c_index) => {
      console.log("RowData: ", rowData);
      console.log("C_Index: ", c_index);
      // newArray.push(rowData)
      newArray[counter] = rowData;
      counter++;
      // this.setState({ tableData: newArray })
    })
    console.log(currentTableData.length);
    console.log(newArray.length);
    console.log("New Array: ", newArray);
    this.setState({ tableData: newArray });
    // console.log(newTableData);
    // console.log(this.state.tableData);
  };
  handleSubmitDocumnet = (e) => {
    e.preventDefault();
    if (!this.state.formData.document_sub_type_id || !this.state.formData.document_name || !this.state.formData.file) {
      let missingFields = '';
      if (!this.state.formData.document_sub_type_id) {
        missingFields += '<li>Category</li>';
      }
      if (!this.state.formData.document_name) {
        missingFields += '<li>Name</li>';
      }
      if (!this.state.formData.file) {
        missingFields += '<li>File</li>';
      }
      showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
      return false;
    }
    const { formData, tableData } = this.state;
    newData.push(formData)
    this.setState({ tableData: newData });
    this.setState({
      formData: { document_sub_type_id: '', document_name: '', file: '' }
    });
    document.getElementById('document_file').value= ''
  }
  handleSubmit(e) {
    e.preventDefault();
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }

  render() {
    //meta title
    document.title = "Add Nominee";
    const { validation } = this.state;
    const { selectedNomineeTitle } = this.state;
    const { selectedGroupCountry } = this.state;
    const { selectedGroupCity } = this.state;
    const { selectedGroupNationalities } = this.state;
    const { selectedGroupIdentity } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Nominees" breadcrumbItem="Add Nominee" parentLink="/customer/nominees/list" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        nomineeFirstName: (this.state && this.state.nomineeFirstName) || "",
                        nomineeLastName: (this.state && this.state.nomineeLastName) || "",
                        addresslineOne: (this.state && this.state.addresslineOne) || "",
                        mobileNumber: (this.state && this.state.mobileNumber) || "",
                        postcode: (this.state && this.state.postcode) || "",
                        city_name: (this.state && this.state.city_name) || "",
                        primaryEmail: (this.state && this.state.primaryEmail) || "",
                      }}
                      validationSchema={Yup.object().shape({
                        nomineeFirstName: Yup.string().required(
                          "Nominee first name is required!"
                        ),
                        nomineeLastName: Yup.string().required(
                          "Nominee last name is required!"
                        ),
                        addresslineOne: Yup.string().required(
                          "Please Enter Your Address Line 1"
                        ),
                        mobileNumber: Yup.string().required(
                          "Please Enter Your Mobile Number"
                        ),
                        city_name: Yup.string().required("Please Enter Your city"),
                        postcode: Yup.string().required("Please Enter Your postcode"),
                        primaryEmail: Yup.string().required("Please Enter Your Email"),
                      })}
                      onSubmit={values => {
                        console.log(values);
                        var documentArray = [];
                        var counter = 0;
                        this.state.tableData.forEach((rowData, c_index) => {
                          console.log("RowData: ", rowData);
                          console.log("C_Index: ", c_index);
                          // newArray.push(rowData)
                          documentArray[counter] = rowData;
                          counter++;
                        })
                        console.log(documentArray);
                        axios.post(baseURL + "/api/nominees", {

                          "title": selectedNomineeTitle.value,
                          "first_name": values.nomineeFirstName,
                          "middle_name": values.nomineeMiddleName,
                          "last_name": values.nomineeLastName,
                          "identity_type": selectedGroupIdentity?.label==null?'':selectedGroupIdentity?.label,
                          "identity_number": values.identityNumber,
                          "nationality": selectedGroupNationalities?.label==null?'':selectedGroupNationalities?.label,
                          "organisation_id": window.localStorage.getItem("organisation_id"),
                          "customer_id": window.localStorage.getItem("current_customer_id"),
                          "address_line_1": values.addresslineOne,
                          "address_line_2": values.addresslineTwo,
                          // "city_id": selectedGroupCity.value,
                          // "city_title": selectedGroupCity.label,
                          "city": values.city_name,
                          // "country_id": selectedGroupCountry.value,
                          // "country_title": selectedGroupCountry.label,
                          "country": selectedGroupCountry.label,
                          "postcode": values.postcode,
                          "telephone_home": values.phoneHome,
                          "telephone_office": values.phoneOffice,
                          "mobile_number": values.mobileNumber,
                          "primary_email": values.primaryEmail,
                          "secondary_email": values.secondaryEmail,
                          "documents": documentArray,
                          "documents_counter": documentArray.length
                        }, config)
                          .then((response) => {
                            showToast("Success", "The nominee "+ response.data.data.full_name + " has been added successfully.", "success");
                            this.setState({ redirect: "/customer/nominees/list" });
                            console.log(response);
                          })
                          .catch((error) => {
                            showToast("Failed", "Nominee Not Added Successfully", "error")
                            console.log(error);
                          });
                      }}
                    >
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation">
                          <Row>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="customerTitle">Title*</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={selectedNomineeTitle}
                                    onChange={this.handleSelectedNomineeTitle}
                                    options={this.state.titlesArray}
                                    classNamePrefix="select2-selection"
                                  />
                                  {this.state.titleSelected == 0 ? <>
                                    <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Title is not selected, please select first</p>
                                  </> : <></>
                                  }
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="nomineeFirstName">
                                  First Name*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="nomineeFirstName"
                                    placeholder="First Name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.nomineeFirstName && touched.nomineeFirstName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="nomineeFirstName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="nomineeMiddleName">
                                  Middle Name
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="nomineeMiddleName"
                                    placeholder="Middle Name"
                                    type="text"
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="nomineeLastName">
                                  Last Name*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="nomineeLastName"
                                    placeholder="Last Name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.nomineeLastName && touched.nomineeLastName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="nomineeLastName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectIdentity">Identity Type</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={selectedGroupIdentity}
                                    onChange={this.handleSelectGroupIdentity}
                                    options={this.state.optionGroupIdentity}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="identityNumber">
                                  Identity Number
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="identityNumber"
                                    placeholder="Identity Number"
                                    type="text"
                                    className={
                                      "form-control"
                                    }
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectNationality">Nationality</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={selectedGroupNationalities}
                                    onChange={this.handleSelectGroupNationalities}
                                    options={this.state.optionGroupNationalities}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </div>
                            </Col>

                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="addresslineOne">
                                  Address Line 1*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="addresslineOne"
                                    placeholder="Address Line 1"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.addresslineOne && touched.addresslineOne
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="addresslineOne"
                                    component="div"
                                    className="invalid-feedback"
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="addresslineTwo">
                                  Address Line 2
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="addresslineTwo"
                                    placeholder="Address Line 2"
                                    type="text"
                                    className={
                                      "form-control"
                                    }
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectCountry">Country*</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={selectedGroupCountry}
                                    onChange={this.handleSelectGroupCountry}
                                    options={this.state.optionGroupCountry}
                                    classNamePrefix="select2-selection"
                                  />
                                  {this.state.countrySelected == 0 ? <>
                                    <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Country is not selected, please select first</p>
                                  </> : <></>
                                  }
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectCity">City*</Label>
                                <div className="col-md-10">
                                  <Field
                                    name="city_name"
                                    placeholder="Enter City Name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.city_name && touched.city_name
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="city_name"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="postcode">Postcode*</Label>
                                <div className="col-md-10">
                                  <Field
                                    name="postcode"
                                    placeholder="Postcode"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.postcode && touched.postcode
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="postcode"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="phoneHome">
                                  Phone Home
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="phoneHome"
                                    placeholder="Phone Home"
                                    type="text"
                                    className={
                                      "form-control"
                                    }
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="phoneOffice">
                                  Phone Office
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="phoneOffice"
                                    placeholder="Phone Office"
                                    type="text"
                                    className={
                                      "form-control"
                                    }
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="mobileNumber">
                                  Mobile Number*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="mobileNumber"
                                    placeholder="Mobile Number"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.mobileNumber && touched.mobileNumber
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="mobileNumber"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="primaryEmail">
                                  Primary Email*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="primaryEmail"
                                    placeholder="Primary Email"
                                    type="email"
                                    className={
                                      "form-control" +
                                      (errors.primaryEmail && touched.primaryEmail
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="primaryEmail"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="secondaryEmail">
                                  Secondary Email
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="secondaryEmail"
                                    placeholder="Secondary Email"
                                    type="email"
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <form>
                                <div className="d-flex align-items-center mb-3 mt-3">
                                  <h5 className="mb-0 card-title flex-grow-1">Identity Document</h5>
                                </div>
                                <Card>
                                  <CardBody className="border card border-primary">
                                    <div className="mb-3 row d-flex select2-container">
                                      <label className="mt-3 col-md-2 col-form-label">
                                        Category:
                                      </label>
                                      <div className="col-md-10">
                                        <select className="mt-3 form-select" name="document_sub_type_id" value={this.state.formData.document_sub_type_id} onChange={this.handleChange}>
                                          <option value="">Select...</option>
                                          {this.state.optionsArray?.map(option => (
                                            <option value={option} key={option}>{option}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <br />
                                    <div className="mb-3 row d-flex">
                                      <label className="col-md-2 col-form-label">
                                        Name:
                                      </label>
                                      <div className="col-md-10">
                                        <input className="form-control" type="text" name="document_name" value={this.state.formData.document_name} onChange={this.handleChange} />
                                      </div>
                                    </div>
                                    <br />
                                    <Col md="12">
                                      <div className="mb-3 row d-flex">
                                        <Label className="col-md-2 col-form-label" htmlFor="file">
                                          File
                                        </Label>
                                        <div className="col-md-10">
                                          <Input className="form-control form-control" name='file' type="file" id="document_file"
                                            onChange={this.handleDocumentFile} />
                                        </div>
                                      </div>
                                    </Col>
                                  </CardBody>
                                  <div className='col-md-12 float-end'>
                                    <button color="primary" className="btn btn-primary ms-1 mt-2 mb-2" onClick={this.handleSubmitDocumnet}>Add Document</button>
                                  </div>
                                </Card>
                              </form>
                              {this.state.tableData?.map((data, index) => (
                                <div key={index}>
                                  <Card>
                                    <CardBody className="card">
                                      <Row>
                                        <Col md="6">
                                          <div className="mb-3 row d-flex select2-container">
                                            <label className="mt-3 col-md-4 col-form-label">
                                              Category:
                                            </label>
                                            <div className="mt-3 col-md-8">
                                              {/* <select value={selectedOptions[index]} onChange={(event) => handleOptionChange(index, event)}> */}
                                              <select className="form-select test-000" name={"category-" + index} id={"category-" + index} value={data.document_sub_type_id} onChange={(event) => this.handleChangeCategory(index, event)}>
                                                <option value="">Select...</option>
                                                {this.state.optionsArray?.map(option => (
                                                  <option value={option} key={option} selected>{option}</option>
                                                ))}
                                              </select>
                                            </div>
                                          </div>
                                          <div className="mb-3 row d-flex">
                                            <label className="col-md-4 col-form-label">
                                              Name:
                                            </label>
                                            <div className="col-md-8">
                                              <input
                                                name='occurance[]'
                                                id={'occurance-' + index}
                                                placeholder="0.00"
                                                defaultValue={data.document_name}
                                                onChange={(event) => this.handleChangeFileName(index, event)}
                                                type="text"
                                                className={"form-control"}
                                              />
                                            </div>
                                          </div>
                                        </Col>
                                        <Col md="6" className="text-center mt-3">
                                          <i className="fa fa-file-image" style={{ fontSize: '6rem' }} aria-hidden="true"></i>
                                        </Col>
                                        <Col md="12">
                                          <div className="row d-flex">
                                            <div><button color="danger" className="btn btn-danger ms-1 mt-2 mb-2" onClick={() => this.handleDelete(index)}>Delete</button></div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </div>
                              ))}
                            </Col>
                          </Row>
                          <Button color="primary" type="submit" onClick={this.checkValidation}  className="float-end" >
                            Add Nominee
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default AddNominees;
