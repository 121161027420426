import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../../../../../UI/ui-toast'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../../../../../../components/Common/Breadcrumb";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: `${token}`
    }
};
import constant from '../../../../../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
import { get_email_template, subject_variable_list } from '../../../../../api/templatesApi';
import EditEmailBody from "../../../../EditEmailBody";
class EditReceiptEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "",
            redirect: null,
            status_templates: constant.status_templates,
            template_name: '',
            address: '',
            email: '',
            phone: '',
            mobile: '',
            website: '',
            company_reg_no: '',
            project_name: '',
            document_sub_type_id: '',
            status: '',
            project_id: '',
            default_template_name: '',
            subject: '',
            variable_list: null,
            htmlData: null,
            org_id: null,
            emailJSON: null,
        };
    }
    componentDidMount() {
        Promise.all([get_email_template(localStorage.getItem("current_sample_id"))]).then((values) => {
            console.log(values[0]);
            this.setState({ template_name: values[0].template_name })
            this.setState({ email: values[0].email })
            this.setState({ website: values[0].website })
            this.setState({ company_reg_no: values[0].company_reg_no })
            this.setState({ project_name: values[0].project_name })
            this.setState({ mobile: values[0].mobile })
            this.setState({ phone: values[0].telephone })
            this.setState({ status: values[0].status })
            this.setState({ address: values[0].address })
            this.setState({ document_sub_type_id: values[0].document_sub_type_id })
            this.setState({ project_id: values[0].project_id })
            this.setState({ default_template_name: values[0].default_template_name })
            // this.setState({ subject: values[0].subject})
            document.getElementById("subject").value = values[0].subject
            this.setState({ org_id: values[0].orgnisation_id })
        });
        Promise.all([subject_variable_list()]).then((values) => {
            const variable_list = Object.entries(values[0]).map(([label, value]) => (label));
            this.setState({ variable_list: variable_list })
            console.log(variable_list);
        });
    }
    componentDidUpdate() {
    }
    handleSelectedStatusTemplate = selectedStatusTemplate => {
        console.log(selectedStatusTemplate);
        this.setState({ status: selectedStatusTemplate.value })
    }
    handleSubmit = (e) => {

        e.preventDefault();
        const subjectField = document.getElementById("subject")
        if (!this.state.template_name ||
            !this.state.phone ||
            !this.state.mobile ||
            !this.state.email ||
            !this.state.website ||
            !subjectField.value ||
            !this.state.address) {
            let missingFields = '';
            if (!this.state.template_name) {
                missingFields += '<li>Template Name</li>';
            }
            if (!this.state.address) {
                missingFields += '<li>Address</li>';
            }
            if (!this.state.email) {
                missingFields += '<li>Email</li>';
            }
            if (!this.state.phone) {
                missingFields += '<li>Phone</li>';
            }
            if (!this.state.mobile) {
                missingFields += '<li>Mobile</li>';
            }
            if (!this.state.website) {
                missingFields += '<li>Website</li>';
            }
            if (!subjectField.value) {
                missingFields += '<li>Subject</li>';
            }
            showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
            return false;
        }
        axios.patch(baseURL + "/api/email-templates/" + window.localStorage.getItem("current_sample_id"), {
            "template_name": this.state.template_name,
            'default_template_name': this.state.default_template_name,
            'address': this.state.address,
            'telephone': this.state.phone,
            'mobile': this.state.mobile,
            'email': this.state.email,
            'website': this.state.website,
            'company_reg_no': this.state.company_reg_no,
            'project_id': this.state.project_id,
            'status': this.state.status,
            'subject': subjectField.value,
            'body': this.state.htmlData,
            'bodyjson': this.state.emailJSON,
            'email_type': 'Receipt Template',
            'organisation_id': this.state.org_id,
        }, config)
            .then((response) => {
                showToast("Success", "The email template has been updated successfully.", "success");
                this.setState({ redirect: "/template/email/financial/receipt-email/clone-list" });
                console.log(response);
            })
            .catch((error) => {
                showToast("Failed", error.response.data.message, "error")
                console.log(error);
            });
    }
    handleChildData = (emailJSON, html) => {
        this.setState({ htmlData: html })
        this.setState({ emailJSON: emailJSON })
    };
    appendVariableToSubject = (variable) => {
        const subjectField = document.getElementById("subject");
        const currentValue = subjectField.value;
        const updatedValue = currentValue + " " + variable;
        subjectField.value = updatedValue;
    };
    render() {
        //meta title
        const { variable_list } = this.state;
        const variableButtons = variable_list?.map((variable, index) => (
            <Button
                key={index}
                type="button"
                className="m-1 btn-sm bg-primary"
                onClick={() => this.appendVariableToSubject(variable)}
            >
                {variable}
            </Button>
        ));
        document.title = "Clone Template";
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Receipt Email" breadcrumbItem="Clone Template" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Formik>
                                            {({ errors, status, touched }) => (
                                                <Form className="needs-validation" onSubmit={this.handleSubmit}>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="template_name">
                                                                    Template Name*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="template_name"
                                                                        placeholder="Template Name"
                                                                        type="text"
                                                                        value={this.state.template_name}
                                                                        onChange={(e) => this.setState({ template_name: e.target.value })}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="project">
                                                                    Project*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="project"
                                                                        placeholder="Project"
                                                                        type="text"
                                                                        disabled
                                                                        value={this.state.project_name}
                                                                        onChange={(e) => this.setState({ project_name: e.target.value })}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />

                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        {/* <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="company_reg_no">
                                                                    Company Reg No*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="company_reg_no"
                                                                        placeholder="Company Reg No"
                                                                        type="text"
                                                                        disabled
                                                                        value={this.state.company_reg_no}
                                                                        onChange={(e) => this.setState({ company_reg_no: e.target.value })}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col> */}
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="address">
                                                                    Address*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="address"
                                                                        placeholder="Address"
                                                                        type="text"
                                                                        value={this.state.address}
                                                                        onChange={(e) => this.setState({ address: e.target.value })}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="phone">
                                                                    Phone*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="phone"
                                                                        placeholder="Phone"
                                                                        type="text"
                                                                        value={this.state.phone}
                                                                        onChange={(e) => this.setState({ phone: e.target.value })}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="mobileNumber">
                                                                    Mobile Number*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="mobileNumber"
                                                                        placeholder="Mobile Number"
                                                                        type="text"
                                                                        value={this.state.mobile}
                                                                        onChange={(e) => this.setState({ mobile: e.target.value })}
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.mobileNumber && touched.mobileNumber
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="mobileNumber"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="email">
                                                                    Email*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="email"
                                                                        placeholder="Email"
                                                                        type="email"
                                                                        value={this.state.email}
                                                                        onChange={(e) => this.setState({ email: e.target.value })}
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.email && touched.email
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="email"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="website">
                                                                    Website*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="website"
                                                                        placeholder="Website"
                                                                        type="text"
                                                                        value={this.state.website}
                                                                        onChange={(e) => this.setState({ website: e.target.value })}
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.website && touched.website
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="website"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="userTitle">Status*</Label>
                                                                <div className="col-md-10">
                                                                    <Select
                                                                        onChange={this.handleSelectedStatusTemplate}
                                                                        options={this.state.status_templates}
                                                                        value={this.state.status == 1 ? { label: "Active", value: this.state.status } : { label: "Inactive", value: this.state.status }}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="subject">
                                                                    Subject*
                                                                </Label>
                                                                <div className="col-md-6">
                                                                    <Field
                                                                        id="subject"
                                                                        name="subject"
                                                                        placeholder="Subject"
                                                                        type="text"
                                                                        className={"form-control"}
                                                                        onChange={(e) => {
                                                                            // this.setState({ subject: e.target.value })
                                                                            document.getElementById("subject").value = e.target.value
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <h4>Variables List</h4>
                                                                    {variableButtons}
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="emailBody">Email Body*</Label>
                                                                <div className="col-md-12">
                                                                    <EditEmailBody sendDataToParent={this.handleChildData} />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Button color="primary" type="submit" className="float-end" >
                                                        Update Template
                                                    </Button>
                                                </Form>
                                            )}
                                        </Formik>
                                        <br />
                                        <br />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default EditReceiptEmail;