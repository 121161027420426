import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { Link, useHistory, Redirect } from "react-router-dom";
import { isEmpty, size } from "lodash";
import { showToast } from '../../../UI/ui-toast';
import { get_user_permissions } from "../../../../helpers/custom_helper"

import DeleteModal from "../../../../components/Common/DeleteModal";
import moment from "moment";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { get_current_nominee, get_nominee_documents } from '../../api/customersApi';

import default_avatar from "../../../../assets/images/avatar_01.png";

import { delete_document } from '../../../Documents/api/documentsApi';

var customerEdit, customerView, customerCreate, identityDelete;
class ListNomineeDocuments extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 10,
            listData: [],
            listdata: "",
            viewmodal: false,
            modal: false,
            isOpen: false,
            selectDate: false,
            deleteModal: false,
            recordDeleteID: null,
            redirect: null,
            currentNomineeId: 0,
            currentNomineeInfo: {},
        };
        this.handleRecordClick = this.handleRecordClick.bind(this);
        // this.handleAddNominee = this.handleAddNominee.bind(this);
        // this.handleListNominee = this.handleListNominee.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleRecordClicks = this.handleRecordClicks.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.handleEditNominee = this.handleEditNominee.bind(this);
        this.onClickDownload = this.onClickDownload.bind(this);

    }
    componentDidMount() {
        customerEdit = get_user_permissions('customers-edit');
        customerView = get_user_permissions('customers-view');
        customerCreate = get_user_permissions('customers-create');
        identityDelete = get_user_permissions('identity-documents-delete');
        // window.localStorage.removeItem("current_customer_id")
        // 
        console.log("current_nominee_id", localStorage.getItem("current_nominee_id"));
        this.setState({ currentNomineeId: localStorage.getItem("current_nominee_id") });

        Promise.all([get_current_nominee(localStorage.getItem("current_nominee_id"))]).then((values) => {
            console.log("currentNomineeInfo", values[0]);
            this.setState({ currentNomineeInfo: values[0] });
        });

        Promise.all([get_nominee_documents(localStorage.getItem("current_nominee_id"))]).then((values) => {
            console.log(values[0]);
            this.setState({ listData: values[0] });
        });

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    };
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    handleEditNominee = () =>{
        window.localStorage.setItem("current_nominee_id", this.state.currentNomineeId);
        this.setState({ redirect: "/customer/nominee/edit" });
    }

    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };
    onClickDelete = listdata => {
        this.setState({ listdata: listdata });
        this.setState({ deleteModal: true });
    };

    handleDeletejob = () => {
        // const { onDeleteJobList } = this.props;
        const { listdata } = this.state;
        if (listdata.id !== undefined) {
            console.log(this.state.recordDeleteID);
            // onDeleteJobList(listdata);
            Promise.all([delete_document(this.state.recordDeleteID, 'nominee', listdata.documentable_id)]).then((values) => {
                showToast("Success", "The document deleted successfully.", "success");
                Promise.all([get_nominee_documents(this.state.currentNomineeId)]).then((values) => {
                    console.log(values[0]);
                    this.setState({ listData: values[0] });
                });
            }).catch((error) => {
                showToast("Failed", "Document Not Deleted.", "error");
            });
            this.setState({ deleteModal: false });
        }
    };
    handleRecordClicks = () => {
        this.setState({ listdata: "", isEdit: false });
        this.toggle();
    };

    handleRecordClick = arg => {
        const listdata = arg;

        //let history = useHistory();

        /*this.setState({
            listdata: {
                id: listdata.id,
                name: listdata.name,
            },
            isEdit: true,
        });*/

        //this.toggle();
        window.localStorage.setItem("current_nominee_id", this.state.currentNomineeId);
        this.setState({ redirect: "/customer/nominee/edit" });
        //history.push("/customers/edit");
        //return  <Redirect  to="/customers/edit" />

    };
    onClickDownload = listdata => {
        const contentType = listdata.document_path.split(';')[0].split(':')[1];
        const base64Content = listdata.document_path.split(',')[1];
        const byteCharacters = atob(base64Content);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const extension = listdata.document_path.split(';')[0].split('/')[1];
        const blob = new Blob([byteArray], { type: contentType });
        const fileName = `${listdata.document_name}.${extension}`;
      
        setTimeout(() => {
            if (contentType.startsWith('image/png') || contentType.startsWith('image/jpg') || contentType.startsWith('image/jpeg')) {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                a.rel = 'noopener noreferrer';
                a.click();
            } else {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.target = '_blank';
                a.rel = 'noopener noreferrer';
                a.click();
            }
        }, 100);
    };
    render() {

        const { isEdit, deleteModal } = this.state;

        const listdata = this.state.listdata;

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        const columns = [
            {
                dataField: "id",
                text: "Id",
                sort: true,
            },
            // {
            //     dataField: "document_path",
            //     text: "",
            //     sort: false,
            //     formatter: (cellContent, listdata) => (

            //         <div>
            //             <img src={listdata.document_path == null ? default_avatar : listdata.document_path} alt="" className="avatar-sm" />
            //         </div>

            //     ),
            // },
            {
                dataField: "document_name",
                text: "Document Name",
                sort: true,
            },
            {
                dataField: "document_sub_type_name",
                text: "Document Type",
                sort: true,
            },
            {
                dataField: "updated_name",
                text: "Updated By",
                sort: true,
            },
            {
                dataField: "updated_at",
                text: "Updated At",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {moment(listdata.updated_at).format('DD/MM/YYYY h:mma')}
                    </>

                ),
            },
            {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                formatter: (cellContent, listdata) => (
                    <>
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link
                                    to="#"
                                    onClick={() => this.onClickDownload(listdata)}
                                    className="btn btn-sm btn-soft-info" >
                                    <i className="mdi mdi-file-download-outline" id="downloadDocument" />
                                    <UncontrolledTooltip placement="top" target="downloadDocument">
                                        Download Document
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            {identityDelete ? <>
                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-danger"
                                        onClick={() => this.onClickDelete(listdata)}
                                    >
                                        <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                        <UncontrolledTooltip placement="top" target="deletetooltip">
                                            Delete
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </> : <></>}
                        </ul>
                    </>
                ),
            },

        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;

        //meta title
        document.title = "List Nominee Documents";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                console.log(row);
                this.setState({ recordDeleteID: row.id });

            }
        }
        return (
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeletejob}
                    record={`nominee`}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Indentity Documents" breadcrumbItem="List Nominee Documents" parentLink="/documents/identity" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                  <CardBody className="border-bottom">
                                    <h5 className="mb-3 card-title flex-grow-1">Nominee Details</h5>
                                      <div className="d-flex">
                                        <img src={this.state.currentNomineeInfo.picture_path == null ? default_avatar : this.state.currentNomineeInfo.picture_path} alt="" height="50" />
                                        <div className="flex-grow-1 ms-3">
                                              <h5 className="fw-semibold">{this.state.currentNomineeInfo.full_name}</h5>
                                              <ul className="list-unstyled hstack gap-2 mb-0">
                                                  <li>
                                                      <i className="bx bx-map"></i> <span className="text-muted">{this.state.currentNomineeInfo.city}, {this.state.currentNomineeInfo.country}</span>
                                                  </li>
                                              </ul>
                                        </div>
                                      </div>
                                  </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">List Nominee Documents</h5>
                                                {customerEdit ? <>
                                                    <div className="flex-shrink-0">
                                                        <Link to="/customer/nominee/edit" className="btn btn-primary me-1" onClick={() => this.handleEditNominee()}>Edit Nominee Documents</Link>
                                                    </div>
                                                </> : <></>}
                                            </div>
                                        </CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.listData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.listData}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            selectRow={selectRow}
                                                                            rowEvents={rowEvents}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            ref={this.node}
                                                                        />
                                                                        <Modal
                                                                            isOpen={this.state.modal}
                                                                            className={this.props.className}
                                                                        >
                                                                            <ModalHeader toggle={this.toggle} tag="h4">
                                                                                {!!isEdit ? "Edit Nominee" : "Add Nominee"}
                                                                            </ModalHeader>
                                                                            <ModalBody>
                                                                                <Formik
                                                                                    enableReinitialize={true}
                                                                                    initialValues={{
                                                                                        id: (listdata && listdata.id) || '',
                                                                                        name: (listdata && listdata.name) || '',
                                                                                    }}
                                                                                    validationSchema={Yup.object().shape({
                                                                                        id: Yup.string().required("Please Enter Your Id"),
                                                                                        name: Yup.string().required("Please Enter Your Name"),
                                                                                    })}
                                                                                    onSubmit={values => {
                                                                                        /*Promise.all([update_project(values.id, values.name)]).then((response) => {
                                                                                            console.log(response[0]);
                                                                                            Promise.all([get_nominee_documents(this.state.currentNomineeId)]).then((values) => {
                                                                                                console.log(values[0]);
                                                                                                this.setState({ listData: values[0] });
                                                                                            });
                                                                                        });*/
                                                                                        this.toggle();
                                                                                    }}
                                                                                >
                                                                                    {({ errors, status, touched }) => (
                                                                                        <Form>
                                                                                            <Row>
                                                                                                <Col className="col-12">
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            Property Type Id
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            name="id"
                                                                                                            disabled
                                                                                                            type="text"
                                                                                                            className={
                                                                                                                "form-control" +
                                                                                                                (errors.id &&
                                                                                                                    touched.id
                                                                                                                    ? " is-invalid"
                                                                                                                    : "")
                                                                                                            }
                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            name="id"
                                                                                                            component="div"
                                                                                                            className="invalid-feedback"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            Name
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            name="name"
                                                                                                            type="text"
                                                                                                            className={
                                                                                                                "form-control" +
                                                                                                                (errors.name &&
                                                                                                                    touched.name
                                                                                                                    ? " is-invalid"
                                                                                                                    : "")
                                                                                                            }
                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            name="name"
                                                                                                            component="div"
                                                                                                            className="invalid-feedback"
                                                                                                        />
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>
                                                                                                    <div className="text-end">
                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            className="btn btn-primary save-user"
                                                                                                        >
                                                                                                            Save
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Form>
                                                                                    )}
                                                                                </Formik>
                                                                            </ModalBody>
                                                                        </Modal>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default ListNomineeDocuments;
