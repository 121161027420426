import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row, UncontrolledAlert, UncontrolledTooltip
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../UI/ui-toast'

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"
import { get_features_against_project, get_project_sizes, get_project_phases_blocks, get_blocks_against_phases, get_project_floors } from "../../Projects/api/projectApi"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

class AddProperty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "",
            property_area_size: '',
            optionGroupAreaUnit: constant.area_unit_titles,
            optionGroupProjects: [],
            optionGroupSizes: [],
            optionGroupPhases: [],
            optionGroupBlocks: [],
            optionGroupFloors: [],
            optionGroupFeatures: [],
            optionGroupPropertyTypes: [],
            currencies: [],
            project_id: '',
            size_id: '',
            phase_id: '',
            block_id: '',
            floor_id: '',
            percentage_value_against_price: '',
            pricesArray: [],
            totalFeaturesPrices: [],
            start_counter: 0,
            end_counter: 0,
            existing_counter_value: '',
            reference_prefix: '',
            property_count: 0,
            unique_reference_counter: 0,
            features: [],
            redirect: null,
            has_phases: 0,
            has_blocks: 0,
            has_floors: 0,
            has_sizes: 0,
            id: "",
            name: '',
            projectSelected: -1,
            featureSelected: -1,
            propertyTypeSelected: -1,
            sizeSelected: -1,
            floorSelected: -1,
            phaseSelected: -1,
            blockSelected: -1,
            blockCheck: 0,
            project_prefix: '',
            templateCloneMessage: null,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeValidation = this.onChangeValidation.bind(this);
    }

    async getOptionsProjects() {
        const res = await axios.get(baseURL + "/api/project-list", config);
        const data = res.data.data;
        console.log(data);

        const options = data.map(d => ({
            "value": d.id,
            "label": d.name,
            "doc_template_message": d.doc_template_message
        }))
        this.setState({ optionGroupProjects: options })
    }
    getOptionsFeatures(project_id) {
        Promise.all([get_features_against_project(project_id)]).then((values) => {
            console.log(values[0]);
            const options = values[0].map(d => ({
                "value": d.feature_id,
                "label": d.feature_name,
                "percentage_value": d.percentage_value
            }))
            this.setState({ optionGroupFeatures: options })
        });


    }
    async getOptionsTypes() {
        const res = await axios.get(baseURL + "/api/property-type-listing", config);
        const data = res.data.data;
        // console.log(data);
        const options = data.map(d => ({
            "value": d.id,
            "label": d.type_name
        }))
        this.setState({ optionGroupPropertyTypes: options })
    }
    async getProjectDetail(project_id) {
        const res = await axios.get(baseURL + "/api/project-details/" + project_id, config);
        const data = res.data.data;
        console.log(data);
        this.setState({ project_prefix: data.prefix })
        this.setState({ has_sizes: data.has_sizes })
        this.setState({ has_phases: data.has_phases })
        this.setState({ has_blocks: data.has_blocks })
        this.setState({ has_floors: data.has_floors })
        // console.log("Before");
        if (data.has_sizes) {
            // console.log("True");
            Promise.all([get_project_sizes(project_id)]).then((values) => {
                // console.log(values[0]);
                this.getOptionsSizes(values[0]);
            });
        }
        if (data.has_phases) {
            // console.log("True");
            Promise.all([get_project_phases_blocks(project_id)]).then((values) => {
                // console.log(values[0]);
                this.getOptionsPhases(values[0]);
            });
        }
        if (data.has_floors) {
            // console.log("True");
            Promise.all([get_project_floors(project_id)]).then((values) => {
                // console.log(values[0]);
                this.getOptionsFloors(values[0]);
            });
        }
        const currencies = await axios.get(baseURL + "/api/project-currencies/" + project_id, config);
        const currenciesData = currencies.data.data;
        // console.log(currenciesData);
        this.setState({ currencies: currenciesData })
        const { pricesArray } = this.state;
        for (var i = 0; i < currenciesData.length; i++) {
            pricesArray[i] = { "price": 0, "currency_id": currenciesData[i].currency_id };
            this.setState({ pricesArray });
        }
        // console.log(this.state.pricesArray);
        // const listItems = currenciesData?.map((d) =>
        //     <li key={d.currency_id}>{d.currency_name}</li>
        // );
    }
    getOptionsFloors(floors) {
        const options = floors.map(d => ({
            "value": d.id,
            "label": d.name
        }))
        this.setState({ optionGroupFloors: options })
    }

    getOptionsSizes(sizes) {
        const options = sizes.map(d => ({
            "value": d.id,
            "label": d.name
        }))
        this.setState({ optionGroupSizes: options })
    }
    getOptionsPhases(phases) {
        const options = phases.map(d => ({
            "value": d.id,
            "label": d.name
        }))
        this.setState({ optionGroupPhases: options })
    }
    getBlocksAgainstPhases(phase_id) {
        Promise.all([get_blocks_against_phases(phase_id)]).then((values) => {
            // console.log(values[0]);
            let data = values[0];
            if (data == '') {
                this.setState({ blockCheck: 0 })
            } else {
                this.setState({ blockCheck: 1 })
            }
            const options = data.map(d => ({
                "value": d.id,
                "label": d.name
            }))
            this.setState({ optionGroupBlocks: options })
        });

    }
    async getMaxPropertyReference() {
        const response = await axios.post(baseURL + "/api/get-max-property", {
            "project_id": this.state.project_id,
            "size_id": this.state.size_id,
            "phase_id": this.state.phase_id,
            "block_id": this.state.block_id,
            "floor_id": this.state.floor_id
        }, config);
        console.log(response.data.data);
        if (Object.keys(response.data.data).length > 0) {
            var myData = response.data?.data
            this.setState({ existing_counter_value: myData })
        }

    }
    componentDidMount() {
        this.getOptionsProjects()
        this.getOptionsTypes()
    }
    checkValidation = () => {
        if (this.state.projectSelected != 1) {
            this.setState({ projectSelected: 0 })
        }
        if (this.state.featureSelected != 1) {
            this.setState({ featureSelected: 0 })
        }
        if (this.state.propertyTypeSelected != 1) {
            this.setState({ propertyTypeSelected: 0 })
        }
        if (this.state.propertyTypeSelected != 1) {
            this.setState({ propertyTypeSelected: 0 })
        }
        if (this.state.sizeSelected != 1) {
            this.setState({ sizeSelected: 0 })
        }
        if (this.state.phaseSelected != 1) {
            this.setState({ phaseSelected: 0 })
        }
        // if (this.state.blockSelected != 1) {
        //     this.setState({ blockSelected: 0 })
        // }
        if (this.state.floorSelected != 1) {
            this.setState({ floorSelected: 0 })
        }
    }
    componentDidUpdate() {
        // for (var j = 0; j < this.state.currencies.length; j++) {
        //     console.log(this.state.currencies[j]);
        //     console.log(this.state.currencies[j].id);
        //     this.setPriceAgainstPercentageValue(this.state.currencies[j].id);
        // }
    }

    handleSelectGroupAreaUnit = selectedGroupAreaUnit => {
        this.setState({ selectedGroupAreaUnit })
    }
    handleSelectGroupProject = selectedGroupProject => {
        this.setState({ templateCloneMessage: selectedGroupProject.doc_template_message })
        this.setState({ selectedGroupProject })
        this.setState({ blockCheck: 0 })
        this.setState({ selectedGroupSizes: '' })
        this.setState({ selectedGroupPhases: '' })
        this.setState({ selectedGroupBlocks: '' })
        this.setState({ selectedGroupFloors: '' })
        this.setState({ unique_reference_counter: 0 })
        // console.log(selectedGroupProject);
        this.getProjectDetail(selectedGroupProject.value)
        this.getOptionsFeatures(selectedGroupProject.value)
        this.setState({ project_id: selectedGroupProject.value })
        this.setState({ projectSelected: 1 })
    }
    handleSelectGroupSizes = selectedGroupSizes => {
        this.setState({ sizeSelected: 1 })
        this.setState({ selectedGroupSizes })
        // console.log(selectedGroupSizes);
        this.setState({ size_id: selectedGroupSizes.value })
        setTimeout(() => {
            this.getMaxPropertyReference()
        }, 500);
    }
    handleSelectGroupPhases = selectedGroupPhases => {
        this.setState({ phaseSelected: 1 })
        this.setState({ selectedGroupPhases })
        // console.log(selectedGroupPhases);
        this.getBlocksAgainstPhases(selectedGroupPhases.value)
        this.setState({ phase_id: selectedGroupPhases.value })
        setTimeout(() => {
            this.getMaxPropertyReference()
        }, 500);
    }
    handleSelectGroupBlocks = selectedGroupBlocks => {
        this.setState({ blockSelected: 1 })
        this.setState({ selectedGroupBlocks })
        // console.log(selectedGroupBlocks);
        this.setState({ block_id: selectedGroupBlocks.value })
        setTimeout(() => {
            this.getMaxPropertyReference()
        }, 500);
    }
    handleSelectGroupFloors = selectedGroupFloors => {
        this.setState({ floorSelected: 1 })
        this.setState({ selectedGroupFloors })
        // console.log(selectedGroupFloors);
        this.setState({ floor_id: selectedGroupFloors.value })
        setTimeout(() => {
            this.getMaxPropertyReference()
        }, 500);
    }
    handleSelectGroupPropertyTypes = selectedGroupPropertyTypes => {
        this.setState({ selectedGroupPropertyTypes })
        // console.log(selectedGroupPropertyTypes);
        this.setState({ propertyTypeSelected: 1 })
    }
    handlePropertyAreaSize = (e) => {
        this.setState({ property_area_size: e.target.value })
    }
    handlePropertyCount = (e) => {
        var val_1 = parseInt(e.target.value);
        // console.log(val_1);

        if (isNaN(val_1)) {
            showToast("Failed", "The Number of properties should be an integer value.", "error");
            return false;
        }
        if (val_1 == 0) {
            showToast("Failed", "The Number of properties should not be 0.", "error");
            return false;
        }
        // console.log(e.target.value);
        this.setState({ property_count: e.target.value })
        // var property_count = parseInt(e.target.value)
        // var existing_counter_value = parseInt(this.state.existing_counter_value)
        // var end = property_count + existing_counter_value
        // this.setState({ end_counter: end })
    }
    handleUniqueReferenceCounter = (e) => {
        this.setState({ unique_reference_counter: e.target.value })
        var start = e.target.value
        var property_count = parseInt(this.state.property_count);
        if (property_count == 0) {
            showToast("Failed", "The Number of properties should not be 0.", "error");
            return false;
        }
        var counter_start = parseInt(e.target.value)
        var end = (property_count + counter_start) - 1
        this.setState({ end_counter: end })
        this.setState({ start_counter: start })
        // console.log("start="+start);
        // console.log("property_count="+property_count);
        // console.log("counter_start="+counter_start);
        // console.log("end="+end);
        // console.log("reference_prefix="+this.state.reference_prefix);
        // console.log("existing_counter_value="+this.state.existing_counter_value);
    }
    handleUniqueReferencePrefix = (e) => {
        this.setState({ reference_prefix: e.target.value })
    }
    handleSelectGroupFeatures = selectedGroupFeatures => {
        this.setState({ featureSelected: 1 })
        console.log(selectedGroupFeatures);
        this.setState({ selectedGroupFeatures })
        var features = [];
        for (var i = 0; i < selectedGroupFeatures.length; i++) {
            selectedGroupFeatures[i].feature_id = selectedGroupFeatures[i].value;
            features.push({ "feature_id": selectedGroupFeatures[i].feature_id, "feature_name": selectedGroupFeatures[i].label, "percentage_value": selectedGroupFeatures[i].percentage_value })
        }
        console.log(features);
        this.setState({ features: features })
        setTimeout(() => {
            for (var i = 0; i < this.state.currencies.length; i++) {
                console.log(this.state.currencies[i].currency_id);
                //   document.getElementById('price-' + i).value = this.state.currencies[i].price
                this.setPriceAgainstPercentageValue(this.state.currencies[i].currency_id)
            }
        }, 1000);
        console.log(features == '');
        if (features == '') {
            for (var j = 0; j < this.state.currencies.length; j++) {
                // percentage = (percentage * this.state.prices[j].price) / 100
                // document.getElementById('feature-' + i + '-' + currency_id).value = parseFloat(percentage);
                document.getElementById('feature_price-' + this.state.currencies[j].currency_id).value = 0.00;
                document.getElementById('total-' + this.state.currencies[j].currency_id).value = document.getElementById('price-' + j).value;
            }
        }
    }

    setPriceAgainstPercentageValue(currency_id) {
        var percentage = 0;
        var total = 0;
        for (var j = 0; j < this.state.currencies.length; j++) {
            if (this.state.pricesArray[j].currency_id == currency_id) {
                for (var i = 0; i < this.state.features.length; i++) {
                    percentage = this.state.features[i].percentage_value
                    // console.log(percentage);
                    document.getElementById('feature-' + i + '-' + currency_id).value = 0.00
                    document.getElementById('feature_price-' + currency_id).value = 0.00
                    document.getElementById('total-' + currency_id).value = 0.00
                    percentage = (percentage * this.state.pricesArray[j].price) / 100
                    document.getElementById('feature-' + i + '-' + currency_id).value = parseFloat(percentage).toFixed(2);
                    total += parseFloat(percentage);
                    document.getElementById('feature_price-' + currency_id).value = (total + parseFloat(this.state.pricesArray[j].price)).toFixed(2);
                }
                // console.log(this.state.pricesArray[j].price);
                document.getElementById('total-' + currency_id).value = (total + parseFloat(this.state.pricesArray[j].price)).toFixed(2);
            }
        }
    }
    handlePriceChange = (e, index, currency_id) => {
        const { pricesArray } = this.state;
        pricesArray[index] = { "price": e.target.value, "currency_id": currency_id };
        this.setState({ pricesArray });

        console.log(this.state.pricesArray);
        this.setPriceAgainstPercentageValue(currency_id)
    }
    handleSubmit(e) {
        e.preventDefault();
    }

    //for change tooltip display propery
    onChangeValidation(fieldName, value) {
        const modifiedV = { ...this.state.validation };
        if (value !== "") {
            modifiedV[fieldName] = true;
        } else {
            modifiedV[fieldName] = false;
        }
        this.setState({ validation: modifiedV });
    }
    render() {
        //meta title
        document.title = "Add Property";
        const { validation } = this.state;
        const { selectedGroupSizes } = this.state;
        const { selectedGroupAreaUnit } = this.state;
        const { selectedGroupProject } = this.state;
        const { selectedGroupPhases } = this.state;
        const { selectedGroupBlocks } = this.state;
        const { selectedGroupFloors } = this.state;
        const { selectedGroupFeatures } = this.state;
        const { selectedGroupPropertyTypes } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Property" breadcrumbItem="Add Property" parentLink="/properties/list" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        {this.state.templateCloneMessage ?
                                            <UncontrolledAlert color="danger" role="alert">
                                                {this.state.templateCloneMessage} &nbsp;&nbsp;&nbsp;
                                                <Link
                                                    to="/templates/documents-templates/sales-documents-tabs-view"
                                                    className="btn btn-sm btn-info"
                                                >
                                                    <i className="mdi mdi-arrow-right" id="templatetooltip" />
                                                    <UncontrolledTooltip placement="top" target="templatetooltip">
                                                        Clone Templates
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </UncontrolledAlert>
                                            :
                                            null
                                        }
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                // firstName: (this.state && this.state.firstName) || "",
                                            }}
                                            validationSchema={Yup.object().shape({
                                                // firstName: Yup.string().required(
                                                //     "User first name is required!"
                                                // ),
                                            })}
                                            onSubmit={values => {
                                                console.log('Log');
                                                if (this.state.property_count == 0) {
                                                    console.log('Log');
                                                    showToast("Failed", "The Number of properties should not be 0.", "error");
                                                    return false;
                                                }
                                                if (this.state.propertyTypeSelected == 0) {
                                                    console.log('Log');
                                                    showToast("Failed", "Property Type is not selected, select first", "error");
                                                    return false;
                                                }
                                                if (this.state.projectSelected == 0) {
                                                    console.log('Log');
                                                    showToast("Failed", "Project is not selected, select first", "error");
                                                    return false;
                                                }
                                                // if (this.state.featureSelected == 0) {
                                                //     console.log('Log');
                                                //     showToast("Property", "Features are not selected, select first", "error");
                                                //     return false;
                                                // }
                                                if (this.state.has_sizes == 1) {
                                                    console.log('Log');
                                                    if (this.state.sizeSelected == 0) {
                                                        console.log('Log');
                                                        showToast("Failed", "Project size is not selected, select first", "error");
                                                        return false;
                                                    }
                                                }
                                                if (this.state.has_phases == 1) {
                                                    console.log('Log');
                                                    if (this.state.phaseSelected == 0) {
                                                        console.log('Log');
                                                        showToast("Failed", "Project phase is not selected, select first", "error");
                                                        return false;
                                                    }
                                                }
                                                // if (this.state.has_blocks == 1) {
                                                //     console.log('Log');
                                                //     if (this.state.blockSelected == 0) {
                                                //         console.log('Log');
                                                //         showToast("Property", "Project block is not selected, select first", "error");
                                                //         return false;
                                                //     }
                                                // }
                                                if (this.state.has_floors == 1) {
                                                    console.log('Log');
                                                    if (this.state.floorSelected == 0) {
                                                        console.log('Log');
                                                        showToast("Failed", "Project floor is not selected, select first", "error");
                                                        return false;
                                                    }
                                                }

                                                console.log("values=");
                                                console.log(values);
                                                var prices = []
                                                for (var j = 0; j < this.state.currencies.length; j++) {

                                                    var total = document.getElementById('total-' + this.state.currencies[j].currency_id).value
                                                    // var with_feature_price = document.getElementById('feature-'+j+'-'+this.state.currencies[j].currency_id).value
                                                    prices.push({ "with_feature_price": total, "price": this.state.pricesArray[j].price, "currency_id": this.state.currencies[j].currency_id })
                                                }
                                                console.log(prices);
                                                axios.post(baseURL + "/api/properties", {
                                                    "status": "Available",
                                                    "project_id": selectedGroupProject.value,
                                                    "floor_id": selectedGroupFloors?.value == undefined ? "" : selectedGroupFloors?.value,
                                                    "type_id": selectedGroupPropertyTypes.value,
                                                    "size_id": selectedGroupSizes.value,
                                                    "phase_id": selectedGroupPhases?.value == undefined ? "" : selectedGroupPhases?.value,
                                                    "block_id": selectedGroupBlocks?.value == undefined ? "" : selectedGroupBlocks?.value,
                                                    "count": this.state.property_count,
                                                    "features": this.state.features,
                                                    "prices": prices,
                                                    "unique_reference": this.state.reference_prefix,
                                                    "unique_reference_start": this.state.unique_reference_counter,
                                                    "area_unit": selectedGroupAreaUnit.value,
                                                    "area_size": this.state.property_area_size
                                                }, config)
                                                    .then((response) => {
                                                        showToast("Success", "The property has been added successfully.", "success");
                                                        this.setState({ redirect: "/properties/list" });
                                                        console.log(response);
                                                    })
                                                    .catch((error) => {
                                                        showToast("Failed", "Property Not Added Successfully", "error")
                                                        console.log(error);
                                                    });
                                            }}
                                        >
                                            {({ errors, status, touched }) => (
                                                <Form className="needs-validation">
                                                    <Row>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="selectProject">Projects</Label>
                                                                <div className="col-md-10">
                                                                    <Select
                                                                        name="selectProject"
                                                                        value={selectedGroupProject}
                                                                        onChange={this.handleSelectGroupProject}
                                                                        options={this.state.optionGroupProjects}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                    {this.state.projectSelected == 0 ? <>
                                                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Project is not selected, please select first</p>
                                                                    </> : <></>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        {this.state.has_sizes == 0 ? <></> : <>
                                                            <Col md="12">
                                                                <div className="mb-3 row d-flex select2-container">
                                                                    <Label className="col-md-2 col-form-label" htmlFor="selectSizes">Size</Label>
                                                                    <div className="col-md-10">
                                                                        <Select
                                                                            name="selectSizes"
                                                                            value={selectedGroupSizes}
                                                                            onChange={this.handleSelectGroupSizes}
                                                                            options={this.state.optionGroupSizes}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                        {this.state.sizeSelected == 0 ? <>
                                                                            <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Project size is not selected, please select first</p>
                                                                        </> : <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </>}
                                                        {this.state.has_phases == 0 ? <></> : <>
                                                            <Col md="12">
                                                                <div className="mb-3 row d-flex select2-container">
                                                                    <Label className="col-md-2 col-form-label" htmlFor="selectPhases">Phase</Label>
                                                                    <div className="col-md-10">
                                                                        <Select
                                                                            name="selectPhases"
                                                                            value={selectedGroupPhases}
                                                                            onChange={this.handleSelectGroupPhases}
                                                                            options={this.state.optionGroupPhases}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                        {this.state.phaseSelected == 0 ? <>
                                                                            <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Project phase is not selected, please select first</p>
                                                                        </> : <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </>}
                                                        {this.state.blockCheck == 0 ? <></> : <>
                                                            <Col md="12">
                                                                <div className="mb-3 row d-flex select2-container">
                                                                    <Label className="col-md-2 col-form-label" htmlFor="selectBlocks">Block</Label>
                                                                    <div className="col-md-10">
                                                                        <Select
                                                                            name="selectBlocks"
                                                                            value={selectedGroupBlocks}
                                                                            onChange={this.handleSelectGroupBlocks}
                                                                            options={this.state.optionGroupBlocks}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                        {/* {this.state.blockSelected == 0 ? <>
                                                                            <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Project block is not selected, please select first</p>
                                                                        </> : <></>
                                                                        } */}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </>}
                                                        {this.state.has_floors == 0 ? <></> : <>
                                                            <Col md="12">
                                                                <div className="mb-3 row d-flex select2-container">
                                                                    <Label className="col-md-2 col-form-label" htmlFor="selectFloors">Floor</Label>
                                                                    <div className="col-md-10">
                                                                        <Select
                                                                            name="selectFloors"
                                                                            value={selectedGroupFloors}
                                                                            onChange={this.handleSelectGroupFloors}
                                                                            options={this.state.optionGroupFloors}
                                                                            classNamePrefix="select2-selection"
                                                                        />
                                                                        {this.state.floorSelected == 0 ? <>
                                                                            <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Project floor is not selected, please select first</p>
                                                                        </> : <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </>}
                                                        <Col md="6">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-4 col-form-label" htmlFor="selectArea">Area Unit</Label>
                                                                <div className="col-md-8">
                                                                    <Select
                                                                        name="selectArea"
                                                                        placeholder='Change Area'
                                                                        value={selectedGroupAreaUnit}
                                                                        onChange={this.handleSelectGroupAreaUnit}
                                                                        options={this.state.optionGroupAreaUnit}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="6">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-4 col-form-label" htmlFor="areaSize">
                                                                    Area Size
                                                                </Label>
                                                                <div className="col-md-8">
                                                                    <Field
                                                                        name="areaSize"
                                                                        placeholder="Area Size"
                                                                        onChange={(e) => this.handlePropertyAreaSize(e)}
                                                                        type="text"
                                                                        className={
                                                                            "form-control"}
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="propertyCount">Number of Properties</Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="propertyCount"
                                                                        placeholder="Property Count"
                                                                        type="number"
                                                                        // value={this.state.unique_reference}
                                                                        onChange={(e) => this.handlePropertyCount(e)}
                                                                        required="required"
                                                                        defaultValue="0"
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        {this.state.project_prefix == '' || this.state.project_prefix == null ? <></> : <>
                                                            <Col md="12">
                                                                <FormGroup className="mb-3 row d-flex">
                                                                    <Label className="col-md-2 col-form-label" htmlFor="">Project prefix</Label>
                                                                    <div className="col-form-label col-md-4">
                                                                        {this.state.project_prefix}
                                                                    </div>

                                                                </FormGroup>
                                                            </Col>
                                                        </>}
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="unique_reference_prefix">Unique Reference Prefix</Label>
                                                                <div className="col-md-4">
                                                                    <Field
                                                                        name="unique_reference_prefix"
                                                                        placeholder="Unique Reference Prefix"
                                                                        onChange={(e) => this.handleUniqueReferencePrefix(e)}
                                                                        type="text"
                                                                        required="required"
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                                <Label className="col-md-2 col-form-label" htmlFor="unique_reference_counter">Unique Reference Counter Start</Label>
                                                                <div className="col-md-4">
                                                                    <Field
                                                                        name="unique_reference_counter"
                                                                        placeholder="Unique Reference Counter Start"
                                                                        onChange={(e) => this.handleUniqueReferenceCounter(e)}
                                                                        type="number"
                                                                        required="required"
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />

                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        {this.state.unique_reference_counter <= 0 ? <></> : <>
                                                            <Col md="12">
                                                                <FormGroup className="mb-3 row d-flex">
                                                                    <div className="col-md-4">
                                                                        <p className="text-danger">MAX unique reference: {this.state.existing_counter_value}</p>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <p className="text-info">New unique reference start: {this.state.reference_prefix}{this.state.start_counter}</p>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <p className="text-info">New unique reference end: {this.state.reference_prefix}{this.state.end_counter}</p>
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                        </>}
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="selectFeatures">Property Features</Label>
                                                                <div className="col-md-10">
                                                                    <Select
                                                                        name="selectFeatures"
                                                                        isMulti={true}
                                                                        value={selectedGroupFeatures}
                                                                        onChange={this.handleSelectGroupFeatures}
                                                                        options={this.state.optionGroupFeatures}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                    {/* {this.state.featureSelected == 0 ? <>
                                                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Property features is not selected, please select first</p>
                                                                    </> : <></>
                                                                    } */}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="selectType">Property Type</Label>
                                                                <div className="col-md-10">
                                                                    <Select
                                                                        name="selectType"
                                                                        value={selectedGroupPropertyTypes}
                                                                        onChange={this.handleSelectGroupPropertyTypes}
                                                                        options={this.state.optionGroupPropertyTypes}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                    {this.state.propertyTypeSelected == 0 ? <>
                                                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Property type is not selected, please select first</p>
                                                                    </> : <></>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="currency">Currency Prices</Label>
                                                                {this.state.currencies?.map((d, i) =>
                                                                    <div key={d.currency_id} className="col-md-12 d-flex mb-2" style={{ "marginLeft": "1.5rem" }}>
                                                                        <Label style={{ "marginLeft": "0.5rem" }} className="col-md-2 col-form-label" htmlFor="currency">{d.currency_name}</Label>
                                                                        <div className="col-md-2">
                                                                            <Field
                                                                                id={"price-" + i}
                                                                                name={"price-" + i}
                                                                                placeholder="0.00"
                                                                                // value={this.state.price?this.state.price:""}
                                                                                onChange={(e) => this.handlePriceChange(e, i, d.currency_id)}
                                                                                type="text"
                                                                                pattern="[0-9]+([\.,][0-9]+)?"
                                                                                style={{ textAlign: 'right' }}
                                                                                required="required"
                                                                                className={"form-control"}
                                                                                onBlur={(e) => {
                                                                                    if (e.target.value.trim() === "") {
                                                                                        e.target.value = ""; // Empty value stays empty
                                                                                    } else {
                                                                                        const formattedValue = parseFloat(e.target.value).toFixed(2);
                                                                                        e.target.value = formattedValue;
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <Label style={{ "marginLeft": "1.5rem" }} className="col-md-2 col-form-label" htmlFor="feature_price">Price with features</Label>
                                                                        <div className="col-md-2">
                                                                            <Field
                                                                                name={"feature_price-" + i}
                                                                                placeholder="0.00"
                                                                                id={"feature_price-" + d.currency_id}
                                                                                // value={this.state.price?this.state.price:""}
                                                                                // onChange={(e) => this.handlePriceChange(e, i, d.currency_id)}
                                                                                type="text"
                                                                                disabled
                                                                                pattern="[0-9]+([\.,][0-9]+)?"
                                                                                style={{ textAlign: 'right' }}
                                                                                required="required"
                                                                                className={"form-control"}
                                                                            />
                                                                        </div>
                                                                        <Label style={{ "marginLeft": "1.5rem" }} className="col-md-1 col-form-label" htmlFor="total">Total</Label>
                                                                        <div className="col-md-2">
                                                                            <Field
                                                                                name={"total-" + i}
                                                                                placeholder="0.00"
                                                                                id={"total-" + d.currency_id}
                                                                                // value={this.state.price?this.state.price:""}
                                                                                // onChange={(e) => this.handlePriceChange(e, i, d.currency_id)}
                                                                                type="text"
                                                                                pattern="[0-9]+([\.,][0-9]+)?"
                                                                                style={{ textAlign: 'right' }}
                                                                                required="required"
                                                                                className={"form-control"}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {this.state.features?.map((d, i) =>
                                                                <div key={i}>
                                                                    <div className="d-flex">
                                                                        <Label className="col-md-2" style={{ "paddingTop": "50px", "marginTop": "20px" }}>{d.feature_name}: </Label>
                                                                        <Label className="col-md-2" style={{ "paddingTop": "50px", "marginTop": "20px" }}>{d.percentage_value}% </Label>
                                                                        {this.state.currencies?.map((c, j) =>
                                                                            <table key={j} className="table mt-0 pt-0">
                                                                                <thead>
                                                                                    <tr key={j}>
                                                                                        <th scope="col" key={j}>{c.currency_name}</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>

                                                                                    <tr key={j}>

                                                                                        <td scope="row">

                                                                                            <Field
                                                                                                name={"feature-" + i + "-" + c.currency_id}
                                                                                                id={"feature-" + i + "-" + c.currency_id}
                                                                                                placeholder="0.00"
                                                                                                currency_id={c.currency_id}
                                                                                                disabled
                                                                                                // value={this.state.percentage_value_against_price}
                                                                                                // value={this.state.price?this.state.price:""}
                                                                                                type="text"
                                                                                                pattern="[0-9]+([\.,][0-9]+)?"
                                                                                                style={{ textAlign: 'right' }}
                                                                                                required="required"
                                                                                                className={"form-control"}
                                                                                            />
                                                                                        </td>

                                                                                    </tr>

                                                                                </tbody>
                                                                            </table>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Col>
                                                        {/* <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="propertyCount">Multiple Select</Label>
                                                                <div className="mb-3 select2-container">
                                                                    <Select
                                                                        // value={selectedMulti}
                                                                        isMulti={true}
                                                                        // onChange={this.handleMulti}
                                                                        // options={optionGroup}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col> */}
                                                    </Row>
                                                    <Button color="primary" onClick={this.checkValidation} type="submit" className="float-end" >
                                                        Add Property
                                                    </Button>
                                                </Form>
                                            )}
                                        </Formik>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default AddProperty;
