import axios from "axios";
// import { Link, useHistory } from "react-router-dom";  
import { withRouter } from 'react-router-dom'   

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

export const get_all_documents = async () => {
    let response = await axios.get(baseURL + "/api/documents", config);
    return response.data.data;
}
export const delete_document = async (document_id, flag, id) => {
    let response = await axios.post(baseURL + "/api/document-delete/"+document_id,{
        "document_flag": flag,
        "id": id
    }, config);
    return response.data.data;
}

export const get_identity_documents = async () => {
    let response = await axios.get(baseURL + "/api/customer-nominees-document", config);
    return response.data.data;
}
export const get_sale_documents = async (sale_id) => {
    let response = await axios.get(baseURL + "/api/sale-documents/"+sale_id, config);
    return response.data.data;
}
export const get_payment_documents = async () => {
    let response = await axios.get(baseURL + "/api/sale-listing-against-payment", config);
    return response.data.data;
}
export const get_sale_payments_documents = async (sale_id) => {
    let response = await axios.get(baseURL + "/api/sale-payments-documents/"+sale_id, config);
    return response.data.data;
}
export const get_current_payment_document = async (payment_id) => {
    let response = await axios.get(baseURL + "/api/payments-documents/"+payment_id, config);
    return response.data.data;
}
export const download_documents = async (file_path) => {
    let response = await axios.post(baseURL + "/api/download-file", {
        "file_path":file_path
    }, config);
    console.log(response);
    return response.data.data;
}
export const get_all_customer_documents_logs = async (customer_id) => {
    let response = await axios.get(baseURL + "/api/customer-documents-activity-log/"+customer_id, config);
    return response.data.data;
}
export const get_all_nominee_documents_logs = async (nominee_id) => {
    let response = await axios.get(baseURL + "/api/nominee-documents-activity-log/"+nominee_id, config);
    return response.data.data;
}
export const get_all_identity_documents_logs = async () => {
    let response = await axios.get(baseURL + "/api/all-activity-logs-of-identity-documents", config);
    return response.data.data;
}
export const get_current_sale_documents_logs = async (sale_id) => {
    let response = await axios.get(baseURL + "/api/sales-document-activity-log/" + sale_id, config);
    return response.data.data;
}
export const get_all_sales_documents_logs = async () => {
    let response = await axios.get(baseURL + "/api/all-sales-document-activity-log", config);
    return response.data.data;
}
export const get_current_payment_documents_logs = async (payment_id) => {
    let response = await axios.get(baseURL + "/api/payment-documents-activity-log/" + payment_id, config);
    return response.data.data;
}
export const sales_receipt_documents_logs = async (sale_id) => {
    let response = await axios.get(baseURL + "/api/sales-receipt-documents-activity-log/" + sale_id, config);
    return response.data.data;
}
export const get_all_payments_documents_logs = async () => {
    let response = await axios.get(baseURL + "/api/all-payment-documents-activity-log", config);
    return response.data.data;
}
export const get_sale_receipt_documents = async (sale_id) => {
    let response = await axios.get(baseURL + "/api/get-sales-receipts-documents/"+sale_id, config);
    return response.data.data;
}