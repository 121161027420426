import React, { Component } from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row,Table
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get_current_project, get_project_detail } from '../api/projectApi';
class ViewProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "",
            optionGroupCountry: [],
            optionGroupCity: [],
            optionProjectType: [],
            project_id: '',
            project_name: '',
            project_phone_primary: '',
            project_phone_secondary: '',
            project_address_line_1: '',
            project_address_line_2: '',
            project_email: '',
            project_postcode: '',
            project_country_id: '',
            project_city_id: '',
            project_city_title: '',
            project_country_title: '',
            project_website: '',
            project_prefix: '',
            project_late_fee: '',
            project_project_type_id: '',
            project_project_type_title: '',
            project_organisation_id: '',
            project_organisation_title: '',
            project_picture: '',
            project_icon: null,
            project_picture: null,
            project_logo: null,
            show_picture: null,
            show_logo: null,
            show_icon: null,
            redirect: null,
            
            project_features: [],
            project_phases: [],
            project_sizes: [],
            project_floors: [],
            project_currencies: [],
        };
    }
    componentDidMount() {
        Promise.all([get_project_detail(localStorage.getItem("current_project_id"))]).then((values) => {
            this.setState({ project_id: values[0].project.id });
            this.setState({ project_name: values[0].project.name });
            this.setState({ project_phone_primary: values[0].project.phone_primary });
            this.setState({ project_phone_secondary: values[0].project.phone_secondary });
            this.setState({ project_email: values[0].project.email });
            this.setState({ project_address_line_1: values[0].project.address_line_1 });
            this.setState({ project_address_line_2: values[0].project.address_line_2 });
            this.setState({ project_postcode: values[0].project.post_code });
            this.setState({ project_country_id: values[0].project.country_id });
            this.setState({ project_country_title: values[0].project.country_title });
            this.setState({ project_website: values[0].project.website });
            this.setState({ project_late_fee: values[0].project.late_fee });
            this.setState({ project_prefix: values[0].project.prefix });
            this.setState({ project_project_type_id: values[0].project.project_type_id });
            this.setState({ project_project_type_title: values[0].project.project_type_name });
            this.setState({ project_organisation_id: values[0].project.organisation_id });
            this.setState({ project_organisation_title: values[0].project.organisation_name });
            this.setState({ show_picture: values[0].project.picture });
            this.setState({ show_icon: values[0].project.icon });
            this.setState({ show_logo: values[0].project.logo });


            this.setState({ project_city_id: values[0].project.city_id });
            this.setState({ project_city_title: values[0].project.city_title });

            this.setState({ project_features: values[0].project_features })
            this.setState({ project_phases: values[0].phases })
            this.setState({ project_sizes: values[0].sizes })
            this.setState({ project_floors: values[0].floors })
            this.setState({ project_currencies: values[0].project_currencies })
        });
    }
    render() {
        //meta title
        document.title = "Project Detail";
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Project" breadcrumbItem="Project Detail" />
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody className="border-bottom">
                                        <div className="d-flex">
                                            <img src={this.state.show_logo} alt="" height="50" />
                                            <div className="flex-grow-1 ms-3">
                                                <h5 className="fw-semibold">{this.state.project_name}</h5>
                                                <ul className="list-unstyled hstack gap-2 mb-0">
                                                    {/* <li>
                                                        <i className="bx bx-building-house"></i> <span className="text-muted">Themesbrand</span>
                                                    </li> */}
                                                    <li>
                                                        <i className="bx bx-map"></i> <span className="text-muted">{this.state.project_country_title}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        <h5 className="fw-semibold mb-3">Description & Details</h5>
                                        {/* <p className="text-muted"> */}
                                        {/* </p> */}
                                        <div className="table-responsive">
                                            <Table className="table table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Project ID</th>
                                                        <th>Locality</th>
                                                        <th>Type</th>
                                                        <th>City</th>
                                                        {/* <th>Organisation</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{this.state.project_id}</td>
                                                        <td>{this.state.project_address_line_1}</td>
                                                        <td>{this.state.project_project_type_title}</td>
                                                        <td>{this.state.project_city_title}</td>
                                                        {/* <td>{this.state.project_organisation_title}</td> */}
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                                {this.state.project_features == '' ? <></> : <>
                                    <Card>
                                        <CardBody>
                                            <h5 className="fw-semibold mb-3 mt-3">Project Features</h5>
                                            <div className="table-responsive">
                                                <Table className="table table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>FEATURE NAME</th>
                                                            <th>PERCENTAGE VALUE</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.project_features?.map((data, index) =>

                                                            <tr key={index}>
                                                                <td>{data.feature_name}</td>
                                                                <td>{data.percentage_value}</td>
                                                            </tr>

                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </>}
                                {this.state.project_currencies == '' ? <></> : <>
                                    <Card>
                                        <CardBody>
                                            <h5 className="fw-semibold mb-3 mt-3">Project Currencies</h5>
                                            <div className="table-responsive">
                                                <Table className="table table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>CURRENCIES</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <Row className="ms-0" style={{ "border": "none" }}>
                                                            {this.state.project_currencies?.map((data, index) =>
                                                                <tr style={{ "border": "none" }} key={index} className="col-md-4">
                                                                    <td>{data.currency_name}</td>
                                                                </tr>
                                                            )}
                                                        </Row>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </>}
                                {this.state.project_sizes == '' ? <></> : <>
                                    <Card>
                                        <CardBody>
                                            <h5 className="fw-semibold mb-3 mt-3">Project Sizes</h5>
                                            <div className="table-responsive">
                                                <Table className="table table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>SIZES NAME</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <Row className="ms-0" style={{ "border": "none" }}>
                                                            {this.state.project_sizes?.map((data, index) =>
                                                                <tr style={{ "border": "none" }} key={index} className="col-md-4">
                                                                    <td>{data.name}</td>
                                                                </tr>
                                                            )}
                                                        </Row>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </>}
                                {this.state.project_phases == '' ? <></> : <>
                                    <Card>
                                        <CardBody>
                                            <h5 className="fw-semibold mb-3 mt-3">Project Phase & Blocks</h5>
                                            <Row className="ms-0">
                                                {this.state.project_phases?.map((data, index) =>
                                                    <ul key={index} className="col-md-4"><b>{data.name}</b>
                                                        {data.blocks?.map((b, i) =>
                                                            <li className="ms-4 mt-2" key={i}>
                                                                {b.name}
                                                            </li>
                                                        )}
                                                    </ul>
                                                )}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </>}
                                {this.state.project_floors == '' ? <></> : <>
                                    <Card>
                                        <CardBody>
                                            <h5 className="fw-semibold mb-3 mt-3">Project Floors</h5>
                                            <div className="table-responsive">
                                                <Table className="table table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>FLOORS NAME</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <Row style={{ "border": "none" }}>
                                                            {this.state.project_floors?.map((data, index) =>
                                                                <tr style={{ "border": "none" }} key={index} className="col-md-4">
                                                                    <td>{data.name}</td>
                                                                </tr>
                                                            )}
                                                        </Row>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </>}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default ViewProject;
