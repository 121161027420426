import axios from "axios";

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

export const get_all_countries = async () => {
    let response = await axios.get(baseURL + "/api/countries", config);
    return response.data.data;
}
export const update_country = async (country_id,name) => {
    let response = await axios.patch(baseURL + "/api/countries/"+country_id,{
        "title": name, 
      }, config);
    return response.data.data;
}
export const delete_country = async (country_id) => {
    let response = await axios.delete(baseURL + "/api/countries/"+country_id, config);
    return response.data.data;
}