import React, { Component } from "react";
import { convertToRaw, EditorState, Modifier, convertFromRaw } from "draft-js";
import draftToHtmlCmp from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import { template_variable_list, get_email_template } from "../api/templatesApi"
class EditEmailBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentState: null,
            editorState: EditorState.createEmpty(),
            variable_list: [],
        };
    }
    componentDidMount() {
        Promise.all([get_email_template(localStorage.getItem("current_sample_id"))]).then((values) => {
            Promise.all([template_variable_list(values[0].email_type)]).then((values) => {
                const variable_list = Object.entries(values[0]).map(([label, value]) => (label));
                this.setState({ variable_list: variable_list })
            });
            const replacedString = values[0].bodyjson.replace(/\//g, '"');
            const emailBodyObj = JSON.parse(replacedString)
            var contentState = convertFromRaw(emailBodyObj);
            var editorState = EditorState.createWithContent(contentState);
            this.setState({ contentState, editorState });
            setTimeout(() => {
                this.sendDataToParent()
            }, 500);
        });
    }
    onEditorStateChange = editorState => {
        this.setState({ editorState });
    };

    // Function to handle adding a variable to the editor
    addVariableToEditor = (variable) => {
        const currentEditorState = this.state.editorState;
        const contentState = currentEditorState.getCurrentContent();
        const selectionState = currentEditorState.getSelection();
        const contentStateWithEntity = contentState.createEntity('VARIABLE', 'IMMUTABLE', { variable });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newContentState = Modifier.insertText(contentStateWithEntity, selectionState, `${variable}`, null, entityKey);
        const newEditorState = EditorState.push(currentEditorState, newContentState, 'insert-characters');
        this.setState({ editorState: newEditorState });
    };
    sendDataToParent = () => {
        const html = draftToHtmlCmp(
            convertToRaw(this.state.editorState.getCurrentContent())
        )
        const emailJSON = convertToRaw(this.state.editorState.getCurrentContent())
        this.props.sendDataToParent(emailJSON, html);
    };
    render() {
        const { editorState, variable_list } = this.state;
        // Render the variable buttons
        const variableButtons = variable_list?.map((variable, index) => (
            <Button key={index} className="m-1 btn-sm bg-primary" onClick={() => this.addVariableToEditor(variable)}>
                {variable}
            </Button>
        ));
        return (
            <React.Fragment>
                <Container fluid={true}>
                    <Row className="pb-3">
                        <Col xl="8">
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={this.onEditorStateChange}
                                toolbar={{
                                    options: [
                                        'inline',
                                        'fontSize',
                                        'fontFamily',
                                        'textAlign',
                                        'colorPicker',
                                        'link',
                                        'remove',
                                        'history'
                                    ],
                                    inline: {
                                        inDropdown: false,
                                        options: ['bold', 'italic', 'underline']
                                    }
                                }}
                                onChange={this.sendDataToParent}
                            />
                        </Col>
                        <Col xl="4">
                            <div>
                                <div>
                                    <h4>Variables List</h4>
                                </div>
                                {variableButtons}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}
export default EditEmailBody