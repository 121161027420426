import axios from "axios";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
// const baseURL = secureLocalStorage.getItem("baseURL");
export const get_ip_data = async () => {
    let response = await axios.get(baseURL + "/api/propertyappz-ip-address", config);
    return response.data.data;
}
export const update_ip_data = async (payload) => {
    let response = await axios.post(baseURL + "/api/propertyappz-ip-address", config);
    return response.data.data;
}