import React, { Component } from "react";
import {
    Card,
    CardBody,
    Col,
    Collapse,
    Container,
    Row,
} from "reactstrap";
import { Link } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../../../../components/Common/Breadcrumb";
import { get_user_permissions } from "helpers/custom_helper";
var emailTemplatesLogsView;
class TabView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            col1: true,
            col2: false,
            col3: false,
        };
        this.t_col1 = this.t_col1.bind(this);
        this.t_col2 = this.t_col2.bind(this);
        this.t_col3 = this.t_col3.bind(this);
    }

    t_col1() {
        this.setState({
            col1: !this.state.col1,
            col2: false,
            col3: false
        });
    }

    t_col2() {
        this.setState({
            col1: false,
            col2: !this.state.col2,
            col3: false
        });
    }
    t_col3() {
        this.setState({
            col1: false,
            col2: false,
            col3: !this.state.col3,
        });
    }
    render() {
        //meta title
        document.title = "Tab View";
        emailTemplatesLogsView = get_user_permissions("email-templates-logs-view");
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs
                            title="Email Templates"
                            breadcrumbItem="Financial Emails"
                        />
                        <Row>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="accordion" id="accordion">
                                                {/* Invoice Email */}
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button
                                                            className="accordion-button fw-medium"
                                                            type="button"
                                                            onClick={this.t_col1}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Invoice Email
                                                        </button>
                                                    </h2>
                                                    <Collapse
                                                        isOpen={this.state.col1}
                                                        className="accordion-collapse"
                                                    >
                                                        <div className="accordion-body">
                                                            <div className="text-dark">
                                                                <Link to="/template/email/financial/invoice-email/samples-list">
                                                                    <p>
                                                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                        Samples Templates
                                                                    </p>
                                                                </Link>
                                                                <Link to="/template/email/financial/invoice-email/clone-list">
                                                                    <p>
                                                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                        List Cloned Templates
                                                                    </p>
                                                                </Link>
                                                                {emailTemplatesLogsView ? <>
                                                                    <Link to="/template/email/financial/invoice-email/logs">
                                                                        <p>
                                                                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                            Logs
                                                                        </p>
                                                                    </Link>
                                                                </> : <></>}
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                                {/* End Invoice Email */}
                                                {/* Receipt Email */}
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingTwo">
                                                        <button
                                                            className="accordion-button fw-medium collapsed"
                                                            type="button"
                                                            onClick={this.t_col2}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Receipt Email
                                                        </button>
                                                    </h2>
                                                    <Collapse
                                                        isOpen={this.state.col2}
                                                        className="accordion-collapse"
                                                    >
                                                        <div className="accordion-body">
                                                            <div className="text-dark">
                                                                <Link to="/template/email/financial/receipt-email/samples-list">
                                                                    <p>
                                                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                        Samples Templates
                                                                    </p>
                                                                </Link>
                                                                <Link to="/template/email/financial/receipt-email/clone-list">
                                                                    <p>
                                                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                        List Cloned Templates
                                                                    </p>
                                                                </Link>
                                                                {emailTemplatesLogsView ? <>
                                                                    <Link to="/template/email/financial/receipt-email/logs">
                                                                        <p>
                                                                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                            Logs
                                                                        </p>
                                                                    </Link>
                                                                </> : <></>}
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                                {/* End Receipt Email */}
                                                {/* Payment Reminder Email */}
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingThree">
                                                        <button
                                                            className="accordion-button fw-medium collapsed"
                                                            type="button"
                                                            onClick={this.t_col3}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Payment Reminder Email
                                                        </button>
                                                    </h2>
                                                    <Collapse
                                                        isOpen={this.state.col3}
                                                        className="accordion-collapse"
                                                    >
                                                        <div className="accordion-body">
                                                            <div className="text-dark">
                                                                <Link to="/template/email/financial/payment-reminder-email/samples-list">
                                                                    <p>
                                                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                        Samples Templates
                                                                    </p>
                                                                </Link>
                                                                <Link to="/template/email/financial/payment-reminder-email/clone-list">
                                                                    <p>
                                                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                        List Cloned Templates
                                                                    </p>
                                                                </Link>
                                                                {emailTemplatesLogsView ? <>
                                                                    <Link to="/template/email/financial/payment-reminder-email/logs">
                                                                        <p>
                                                                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                            Logs
                                                                        </p>
                                                                    </Link>
                                                                </> : <></>}
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                                {/* End Payment Reminder Email */}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default TabView;