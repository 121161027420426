import React, { Component } from "react";
import { Row, Col, Table, Card, CardBody, CardTitle, Button, Label, InputGroup, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { isEmpty, size } from "lodash";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Flatpickr from "react-flatpickr"
import axios from "axios";
import { showToast } from '../../UI/ui-toast'
import Select from "react-select"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Formik, Field, Form, ErrorMessage } from "formik";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
import { get_current_payment_document, download_documents } from '../api/documentsApi';
class PaymentDocumentsList extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 500,
            listData: [],
            listdata: "",
            selectDate: false,
            redirect: null,
        };
        this.onClickDownload = this.onClickDownload.bind(this);
    }
    componentDidMount() {
        Promise.all([get_current_payment_document(localStorage.getItem("current_payment_type_id"))]).then((values) => {
            console.log(values[0]);
            this.setState({ listData: values[0] });
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    };
    onClickDownload = listdata => {
        Promise.all([download_documents(listdata.document_path)]).then((values) => {
            const contentType = values[0].split(';')[0].split(':')[1];
            const base64Content = values[0].split(',')[1];
            const byteCharacters = atob(base64Content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const extension = values[0].split(';')[0].split('/')[1];
            const blob = new Blob([byteArray], { type: contentType });
            const fileName = `${listdata.document_name}.${extension}`;

            setTimeout(() => {
                if (contentType.startsWith('image/png') || contentType.startsWith('image/jpg') || contentType.startsWith('image/jpeg')) {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.target = '_blank';
                    a.rel = 'noopener noreferrer';
                    a.click();
                } else {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    a.target = '_blank';
                    a.rel = 'noopener noreferrer';
                    a.click();
                }
                showToast("Success", "Download Success.", "success")
            }, 100);
        }).catch((error) => {
            showToast("Failed", "Download Failed!", "error")
        });
    };
    showToast(title, message, toastType) {
        //position class
        var positionClass = "toast-top-right";
        toastr.options = {
            positionClass: positionClass,
            timeOut: this.state.timeOut,
            extendedTimeOut: this.state.extendedTimeOut,
            showEasing: this.state.showEasing,
            hideEasing: this.state.hideEasing,
            showMethod: this.state.showMethod,
            hideMethod: this.state.hideMethod,
            showDuration: this.state.showDuration,
            hideDuration: this.state.hideDuration,
        };

        // setTimeout(() => toastr.success(`Settings updated `), 300)
        //Toaster Types
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
    }
    render() {
        const listdata = this.state.listdata;
        const columns = [
            {
                dataField: "document_name",
                text: "Dcoument Name",
                sort: true,
            },
            {
                dataField: "document_type_name",
                text: "Document Type Name",
                sort: true,
            },
            {
                dataField: "document_sub_type_name",
                text: "Document Sub Type",
                sort: true,
            },
            {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                formatter: (cellContent, listdata) => (
                    <>
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link
                                    // to={listdata.document_path}
                                    to="#"
                                    onClick={() => this.onClickDownload(listdata)}
                                    className="btn btn-sm btn-soft-info" >
                                    {/* <a download="FILENAME.EXT" href={listdata.document_path}>Download</a> */}
                                    <i className="mdi mdi-file-download-outline" id="downloadDocument" />
                                    <UncontrolledTooltip placement="top" target="downloadDocument">
                                        Download Document
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    </>
                ),
            },

        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];

        const pageOptions = {
            sizePerPage: 500,
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;

        //meta title
        document.title = "List Documents of Payments";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                console.log(row);
                this.setState({ recordDeleteID: row.id });
            }
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Financial Documents" breadcrumbItem="List Documents of Payments" parentLink="/payments/documents" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">List Documents of Payments</h5>
                                            </div>
                                        </CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.listData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.listData}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            selectRow={selectRow}
                                                                            rowEvents={rowEvents}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            ref={this.node}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default PaymentDocumentsList;
