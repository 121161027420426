import React, { Component } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Redirect } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row, InputGroup, Form
} from "reactstrap";
import axios from "axios";
import Flatpickr from 'react-flatpickr'
import * as Yup from "yup";
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { showToast } from "pages/UI/ui-toast";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
// const baseURL = secureLocalStorage.getItem("baseURL");
import { get_ip_data, update_ip_data } from '../api/ip-managementApi';
import { get_user_permissions } from "helpers/custom_helper";
var propertyappzIPmanagementView, propertyappzIPmanagementEdit;
class IPManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "",
            redirect: null,
            optionGroup: [],
            ip_addresses: [''],
            loading: false,
        };
    }
    componentDidMount() {
        propertyappzIPmanagementView = get_user_permissions('ip-management-view')
        propertyappzIPmanagementEdit = get_user_permissions('ip-management-edit')
        Promise.all([get_ip_data()])
            .then((values) => {
                showToast("Success", "The IP addresses fetched successfully.", "success");
                this.setState({ ip_addresses: values[0] })
            }).catch((error) => {
                showToast("Failed", error.response.data.message, "error");
            });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ loading: true }); // Set loading to true

        const payload = {
            ip_address: this.state.ip_addresses,
        };

        axios.post(baseURL + "/api/propertyappz-ip-address", payload, config)
            .then((response) => {
                showToast("Success", "The IP addresses updated successfully.", "success");
                this.setState({ redirect: "", loading: false }); // Set loading to false on success
            })
            .catch((error) => {
                showToast("Failed", error.response.data.message, "error");
                this.setState({ loading: false }); // Set loading to false on error
            });
    }
    showToast(title, message, toastType) {
        //position class
        var positionClass = "toast-top-right";
        toastr.options = {
            positionClass: positionClass,
            timeOut: this.state.timeOut,
            extendedTimeOut: this.state.extendedTimeOut,
            showEasing: this.state.showEasing,
            hideEasing: this.state.hideEasing,
            showMethod: this.state.showMethod,
            hideMethod: this.state.hideMethod,
            showDuration: this.state.showDuration,
            hideDuration: this.state.hideDuration,
        };

        // setTimeout(() => toastr.success(`Settings updated `), 300)
        //Toaster Types
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
    }
    handleIPChange = (index, value) => {
        const ip_addresses = [...this.state.ip_addresses];
        ip_addresses[index] = value;
        this.setState({ ip_addresses });
    };

    addIP = () => {
        this.setState(prevState => ({
            ip_addresses: [...prevState.ip_addresses, '']
        }));
    };

    removeIP = index => {
        const ip_addresses = [...this.state.ip_addresses];
        ip_addresses.splice(index, 1);
        this.setState({ ip_addresses });
    };

    render() {
        //meta title
        document.title = "IP Management";
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Settings" breadcrumbItem="IP Management" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Form onSubmit={this.handleSubmit}>
                                            <Row>
                                                <Col md="12">
                                                    <Card>
                                                        <CardTitle className="mb-2">IP Addresses*</CardTitle>
                                                        <CardBody className="border border-primary">
                                                            {this.state.ip_addresses.map((ip, index) => (
                                                                <FormGroup key={index} className="mb-3 row d-flex">
                                                                    <Label className="col-md-2 col-form-label">IP Address {index + 1}</Label>
                                                                    <div className="col-md-8">
                                                                        <Input
                                                                            type="text"
                                                                            value={ip}
                                                                            onChange={e => this.handleIPChange(index, e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <Button color="danger" onClick={() => this.removeIP(index)}>Remove</Button>
                                                                    </div>
                                                                </FormGroup>
                                                            ))}
                                                            <Button color="primary" onClick={this.addIP}>Add IP Address</Button>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            {propertyappzIPmanagementEdit ? <>
                                                <Button color="success" type="submit" className="float-end" disabled={this.state.loading}>
                                                    {this.state.loading ? 'Loading...' : 'Update'}
                                                </Button>
                                            </> : <></>}
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default IPManagement;
