import React, { Component } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Redirect } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row, InputGroup, Form
} from "reactstrap";
import axios from "axios";
import Flatpickr from 'react-flatpickr'
import * as Yup from "yup";
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { showToast } from "pages/UI/ui-toast";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
class AddCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "",
            redirect: null,
            optionGroup: [],
            id: "",
            name: '',
            campaign_ID: '',
            statusArray: [
                {
                    "value": '1',
                    "label": 'Yes'
                },
                {
                    "value": '0',
                    "label": 'No'
                }
            ],
            selectedStatus: '',
            startDate: [new Date()],
            endDate: [new Date()],
            todayDate: '',
            parameters: [{ key: "", value: "" }],
            domain_URL: '',
        };
    }

    async getOptions() {
        const res = await axios.get(baseURL + "/api/campaign-type-listing", config);
        const data = res.data.data
        const options = data.map(d => ({
            "value": d.id,
            "label": d.name
        }))
        this.setState({ optionGroup: options })
    }
    componentDidMount() {
        this.getOptions()
        this.setState({ todayDate: new Date() });
    }
    handleSelectGroup = selectedGroup => {
        this.setState({ campaign_ID: selectedGroup })
        this.setState({ selectedGroup })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const parametersJSON = (this.state.parameters);
        const transformedParams = {};
        parametersJSON.forEach(param => {
            transformedParams[param.key] = param.value;
        });
        const name = document.getElementById('name').value;
        const desc = document.getElementById('description').value;
        const adID = document.getElementById('adID').value;
        const domain = document.getElementById('domain_URL').value;
        if (!name ||
            !desc ||
            !this.state.selectedStatus ||
            !this.state.startDate ||
            !this.state.endDate ||
            !this.state.campaign_ID?.value || !domain) {
            let missingFields = '';
            if (!name) {
                missingFields += '<li>Name</li>';
            }
            if (!domain) {
                missingFields += '<li>Domain</li>';
            }
            if (!desc) {
                missingFields += '<li>Description</li>';
            }
            if (!this.state.selectedStatus) {
                missingFields += '<li>Status</li>';
            }
            if (!this.state.startDate) {
                missingFields += '<li>Start Date</li>';
            }
            if (!this.state.endDate) {
                missingFields += '<li>End Date</li>';
            }
            if (!this.state.campaign_ID?.value) {
                missingFields += '<li>Campagin Type</li>';
            }
            showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
            return false;
        }
        var formatedStartDate = this.getDateFormateMethod(this.state.startDate[0]);
        var formatedEndDate = this.getDateFormateMethod(this.state.endDate[0]);
        axios.post(baseURL + "/api/campaigns", {
            "name": name,
            "campaign_type_id": this.state.campaign_ID?.value,
            "discription": desc,
            "start_date": formatedStartDate,
            "end_date": formatedEndDate,
            "ads_id": adID,
            "status": this.state.selectedStatus?.value,
            "domain_url": domain,
            "utm_url": this.getCompleteURL(),
            "parameters": transformedParams
        }, config)
            .then((response) => {
                showToast("Success", "The campaign added successfully.", "success");
                this.setState({ redirect: "/campaign/list" });
            })
            .catch((error) => {
                showToast("Failed", error.response.data.message, "error")
            });
    }

    getDateFormateMethod = (myDate) => {
        var today = myDate
        const dd = today.getDate().toString()
        const mm = today.getMonth() + 1
        const yyyy = today.getFullYear()
        const fromate_date = (today = yyyy + "-" + mm + "-" + dd)
        return fromate_date
    }
    showToast(title, message, toastType) {
        //position class
        var positionClass = "toast-top-right";
        toastr.options = {
            positionClass: positionClass,
            timeOut: this.state.timeOut,
            extendedTimeOut: this.state.extendedTimeOut,
            showEasing: this.state.showEasing,
            hideEasing: this.state.hideEasing,
            showMethod: this.state.showMethod,
            hideMethod: this.state.hideMethod,
            showDuration: this.state.showDuration,
            hideDuration: this.state.hideDuration,
        };

        // setTimeout(() => toastr.success(`Settings updated `), 300)
        //Toaster Types
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
    }
    handleSelectedStatus = selectedStatus => {
        this.setState({ selectedStatus: selectedStatus })
    }
    // Function to handle changes in parameter inputs
    handleParameterChange = (index, key, value) => {
        const parameters = [...this.state.parameters];
        parameters[index] = { ...parameters[index], [key]: value };
        this.setState({ parameters });
    };

    // Function to add a new parameter
    addParameter = () => {
        this.setState(prevState => ({
            parameters: [...prevState.parameters, { key: "", value: "" }]
        }));
    };

    // Function to remove a parameter
    removeParameter = index => {
        const parameters = [...this.state.parameters];
        parameters.splice(index, 1);
        this.setState({ parameters });
    };
    generateURL = () => {
        const { parameters, domain_URL } = this.state;
        var domain = document.getElementById('domain_URL').value;
        let url = domain;
        // Loop through parameters and append UTM parameters to the URL
        parameters.forEach(parameter => {
            const { key, value } = parameter;
            if (key && value) {
                // Append "&" if URL already has parameters, otherwise "?"
                url += url.includes("?") ? `&${encodeURIComponent(key)}=${encodeURIComponent(value)}` : `?${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            }
        });
        this.setState({ domain_URL: url })
    };
    getCompleteURL = () => {
        const { parameters } = this.state;
        var domain = document.getElementById('domain_URL').value;
        let url = domain;
        parameters.forEach(parameter => {
            const { key, value } = parameter;
            if (key && value) {
                url += url.includes("?") ? `&${encodeURIComponent(key)}=${encodeURIComponent(value)}` : `?${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            }
        });
        return url;
    };
    render() {
        //meta title
        document.title = "Add Campaign";
        const { selectedStatus, parameters, domain_URL } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        const { selectedGroup } = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Campaigns" breadcrumbItem="Add Campaign" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Form onSubmit={this.handleSubmit}>
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="name">
                                                            Name*
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="name"
                                                                name="name"
                                                                placeholder="Name"
                                                                type="text"
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <div className="mb-3 row d-flex select2-container">
                                                        <Label className="col-md-2 col-form-label" htmlFor="campaignType">Campaign Type*</Label>
                                                        <div className="col-md-10">
                                                            <Select
                                                                value={selectedGroup}
                                                                onChange={this.handleSelectGroup}
                                                                options={this.state.optionGroup}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="adID">
                                                            Ads ID
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="adID"
                                                                name="adID"
                                                                placeholder="Ads ID"
                                                                type="text"
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <div className="mb-3 row d-flex ">
                                                        <Label className="col-md-2 col-form-label" htmlFor="selectDate">Start Date*</Label>
                                                        <div className="docs-datepicker col-md-10">
                                                            <InputGroup>
                                                                <Flatpickr
                                                                    className="form-control d-block"
                                                                    placeholder="Pick a start date"
                                                                    value={this.state.todayDate}
                                                                    options={{
                                                                        minDate: "today",
                                                                        enableTime: false,
                                                                        dateFormat: "d/m/Y",
                                                                    }}
                                                                    onChange={date => {
                                                                        this.setState({ startDate: date })
                                                                    }}
                                                                />
                                                                <div className="input-group-append">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                        disabled
                                                                    >
                                                                        <i
                                                                            className="fa fa-calendar"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </button>
                                                                </div>
                                                            </InputGroup>
                                                            <div className="docs-datepicker-container" />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="12">
                                                    <div className="mb-3 row d-flex ">
                                                        <Label className="col-md-2 col-form-label" htmlFor="selectDate">End Date*</Label>
                                                        <div className="docs-datepicker col-md-10">
                                                            <InputGroup>
                                                                <Flatpickr
                                                                    className="form-control d-block"
                                                                    placeholder="Pick a end date"
                                                                    value={this.state.todayDate}
                                                                    options={{
                                                                        minDate: "today",
                                                                        enableTime: false,
                                                                        dateFormat: "d/m/Y",
                                                                    }}
                                                                    onChange={date => {
                                                                        this.setState({ endDate: date })
                                                                    }}
                                                                />
                                                                <div className="input-group-append">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                        disabled
                                                                    >
                                                                        <i
                                                                            className="fa fa-calendar"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </button>
                                                                </div>
                                                            </InputGroup>
                                                            <div className="docs-datepicker-container" />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="description">Description*</Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="description"
                                                                name="description"
                                                                placeholder="Type description here..."
                                                                type="textarea"
                                                                rows={3}
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <div className="mb-3 row d-flex select2-container">
                                                        <Label className="col-md-2 col-form-label" htmlFor="selectedStatus">Status*</Label>
                                                        <div className="col-md-10">
                                                            <Select
                                                                value={selectedStatus}
                                                                onChange={this.handleSelectedStatus}
                                                                options={this.state.statusArray}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                            <div className="invalid-feedback" id="status-select-msg" style={{ display: this.state.status_select_display }}>Please Select Role Status.</div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="domain">Domain*</Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="domain_URL"
                                                                name="domain"
                                                                placeholder="Type your domain here..."
                                                                type="text"
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="utm_url">UTM URL</Label>
                                                        <div className="col-md-10">
                                                            <Label className="col-form-label text-info" htmlFor="utm_url">{domain_URL?domain_URL:"Please Generate UTM URL"}</Label>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <div className="mb-3 row d-flex select2-container">
                                                        <div className="col-md-2"></div>
                                                        <div className="col-md-10">
                                                            <div className="float-end">
                                                                <Button color="primary" className="mx-2" onClick={this.generateURL}>
                                                                    Generate URL
                                                                </Button>
                                                                <Button color="primary" className="" onClick={this.addParameter}>
                                                                    Add Parameters
                                                                </Button>.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                {/* <Button color="primary" type="submit" className="float-end">
                                                Add Campaign
                                            </Button> */}
                                            </Row>
                                            <Row >
                                                <Col md="12">
                                                    <Card>
                                                        <CardTitle className="mb-2">Parameters*</CardTitle>
                                                        <CardBody className="border border-primary">
                                                            {parameters.map((parameter, index) => (
                                                                <FormGroup key={index} className="mb-3 row d-flex">
                                                                    <Label className="col-md-1 col-form-label">Key</Label>
                                                                    <div className="col-md-4">
                                                                        <Input
                                                                            type="text"
                                                                            value={parameter.key}
                                                                            onChange={e => this.handleParameterChange(index, "key", e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <Label className="col-md-1 col-form-label">Value</Label>
                                                                    <div className="col-md-4">
                                                                        <Input
                                                                            type="text"
                                                                            value={parameter.value}
                                                                            onChange={e => this.handleParameterChange(index, "value", e.target.value)}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <Button color="danger" onClick={() => this.removeParameter(index)}>Remove</Button>
                                                                    </div>
                                                                </FormGroup>
                                                            ))}
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <Button color="success" type="submit" className="float-end">
                                                Submit
                                            </Button>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default AddCampaign;
