import React, { Component } from "react";
import { Row, Col, Button, Table, Card, CardBody } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { isEmpty, size } from "lodash";
import moment from "moment";
import { showToast } from '../../../../../UI/ui-toast'

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../../../../../components/Common/Breadcrumb";
import { get_all_email_template_activity_log } from '../../../../api/templatesApi';
import ViewHTMLModal from "pages/Templates/Email/Common/ViewHTMLModal";
class PaymentReminderEmailLogs extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 50,
            listData: [],
            listdata: "",
            recordDeleteID: null,
            redirect: null,
            viewHTML: false,
            htmlToModal: null
        };
    }
    toggleViewHTMLModal = () => {
        this.setState(prevState => ({
            viewHTML: !prevState.viewHTML,
        }));
    };
    onClickViewHTML = html => {
        this.setState({ htmlToModal: html })
        this.setState({ viewHTML: true });
    };
    componentDidMount() {
        Promise.all([get_all_email_template_activity_log("Payment Reminder Template")]).then((values) => {
            this.setState({ listData: values[0] })
            this.setState({ log_data: values[0] })
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    }
    render() {
        const listdata = this.state.listdata;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        const { log_data } = this.state;
        const { viewHTML } = this.state;
        const columns = [
            {
                dataField: "id",
                text: "#",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {Object.entries(listdata.properties.new).length > 0 || Object.entries(listdata.properties.old).length > 0 ? (
                            <>
                                {listdata.logsDetails.id}
                            </>
                        ) : null}
                    </>

                ),
            },
            {
                dataField: "full_name",
                text: "Description",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {Object.entries(listdata.properties.new).length > 0 || Object.entries(listdata.properties.old).length > 0 ? (
                            <>
                                {listdata.logsDetails.description.split("-").pop() == 'updated' ? <>
                                    <strong>{listdata.full_name}</strong> made the following changes in <br /><strong>{listdata.logsDetails.subject_type.split("\\").pop()}</strong> of&nbsp;
                                    <Link
                                        to="#"
                                    >
                                        <strong>{listdata.email_template_name}</strong>&nbsp;</Link>

                                    at {moment(listdata.logsDetails.created_at).format('DD/MM/YYYY h:mma')}
                                </> : <></>}

                                {listdata.logsDetails.description.split("-").pop() == 'created' ? <>
                                    <strong>{listdata.full_name}</strong> added the following information in <br /><strong>{listdata.logsDetails.subject_type.split("\\").pop()}</strong> of&nbsp;
                                    <Link
                                        to="#"
                                    >
                                        <strong>{listdata.email_template_name}</strong>&nbsp;</Link>

                                    at {moment(listdata.logsDetails.created_at).format('DD/MM/YYYY h:mma')}
                                </> : <></>}

                                {listdata.logsDetails.description.split("-").pop() == 'deleted' ? <>
                                    <strong>{listdata.full_name}</strong> deleted the following information in <br /><strong>{listdata.logsDetails.subject_type.split("\\").pop()}</strong> of&nbsp;
                                    <Link
                                        to="#"
                                    >
                                        <strong>{listdata.email_template_name}</strong>&nbsp;</Link>

                                    at {moment(listdata.logsDetails.created_at).format('DD/MM/YYYY h:mma')}
                                </> : <></>}
                            </>
                        ) : null}
                    </>

                ),
            },
            {
                dataField: "changes",
                text: "Changes",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        <tr>
                            <td colSpan={3}>
                                <div className="table-responsive">
                                    <Table className="table table-bordered table-striped">
                                        <thead className="border border-1 border-white bg-dark text-white">
                                            <tr>
                                                <th scope="col">Column</th>
                                                <th scope="col">Old</th>
                                                <th scope="col">New</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(listdata.properties.new).map(([key, value], index) => {
                                                const oldValue = listdata.properties.old[key];
                                                if (!oldValue && !value) {
                                                    return null;
                                                }
                                                return (
                                                    <tr key={index}>
                                                        <td>{key}</td>
                                                        <td>
                                                            {key === 'Body' && oldValue ? (
                                                                <Button variant="primary" onClick={() => this.onClickViewHTML(oldValue)}>
                                                                    View Old HTML
                                                                </Button>
                                                            ) : (
                                                                oldValue
                                                            )}
                                                        </td>
                                                        <td>
                                                            {key === 'Body' ? (
                                                                <Button color="primary" className="btn btn-primary" onClick={() => this.onClickViewHTML(value)}>
                                                                    View HTML
                                                                </Button>
                                                            ) : (
                                                                value
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </td>
                        </tr>
                    </>
                ),
            },

        ];
        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];
        const pageOptions = {
            sizePerPage: 50,
            custom: true,
        };
        const selectRow = {
            mode: "checkbox",
        };
        const { SearchBar } = Search;
        //meta title
        document.title = "Payment Reminder Email Logs";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState({ recordDeleteID: row.id });
            }
        }
        return (
            <React.Fragment>
                <ViewHTMLModal
                    show={viewHTML}
                    record={this.state.htmlToModal}
                    onCloseClick={() => this.setState({ viewHTML: false })}
                />
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Financial Templates" breadcrumbItem="Payment Reminder Email Logs" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.listData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.listData}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            // selectRow={selectRow}
                                                                            rowEvents={rowEvents}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            ref={this.node}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default PaymentReminderEmailLogs;
