import React, { Component } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";

const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

import constant from '../../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

class AddCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      redirect: null,
      optionGroup: [],
      id: "",
      name: '',
      countrySelected: -1,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }

  async getOptions() {
    var endpoint;
    if (localStorage.getItem("adminUserLoginFlag")) {
      endpoint = "/api/countries"
    } else {
      endpoint = "/api/countries-listing"
    }
    const res = await axios.get(baseURL + endpoint, config);
    const data = res.data.data

    const options = data.map(d => ({
      "value": d.id,
      "label": d.title
    }))
    this.setState({ optionGroup: options })
  }
  componentDidMount() {
    this.getOptions()
  }
  checkValidation = () => {
    if (this.state.countrySelected != 1) {
      this.setState({ countrySelected: 0 })
    }
  }
  handleSelectGroup = selectedGroup => {
    this.setState({ countrySelected: 1 })
    this.setState({ selectedGroup })
  }


  handleSubmit(e) {
    e.preventDefault();

    const modifiedV = { ...this.state.validation };
    var fnm = document.getElementById("validationTooltip01").value;
    var lnm = document.getElementById("validationTooltip02").value;
    var unm = document.getElementById("validationTooltipUsername").value;
    var city = document.getElementById("validationTooltip03").value;
    var stateV = document.getElementById("validationTooltip04").value;

    if (fnm === "") {
      modifiedV["fnm"] = false;
    } else {
      modifiedV["fnm"] = true;
    }

    if (lnm === "") {
      modifiedV["lnm"] = false;
    } else {
      modifiedV["lnm"] = true;
    }

    if (unm === "") {
      modifiedV["unm"] = false;
    } else {
      modifiedV["unm"] = true;
    }

    if (city === "") {
      modifiedV["city"] = false;
    } else {
      modifiedV["city"] = true;
    }

    if (stateV === "") {
      modifiedV["stateV"] = false;
    } else {
      modifiedV["stateV"] = true;
    }

    this.setState({ validation: modifiedV });
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }
  showToast(title, message, toastType) {
    //position class
    var positionClass = "toast-top-right";
    toastr.options = {
      positionClass: positionClass,
      timeOut: this.state.timeOut,
      extendedTimeOut: this.state.extendedTimeOut,
      showEasing: this.state.showEasing,
      hideEasing: this.state.hideEasing,
      showMethod: this.state.showMethod,
      hideMethod: this.state.hideMethod,
      showDuration: this.state.showDuration,
      hideDuration: this.state.hideDuration,
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }
  render() {
    //meta title
    document.title = "Add City";
    const { validation } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    const { selectedGroup } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Cities" breadcrumbItem="Add City" parentLink="/catalogue/cities/list" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        cityName: (this.state && this.state.cityName) || "",
                      }}
                      validationSchema={Yup.object().shape({
                        cityName: Yup.string().required(
                          "Please Enter Your City Name"
                        ),
                      })}
                      onSubmit={values => {
                        var endpoint = '/api/cities';
                        if (this.state.countrySelected == 0) {
                          var toastTitle = "Failed";
                          var toastMessage = "Country not selected.";
                          var toastType = "error";
                          this.showToast(toastTitle, toastMessage, toastType);
                        } else {
                          if (localStorage.getItem('adminUserLoginFlag')) {
                            endpoint = '/api/platform-cities'
                          } else {
                            endpoint = '/api/cities';
                          }
                          axios.post(baseURL + endpoint, {
                            "title": values.cityName,
                            "country_id": selectedGroup.value,
                          }, config)
                            .then((response) => {
                              var toastTitle = "Success";
                              var toastMessage = "The city has been added successfully.";
                              var toastType = "success";
                              this.showToast(toastTitle, toastMessage, toastType);
                              console.log(response);
                              this.setState({ redirect: "/catalogue/cities/list" });
                            })
                            .catch((error) => {
                              var toastTitle = "Failed";
                              var toastMessage = "City Not Added";
                              var toastType = "error";
                              this.showToast(toastTitle, toastMessage, toastType);
                              console.log(error);
                            });
                        }
                      }}
                    >
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation">
                          <Row>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="cityName">
                                  City Name*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="cityName"
                                    placeholder="City Name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.cityName && touched.cityName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="cityName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectCountry">Country Select*</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={selectedGroup}
                                    onChange={this.handleSelectGroup}
                                    options={this.state.optionGroup}
                                    classNamePrefix="select2-selection"
                                  />
                                  {this.state.countrySelected == 0 ? <>
                                    <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Country is not selected, please select first</p>
                                  </> : <></>
                                  }
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Button color="primary" type="submit" onClick={this.checkValidation} className="float-end">
                            Add City
                          </Button>
                        </Form>
                      )}
                    </Formik>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default AddCity;
