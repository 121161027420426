import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../UI/ui-toast'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"
import { get_platform_active_roles} from '../../PlatformRoles/api/platformRolesApi';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get_current_user } from '../api/usersApi';
const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      optionGroupRole: [],
      u_first_name: '',
      u_last_name: '',
      u_middle_name: '',
      u_mobile_number: '',
      u_telephone: '',
      u_email: '',
      u_title: '',
      u_status: '',
      u_role: '',
      u_picture: null,
      u_picture_show: null,
      redirect: null,
      titlesArray: constant.titles_list,
      statusArray: [
        {
          "value": '1',
          "label": 'Active'
        },
        {
          "value": '0',
          "label": 'Inactive'
        }
      ],
      id: "",
      name: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }
  handleChange(event) {
  }

  async getOptionsRoles() {

    Promise.all([get_platform_active_roles()]).then((values) => {
      console.log(values[0]);
      // this.setState({ listData: values[0] });
      const options = values[0].map(d => ({
        "value": d.id,
        "label": d.name
      }))
      this.setState({ optionGroupRole: options });
    });
  }
  componentDidMount() {
    console.log(localStorage.getItem("current_user"));
    Promise.all([get_current_user(localStorage.getItem("current_user"))]).then((values) => {
      this.setState({ u_first_name: values[0].first_name });
      this.setState({ u_last_name: values[0].last_name });
      this.setState({ u_middle_name: values[0].middle_name });
      this.setState({ u_mobile_number: values[0].mobile });
      this.setState({ u_telephone: values[0].telephone });
      this.setState({ u_email: values[0].email });
      this.setState({ u_title: values[0].title });
      this.setState({ u_status: values[0].status });
      this.setState({ u_role: values[0].roles[0].name });
      this.setState({ u_picture_show: values[0].picture });
    });
    this.getOptionsRoles()
  }

  handleSelectedUserTitle = selectedUserTitle => {
    this.setState({ u_title: selectedUserTitle.value });
    this.setState({ selectedUserTitle })
    console.log(selectedUserTitle);
  }
  handleSelectGroupRole = selectedGroupRole => {
    this.setState({ selectedGroupRole })
    this.setState({ u_role: selectedGroupRole.label });
    console.log(selectedGroupRole);
  }
  handleImageChangePicture = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log('Picture');
      console.log(e.target.files[0]);
      this.setState({ u_picture: e.target.files[0] });
    }
  };
  handleSelectedUserStatus = selectedUserStatus => {
    this.setState({ u_status: selectedUserStatus.value });
    this.setState({ selectedUserStatus })
    console.log(selectedUserStatus);
  }
  handleSubmit(e) {

    e.preventDefault();
    if (!this.state.u_first_name || !this.state.u_last_name || !this.state.u_mobile_number || !this.state.u_email) {
      let missingFields = '';
      if (!this.state.u_first_name) {
        missingFields += '<li>First Name</li>';
      }
      if (!this.state.u_last_name) {
        missingFields += '<li>Last Name</li>';
      }
      if (!this.state.u_mobile_number) {
        missingFields += '<li>Mobile Number</li>';
      }
      if (!this.state.u_email) {
        missingFields += '<li>Email</li>';
      }
      showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
      return false;
    }
    axios.post(baseURL + "/api/platform-user-update/" + localStorage.getItem("current_user"), {

      "title": this.state.u_title,
      "first_name": e.target.firstName.value,
      "middle_name": e.target.middleName.value,
      "last_name": e.target.lastName.value,
      "telephone": e.target.telephone.value,
      "mobile": e.target.mobileNumber.value,
      "role": this.state.u_role,
      "email": e.target.email.value,
      "picture": this.state.u_picture,
      "status": this.state.u_status
    }, config)
      .then((response) => {
        showToast("Success", "The user "+e.target.firstName.value+" has been updated successfully.", "success");
        this.setState({ redirect: "/users/list" });
        localStorage.removeItem("current_user");
        // window.location.replace('/customers/list');
        console.log(response);
      })
      .catch((error) => {
        showToast("Failed", "User Not Edited Successfully", "error");
        console.log(error);
      });

    // const modifiedV = { ...this.state.validation };
    // var fnm = document.getElementById("validationTooltip01").value;
    // var lnm = document.getElementById("validationTooltip02").value;
    // var unm = document.getElementById("validationTooltipUsername").value;
    // var city = document.getElementById("validationTooltip03").value;
    // var stateV = document.getElementById("validationTooltip04").value;

    // if (fnm === "") {
    //   modifiedV["fnm"] = false;
    // } else {
    //   modifiedV["fnm"] = true;
    // }

    // if (lnm === "") {
    //   modifiedV["lnm"] = false;
    // } else {
    //   modifiedV["lnm"] = true;
    // }

    // if (unm === "") {
    //   modifiedV["unm"] = false;
    // } else {
    //   modifiedV["unm"] = true;
    // }

    // if (city === "") {
    //   modifiedV["city"] = false;
    // } else {
    //   modifiedV["city"] = true;
    // }

    // if (stateV === "") {
    //   modifiedV["stateV"] = false;
    // } else {
    //   modifiedV["stateV"] = true;
    // }

    // this.setState({ validation: modifiedV });
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }

  render() {
    //meta title
    document.title = "Edit User";
    const { validation } = this.state;
    const { selectedUserTitle } = this.state;
    const { selectedGroupRole } = this.state;
    const { selectedUserStatus } = this.state;
     if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
     }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="User" breadcrumbItem="Edit User" parentLink="/users/list" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        /*customerTitle: (this.state && this.state.customerTitle) || "",*/
                        // customerFirstName: (this.state && this.state.customerFirstName) || "",
                        /*customerMiddleName: (this.state && this.state.customerMiddleName) || "",
                        customerLastName: (this.state && this.state.customerLastName) || "",
                        identityType: (this.state && this.state.identityType) || "",
                        identityNumber: (this.state && this.state.identityNumber) || "",
                        fatherName: (this.state && this.state.fatherName) || "",
                        husbandName: (this.state && this.state.husbandName) || "",
                        customerNationality: (this.state && this.state.customerNationality) || "",
                        addresslineOne: (this.state && this.state.addresslineOne) || "",
                        addresslineTwo: (this.state && this.state.addresslineTwo) || "",
                        phoneHome: (this.state && this.state.phoneHome) || "",
                        mobileNumber: (this.state && this.state.mobileNumber) || "",
                        phoneOffice: (this.state && this.state.phoneOffice) || "",
                        postcode: (this.state && this.state.postcode) || "",
                        primaryEmail: (this.state && this.state.primaryEmail) || "",
                        secondaryEmail: (this.state && this.state.secondaryEmail) || "",*/
                        // selectCountry: (this.state && this.state.selectCountry) || "",
                        // selectCity: (this.state && this.state.selectCity) || "",
                      }}
                      validationSchema={Yup.object().shape({
                        /*customerTitle: Yup.string().required(
                          "Customer title required!"
                        ),*/
                        // customerFirstName: Yup.string().required(
                        //   "Customer first name is required!"
                        // ),
                        /*
                        addresslineOne: Yup.string().required(
                          "Please Enter Your Address Line 1"
                        ),
                        addresslineTwo: Yup.string().required(
                          "Please Enter Your Address Line 2"
                        ),
                        phoneHome: Yup.string().required(
                          "Please Enter Your Home Phone"
                        ),
                        mobileNumber: Yup.string().required(
                          "Please Enter Your Mobile Number"
                        ),
                        phoneOffice: Yup.string().required(
                          "Please Enter Your Office Phone"
                        ),
                        postcode: Yup.string().required("Please Enter Your postcode"),
                        primaryEmail: Yup.string().required("Please Enter Your Email"),
                        */

                        // selectCountry: Yup.string().required("Please Select Country"),
                        // selectCity: Yup.string().required("Please Select City"),
                      })}
                    // onSubmit={values => {
                    //   console.log(values);
                    //   console.log(selectedGroupCountry.value);
                    //   axios.patch(baseURL + "/api/customers/" + this.state.customerData.id, {

                    //     "title": selectedCustomerTitle.value,
                    //     "first_name": values.customerFirstName,
                    //     "middle_name": values.customerMiddleName,
                    //     "last_name": values.customerLastName,
                    //     "identity_type": values.identityType,
                    //     "identity_number": values.identityNumber,
                    //     "father_name": values.fatherName,
                    //     "husband_name": values.husbandName,
                    //     "nationality": values.customerNationality,
                    //     "organisation_id": window.localStorage.getItem("organisation_id"),
                    //     "address_line_1": values.addresslineOne,
                    //     "address_line_2": values.addresslineTwo,
                    //     "city_id": selectedGroupCity.value,
                    //     /*"city_title": selectedGroupCity.label,*/
                    //     "country_id": selectedGroupCountry.value,
                    //     /*"country_title": selectedGroupCountry.label,*/
                    //     "postcode": values.postcode,
                    //     "telephone_home": values.phoneHome,
                    //     "telephone_office": values.phoneOffice,
                    //     "mobile_number": values.mobileNumber,
                    //     "primary_email": values.primaryEmail,
                    //     "secondary_email": values.secondaryEmail,
                    //     "picture_path": "p1"
                    //   }, config)
                    //     .then(function (response) {
                    //       console.log(response);
                    //     })
                    //     .catch(function (error) {
                    //       console.log(error);
                    //     });
                    // }}
                    >
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation" onSubmit={this.handleSubmit}>
                        <Row>
                        <Col md="12">
                            <div className="mb-3 row d-flex select2-container">
                              <Label className="col-md-2 col-form-label" htmlFor="selectCountry">Role Select</Label>
                              <div className="col-md-10">
                                <Select
                                  value={{ label: this.state.u_role, value: 0 }}
                                  // value={selectedGroupCountry}
                                  onChange={this.handleSelectGroupRole}
                                  options={this.state.optionGroupRole}
                                  classNamePrefix="select2-selection"
                                // className={
                                //   "" +
                                //   (errors.selectCountry && touched.selectCountry
                                //     ? " is-invalid"
                                //     : "")
                                // }
                                />
                                {/* <ErrorMessage
                                  name="selectCountry"
                                  component="div"
                                  className="invalid-feedback"
                                /> */}
                              </div>
                            </div>
                          </Col>
                          <Col md="12">
                            <div className="mb-3 row d-flex select2-container">
                              <Label className="col-md-2 col-form-label" htmlFor="userTitle">Select title</Label>
                              <div className="col-md-10">
                                <Select
                                  onChange={this.handleSelectedUserTitle}
                                  options={this.state.titlesArray}
                                  // value={this.state.c_title}
                                  value={{ label: this.state.u_title, value: 0 }}
                                  classNamePrefix="select2-selection"
                                // className={
                                //   "" +
                                //   (errors.customerTitle && touched.customerTitle
                                //     ? " is-invalid"
                                //     : "")
                                // }
                                />
                                { /*<ErrorMessage
                                  name="customerTitle"
                                  component="div"
                                  className="invalid-feedback"
                                />*/ }
                              </div>
                            </div>
                          </Col>

                          <Col md="12">
                            <FormGroup className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="firstName">
                                First Name*
                              </Label>
                              <div className="col-md-10">
                                <Field
                                  name="firstName"
                                  placeholder="First Name"
                                  type="text"
                                  // value={this.state.customerData.first_name}
                                  value={this.state.u_first_name}
                                  // onChange={this.handleChange}
                                  onChange={(e) => this.setState({ u_first_name: e.target.value })}
                                  className={
                                    "form-control"
                                  }
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="middleName">
                                Middle Name
                              </Label>
                              <div className="col-md-10">
                                <Field
                                  name="middleName"
                                  placeholder="Middle Name"
                                  type="text"
                                  value={this.state.u_middle_name}
                                  // onChange={this.handleChange}
                                  onChange={(e) => this.setState({ u_middle_name: e.target.value })}
                                  className={
                                    "form-control"
                                  }
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="lastName">
                                Last Name*
                              </Label>
                              <div className="col-md-10">
                                <Field
                                  name="lastName"
                                  placeholder="Last Name"
                                  type="text"
                                  onChange={(e) => this.setState({ u_last_name: e.target.value })}
                                  value={this.state.u_last_name}
                                  className={
                                    "form-control"
                                  }
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="telephone">
                                Telephone
                              </Label>
                              <div className="col-md-10">
                                <Field
                                  name="telephone"
                                  placeholder="Telephone"
                                  type="text"
                                  onChange={(e) => this.setState({ u_telephone: e.target.value })}
                                  value={this.state.u_telephone}
                                  className={
                                    "form-control"
                                  }
                                />
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <FormGroup className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="mobileNumber">
                                Mobile Number*
                              </Label>
                              <div className="col-md-10">
                                <Field
                                  name="mobileNumber"
                                  placeholder="Mobile Number"
                                  type="text"
                                  onChange={(e) => this.setState({ u_mobile_number: e.target.value })}
                                  value={this.state.u_mobile_number}
                                  className={
                                    "form-control"
                                  }
                                />

                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="email">
                                 Email*
                              </Label>
                              <div className="col-md-10">
                                <Field
                                  name="email"
                                  placeholder="Email"
                                  type="email"
                                  onChange={(e) => this.setState({ u_email: e.target.value })}
                                  value={this.state.u_email}
                                  className={
                                    "form-control"
                                  }
                                />

                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="12">
                            <div className="mb-3 row d-flex select2-container">
                              <Label className="col-md-2 col-form-label" htmlFor="userTitle">Select Status</Label>
                              <div className="col-md-10">
                                <Select
                                  onChange={this.handleSelectedUserStatus}
                                  options={this.state.statusArray}
                                  value={this.state.u_status==1?{ label: "Active", value: this.state.u_status }:{ label: "Inactive", value: this.state.u_status }}
                                  classNamePrefix="select2-selection"
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md="12">
                            <div className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="picture">
                                Picture
                              </Label>
                              <div className="col-md-10">
                                <Input className="form-control form-control" type="file" id="inputPicture" accept="image/*"
                                  onChange={this.handleImageChangePicture} />
                              </div>
                            </div>
                          </Col>
                          {this.state.u_picture_show == null ?

                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="picture">
                                  Existing Picture
                                </Label>
                                <div className="col-md-10 mt-2">
                                  No Picture Exists
                                </div>
                              </div>
                            </Col>
                            :
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="picture">
                                  Existing Picture
                                </Label>
                                <div className="col-md-10">
                                  <img className="rounded me-2" alt="200x200" width="200" src={this.state.u_picture_show} data-holder-rendered="true" />
                                </div>
                              </div>
                            </Col>
                          }
                        </Row>
                        <Button color="primary" type="submit" className="float-end" >
                          Update User
                        </Button>
                      </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default EditUser;
