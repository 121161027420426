import React, { Component } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"

//Import Breadcrumb

const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

class AddProjectCurrency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      optionGroup: [],
      currencies: [],
      redirect: null,
      id: "",
      name: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }

  async getOptions() {
    const res = await axios.get(baseURL + "/api/currencies-listing", config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.currency_name
    }))
    this.setState({ optionGroup: options })
  }
  componentDidMount() {
    this.getOptions()
  }

  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
    var currencies = [];
    for (var i = 0; i < selectedGroup.length; i++) {
      selectedGroup[i].feature_id = selectedGroup[i].value;
      currencies.push({ "currency_id": selectedGroup[i].feature_id })
    }
    console.log(currencies);
    this.setState({ currencies: currencies })
  }


  handleSubmit(e) {
    e.preventDefault();

    const modifiedV = { ...this.state.validation };
    var fnm = document.getElementById("validationTooltip01").value;
    var lnm = document.getElementById("validationTooltip02").value;
    var unm = document.getElementById("validationTooltipUsername").value;
    var city = document.getElementById("validationTooltip03").value;
    var stateV = document.getElementById("validationTooltip04").value;

    if (fnm === "") {
      modifiedV["fnm"] = false;
    } else {
      modifiedV["fnm"] = true;
    }

    if (lnm === "") {
      modifiedV["lnm"] = false;
    } else {
      modifiedV["lnm"] = true;
    }

    if (unm === "") {
      modifiedV["unm"] = false;
    } else {
      modifiedV["unm"] = true;
    }

    if (city === "") {
      modifiedV["city"] = false;
    } else {
      modifiedV["city"] = true;
    }

    if (stateV === "") {
      modifiedV["stateV"] = false;
    } else {
      modifiedV["stateV"] = true;
    }

    this.setState({ validation: modifiedV });
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }
  showToast(title, message, toastType) {
    //position class
    var positionClass = "toast-top-right";
    toastr.options = {
      positionClass: positionClass,
      timeOut: 5000,
      extendedTimeOut: 1000,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }
  render() {
    const { validation } = this.state;
    const { selectedGroup } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{

        }}
        validationSchema={Yup.object().shape({

        })}
        onSubmit={values => {
          console.log(values);
          window.localStorage.removeItem("has_phases")
          window.localStorage.removeItem("has_floors")
          if(this.state.currencies==''){
            var toastTitle = "Failed";
              var toastMessage = "Currency not selected.";
              var toastType = "error";
              this.showToast(toastTitle, toastMessage, toastType);
          }else{
            axios.post(baseURL + "/api/project_currency", {
              "project_id": localStorage.getItem("project_id"),
              "project_currencies": this.state.currencies
            }, config)
              .then((response) => {
                var toastTitle = "Success";
                var toastMessage = "The project currencies have been added successfully.";
                var toastType = "success";
                this.showToast(toastTitle, toastMessage, toastType);
                console.log(response);
                this.setState({ redirect: '/projects/list' })
                localStorage.removeItem('project_id');
                localStorage.removeItem('has_phase_blocks');
                localStorage.removeItem('has_sizes');
                localStorage.removeItem('has_floors');
              })
              .catch((error) => {
                var toastTitle = "Failed";
                var toastMessage = "Currency Not Added";
                var toastType = "error";
                this.showToast(toastTitle, toastMessage, toastType);
                console.log(error);
              });
          }
        }}
      >
        {({ errors, status, touched }) => (
          <Form className="mt-4 needs-validation">
            <Row>
              <Col md="12">
                <div className="mt-3 mb-3 row d-flex select2-container">
                  <Label className="col-md-2 col-form-label">Currency Select</Label>
                  <div className="col-md-10">
                    <Select
                      value={selectedGroup}
                      isMulti={true}
                      onChange={this.handleSelectGroup}
                      options={this.state.optionGroup}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Button style={{ float: "right" }} color="primary" type="submit" >
              Save Project
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default AddProjectCurrency;
