import React, { Component } from "react";
import toastr from "toastr";
import { Link } from "react-router-dom";
import "toastr/build/toastr.min.css";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row, UncontrolledAlert
} from "reactstrap";
import axios from "axios";
import BuildPhasesBlocks from "./BuildPhasesBlocks";
// remove old import of secureLocalStorage "react-secure-storage";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import BuildFloors from "./BuildFloors";
import BuildSizes from "./BuildSizes";
import AddProjectCurrency from "./AddProjectCurrency";
const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'multipart/form-data',
    Authorization: `${token}`
  }
};

import constant from '../../../constants.json';
import AddProjectFeature from "./AddProjectFeature";
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

class AddProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      optionGroupCountry: [],
      optionGroupCity: [],
      optionProjectType: [],
      pageSection: 1,
      hasFloors: 0,
      hasPhasesBlocks: 0,
      hasSizes: 1,
      project_icon: null,
      project_picture: null,
      project_logo: null,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",

      showDuration: 300,
      hideDuration: 1000,
      timeOut: 5000,
      extendedTimeOut: 1000,
      countrySelected: -1,
      citySelected: -1,
      projectTypeSelected: -1,

    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
    this.showToast.bind(this);
    // this.clearToast.bind(this);
  }


  async getOptionsCountry() {
    const res = await axios.get(baseURL + "/api/countries-listing", config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.title
    }))
    this.setState({ optionGroupCountry: options })
  }
  async getOptionsCity(country_id) {
    const res = await axios.get(baseURL + "/api/country-cities/" + country_id, config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.title
    }))
    this.setState({ optionGroupCity: options })
  }
  async getOptionsProjectTypes() {
    const res = await axios.get(baseURL + "/api/project-type-listing", config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.name
    }))
    this.setState({ optionProjectType: options })
  }
  // sectionOne() {
  //   this.setState({ pageSection: 2 })
  // }
  componentDidMount() {
    var queryParam = window.location.search.split('=');
    console.log(queryParam[1]);
    this.setState({ pageSection: queryParam[1] });
    this.getOptionsProjectTypes()
    this.getOptionsCountry()
  }
  checkValidation = () => {
    if (this.state.citySelected != 1) {
      this.setState({ citySelected: 0 })
    }
    if (this.state.countrySelected != 1) {
      this.setState({ countrySelected: 0 })
    }
    if (this.state.projectTypeSelected != 1) {
      this.setState({ projectTypeSelected: 0 })
    }
  }
  handleSelectGroupCountry = selectedGroupCountry => {
    this.setState({ countrySelected: 1 })
    this.setState({ selectedGroupCountry })
    this.getOptionsCity(selectedGroupCountry.value)
    console.log(selectedGroupCountry);
  }
  handleSelectGroupCity = selectedGroupCity => {
    this.setState({ citySelected: 1 })
    this.setState({ selectedGroupCity })
    console.log(selectedGroupCity);
  }
  handleSelectGroupProjectTypes = selectedGroupProjectTypes => {
    this.setState({ projectTypeSelected: 1 })
    this.setState({ selectedGroupProjectTypes })
    console.log(selectedGroupProjectTypes);
  }
  handleImageChangeLogo = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log('Logo');
      console.log(e.target.files[0]);
      this.setState({ project_logo: e.target.files[0] });
    }
  };
  handleImageChangeIcon = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log('Icon');
      console.log(e.target.files[0]);
      this.setState({ project_icon: e.target.files[0] });
    }
  };
  handleImageChangePicture = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log('Picture');
      console.log(e.target.files[0]);
      this.setState({ project_picture: e.target.files[0] });
    }
  };

  handleSubmit(e) {
    console.log("HANDLE");
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }
  showToast(title, message, toastType) {
    //position class
    var positionClass = "toast-top-right";
    toastr.options = {
      positionClass: positionClass,
      timeOut: this.state.timeOut,
      extendedTimeOut: this.state.extendedTimeOut,
      showEasing: this.state.showEasing,
      hideEasing: this.state.hideEasing,
      showMethod: this.state.showMethod,
      hideMethod: this.state.hideMethod,
      showDuration: this.state.showDuration,
      hideDuration: this.state.hideDuration,
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }

  // clearToast() {
  //   toastr.clear();
  // }

  render() {
    //meta title
    document.title = "Add Project";

    const { validation } = this.state;
    const { selectedGroupCountry } = this.state;
    const { selectedGroupCity } = this.state;
    const { selectedGroupProjectTypes } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Project" breadcrumbItem="Add Project" />
            {this.state.pageSection == 1 ?
              <>
                <Row>
                  <Col xl="12">
                    <Card>
                      <CardBody>
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            projectName: (this.state && this.state.projectName) || "",
                            addresslineOne: (this.state && this.state.addresslineOne) || "",
                            phoneOne: (this.state && this.state.phoneOne) || "",
                            postcode: (this.state && this.state.postcode) || "",
                            email: (this.state && this.state.email) || "",
                            latefee: (this.state && this.state.latefee) || "",
                            hasPhasesBlocks: false,
                            hasFloors: false,
                            hasSizes: true,
                          }}
                          validationSchema={Yup.object().shape({
                            projectName: Yup.string().required(
                              "Please Enter Your Project Name"
                            ),
                            addresslineOne: Yup.string().required(
                              "Please Enter Your Address Line 1"
                            ),
                            phoneOne: Yup.string().required(
                              "Please Enter Your Phone Primary"
                            ),
                            postcode: Yup.string().required("Please Enter Your postcode"),
                            email: Yup.string().required("Please Enter Your Email"),
                            latefee: Yup.string().required("Please Enter Fee"),
                          })}

                          onSubmit={values => {
                            // if(selectedGroupCity.value==''){
                            //   this.setState({citySelected:0})
                            // }
                            console.log(values.hasSizes == true ? 1 : 0);
                            // this.setState({hasFloors: values.hasSizes == true ? 1 : 0})
                            // this.setState({hasPhasesBlocks: values.hasPhasesBlocks == true ? 1 : 0})
                            console.log("ON SUBMIT");

                            axios.post(baseURL + "/api/projects", {

                              "name": values.projectName,
                              "project_type_id": selectedGroupProjectTypes.value,
                              // "project_type_name": selectedGroupProjectTypes.label,
                              "organisation_id": window.localStorage.getItem("organisation_id"),
                              "address_line_1": values.addresslineOne,
                              "address_line_2": values.addresslineTwo,
                              "city_id": selectedGroupCity.value,
                              "city_title": selectedGroupCity.label,
                              "country_id": selectedGroupCountry.value,
                              "country_title": selectedGroupCountry.label,
                              "post_code": values.postcode,
                              "phone_primary": values.phoneOne,
                              "phone_secondary": values.phoneTwo,
                              "email": values.email,
                              "website": values.website,
                              "late_fee": values.latefee,
                              "prefix": values.pre_fix,
                              "logo": this.state.project_logo,
                              "icon": this.state.project_icon,
                              "picture": this.state.project_picture,
                              "has_phases": values.hasPhasesBlocks == true ? 1 : 0,
                              "has_blocks": values.hasPhasesBlocks == true ? 1 : 0,
                              "has_floors": values.hasFloors == true ? 1 : 0,
                              "has_sizes": values.hasSizes == true ? 1 : 0
                            }, config)
                              .then((response) => {
                                console.log(response.data);
                                var toastTitle = "Success";
                                var toastMessage = "The project " + response.data.data.name + " has been added successfully.";
                                var toastType = "success";
                                this.showToast(toastTitle, toastMessage, toastType);
                                this.setState({ pageSection: 2 });
                                console.log(response.data.data);
                                window.localStorage.setItem("has_phase_blocks", response.data.data.has_phases);
                                window.localStorage.setItem("has_sizes", response.data.data.has_sizes);
                                window.localStorage.setItem("has_floors", response.data.data.has_floors);
                                // window.location.replace('/projects/sizes?section=2');
                                // setSectionValue();
                                // this.projectAddedEvent();
                                // this.setState({projectAdded: true})
                                // setProjectAdded();
                                // manageState(newState.projectAdded);
                                window.localStorage.setItem("project_id", response.data.data.id);
                                // window.localStorage.setItem("section", "1");
                                // useHistory().push("/projects/floors?section=3");
                                // history.push("/projects/floors?section=3");

                              })
                              .catch((error) => {
                                var toastTitle = "Failed";
                                var toastMessage = "The project has not been added successfuly.";
                                var toastType = "error";
                                this.showToast(toastTitle, toastMessage, toastType);
                                console.log(error);
                              });
                          }}
                        >
                          {({ errors, status, touched }) => (
                            <Form className="needs-validation">
                              <UncontrolledAlert color="danger" role="alert">
                                Please create project document templates after creating the project. You
                                can clone them from <Link to="/templates/documents-templates/sales-documents-tabs-view"> Templates </Link> section.
                              </UncontrolledAlert>
                              <Row>
                                <Col md="12">
                                  <FormGroup className="mb-3 row d-flex">
                                    <Label className="col-md-2 col-form-label" htmlFor="projectName">
                                      Project*
                                    </Label>
                                    <div className="col-md-10">
                                      <Field
                                        name="projectName"
                                        placeholder="Project Name"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.projectName && touched.projectName
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="projectName"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md="12">
                                  <div className="mb-3 row d-flex select2-container">
                                    <Label className="col-md-2 col-form-label" htmlFor="selectProjectType">Project Type*</Label>
                                    <div className="col-md-10">
                                      <Select
                                        value={selectedGroupProjectTypes}
                                        onChange={this.handleSelectGroupProjectTypes}
                                        options={this.state.optionProjectType}
                                        classNamePrefix="select2-selection"
                                        className={
                                          "" +
                                          (errors.selectProjectType && touched.selectProjectType
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      {this.state.projectTypeSelected == 0 ? <>
                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Project Type is not selected, please select first</p>
                                      </> : <></>
                                      }
                                      <ErrorMessage
                                        name="selectProjectType"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="12">
                                  <FormGroup className="mb-3 row d-flex">
                                    <Label className="col-md-2 col-form-label" htmlFor="addresslineOne">
                                      Address Line 1*
                                    </Label>
                                    <div className="col-md-10">
                                      <Field
                                        name="addresslineOne"
                                        placeholder="Address Line 1"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.addresslineOne && touched.addresslineOne
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="addresslineOne"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md="12">
                                  <FormGroup className="mb-3 row d-flex">
                                    <Label className="col-md-2 col-form-label" htmlFor="addresslineTwo">
                                      Address Line 2
                                    </Label>
                                    <div className="col-md-10">
                                      <Field
                                        name="addresslineTwo"
                                        placeholder="Address Line 2"
                                        type="text"
                                        className={
                                          "form-control"}
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="12">
                                  <div className="mb-3 row d-flex select2-container">
                                    <Label className="col-md-2 col-form-label" htmlFor="selectCountry">Country*</Label>
                                    <div className="col-md-10">
                                      <Select
                                        value={selectedGroupCountry}
                                        onChange={this.handleSelectGroupCountry}
                                        options={this.state.optionGroupCountry}
                                        classNamePrefix="select2-selection"
                                      // className={
                                      //   "" +
                                      //   (errors.selectCountry && touched.selectCountry
                                      //     ? " is-invalid"
                                      //     : "")
                                      // }
                                      />
                                      {this.state.countrySelected == 0 ? <>
                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Country is not selected, please select first</p>
                                      </> : <></>
                                      }
                                      {/* <ErrorMessage
                                    name="selectCountry"
                                    component="div"
                                    className="invalid-feedback"
                                  /> */}
                                    </div>
                                  </div>
                                </Col>
                                <Col md="12">
                                  <div className="mb-3 row d-flex select2-container">
                                    <Label className="col-md-2 col-form-label" htmlFor="selectCity">City*</Label>
                                    <div className="col-md-10">
                                      <Select
                                        value={selectedGroupCity}
                                        onChange={this.handleSelectGroupCity}
                                        options={this.state.optionGroupCity}
                                        classNamePrefix="select2-selection"
                                      // className={
                                      //   "" +
                                      //   (errors.selectCity && touched.selectCity
                                      //     ? " is-invalid"
                                      //     : "")
                                      // }
                                      />
                                      {this.state.citySelected == 0 ? <>
                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> City is not selected, please select first</p>
                                      </> : <></>
                                      }
                                      {/* <ErrorMessage
                                    name="selectCity"
                                    component="div"
                                    className="invalid-feedback"
                                  /> */}
                                    </div>
                                  </div>
                                </Col>

                                <Col md="12">
                                  <FormGroup className="mb-3 row d-flex">
                                    <Label className="col-md-2 col-form-label" htmlFor="postcode">Postcode*</Label>
                                    <div className="col-md-10">
                                      <Field
                                        name="postcode"
                                        placeholder="Postcode"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.postcode && touched.postcode
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="postcode"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md="12">
                                  <FormGroup className="mb-3 row d-flex">
                                    <Label className="col-md-2 col-form-label" htmlFor="phoneOne">
                                      Primary Phone*
                                    </Label>
                                    <div className="col-md-10">
                                      <Field
                                        name="phoneOne"
                                        placeholder="Phone Primary"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.phoneOne && touched.phoneOne
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="phoneOne"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md="12">
                                  <FormGroup className="mb-3 row d-flex">
                                    <Label className="col-md-2 col-form-label" htmlFor="phoneTwo">
                                      Secondary Phone
                                    </Label>
                                    <div className="col-md-10">
                                      <Field
                                        name="phoneTwo"
                                        placeholder="Phone Secondary"
                                        type="text"
                                        className={
                                          "form-control"
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md="12">
                                  <FormGroup className="mb-3 row d-flex">
                                    <Label className="col-md-2 col-form-label" htmlFor="email">
                                      Email*
                                    </Label>
                                    <div className="col-md-10">
                                      <Field
                                        name="email"
                                        placeholder="Email"
                                        type="email"
                                        className={
                                          "form-control" +
                                          (errors.email && touched.email
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md="12">
                                  <FormGroup className="mb-3 row d-flex">
                                    <Label className="col-md-2 col-form-label" htmlFor="website">
                                      Website
                                    </Label>
                                    <div className="col-md-10">
                                      <Field
                                        name="website"
                                        placeholder="Website"
                                        type="text"
                                        className={
                                          "form-control"
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md="12">
                                  <div className="mb-3 row d-flex">
                                    <Label className="col-md-2 col-form-label" htmlFor="logo">
                                      Logo
                                    </Label>
                                    <div className="col-md-10">
                                      <Input className="form-control form-control" type="file" id="inputLogo" accept="image/*"
                                        onChange={this.handleImageChangeLogo} />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="12">
                                  <div className="mb-3 row d-flex">
                                    <Label className="col-md-2 col-form-label" htmlFor="icon">
                                      Icon
                                    </Label>
                                    <div className="col-md-10">
                                      <Input className="form-control form-control" type="file" id="inputIcon" accept="image/*"
                                        onChange={this.handleImageChangeIcon} />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="12">
                                  <div className="mb-3 row d-flex">
                                    <Label className="col-md-2 col-form-label" htmlFor="picture">
                                      Picture
                                    </Label>
                                    <div className="col-md-10">
                                      <Input className="form-control form-control" type="file" id="inputPicture" accept="image/*"
                                        onChange={this.handleImageChangePicture} />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="12">
                                  <FormGroup className="mb-3 row d-flex">
                                    <Label className="col-md-2 col-form-label" htmlFor="pre_fix">
                                      Prefix
                                    </Label>
                                    <div className="col-md-10">
                                      <Field
                                        name="pre_fix"
                                        placeholder="Prefix"
                                        type="text"
                                        className={
                                          "form-control"
                                        }
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col md="12">
                                  <FormGroup className="mb-3 row d-flex">
                                    <Label className="col-md-2 col-form-label" htmlFor="latefee">
                                      Latefee*
                                    </Label>
                                    <div className="col-md-10">
                                      <Field
                                        name="latefee"
                                        placeholder="Latefee"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.latefee && touched.latefee
                                            ? " is-invalid"
                                            : "")
                                        }
                                      />
                                      <ErrorMessage
                                        name="latefee"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>

                                {/* <Col md="12">
                                  <Field type="checkbox" name="hasPhasesBlocks" />
                                </Col> */}
                                <div className="row mb-3 d-flex">
                                  <Label className="col-md-2 col-form-label">
                                    Specifications
                                  </Label>

                                  <div className="col-md-10 form-check d-flex">
                                    <div className="col-md-2">
                                      <Field
                                        className={"ms-0 form-check-input" +
                                          (errors.hasSizes && touched.hasSizes
                                            ? " is-invalid"
                                            : "")}
                                        type="checkbox"
                                        name="hasSizes"
                                        disabled
                                      />
                                      <ErrorMessage
                                        name="hasSizes"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                      <label
                                        className="ms-1 form-check-label"
                                        htmlFor="hasSizes"
                                      >
                                        Has Sizes
                                      </label>
                                    </div>
                                    <div className="col-md-3">
                                      <Field
                                        className={"form-check-input" +
                                          (errors.hasPhasesBlocks && touched.hasPhasesBlocks
                                            ? " is-invalid"
                                            : "")}
                                        type="checkbox"
                                        name="hasPhasesBlocks"
                                      />
                                      <ErrorMessage
                                        name="hasPhasesBlocks"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="hasPhasesBlocks"
                                      >
                                        Has Phases / Blocks
                                      </label>
                                    </div>
                                    <div className="col-md-3">
                                      <Field
                                        className={"form-check-input" +
                                          (errors.hasFloors && touched.hasFloors
                                            ? " is-invalid"
                                            : "")}
                                        type="checkbox"
                                        name="hasFloors"
                                      />
                                      <ErrorMessage
                                        name="hasFloors"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="hasFloors"
                                      >
                                        Has Floors
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </Row>
                              {/* <Link to="/projects/sizes?section=2"> */}
                              <button type="submit" onClick={this.checkValidation} color="primary"
                                className="btn btn-primary">Next</button>
                              {/* </Link> */}
                            </Form>
                          )}
                        </Formik>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </> : <></>
            }
            {this.state.pageSection == 3 ?
              <>
                <Row>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <h4 className="mb-0 font-size-18">Phases and their Blocks</h4>
                        <BuildPhasesBlocks />

                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </> : <></>}
            {this.state.pageSection == 4 ?
              <>
                <Row>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <h4 className="mb-0 font-size-18">Floors</h4>
                        <BuildFloors />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </> : <></>
            }
            {this.state.pageSection == 2 ?
              <>
                <Row>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <h4 className="mb-0 font-size-18">Sizes</h4>
                        <BuildSizes />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </> : <></>
            }
            {this.state.pageSection == 5 ?
              <>
                <Row>
                  <Col xs="12">
                    <Card>
                      <CardBody style={{ minHeight: "450px" }}>
                        <h4 className="mb-0 font-size-18">Project Features</h4>
                        <AddProjectFeature />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </> : <></>
            }
            {this.state.pageSection == 6 ?
              <>
                <Row>
                  <Col xs="12">
                    <Card>
                      <CardBody style={{ height: "350px" }}>
                        <h4 className="mb-0 font-size-18">Project Currencies</h4>
                        {/* <BuildSizes /> */}
                        {/* <BuildProjectCurrencies /> */}
                        <AddProjectCurrency />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </> : <></>
            }
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default AddProject;