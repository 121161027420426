import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label, InputGroup,
    Row,
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../UI/ui-toast'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get_single_campaign } from '../api/campaignApi';
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
import Flatpickr from "react-flatpickr";
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
class EditCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionsArray: [],
            validation: "",
            optionGroup: [],
            redirect: null,
            campaign_type_name: '',
            campaign_type_id: '',
            campaign_type_selected_ID: '',
            statusArray: [
                {
                    "value": '1',
                    "label": 'Yes'
                },
                {
                    "value": '0',
                    "label": 'No'
                }
            ],
            selectedStatus: '',
            startDate: [new Date()],
            endDate: [new Date()],
            name: '',
            description: '',
            Ads_ID: '',
            recordStartDate: '',
            recordEndDate: '',
            is_active: '',
            active_label: '',
            parameters: [{ key: "", value: "", isNew: true }],
            domain_URL: '',
            utm_URL: '',
            params_length: null
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeValidation = this.onChangeValidation.bind(this);
    }

    async getOptions() {
        const res = await axios.get(baseURL + "/api/campaign-type-listing", config);
        const data = res.data.data
        const options = data.map(d => ({
            "value": d.id,
            "label": d.name
        }))
        this.setState({ optionGroup: options })
    }
    componentDidMount() {
        Promise.all([get_single_campaign(localStorage.getItem("campaign_id"))]).then((values) => {
            this.setState({ campaign_type_name: values[0].campaign_type_name });
            this.setState({ campaign_type_id: values[0].campaign_type_id });
            this.setState({ name: values[0].name });
            this.setState({ description: values[0].discription });
            this.setState({ recordDate: values[0].discription });
            this.setState({ Ads_ID: values[0].ads_id });
            this.setState({ recordStartDate: values[0].start_date });
            this.setState({ recordEndDate: values[0].end_date });
            this.setState({ is_active: values[0].status });
            this.setState({ domain_URL: values[0].domain_url });
            this.setState({ active_label: (values[0].status == 1 ? 'Yes' : 'No') });
            const parametersArray = Object.keys(values[0]?.parameters).map(key => ({
                key: key,
                value: values[0].parameters[key],
                isNew: false
            }));
            this.setState({ params_length: parametersArray.length })
            this.setState({ parameters: parametersArray });
            setTimeout(() => {

                this.generateURL()
            }, 100);
        });
        this.getOptions()
    }
    handleSelectGroup = selectedGroup => {
        this.setState({ campaign_type_id: selectedGroup.value })
        this.setState({ campaign_type_name: selectedGroup.label })
        this.setState({ selectedGroup })
    }
    getDateFormateMethod = (myDate) => {
        var today = myDate
        const dd = today.getDate().toString()
        const mm = today.getMonth() + 1
        const yyyy = today.getFullYear()
        const fromate_date = (today = yyyy + "-" + mm + "-" + dd)
        return fromate_date
    }
    responseDateFormatter = (dateString) => {
        if (typeof dateString === 'string') {
            const parts = dateString.split('-');
            const date = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        } else {
            // Return object as it is
            return dateString;
        }
    }
    handleSelectedStatus = selectedStatus => {
        this.setState({ is_active: selectedStatus.value });
        this.setState({ active_label: selectedStatus.label });
        this.setState({ selectedStatus });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const name = document.getElementById('name').value;
        const desc = document.getElementById('description').value;
        const adID = document.getElementById('adID').value;
        const domain = document.getElementById('domain_URL').value;
        const parametersJSON = (this.state.parameters);
        const transformedParams = {};
        parametersJSON.forEach(param => {
            transformedParams[param.key] = param.value;
        });
        if (!name ||
            !desc || !domain ||
            !this.state.active_label ||
            !this.state.recordStartDate ||
            !this.state.recordEndDate ||
            !this.state.campaign_type_id) {
            let missingFields = '';
            if (!name) {
                missingFields += '<li>Name</li>';
            }
            if (!domain) {
                missingFields += '<li>Domain</li>';
            }
            if (!desc) {
                missingFields += '<li>Description</li>';
            }
            if (!this.state.active_label) {
                missingFields += '<li>Status</li>';
            }
            if (!this.state.recordStartDate) {
                missingFields += '<li>Start Date</li>';
            }
            if (!this.state.recordEndDate) {
                missingFields += '<li>End Date</li>';
            }
            if (!this.state.campaign_type_id) {
                missingFields += '<li>Campagin Type</li>';
            }
            showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
            return false;
        }
        var formatedStartDate;
        var formatedEndDate;
        if (typeof this.state.recordStartDate === 'string' || typeof this.state.recordEndDate === 'string') {
            formatedStartDate = this.state.recordStartDate;
            formatedEndDate = this.state.recordEndDate;
        } else {
            formatedStartDate = this.getDateFormateMethod(this.state.recordStartDate[0]);
            formatedEndDate = this.getDateFormateMethod(this.state.recordEndDate[0]);
        }
        axios.patch(baseURL + "/api/campaigns/" + window.localStorage.getItem('campaign_id'), {
            "name": name,
            "campaign_type_id": this.state.campaign_type_id,
            "discription": desc,
            "start_date": formatedStartDate,
            "end_date": formatedEndDate,
            "ads_id": adID,
            "status": this.state.is_active,
            "domain_url": domain,
            "utm_url": this.getCompleteURL(),
            "parameters": transformedParams
        }, config)
            .then((response) => {
                showToast("Success", "The campaign added successfully.", "success");
                this.setState({ redirect: "/campaign/list" });
            })
            .catch((error) => {
                showToast("Failed", error.response.data.message, "error")
            });
    }

    //for change tooltip display propery
    onChangeValidation(fieldName, value) {
        const modifiedV = { ...this.state.validation };
        if (value !== "") {
            modifiedV[fieldName] = true;
        } else {
            modifiedV[fieldName] = false;
        }
        this.setState({ validation: modifiedV });
    }
    // Function to handle changes in parameter inputs
    handleParameterChange = (index, key, value) => {
        const parameters = [...this.state.parameters];
        parameters[index] = { ...parameters[index], [key]: value };
        this.setState({ parameters });
    };

    // Function to add a new parameter
    addParameter = () => {
        this.setState(prevState => ({
            parameters: [...prevState.parameters, { key: "", value: "", isNew: true }]
        }));
    };

    // Function to remove a parameter
    removeParameter = index => {
        const parameters = [...this.state.parameters];
        parameters.splice(index, 1);
        this.setState({ parameters });
    };
    generateURL = () => {
        const { parameters, domain_URL, utm_URL } = this.state;
        var domain = document.getElementById('domain_URL').value;
        let url = domain;
        // Loop through parameters and append UTM parameters to the URL
        parameters.forEach(parameter => {
            const { key, value } = parameter;
            if (key && value) {
                // Append "&" if URL already has parameters, otherwise "?"
                url += url.includes("?") ? `&${encodeURIComponent(key)}=${encodeURIComponent(value)}` : `?${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            }
        });
        this.setState({ utm_URL: url })
    };
    getCompleteURL = () => {
        const { parameters } = this.state;
        var domain = document.getElementById('domain_URL').value;
        let url = domain;
        parameters.forEach(parameter => {
            const { key, value } = parameter;
            if (key && value) {
                url += url.includes("?") ? `&${encodeURIComponent(key)}=${encodeURIComponent(value)}` : `?${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            }
        });
        return url;
    };
    render() {
        //meta title
        document.title = "Edit Campaign";
        const { selectedStatus, parameters, domain_URL, utm_URL, params_length } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Campaigns" breadcrumbItem="Edit Campaign" parentLink="/Campaign/list" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Formik>
                                            {({ errors, status, touched }) => (
                                                <Form className="needs-validation" onSubmit={this.handleSubmit}>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="name">
                                                                    Name*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Input
                                                                        id="name"
                                                                        name="name"
                                                                        defaultValue={this.state.name}
                                                                        placeholder="Name"
                                                                        type="text"
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="selectIdentity">Campaign Types*</Label>
                                                                <div className="col-md-10">
                                                                    <Select
                                                                        value={{ label: this.state.campaign_type_name, value: this.state.campaign_type_id }}
                                                                        onChange={this.handleSelectGroup}
                                                                        options={this.state.optionGroup}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="adID">
                                                                    Ads ID
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Input
                                                                        id="adID"
                                                                        name="adID"
                                                                        defaultValue={this.state.Ads_ID}
                                                                        placeholder="Ads ID"
                                                                        type="text"
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex ">
                                                                <Label className="col-md-2 col-form-label" htmlFor="selectDate">Start Date*</Label>
                                                                <div className="docs-datepicker col-md-10">
                                                                    <InputGroup>
                                                                        <Flatpickr
                                                                            className="form-control d-block"
                                                                            placeholder="Pick a start date"
                                                                            value={this.responseDateFormatter(this.state.recordStartDate)}
                                                                            options={{
                                                                                enableTime: false,
                                                                                dateFormat: "d/m/Y",
                                                                            }}
                                                                            onChange={date => {
                                                                                this.setState({ recordStartDate: date })
                                                                            }}
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                disabled
                                                                            >
                                                                                <i
                                                                                    className="fa fa-calendar"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                        </div>
                                                                    </InputGroup>
                                                                    <div className="docs-datepicker-container" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex ">
                                                                <Label className="col-md-2 col-form-label" htmlFor="selectDate">End Date*</Label>
                                                                <div className="docs-datepicker col-md-10">
                                                                    <InputGroup>
                                                                        <Flatpickr
                                                                            className="form-control d-block"
                                                                            placeholder="Pick a end date"
                                                                            value={this.responseDateFormatter(this.state.recordEndDate)}
                                                                            options={{
                                                                                dateFormat: "d/m/Y",
                                                                                enableTime: false,
                                                                            }}
                                                                            onChange={date => {
                                                                                this.setState({ recordEndDate: date })
                                                                            }}
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                disabled
                                                                            >
                                                                                <i
                                                                                    className="fa fa-calendar"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                        </div>
                                                                    </InputGroup>
                                                                    <div className="docs-datepicker-container" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="description">Description*</Label>
                                                                <div className="col-md-10">
                                                                    <Input
                                                                        id="description"
                                                                        name="description"
                                                                        defaultValue={this.state.description}
                                                                        placeholder="Type description here..."
                                                                        type="textarea"
                                                                        rows={3}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="selectedStatus">Status*</Label>
                                                                <div className="col-md-10">
                                                                    <Select
                                                                        value={{ label: this.state.active_label, value: this.state.is_active }}
                                                                        onChange={this.handleSelectedStatus}
                                                                        options={this.state.statusArray}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                    <div className="invalid-feedback" id="status-select-msg" style={{ display: this.state.status_select_display }}>Please Select Role Status.</div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="domain">Domain*</Label>
                                                                <div className="col-md-10">
                                                                    <Input
                                                                        id="domain_URL"
                                                                        name="domain"
                                                                        defaultValue={this.state.domain_URL}
                                                                        placeholder="Type your domain here..."
                                                                        type="text"
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="utm_url">UTM URL</Label>
                                                                <div className="col-md-10">
                                                                    <Label className="col-form-label text-info" htmlFor="utm_url">{utm_URL ? utm_URL : "Please Generate UTM URL"}</Label>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <div className="col-md-2"></div>
                                                                <div className="col-md-10">
                                                                    <div className="float-end">
                                                                        <Button color="primary" className="mx-2" onClick={this.generateURL}>
                                                                            Generate URL
                                                                        </Button>
                                                                        <Button color="primary" className="" onClick={this.addParameter}>
                                                                            Add Parameters
                                                                        </Button>.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {parameters ? <>
                                                        <Row >
                                                            <Col md="12">
                                                                <Card>
                                                                    <CardTitle className="mb-2">Parameters*</CardTitle>
                                                                    <CardBody className="border border-primary">
                                                                        {parameters.map((parameter, index) => {
                                                                            return (
                                                                                <>
                                                                                    <FormGroup key={index} className="mb-3 row d-flex">
                                                                                        <Label className="col-md-1 col-form-label">Key</Label>
                                                                                        <div className="col-md-4">
                                                                                            {!parameter.isNew ?
                                                                                                <>
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        value={parameter.key}
                                                                                                        disabled={!parameter.isNew}
                                                                                                    />
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <Input
                                                                                                        type="text"
                                                                                                        value={parameter.key}
                                                                                                        onChange={e => this.handleParameterChange(index, "key", e.target.value)}
                                                                                                    />
                                                                                                </>
                                                                                            }
                                                                                        </div>
                                                                                        <Label className="col-md-1 col-form-label">Value</Label>
                                                                                        <div className="col-md-4">
                                                                                            <Input
                                                                                                type="text"
                                                                                                value={parameter.value}
                                                                                                onChange={e => this.handleParameterChange(index, "value", e.target.value)}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="col-md-2">
                                                                                            <Button color="danger" onClick={() => this.removeParameter(index)}>Remove</Button>
                                                                                        </div>
                                                                                    </FormGroup>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    </> : null}

                                                    <Button color="primary" type="submit" className="float-end" >
                                                        Update Campaign
                                                    </Button>
                                                </Form>
                                            )}
                                        </Formik>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default EditCampaign;
