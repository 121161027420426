import React, { Component } from 'react';
import { get_project_sizes, get_all_projects, get_current_currencies } from "../../Projects/api/projectApi"
import { get_all_payment_types_for_organisation } from "../../Catalogue/PaymentTypes/api/paymentTypesApi"
import { post_payment_plan, get_current_payment_plans, update_payment_plan } from '../api/paymentPlanApi'
import { showToast } from '../../UI/ui-toast';
import { Redirect } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select"
import axios from "axios";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
import { numberWithCommas } from '../../UI/numeric-helper'
import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
class ViewPaymentPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: { PaymentType: '', no_of_recurrences: '', order: '', id: 0 },
            currentPaymentId: 0,
            tableData: [],
            paymentTypesArray: [],
            optionGroupProjects: [],
            currencies: [],
            tableHead: [],
            amount: [],
            project_size_id: '',
            project_id: '',
            project_name: '',
            size_name: '',
            payment_plan_name: '',
            has_payment_types: 0,
            has_sizes: 0,
            redirect: null,
            saved_payment_types: []
            // currenciesTotals: '',
        };
        // this.dropdownOptions = ['Option 1', 'Option 2', 'Option 3'];
    }

    componentDidMount() {
        this.setState({ currentPaymentId: localStorage.getItem('current_payment_plan') })
        Promise.all([get_current_payment_plans(localStorage.getItem('current_payment_plan'))]).then((values) => {
            this.setState({ project_size_id: values[0].size_id })
            this.setState({ project_id: values[0].project_id })
            this.setState({ project_name: values[0].project_name })
            this.setState({ size_name: values[0].size_name })
            this.setState({ payment_plan_name: values[0].name })

            this.setState({ saved_payment_types: values[0].payment_types })
            // this.setState({ tableData: values[0].payment_types })
            const tableheading = values[0].payment_types[0].currencies.map(d => ([d.currency_name]))
            this.setState({ tableHead: tableheading })
            const tableAmount = values[0].payment_types.map(p => (
                // [p.currencies]
                p.currencies.map(c => ([c.amount]))
            ))
            this.setState({ tableAmount: tableAmount })
            this.getProjectDetail(values[0].project_id, values[0].payment_types);
        });
        setTimeout(() => {
            Promise.all([get_all_payment_types_for_organisation()]).then((values) => {
                const paymentTypesOptions = values[0].filter(d => {
                    // Check if the payment type is already added in the table
                    const isAdded = this.state.saved_payment_types.some(tableType => tableType.payment_type_name === d.payment_types_name);

                    // If the payment type is not added, add it to the options
                    return !isAdded;
                }).map(d => ([d.payment_types_name]))

                this.setState({ paymentTypesArray: paymentTypesOptions });
            });
        }, 3000);
    }

    setPlanPaymentTypeData(planPaymentTypes, projectCurrencies) {
        var updatedData = [];
        planPaymentTypes.forEach((rowData, index) => {
            var nCurrencies = rowData.currencies;
            var currencyArray = [];
            projectCurrencies.forEach((pcData, pcIndex) => {
                var currencyFound = 0;
                var currencyObj = null;

                nCurrencies.forEach((cData, cIndex) => {
                    if (pcData.currency_id == cData.currency_id) {
                        currencyFound = 1;
                        currencyObj = cData;
                    }
                })
                if (currencyFound == 1) {
                    // If found then keep the currencyObj
                }
                else {
                    currencyObj = {
                        id: 0,
                        amount: 0,
                        currency_id: pcData.currency_id,
                        currency_name: pcData.currency_name,
                    }

                }
                currencyArray[pcIndex] = currencyObj;
            })
            rowData.currencies = currencyArray;
            updatedData[index] = rowData;
        });

        this.setState({ tableData: updatedData })
    }

    componentDidUpdate() {
        this.state.tableData.forEach((rowData, index) => {
            if (document.getElementById("PaymentType-" + index)) {
                if (rowData.payment_type_name == undefined) {
                    document.getElementById("PaymentType-" + index).value = rowData.payment_type_id;
                }
                else {
                    document.getElementById("PaymentType-" + index).value = rowData.payment_type_name;
                }
            }
        })
    }

    async getProjectDetail(project_id, payment_types) {
        this.setState({ project_id: project_id })
        const res = await axios.get(baseURL + "/api/project-details/" + project_id, config);
        const data = res.data.data;
        this.setState({ has_sizes: data.has_sizes });

        const currencies = await axios.get(baseURL + "/api/project-currencies/" + project_id, config);
        const currenciesData = currencies.data.data;
        this.setState({ currencies: currenciesData });
        this.setPlanPaymentTypeData(payment_types, currenciesData);
        const tableheading = currenciesData.map(d => ([d.currency_name]))
        this.setState({ tableHead: tableheading })
        this.setState({
            formData: { PaymentType: '', no_of_recurrences: '', order: '', currenciesData, id: 0 }
        });
    }

    calculateTotals = () => {
        const currencyTotals = {};
        let tableData = [...this.state.tableData];
        tableData.forEach((data) => {
            data.currencies?.forEach((c) => {
                if (!currencyTotals[c.currency_name]) {
                    currencyTotals[c.currency_name] = 0;
                }
                currencyTotals[c.currency_name] += parseFloat(data.no_of_recurrences) * parseFloat(c.amount);
            });
        });
        Object.entries(currencyTotals).forEach(([currency_name, total]) => {
        });
        // this.setState({currenciesTotals: currencyTotals})
        return currencyTotals
    }

    render() {
        const currenciesTotals = this.calculateTotals();
        document.title = "View Payment Plan";
        const { formData, tableData } = this.state;
        const { selectedGroupSizes } = this.state;
        const { selectedGroupProject } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Payment Plans" breadcrumbItem="View Payment Plan" parentLink="/payment-plan/list" />
                    <Row className="">
                        <Col xl="12" className="col-12">
                            <Card>
                                <CardBody>
                                    <div>
                                        <Col md="12">
                                            <div className="mb-3 row d-flex">
                                                <Label className="col-md-2 col-form-label" htmlFor="payment_plan_name">Payment Plan:</Label>
                                                <div className="mt-2 col-md-10">
                                                    {this.state.payment_plan_name}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="mb-3 row d-flex">
                                                <Label className="col-md-2 col-form-label" htmlFor="selectProject">Project:</Label>
                                                <div className="mt-2 col-md-10">
                                                    {this.state.project_name}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md="12">
                                            <div className="mb-3 row d-flex">
                                                <Label className="col-md-2 col-form-label" htmlFor="selectSizes">Size: </Label>
                                                <div className="mt-2 col-md-10">
                                                    {this.state.size_name}
                                                </div>
                                            </div>
                                        </Col>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">Payment Types</h5>
                                            </div>
                                        </CardBody>
                                        <br />
                                        <Row>
                                            <Col md="12">
                                                <Card>
                                                    <CardBody>
                                                        <div className='d-flex'>
                                                            {this.state.currencies?.map((c, i) => (
                                                                <div key={i}>
                                                                    <div md='12'>
                                                                        <b>Total in {c.currency_name}: {" "}</b>
                                                                        {numberWithCommas(currenciesTotals[c.currency_name])} &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        {
                                            this.state.tableHead != '' ? <>
                                                {/* {JSON.stringify(currenciesTotals)} */}
                                                {this.state.tableData.map((data, index) => (
                                                    <div key={index}>
                                                        <Card id="payment-type-box">
                                                            <CardBody className="border">
                                                                <div className="mb-3 row d-flex">
                                                                    <label className="col-md-2 col-form-label">
                                                                        Payment Type:
                                                                    </label>
                                                                    <div className="mt-2 col-md-10">
                                                                        {data.payment_type_name}
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3 row d-flex">
                                                                    <label className="col-md-2 col-form-label">
                                                                        Occurances:
                                                                    </label>
                                                                    <div className="mt-2 col-md-4">
                                                                        {data.no_of_recurrences}
                                                                    </div>
                                                                    <label style={{ textAlign: 'right' }} className="col-md-1 col-form-label text-right">
                                                                        Order:
                                                                    </label>
                                                                    <div className="mt-2 col-md-5">
                                                                        {data.order}
                                                                    </div>
                                                                </div>
                                                                <Col md="12">
                                                                    <div className="row d-flex">
                                                                        {data.currencies?.map((c, i) => (
                                                                            <div className="row d-flex mb-3" key={i}>
                                                                                <Label className="col-md-2 col-form-label">{this.state.tableHead[i]}</Label>
                                                                                <div className='col-md-8'>
                                                                                    {this.state.currencies[i].currency_symbol}{" "}{numberWithCommas((c.amount))}&nbsp;&nbsp;{" X "}&nbsp;&nbsp;{data.no_of_recurrences}{" "}&nbsp;&nbsp; = &nbsp;&nbsp;{this.state.currencies[i].currency_symbol}{" "}{numberWithCommas(data.no_of_recurrences * c.amount)}
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                ))}
                                            </> : <></>}
                                    </div >
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container >
            </div >
        );
    }
}
export default ViewPaymentPlan;
