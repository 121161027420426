import axios from "axios";

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

export const get_all_orginasation_permissions = async () => {
    let response = await axios.get(baseURL + "/api/orginasation/allPermission", config);
    return response.data.data;
}

export const get_all_organisation_roles = async () => {
    let response = await axios.get(baseURL + "/api/orginasation-role", config);
    return response.data.data;
}

export const get_organisation_active_roles = async () => {
    let response = await axios.get(baseURL + "/api/orginasation-active-role", config);
    return response.data.data;
}

export const get_current_orginasation_role = async (orginasation_role_id) => {
    let response = await axios.get(baseURL + "/api/orginasation-role/" + orginasation_role_id + "/edit", config);
    return response.data.data;
}

export const update_orginasation_role = async (orginasation_role_id,name) => {
    let response = await axios.patch(baseURL + "/api/orginasation-role/"+orginasation_role_id,{
        "name": name, 
      }, config);
    return response.data.data;
}

export const delete_orginasation_role = async (orginasation_role_id) => {
    let response = await axios.delete(baseURL + "/api/orginasation-role/"+orginasation_role_id, config);
    return response.data.data;
}