import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { Link, useHistory, Redirect } from "react-router-dom";
import { isEmpty, size } from "lodash";
import { showToast } from '../../UI/ui-toast'

import DeleteModal from "../../../components/Common/DeleteModal";
import moment from "moment";
import { get_user_permissions } from "../../../helpers/custom_helper"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get_all_deleted_payment_plans, restore_payment_plan } from '../api/paymentPlanApi';

var paymentPlanEdit, paymentPlanView, paymentPlanCreate, paymentPlanDelete;
import RestoreModal from "components/Common/RestoreModal";
var paymentPlanView, paymentPlanLog, paymentPlanRestore;
class ListDeletedPaymentPlans extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 10,
            listData: [],
            listdata: "",
            log_data: '',
            viewmodal: false,
            modal: false,
            isOpen: false,
            selectDate: false,
            deleteModal: false,
            restoreModal: false,
            recordDeleteID: null,
            redirect: null,
            listDataLoded: 0,
            dataLength: 0,
        };
        this.toggle = this.toggle.bind(this);
        this.onClickActivityLog = this.onClickActivityLog.bind(this);
    }
    componentDidMount() {
        paymentPlanView = get_user_permissions('payment-plan-view');
        paymentPlanLog = get_user_permissions('payment-plan-logs-view');
        paymentPlanRestore = get_user_permissions('payment-plan-restore');
        window.localStorage.removeItem('current_payment_plan')
        Promise.all([get_all_deleted_payment_plans()]).then((values) => {
            console.log(values[0]);
            this.setState({ listData: values[0] });
            this.setState({ listDataLoded: 1 });
            this.setState({ dataLength: values[0].length });
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    };
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    toggleRestoreModal = () => {
        this.setState(prevState => ({
            restoreModal: !prevState.restoreModal,
        }));
    };
    onClickRestore = listdata => {
        this.setState({ listdata: listdata });
        this.setState({ restoreModal: true });
    };
    handleRestore = () => {
        const { listdata } = this.state;
        if (listdata.id !== undefined) {
            console.log(listdata);
            console.log(this.state.recordDeleteID);
            Promise.all([restore_payment_plan(this.state.recordDeleteID)]).then((values) => {
                showToast("Success", "The payment plan " + listdata.name + " has been restore successfully.", "success");
                Promise.all([get_all_deleted_payment_plans()]).then((values) => {
                    console.log(values[0]);
                    this.setState({ listData: values[0] });
                });
                this.setState({ redirect: "/payment-plan/list" });
            }).catch((error) => {
                showToast("Failed", "Payment Plan Not Restored Successfully", "error");
            });
            this.setState({ restoreModal: false });
        }
    };
    onClickActivityLog = arg => {
        const listdata = arg;
        window.localStorage.setItem("current_payment_plan", listdata.id);
        this.setState({ redirect: "/payment-plan/log" });
    }
    render() {
        const { isEdit, deleteModal, restoreModal } = this.state;
        const listdata = this.state.listdata;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        const columns = [
            {
                dataField: "name",
                text: "Payment Plan",
                sort: true,
            },
            {
                dataField: "updated_at",
                text: "Updated At",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {moment(listdata.updated_at).format('DD/MM/YYYY h:mma')}
                    </>

                ),
            },
            {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                formatter: (cellContent, listdata) => (
                    <>
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            {paymentPlanView ? <>
                                {paymentPlanLog ? <>
                                    <li>
                                        <Link
                                            to="#"
                                            className="btn btn-sm btn-soft-primary"
                                            onClick={() => this.onClickActivityLog(listdata)}
                                        >
                                            <i className="mdi mdi-file-document-outline" id="logtooltip" />
                                            <UncontrolledTooltip placement="top" target="logtooltip">
                                                Activity Log
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>
                                </> : <></>}
                            </> : <></>}
                            {paymentPlanRestore ? <>
                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-success"
                                        onClick={() => this.onClickRestore(listdata)}
                                    >
                                        <i className="mdi mdi-restore" id="restoretooltip" />
                                        <UncontrolledTooltip placement="top" target="restoretooltip">
                                            Restore Plan
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </> : <></>}
                        </ul>
                    </>
                ),
            },
        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            // totalSize: productsData.length, // replace later with size(customers),
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;

        //meta title
        document.title = "List Deleted Payment Plans";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                console.log(row);
                this.setState({ recordDeleteID: row.id });

            }
        }
        return (
            <React.Fragment>
                <RestoreModal
                    show={restoreModal}
                    onDeleteClick={this.handleRestore}
                    record={`payment plan`}
                    onCloseClick={() => this.setState({ restoreModal: false })}
                />
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Payment Plans" breadcrumbItem="List Deleted Payment Plans" />

                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        {this.state.dataLength == 0 && this.state.listDataLoded == 1 ? <>
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">Payment Plans not found.</h5>
                                            </div>
                                        </> : <>
                                            <PaginationProvider
                                                pagination={paginationFactory(pageOptions)}
                                                keyField="id"
                                                columns={columns}
                                                data={this.state.listData}
                                            >
                                                {({ paginationProps, paginationTableProps }) => (
                                                    <ToolkitProvider
                                                        keyField="id"
                                                        columns={columns}
                                                        data={this.state.listData}
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row className="mb-2">
                                                                    <Col md="4">
                                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                                            <div className="position-relative">
                                                                                <SearchBar
                                                                                    {...toolkitProps.searchProps}
                                                                                />
                                                                                <i className="bx bx-search-alt search-icon" />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col xl="12">
                                                                        <div className="table-responsive">
                                                                            <BootstrapTable
                                                                                keyField={"id"}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                defaultSorted={defaultSorted}
                                                                                selectRow={selectRow}
                                                                                rowEvents={rowEvents}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                {...paginationTableProps}
                                                                                ref={this.node}
                                                                            />
                                                                            <Modal
                                                                                isOpen={this.state.modal}
                                                                                className={this.props.className}
                                                                            >
                                                                                <ModalHeader toggle={this.toggle} tag="h4">
                                                                                    {!!isEdit ? "Edit Payment Plan" : "Add Payment Plan"}
                                                                                </ModalHeader>
                                                                                <ModalBody>
                                                                                    <Formik
                                                                                        enableReinitialize={true}
                                                                                        initialValues={{
                                                                                            id: (listdata && listdata.id) || '',
                                                                                            name: (listdata && listdata.name) || '',
                                                                                        }}
                                                                                        validationSchema={Yup.object().shape({
                                                                                            id: Yup.string().required("Please Enter Your Id"),
                                                                                            name: Yup.string().required("Please Enter Your Name"),
                                                                                        })}
                                                                                        onSubmit={values => {
                                                                                            /*Promise.all([update_project(values.id, values.name)]).then((response) => {
                                                                                                console.log(response[0]);
                                                                                                Promise.all([get_all_customers()]).then((values) => {
                                                                                                    console.log(values[0]);
                                                                                                    this.setState({ listData: values[0] });
                                                                                                });
                                                                                            });*/
                                                                                            this.toggle();
                                                                                        }}
                                                                                    >
                                                                                        {({ errors, status, touched }) => (
                                                                                            <Form>
                                                                                                <Row>
                                                                                                    <Col className="col-12">
                                                                                                        <div className="mb-3">
                                                                                                            <Label className="form-label">
                                                                                                                Payment Plan Id
                                                                                                            </Label>
                                                                                                            <Field
                                                                                                                name="id"
                                                                                                                disabled
                                                                                                                type="text"
                                                                                                                className={
                                                                                                                    "form-control" +
                                                                                                                    (errors.id &&
                                                                                                                        touched.id
                                                                                                                        ? " is-invalid"
                                                                                                                        : "")
                                                                                                                }
                                                                                                            />
                                                                                                            <ErrorMessage
                                                                                                                name="id"
                                                                                                                component="div"
                                                                                                                className="invalid-feedback"
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div className="mb-3">
                                                                                                            <Label className="form-label">
                                                                                                                Name
                                                                                                            </Label>
                                                                                                            <Field
                                                                                                                name="name"
                                                                                                                type="text"
                                                                                                                className={
                                                                                                                    "form-control" +
                                                                                                                    (errors.name &&
                                                                                                                        touched.name
                                                                                                                        ? " is-invalid"
                                                                                                                        : "")
                                                                                                                }
                                                                                                            />
                                                                                                            <ErrorMessage
                                                                                                                name="name"
                                                                                                                component="div"
                                                                                                                className="invalid-feedback"
                                                                                                            />
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                                <Row>
                                                                                                    <Col>
                                                                                                        <div className="text-end">
                                                                                                            <button
                                                                                                                type="submit"
                                                                                                                className="btn btn-primary save-user"
                                                                                                            >
                                                                                                                Save
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </Form>
                                                                                        )}
                                                                                    </Formik>
                                                                                </ModalBody>
                                                                            </Modal>
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <Row className="align-items-md-center mt-30">
                                                                    <Col className="inner-custom-pagination d-flex">
                                                                        <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                            <SizePerPageDropdownStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                        <div className="text-md-right ms-auto">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                )}
                                            </PaginationProvider>
                                        </>}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default ListDeletedPaymentPlans;
