import React from 'react';
import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

    const DashboardPlatform = () => {
        //meta title
        document.title="Dashboard";
        return (
            <>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />
                        {/* write Html code or structure */}
                    </Container>
                </div>
            </>
        );
    }

export default DashboardPlatform;