import axios from "axios";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
export const get_all_lead_types = async () => {
    let response = await axios.get(baseURL + "/api/lead-types", config);
    return response.data.data;
}
export const update_lead_type = async (lead_type_id, name, order) => {
    let response = await axios.patch(baseURL + "/api/lead-types/" + lead_type_id, {
        "name": name,
        "order": order,
    }, config);
    return response.data.data;
}
export const delete_lead_type = async (lead_type_id) => {
    let response = await axios.delete(baseURL + "/api/lead-types/" + lead_type_id, config);
    return response.data.data;
}