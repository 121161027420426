import React, { Component } from "react"
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import Features from "./Features"
import { add_project_feature } from '../api/projectApi';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
const defaultValues = {
    project_id: '',
    features: []
};

function AddProjectFeature() {
    const {
        control,
        register,
        handleSubmit,
        getValues,
        errors,
        reset,
        setValue
    } = useForm({
        defaultValues
    });
    const onSubmit = (data) => {
        console.log(data);
        if (data.features.length == 0) {
            handleClick();
        } else {

            var myData = []
            for (var i = 0; i < data.features.length; i++) {
                // myData.feature_id = data.features[i].feature.feature_id;
                // myData.percentage_value = data.features[i].percentage_value;
                myData.push({ "feature_id": data.features[i].feature?.feature_id, "percentage_value": data.features[i].percentage_value })
            }
            var project_id = localStorage.getItem('project_id')
            var toastTitle = "Failed";
            var toastMessage = "Please fill the required fields.";
            var toastType = "error";
            console.log(myData);
            for (var i = 0; i < myData.length; i++) {
                if (myData[i].feature_id == '' || myData[i].percentage_value == '') {
                    showToast(toastTitle, toastMessage, toastType);
                    return false;
                }
            }
            Promise.all([add_project_feature(myData, project_id)]).then((values) => {
                var toastTitle = "Success";
                var toastMessage = "The project features have been added successfully.";
                var toastType = "success";
                showToast(toastTitle, toastMessage, toastType);
                console.log(values[0]);
                handleClick();
            }).catch((error) => {
                var toastTitle = "Failed";
                var toastMessage = "Not Added";
                var toastType = "error";
                showToast(toastTitle, toastMessage, toastType);
                console.log(error);
            });
        }

    }
    function showToast(title, message, toastType) {
        //position class
        var positionClass = "toast-top-right";
        toastr.options = {
            positionClass: positionClass,
            timeOut: 5000,
            extendedTimeOut: 1000,
            showEasing: "swing",
            hideEasing: "linear",
            showMethod: "fadeIn",
            hideMethod: "fadeOut",
            showDuration: 300,
            hideDuration: 1000,
        };

        // setTimeout(() => toastr.success(`Settings updated `), 300)
        //Toaster Types
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
    }
    let history = useHistory();

    function handleClick() {
        history.push("/projects/currencies?section=6");
        // history.push("/projects/add?section=1");
    }
    return (

        <form onSubmit={handleSubmit(onSubmit)}>

            <Features
                {...{ control, register, defaultValues, getValues, setValue, errors }}
            />
            <button style={{ float: "right" }} onClick={handleSubmit(onSubmit)} color="primary"
                className="btn btn-primary">Next</button>
        </form>

    )
}


export default AddProjectFeature
