import React, { Component } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { showToast } from '../../../UI/ui-toast';

import constant from '../../../../constants.json';

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { get_current_propertyappz_role } from '../api/rolesApi';

const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type' : 'application/json',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

var view_checked;
var add_checked;
var edit_checked;
var del_checked;

var allPermissionsData = [];

const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
// const baseURL = secureLocalStorage.getItem("baseURL");
class EditRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      permissionList: [],
      statusArray:[
         {
          "value": '1',
          "label": 'Yes'
         },
         {
          "value": '0',
          "label": 'No'
         }
      ],
      id: "",
      name: '',
      is_active: '',
      active_label: '',
      role_logo: null,
      permissions: [],
      redirect:null,
      permissionInputs:{},
      current_role_id:null,
      permissionValues:{},
      testView:true,
      testAdd:true,
      testEdit:true,
      testDel:true,
      allViewPermissions: [],
      allAddPermissions: [],
      allEditPermissions: [],
      // allDeletePermissions: [],
      allRestorePermissions: [],
      isCheckedView: false,
      isCheckedAdd: false,
      isCheckedEdit: false,
      isCheckedDelete: false,
      // isCheckedRestore: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }

  findInPermissions(val, selectedPermissions)
  {
    var returnVal;
    returnVal = false;

    const isFound = selectedPermissions?.some(element => {
      if (element.name === val) {
        // var newValues = this.state.permissionValues;
        // newValues[val] = returnVal;
        // console.log(newValues);
        //this.setState({permissionValues:newValues});
        return true;
      }
    });

    if (isFound) {
      returnVal = true;
    }

    return returnVal;
  }

  async getPermissions() {
    let permissionNames = [];
    let nData = null;
    let apiData = null;
    let nViewPermissions = [];
    let nAddPermissions = [];
    let nEditPermissions = [];
    let nDeletePermissions = [];
    // let nRestorePermissions = [];
    let checkData = [];

    let allViewCheck = true;
    let allAddCheck = true;
    let allEditCheck = true;
    let allDelCheck = true;
    // let allRestoreCheck = true;

    let res = await axios.get(baseURL + "/api/propertyappz/allPermission", config);

    if (res.status === 200) {
      nData = res.data;
      const permissionData = nData.data;
      apiData = res.data
      
      permissionNames = Object.keys(apiData);
      const permissionOptions = permissionData.map(d => ({
        "nTitle": d.title,
        "nView": d.view,
        "nAdd": d.add,
        "nEdit": d.edit,
        "nDelete": d.delete,
      }))
      allPermissionsData = permissionOptions;
      //this.setState({ permissionList: permissionOptions });

      var nArray = {};

      permissionData.forEach((el)=>{
        if(el.view != undefined){
          nArray[el.view] = this.findInPermissions(el.view, this.state.permissions);
          if(nArray[el.view] == false){
            allViewCheck = false;
          }
          nViewPermissions.push(el.view);
        }
        if(el.add != undefined){
          nArray[el.add] = this.findInPermissions(el.add, this.state.permissions);
          if(nArray[el.add] == false){
            allAddCheck = false;
          }
          nAddPermissions.push(el.add);
        }
        if(el.edit != undefined){
          nArray[el.edit] = this.findInPermissions(el.edit, this.state.permissions);
          if(nArray[el.edit] == false){
            allEditCheck = false;
          }
          nEditPermissions.push(el.edit);
        }
        if(el.delete != undefined){
          nArray[el.delete] = this.findInPermissions(el.delete, this.state.permissions);
          if(nArray[el.delete] == false){
            allDelCheck = false;
          }
          nDeletePermissions.push(el.delete);
        }
        // if(el.restore != undefined){
        //   nArray[el.restore] = this.findInPermissions(el.restore, this.state.permissions);
        //   if(nArray[el.restore] == false){
        //     allRestoreCheck = false;
        //   }
        //   nRestorePermissions.push(el.restore);
        // }
          // nArray[el.view] = this.findInPermissions(el.view, this.state.permissions);
          // nViewPermissions.push(el.view);
          // nArray[el.add] = this.findInPermissions(el.add, this.state.permissions);
          // nAddPermissions.push(el.add);
          // nArray[el.edit] = this.findInPermissions(el.edit, this.state.permissions);
          // nEditPermissions.push(el.edit);
          // nArray[el.delete] = this.findInPermissions(el.delete, this.state.permissions);
          // nDeletePermissions.push(el.delete);
      });
      
      this.setState({permissionValues:nArray});
      this.setState({allViewPermissions:nViewPermissions});
      this.setState({allAddPermissions:nAddPermissions});
      this.setState({allEditPermissions:nEditPermissions});
      this.setState({allDeletePermissions:nDeletePermissions});
      // this.setState({allRestorePermissions:nRestorePermissions});
      
      this.setState({isCheckedView:allViewCheck});
      this.setState({isCheckedAdd:allAddCheck});
      this.setState({isCheckedEdit:allEditCheck});
      this.setState({isCheckedDelete:allDelCheck});
      // this.setState({isCheckedRestore:allRestoreCheck});
    }
  }


  componentDidMount() {
    
    Promise.all([get_current_propertyappz_role(localStorage.getItem("current_propertyappz_role"))]).then((values) => {
      
      this.setState({ current_role_id: localStorage.getItem("current_propertyappz_role")});
      
      this.setState({ name: values[0].name });
      this.setState({ is_active: values[0].is_active });
      this.setState({ active_label: (values[0].is_active == 1?'Yes':'No') });
      this.setState({ role_logo: values[0].role_icon });
      this.setState({ permissions: values[0].permissions });

      this.getPermissions();
    });
  }

  handlePermissionChange = (e, flag, i) => {
    const isChecked = e.target.checked;
    // console.log('isChecked = ', (isChecked==true?'true':'false'));
    permissionCheckData[i][flag] = isChecked;
  }

  updateAllPermissionColumn = (flag, isChecked) => {

    var newValues = this.state.permissionValues;
    if(flag=='view_check'){
      let nViewPermissions = this.state.allViewPermissions;
      // console.log("nViewPermissions: ", nViewPermissions);
      nViewPermissions.forEach((nData, nIndex) => {
        newValues[nData] = isChecked;
      });
    }

    if(flag=='add_check'){
      let nAddPermissions = this.state.allAddPermissions;
      // console.log("nAddPermissions: ", nAddPermissions);
      nAddPermissions.forEach((nData, nIndex) => {
        newValues[nData] = isChecked;
      });
    }

    if(flag=='edit_check'){
      let nEditPermissions = this.state.allEditPermissions;
      // console.log("nEditPermissions: ", nEditPermissions);
      nEditPermissions.forEach((nData, nIndex) => {
        newValues[nData] = isChecked;
      });
    }

    if(flag=='delete_check'){
      let nDeletePermissions = this.state.allDeletePermissions;
      // console.log("nDeletePermissions: ", nDeletePermissions);
      nDeletePermissions.forEach((nData, nIndex) => {
        newValues[nData] = isChecked;
      });
    }

    // if(flag=='restore_check'){
    //   let nRestorePermissions = this.state.allRestorePermissions;
    //   nRestorePermissions.forEach((nData, nIndex) => {
    //     newValues[nData] = isChecked;
    //   });
    // }

    this.setState({permissionValues:newValues});    
  }

  handleSelectAllView = (e) => {
    const isChecked = e.target.checked;
    // console.log('isChecked = ', (isChecked==true?'true':'false'));
    this.setState({isCheckedView:isChecked});

    this.updateAllPermissionColumn('view_check', isChecked);
  }

  handleSelectAllAdd = (e) => {
    const isChecked = e.target.checked;
    this.setState({isCheckedAdd:isChecked})
    this.updateAllPermissionColumn('add_check', isChecked);
  }

  handleSelectAllEdit = (e) => {
    const isChecked = e.target.checked;
    this.setState({isCheckedEdit:isChecked});
    this.updateAllPermissionColumn('edit_check', isChecked);
  }

  handleSelectAllDelete = (e) => {
    const isChecked = e.target.checked;
    this.setState({isCheckedDelete:isChecked});
    this.updateAllPermissionColumn('delete_check', isChecked);
  }
  // handleSelectAllRestore = (e) => {
  //   const isChecked = e.target.checked;
  //   this.setState({isCheckedRestore:isChecked});
  //   this.updateAllPermissionColumn('restore_check', isChecked);
  // }

  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
  }

  handleSelectedStatus = selectedStatus => {
    this.setState({ is_active: selectedStatus.value });
    this.setState({ active_label: selectedStatus.label });
    this.setState({ selectedStatus });    
  }

  setPermissionsData(nData)
  {
    let aData = {};
    var tArray = [];
    var j = 0;
    for (var i=0; i<nData.length; i++) 
    {
      if(nData[i].checked == true){
        tArray[j] = nData[i].value;
        j++;
      }
    }
    //aData.push(tArray);
    aData = {
      0:tArray,
    };

    return tArray;
  }

  handleSubmit(e) {
    e.preventDefault();
    var nData = e.target.permissions;
    var myData = this.setPermissionsData(nData);

    let selectedPermissionCount = 0;
    let selectedPermissionVals = [];

    let finalPermissionValues = this.state.permissionValues;
    // console.log(finalPermissionValues);

    Object.keys(finalPermissionValues).forEach(eKey => {
      if(finalPermissionValues[eKey]==true){
        // console.log("eKey = ",eKey);
        selectedPermissionVals.push(eKey);
        selectedPermissionCount++;
      }
    });
    // console.log(selectedPermissionVals);

    if(e.target.roleName.value == ''){
      showToast("Failed", "Role Name is required.", "error");
      return false;
    }
    if(selectedPermissionCount==0){
      showToast("Failed", "Please select at least one permission to save the role.", "error");
      return false;
    }
    
    if(selectedPermissionCount>0){
      var jsonData = JSON.stringify(myData);
      
      axios.post(baseURL + "/api/propertyappz-role-update/" + this.state.current_role_id, {

        //"name": values.roleName,
        "name": e.target.roleName.value,
        "permissions": myData,
        "is_active": this.state.is_active,
        // "logo": ""
      }, config)
      .then((response) => {
        showToast("Success", "The role '"+e.target.roleName.value+"' has been updated successfully.", "success");
        localStorage.removeItem("current_propertyappzrole");
        this.setState({ redirect: "/propertyappz/roles/list" });
      })
      .catch( (error) => {
        console.log(error);
        console.log(error.response.data.errors);
        var errorsObj = error.response.data.errors;
        var errorMsg = "";
        if(errorsObj != undefined){
          if(Object.keys(errorsObj).length > 0){
            Object.keys(errorsObj).forEach(eKey => {
              // console.log(errorsObj[eKey]);
              errorMsg = '<br />' + errorsObj[eKey];
            });
          }
        }
        else{
          var errorMsg = '<br />' + error.response.data.message;
        }
        showToast("Failed", "Role Not Updated" + (errorMsg==""?'':errorMsg), "error");
      });
    }

    /*
    const modifiedV = { ...this.state.validation };
    var role = document.getElementById("validationTooltip03").value;
    
    if (role === "") {
      modifiedV["role"] = false;
    } else {
      modifiedV["role"] = true;
    }

    this.setState({ validation: modifiedV });
    */
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }

  handleLogoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      this.setState({ role_logo: e.target.files[0] });
    }
  };


  handleCheck(val) {

    var returnVal;
    returnVal = false;

    const isFound = this.state.permissions?.some(element => {
      if (element.name === val) {
        return true;
      }
    });

    if (isFound) {
      returnVal = true;
    }

    return returnVal;

    
  }

  setCheckAll = (flag) => {

    let oldValues = this.state.permissionValues;
    let newChecked = true;
    // console.log("oldValues: ", oldValues);


    if(flag=='view_check'){
      let nViewPermissions = this.state.allViewPermissions;
      // console.log("nViewPermissions: ", nViewPermissions);
      nViewPermissions.forEach((nData, nIndex) => {
        // oldValues.forEach((nData, nIndex) => {
        //   // newValues[nData] = isChecked;
        // });
        Object.keys(oldValues).forEach(eKey => {
          if(eKey == nData){
            if(oldValues[eKey] == false){
              newChecked = false;
            }
          }
          // console.log(eKey);
          // console.log(oldValues[eKey]);
        });
      });

      this.setState({isCheckedView:newChecked});
    }

    if(flag=='add_check'){
      let nAddPermissions = this.state.allAddPermissions;
      // console.log("nAddPermissions: ", nAddPermissions);
      nAddPermissions.forEach((nData, nIndex) => {
        Object.keys(oldValues).forEach(eKey => {
          if(eKey == nData){
            if(oldValues[eKey] == false){
              newChecked = false;
            }
          }
        });
      });
      this.setState({isCheckedAdd:newChecked});
    }

    if(flag=='edit_check'){
      let nEditPermissions = this.state.allEditPermissions;
      // console.log("nEditPermissions: ", nEditPermissions);
      nEditPermissions.forEach((nData, nIndex) => {
        Object.keys(oldValues).forEach(eKey => {
          if(eKey == nData){
            if(oldValues[eKey] == false){
              newChecked = false;
            }
          }
        });
      });
      this.setState({isCheckedEdit:newChecked});
    }

    if(flag=='delete_check'){
      let nDeletePermissions = this.state.allDeletePermissions;
      // console.log("nDeletePermissions: ", nDeletePermissions);
      nDeletePermissions.forEach((nData, nIndex) => {
        Object.keys(oldValues).forEach(eKey => {
          if(eKey == nData){
            if(oldValues[eKey] == false){
              newChecked = false;
            }
          }
        });
      });
      this.setState({isCheckedDelete:newChecked});
    }
    // if(flag=='restore_check'){
    //   let nRestorePermissions = this.state.allRestorePermissions;
    //   // console.log("nDeletePermissions: ", nDeletePermissions);
    //   nRestorePermissions.forEach((nData, nIndex) => {
    //     Object.keys(oldValues).forEach(eKey => {
    //       if(eKey == nData){
    //         if(oldValues[eKey] == false){
    //           newChecked = false;
    //         }
    //       }
    //     });
    //   });
    //   this.setState({isCheckedRestore:newChecked});
    // }

  }

  handleSelectChange(evt, param, chkFlag) {
    var newValues = this.state.permissionValues;
    newValues[param] = evt.currentTarget.checked;
    this.setState({permissionValues:newValues});
    
    this.setCheckAll(chkFlag);
  }

  render() {
    //meta title
    document.title = "Edit Organisation Roles";
    const { validation } = this.state;
    //const { selectedGroup } = this.state;
    const { selectedStatus } = this.state;

    var tVal = {};

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    // console.log('Testing aaaa');
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Organisation Roles" breadcrumbItem="Edit Organisation Role" parentLink="/roles/list" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        roleName: (this.state && this.state.roleName) || "",
                      }}
                      validationSchema={Yup.object().shape({
                        /*roleName: Yup.string().required(
                          "Please Enter Role Name."
                        ),*/
                      })}
                      
                      /*onSubmit={values => {
                        console.log(values);
                        console.log(values.permissions);
                        axios.post(baseURL + "/api/orginasation-role", {

                          "name": values.roleName,
                          "is_active": selectedStatus.value,
                          "logo": this.state.role_logo,
                          "permissions" : values.permissions
                        }, config)
                          .then(function (response) {
                            console.log(response);
                            window.location.replace('/roles/list');
                          })
                          .catch(function (error) {
                            console.log(error);
                          });

                      }}
                      */
                    >
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation" onSubmit={this.handleSubmit}>
                          <Row>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="roleName">
                                  Role Name*
                                </Label>
                                <div className="col-md-10">
                                <Field
                                  name="roleName"
                                  placeholder="Role Name"
                                  type="text"
                                  value={this.state.name}
                                  onChange={(e) => this.setState({ name: e.target.value })}
                                  className={
                                    "form-control" +
                                    (errors.roleName && touched.roleName
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="roleName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectedStatus">Is Active*</Label>
                                <div className="col-md-10">
                                <Select
                                  //value={selectedStatus}
                                  options={this.state.statusArray}
                                  value={{ label: this.state.active_label, value: this.state.is_active }}
                                  onChange={this.handleSelectedStatus}
                                  classNamePrefix="select2-selection"
                                />
                                </div>
                              </div>
                            </Col>
                            {

                            }
                            {/* 
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="logo">
                                  Logo
                                </Label>
                                <div className="col-md-10">
                                  <Input className="form-control form-control" type="file" id="inputLogo" accept="image/*" onChange={this.handleLogoChange} />
                                </div>
                              </div>
                            </Col>
                            */}

                            <Col md="12" className="mt-4">
                              <div className="mb-3 row d-flex select2-container">
                                <div className="col-md-4"><h5>Role Permissions*</h5></div>
                                <div className="col-md-1"><h6 style={{fontSize: "12px"}}><Field type="checkbox" checked={this.state.isCheckedView} onChange={(e) => this.handleSelectAllView(e)} /> View</h6></div>
                                <div className="col-md-1"><h6 style={{fontSize: "12px"}}><Field type="checkbox" checked={this.state.isCheckedAdd} onChange={(e) => this.handleSelectAllAdd(e)} /> Add</h6></div>
                                <div className="col-md-1"><h6 style={{fontSize: "12px"}}><Field type="checkbox" checked={this.state.isCheckedEdit} onChange={(e) => this.handleSelectAllEdit(e)} /> Edit</h6></div>
                                <div className="col-md-1"><h6 style={{fontSize: "12px"}}><Field type="checkbox" checked={this.state.isCheckedDelete} onChange={(e) => this.handleSelectAllDelete(e)} /> Delete</h6></div>
                                {/* <div className="col-md-1"><h6 style={{fontSize: "12px"}}><Field type="checkbox" checked={this.state.isCheckedRestore} onChange={(e) => this.handleSelectAllRestore(e)} /> Restore</h6></div> */}
                              </div>
                            </Col>
                            {

                            allPermissionsData.map( (nVal, i) => {

                              /*view_checked = (nVal.nView==undefined?false: this.handleCheck(nVal.nView));
                              add_checked = (nVal.nAdd==undefined?false: this.handleCheck(nVal.nAdd));
                              edit_checked = (nVal.nEdit==undefined?false: this.handleCheck(nVal.nEdit));
                              del_checked = (nVal.nDelete==undefined?false: this.handleCheck(nVal.nDelete));*/

                              /*view_checked = this.state.permissionValues[nVal.nView];
                              add_checked = this.state.permissionValues[nVal.nAdd];
                              edit_checked = this.state.permissionValues[nVal.nEdit];
                              del_checked = this.state.permissionValues[nVal.nDelete];*/

                              return(
                                <Col md="12" key={i}>
                                  <div className="mb-3 row d-flex select2-container">
                                    <div className="col-md-4">{nVal.nTitle}</div>
                                    <div className="col-md-1">{nVal.nView==undefined?'': <Field type="checkbox" checked={this.state.permissionValues[nVal.nView]} name="permissions" value={nVal.nView} onChange={(e) => this.handleSelectChange(e, nVal.nView, 'view_check')} />}</div>
                                    <div className="col-md-1">{nVal.nAdd==undefined?'': <Field type="checkbox" checked={this.state.permissionValues[nVal.nAdd]} name="permissions" value={nVal.nAdd} onChange={(e) => this.handleSelectChange(e, nVal.nAdd, 'add_check')} />}</div>
                                    <div className="col-md-1">{nVal.nEdit==undefined?'': <Field type="checkbox" checked={this.state.permissionValues[nVal.nEdit]} name="permissions" value={nVal.nEdit} onChange={(e) => this.handleSelectChange(e, nVal.nEdit, 'edit_check')} />}</div>
                                    <div className="col-md-1">{nVal.nDelete==undefined?'': <Field type="checkbox" checked={this.state.permissionValues[nVal.nDelete]} name="permissions" value={nVal.nDelete} onChange={(e) => this.handleSelectChange(e, nVal.nDelete, 'delete_check')} />}</div>
                                    {/* <div className="col-md-1">{nVal.nRestore==undefined?'': <Field type="checkbox" checked={this.state.permissionValues[nVal.nRestore]} name="permissions" value={nVal.nRestore} onChange={(e) => this.handleSelectChange(e, nVal.nRestore, 'restore_check')} />}</div> */}
                                  </div>
                                </Col>
                              )
                            }) 

                            }
                          </Row>
                          <Button color="primary" type="submit" className="float-end">
                            Update Role
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default EditRole;