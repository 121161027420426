import React, { Component } from "react";
import { showToast } from '../../UI/ui-toast'
import { get_user_permissions } from "../../../helpers/custom_helper"
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  InputGroup,
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import axios from "axios";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get_payment_against_sale } from '../../Sales/api/salesApi';
import { get_current_payment_details } from '../api/paymentsApi';

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

class ReceivePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      redirect: null,
      currentSaleId: 0,
      currentPaymentId: 0,
      paymentDueDate: [new Date()],
      paymentReceivedDate: [new Date()],
      paymentAmount: 0,
      paymentLatefee: 0,
      paymentTotal: 0,
      paymentPaid: 0,
      paymentStatus: '',
      statusArray: [
        {
          "value": 'Received',
          "label": 'Received'
        },
        {
          "value": 'Partially Received',
          "label": 'Partially Received'
        }
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);

    //Received
  }

  componentDidMount() {
    var salesId = localStorage.getItem("payments_sale_id");
    var paymentId = localStorage.getItem("current_payment_id");
    this.setState({ currentSaleId: localStorage.getItem("payments_sale_id") });
    this.setState({ currentPaymentId: localStorage.getItem("current_payment_id") });

    this.getPaymentDetails(paymentId);
  }

  async getPaymentDetails(paymentId) {
    Promise.all([get_current_payment_details(paymentId)]).then((values) => {
      console.log(values[0]);
      var paymentData = values[0];
      //var dueDate = this.getDateFormateMethod(paymentData.due_date);
      this.setState({ paymentDueDate: paymentData.due_date });
      this.setState({ currentSaleId: paymentData.sale_id });
      // this.setState({ paymentStatus: paymentData.sale_id });
      this.setState({ paymentAmount: paymentData.amount });
      this.setState({ paymentLatefee: paymentData.late_fee });
      this.setState({ paymentTotal: (paymentData.amount + paymentData.late_fee) });


    });
  }

  handleSelectedStatus = selectedStatus => {
    this.setState({ selectedStatus })
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  cancelPayment = () => {
    this.setState({ redirect: "/salepayments/list" });
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    // const modifiedV = { ...this.state.validation };
    // if (value !== "") {
    //   modifiedV[fieldName] = true;
    // } else {
    //   modifiedV[fieldName] = false;
    // }
    // this.setState({ validation: modifiedV });
  }

  getDateFormateMethod = (myDate) => {
        var today = myDate
        const dd = today.getDate().toString()
        const mm = today.getMonth() + 1
        const yyyy = today.getFullYear()
        const fromate_date = (today = yyyy + "-" + mm + "-" + dd)
        return fromate_date
    }

  render() {
    //meta title
    document.title = "Receive Payment";
    const { validation } = this.state;
    const { selectedStatus } = this.state;

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Sales" breadcrumbItem="Receive Payment" parentLink="/sales/list" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        amountPaid: (this.state && this.state.amountPaid) || "",
                      }}

                      validationSchema={Yup.object().shape({
                        amountPaid: Yup.string().required(
                          "Please Enter amount Paid"
                        ),
                      })}
                      onSubmit={values => {
                        console.log(values);
                        var formatedDate = this.getDateFormateMethod(this.state.paymentReceivedDate[0]);
                        var paymentId = this.state.currentPaymentId;
                        axios.post(baseURL+"/api/payment-receive/"+paymentId, {
                            "receipt_date": formatedDate,
                            "sale_id": this.state.currentSaleId,
                            "amount": this.state.paymentAmount,
                            "late_fee": this.state.paymentLatefee,
                            "total": this.state.paymentTotal,
                            "paid": values.amountPaid,
                            "status": this.state.selectedStatus.value,
                          },config)
                          .then((response) => {
                            showToast("Success", "Payment Added Successfully", "success");
                            console.log(response);
                            this.setState({ redirect: "/salepayments/list" });
                          })
                          .catch((error) => {
                            showToast("Failed", "Payment Not Added Successfully", "error")
                            console.log(error);
                          });
                      }}
                    >
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation">
                          <Row>
                            <Col md="12">
                                <div className="mb-3 row d-flex ">
                                    <Label className="col-md-2 col-form-label" htmlFor="selectDate">Received Date</Label>

                                    <div className="docs-datepicker col-md-10">
                                        <InputGroup>
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Pick a date"
                                                value={this.state.todayDate}
                                                options={{
                                                    altInput: true,
                                                    // altFormat: "F j, Y",
                                                    dateFormat: "d-m-y"
                                                }}
                                                onChange={date => {
                                                    this.setState({ paymentReceivedDate: date })
                                                }}
                                            />
                                            <div className="input-group-append">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary docs-datepicker-trigger"
                                                    disabled
                                                >
                                                    <i
                                                        className="fa fa-calendar"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </div>
                                        </InputGroup>
                                        <div className="docs-datepicker-container" />
                                    </div>
                                </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex ">
                                <Label className="col-md-2 col-form-label" htmlFor="paymentDueDate">
                                  paymentDueDate
                                </Label>
                                <div className="col-md-10">
                                <Field
                                  name="paymentDueDate"
                                  readOnly
                                  value={this.state.paymentDueDate}
                                  type="text"
                                  className={"form-control" }
                                />
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex ">
                                <Label className="col-md-2 col-form-label" htmlFor="paymentAmount">
                                  Amount
                                </Label>
                                <div className="col-md-10">
                                <Field
                                  name="paymentAmount"
                                  readOnly
                                  value={this.state.paymentAmount}
                                  type="text"
                                  className={"form-control" }
                                />
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex ">
                                <Label className="col-md-2 col-form-label" htmlFor="paymentLatefee">
                                  Late Fee
                                </Label>
                                <div className="col-md-10">
                                <Field
                                  name="paymentLatefee"
                                  placeholder="Late Fee"
                                  type="number"
                                  value={this.state.paymentLatefee}
                                  onChange={(e) => this.setState({ paymentLatefee: e.target.value })}
                                  className={
                                      "form-control" +
                                      (errors.paymentLatefee && touched.paymentLatefee
                                        ? " is-invalid"
                                        : "")
                                    }
                                />
                                <ErrorMessage
                                    name="paymentLatefee"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex ">
                                <Label className="col-md-2 col-form-label" htmlFor="paymentTotal">
                                  Total
                                </Label>
                                <div className="col-md-10">
                                <Field
                                  name="paymentTotal"
                                  placeholder="Total"
                                  type="number"
                                  value={this.state.paymentTotal}
                                  onChange={(e) => this.setState({ paymentTotal: e.target.value })}
                                  className={
                                      "form-control" +
                                      (errors.paymentTotal && touched.paymentTotal
                                        ? " is-invalid"
                                        : "")
                                    }
                                />
                                <ErrorMessage
                                    name="paymentTotal"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex ">
                                <Label className="col-md-2 col-form-label" htmlFor="amountPaid">
                                  Paid
                                </Label>
                                <div className="col-md-10">
                                <Field
                                  name="amountPaid"
                                  placeholder="Amount Paid"
                                  type="number"
                                  className={
                                      "form-control" +
                                      (errors.amountPaid && touched.amountPaid
                                        ? " is-invalid"
                                        : "")
                                    }
                                />
                                <ErrorMessage
                                    name="amountPaid"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectedStatus">Is Active</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={selectedStatus}
                                    onChange={this.handleSelectedStatus}
                                    options={this.state.statusArray}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="col-md-12 float-end">
                                <Button color="primary" type="submit" className="float-end">
                                  Save Payment
                                </Button>
                                <Button color="danger" type="button" className="" onClick={() => this.cancelPayment()}>
                                  Cancel
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ReceivePayment;
