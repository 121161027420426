import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import { generate_payment_summary_against_sale, generate_statement_accounts_against_sale, download_sale_document, send_payment_summary_email_against_sale, send_statement_of_account_email_against_sale } from '../../api/salesApi';
import { showToast } from "pages/UI/ui-toast";
import SendEmailModal from "./SendEmailModal";
class SummaryStatementModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onGenerate: 0,
      sendEmailModal: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.setState({ onGenerate: 0 });
    }
  }
  componentWillUnmount() {
    this.setState({ onGenerate: 0 });
  }
  handleGenerateDocument = (sale_id, flag) => {
    if (flag == "Payment Summary") {
      Promise.all([generate_payment_summary_against_sale(sale_id)])
        .then((values) => {
          this.setState({ onGenerate: 1 })
          showToast("Success", "Payment summary document generated successfully!", "success");
        })
        .catch((error) => {
          this.setState({ onGenerate: 0 })
          showToast("Failed", error.response.data.message, "error");
        });
    } else {
      Promise.all([generate_statement_accounts_against_sale(sale_id)])
        .then((values) => {
          this.setState({ onGenerate: 1 })
          localStorage.setItem("statement-of-accounts-generated", 1)
          showToast("Success", "Statement Of Accounts document generated successfully!", "success");
        })
        .catch((error) => {
          this.setState({ onGenerate: 0 })
          showToast("Failed", error.response.data.message, "error");
        });
    }
  }
  handleDownloadDocument = (sale_id, flag) => {
    Promise.all([download_sale_document(sale_id, flag)]).then((values) => {
      const contentType = values[0].file_path.split(';')[0].split(':')[1];
      const base64Content = values[0].file_path.split(',')[1];
      const byteCharacters = atob(base64Content);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const extension = values[0].file_path.split(';')[0].split('/')[1];
      const blob = new Blob([byteArray], { type: contentType });
      const fileName = `${values[0].template_name}.${extension}`;
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.target = '_blank';
      a.rel = 'noopener noreferrer';
      a.click();
      showToast("Success", "Download Success.", "success")
    }).catch((error) => {
      showToast("Failed", "Download Failed!", "error")
    });
  }
  handleSendEmail = (sale_id, flag) => {
    if (flag == "Payment Summary") {
      this.setState({ sendEmailModal: true });
    } else {
      this.setState({ sendEmailModal: true });
    }
  }
  toggleSendEmailModal = () => {
    this.setState(prevState => ({
      sendEmailModal: !prevState.sendEmailModal,
    }));
  };
  render() {
    const { sendEmailModal } = this.state;
    return (
      <React.Fragment>
        <SendEmailModal
          show={sendEmailModal}
          flag={this.props.title}
          id={this.props.record.id}
          payload={{}}
          onCloseClick={() => this.setState({ sendEmailModal: false })}
        />
        <Modal size="md" isOpen={this.props.show} toggle={this.props.onCloseClick} centered={true}>
          <div className="modal-content">
            <ModalBody className="px-4 py-5 text-center">
              <button type="button" onClick={this.props.onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
              <h2>{this.props.title}</h2>
              <table className="mb-4 mx-auto">
                <tr className="welcome-payment-modal-table-data-head">
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <td className="welcome-payment-modal-table-data"><button type="button" className="btn btn-danger" onClick={() => this.handleGenerateDocument(this.props.record.id, this.props.title)}>Generate</button></td>
                  {this.props.generated_docs_detail_response == 1 || this.state.onGenerate == 1 ? <>
                    <td className="welcome-payment-modal-table-data"> <button type="button" className="btn btn-success" onClick={() => this.handleDownloadDocument(this.props.record.id, this.props.title)}>Download</button></td>
                    <td className="welcome-payment-modal-table-data"> <button type="button" className="btn btn-info" onClick={() => this.handleSendEmail(this.props.record.id, this.props.title)}>Send Email</button></td>
                  </> : <></>}
                </tr>
              </table>
              <div className="hstack gap-2 justify-content-center mb-0">
              </div>
            </ModalBody>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
SummaryStatementModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};
export default SummaryStatementModal;