import React, { Component } from "react"
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import Sizes from "./Sizes"
import toastr from "toastr";
import "toastr/build/toastr.min.css";
const defaultValues = {
    project_id: '',
    sizes: []
};
import { add_sizes } from '../api/projectApi';

function FormBuilderSizesAndFloors() {
    const {
        control,
        register,
        handleSubmit,
        getValues,
        errors,
        reset,
        setValue
    } = useForm({
        defaultValues
    });
    const onSubmit = (data) => {
        // const project_id = window.localStorage.getItem("project_id");
        // defaultValues.project_id = project_id;
        data.project_id = window.localStorage.getItem("project_id");
        if (data.sizes == '') {
            var toastTitle = "Failed";
            var toastMessage = "Please add Size first.";
            var toastType = "error";
            showToast(toastTitle, toastMessage, toastType);
        } else {
            var toastTitle = "Failed";
            var toastMessage = "Size name empty.";
            var toastType = "error";
            for (let i = 0; i < data.sizes.length; i++) {
                if (data.sizes[i].name == '') {
                    showToast(toastTitle, toastMessage, toastType);
                    return false;
                }
            }
            Promise.all([add_sizes(data)]).then((values) => {
                var toastTitle = "Success";
                var toastMessage = "The sizes have added successfully.";
                var toastType = "success";
                showToast(toastTitle, toastMessage, toastType);
                console.log(values[0]);
                console.log('has_phase_blocks = ' + window.localStorage.getItem("has_phase_blocks"));
                console.log('has_floors = ' + window.localStorage.getItem("has_floors"));

                if (window.localStorage.getItem("has_phase_blocks") == 1) {
                    handleClick();
                }
                else if (window.localStorage.getItem("has_floors") == 1) {
                    handleClickfloors();
                }
                else {
                    handleClickFeatures();
                }
                // console.log("data Inside", data);

            }).catch((error) => {
                var toastTitle = "Failed";
                var toastMessage = "Sizes Not Added";
                var toastType = "error";
                showToast(toastTitle, toastMessage, toastType);
                console.log(error);
            });

            // add_sizes(data);
        }
        console.log("data outside", data);
        // window.localStorage.removeItem("section");
        // window.localStorage.removeItem("project_id");
    }
    let history = useHistory();
    function showToast(title, message, toastType) {
        //position class
        var positionClass = "toast-top-right";
        toastr.options = {
            positionClass: positionClass,
            timeOut: 5000,
            extendedTimeOut: 1000,
            showEasing: "swing",
            hideEasing: "linear",
            showMethod: "fadeIn",
            hideMethod: "fadeOut",
            showDuration: 300,
            hideDuration: 1000,
        };

        // setTimeout(() => toastr.success(`Settings updated `), 300)
        //Toaster Types
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
    }

    function handleClick() {
        history.push("/projects/phases?section=3");
    }
    function handleClickfloors() {
        history.push("/projects/floors?section=4");
    }

    function handleClickFeatures() {
        history.push("/projects/features?section=5");
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Sizes
                {...{ control, register, defaultValues, getValues, setValue, errors }}
            />

            {/* <button type="button" onClick={() => reset(defaultValues)}>
                      Reset
                    </button> */}
            <div>
                <button style={{ float: "right" }} onClick={handleSubmit(onSubmit)} color="primary"
                    className="btn btn-primary">Next</button></div>
        </form>
    )
}


export default FormBuilderSizesAndFloors
