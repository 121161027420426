import axios from "axios";
// import { Link, useHistory } from "react-router-dom";  
import { withRouter } from 'react-router-dom'

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

export const get_all_payment_plans = async () => {
    let response = await axios.get(baseURL + "/api/payment_plans", config);
    return response.data.data;
}
export const delete_payment_plan = async (payment_plan_id) => {
    let response = await axios.delete(baseURL + "/api/payment_plans/" + payment_plan_id, config);
    return response.data.data;
}
export const post_payment_plan = async (data) => {
    let response = await axios.post(baseURL + "/api/payment_plans", data, config);
    return response.data.data;
}
export const update_payment_plan = async (payment_plan_id, data) => {
    let response = await axios.patch(baseURL + "/api/payment_plans/" + payment_plan_id, data, config);
    return response.data.data;
}
export const get_current_payment_plans = async (current_payment_plan) => {
    let response = await axios.get(baseURL + "/api/payment_plans/" + current_payment_plan, config);
    return response.data.data;
}
export const get_payment_plan_log = async (current_payment_plan) => {
    let response = await axios.get(baseURL + "/api/payment-plan-activity-log/" + current_payment_plan, config);
    return response.data.data;
}
export const get_all_payment_plan_log = async () => {
    let response = await axios.get(baseURL + "/api/all-payment-plan-activity-log", config);
    return response.data.data;
}
export const get_all_deleted_payment_plans = async () => {
    let response = await axios.get(baseURL + "/api/deleted-payment-plan", config);
    return response.data.data;
}
export const restore_payment_plan = async (current_payment_plan) => {
    let response = await axios.post(baseURL + "/api/restore-the-payment-plan/" + current_payment_plan, {}, config);
    return response.data.data;
}