import axios from "axios";

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
// const baseURL = secureLocalStorage.getItem("baseURL");

export const get_all_propertyappz_permissions = async () => {
    let response = await axios.get(baseURL + "/api/propertyappz/allPermission", config);
    return response.data.data;
}

export const get_all_propertyappz_roles = async () => {
    let response = await axios.get(baseURL + "/api/propertyappz-role", config);
    return response.data.data;
}

export const get_propertyappz_active_roles = async () => {
    let response = await axios.get(baseURL + "/api/propertyappz-active-role", config);
    return response.data.data;
}

export const get_current_propertyappz_role = async (propertyappz_role_id) => {
    let response = await axios.get(baseURL + "/api/propertyappz-role/" + propertyappz_role_id + "/edit", config);
    return response.data.data;
}

export const update_propertyappz_role = async (propertyappz_role_id, name) => {
    let response = await axios.patch(baseURL + "/api/propertyappz-role/" + propertyappz_role_id, {
        "name": name,
    }, config);
    return response.data.data;
}

export const delete_propertyappz_role = async (propertyappz_role_id) => {
    let response = await axios.delete(baseURL + "/api/propertyappz-role/" + propertyappz_role_id, config);
    return response.data.data;
}