import {
    GET_PROJECT_LIST_FAIL,
    GET_PROJECT_LIST_SUCCESS,
    ADD_PROJECT_LIST_SUCCESS,
    ADD_PROJECT_LIST_FAIL,
    UPDATE_PROJECT_LIST_SUCCESS,
    UPDATE_PROJECT_LIST_FAIL,
    DELETE_PROJECT_LIST_SUCCESS,
    DELETE_PROJECT_LIST_FAIL,
    GET_APPLY_PROJECT_SUCCESS,
    GET_APPLY_PROJECT_FAIL,
    DELETE_APPLY_PROJECT_SUCCESS,
    DELETE_APPLY_PROJECT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    projects: [],
    projectApply: [],
    error: {},
}

const projectsLists = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PROJECT_LIST_SUCCESS:
            return {
                ...state,
                projects: action.payload,
            };

        case GET_PROJECT_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_PROJECT_LIST_SUCCESS:
            return {
                ...state,
                projects: [...state.projects, action.payload],
            };

        case ADD_PROJECT_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_PROJECT_LIST_SUCCESS:
            return {
                ...state,
                projects: state.projects.map(project =>
                    project.id.toString() === action.payload.id.toString()
                        ? { project, ...action.payload }
                        : project
                ),
            };

        case UPDATE_PROJECT_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_PROJECT_LIST_SUCCESS:
            return {
                ...state,
                projects: state.projects.filter(
                    project => project.id.toString() !== action.payload.id.toString()
                ),
            };

        case DELETE_PROJECT_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case GET_APPLY_PROJECT_SUCCESS:
            return {
                ...state,
                projectApply: action.payload,
            };
        case GET_APPLY_PROJECT_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case DELETE_APPLY_PROJECT_SUCCESS:
            return {
                ...state,
                projectApply: state.projectApply.filter(data => data.id.toString() !== action.payload.id.toString())
            };
        case DELETE_APPLY_PROJECT_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state
    }
}

export default projectsLists;