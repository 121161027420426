import React, { Component } from "react";
import { Row, Col, Card, CardBody, UncontrolledTooltip } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { isEmpty, size } from "lodash";
import { showToast } from '../../../../../UI/ui-toast'
import moment from "moment";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../../../../../components/Common/Breadcrumb";
import { get_default_welcome_letter_list, sample_file_download } from '../../../../api/templatesApi';
import { get_user_permissions } from "../../../../../../helpers/custom_helper"
class ListWelcomeLetterSamples extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 10,
            listData: [],
            listdata: "",
            recordDeleteID: null,
            redirect: null,
        };
        this.onClickDownload = this.onClickDownload.bind(this);
        this.onClickCloneTemplate = this.onClickCloneTemplate.bind(this);
    }
    componentDidMount() {
        window.localStorage.removeItem('current_sample_id')
        window.localStorage.removeItem('current_sample_template_name')
        Promise.all([get_default_welcome_letter_list()]).then((values) => {
            console.log(values[0]);
            this.setState({ listData: values[0] });
        });
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    };
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    onClickDownload = listdata => {
        Promise.all([sample_file_download(listdata.id)]).then((values) => {
            const contentType = values[0].split(';')[0].split(':')[1];
            const base64Content = values[0].split(',')[1];
            const byteCharacters = atob(base64Content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const extension = values[0].split(';')[0].split('/')[1];
            const blob = new Blob([byteArray], { type: contentType });
            const fileName = `${listdata.template_name}.${extension}`;
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.target = '_blank';
            a.rel = 'noopener noreferrer';
            a.click();
        });
    };
    onClickCloneTemplate = arg => {
        const listdata = arg;
        window.localStorage.setItem("current_sample_id", listdata.id);
        window.localStorage.setItem("current_sample_template_name", listdata.template_name);
        this.setState({ redirect: "/template/documents/sales/welcome-letter/sample-clone" });
    }
    render() {
        const isCreatePermission = get_user_permissions('document-templates-create')
        const listdata = this.state.listdata;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        const columns = [
            {
                dataField: "id",
                text: "Id",
                sort: true,
            },
            {
                dataField: "template_name",
                text: "Template Name",
                sort: true,
            },
            {
                dataField: "template_type",
                text: "Template Type",
                sort: true,
            },
            {
                dataField: "updated_at",
                text: "Generated At",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {moment(listdata.updated_at).format('DD/MM/YYYY h:mma')}
                    </>

                ),
            },
            {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                formatter: (cellContent, listdata) => (
                    <>
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link
                                    to="#"
                                    onClick={() => this.onClickDownload(listdata)}
                                    className="btn btn-sm btn-soft-info" >
                                    <i className="mdi mdi-file-download-outline" id="downloadTemplate" />
                                    <UncontrolledTooltip placement="top" target="downloadTemplate">
                                        Download Template
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            {isCreatePermission ? <>
                                <li>
                                    <Link
                                        to="#"
                                        onClick={() => this.onClickCloneTemplate(listdata)}
                                        className="btn btn-sm btn-soft-primary" >
                                        <i className="mdi mdi-content-copy" id="cloneTemplate" />
                                        <UncontrolledTooltip placement="top" target="cloneTemplate">
                                            Clone Template
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </> : <></>}
                        </ul>
                    </>
                ),
            },
        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;

        //meta title
        document.title = "List Templates";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState({ recordDeleteID: row.id });
            }
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Welcome Letter Samples" breadcrumbItem="List Welcome Letter Sample Templates" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">List Welcome Letter Sample Templates</h5>
                                            </div>
                                        </CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.listData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.listData}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            // selectRow={selectRow}
                                                                            rowEvents={rowEvents}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            ref={this.node}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default ListWelcomeLetterSamples;
