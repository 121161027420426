import axios from "axios";

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

export const get_all_currencies = async () => {
    let response = await axios.get(baseURL + "/api/currency", config);
    return response.data.data;
}
export const update_currency = async (currency_id,name,abbreviation,symbol) => {
    let response = await axios.patch(baseURL + "/api/currency/"+currency_id,{
        "currency_name": name, 
        "currency_abbreviation":abbreviation,
        "currency_symbol": symbol,
      }, config);
    return response.data.data;
}
export const delete_currency = async (currency_id) => {
    let response = await axios.delete(baseURL + "/api/currency/"+currency_id, config);
    return response.data.data;
}