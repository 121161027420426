import axios from "axios";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
export const get_default_welcome_letter_list = async () => {
    let response = await axios.get(baseURL + "/api/default-welcome-letter-list", config);
    return response.data.data;
}
export const get_clone_welcome_letter_list = async () => {
    let response = await axios.get(baseURL + "/api/clone-welcome-letter-list", config);
    return response.data.data;
}
export const get_default_payment_summary_templates_list = async () => {
    let response = await axios.get(baseURL + "/api/default-payment-summary-list", config);
    return response.data.data;
}
export const get_clone_payment_summary_list = async () => {
    let response = await axios.get(baseURL + "/api/clone-payment-summary-list", config);
    return response.data.data;
}
export const get_default_statement_accounts_templates_list = async () => {
    let response = await axios.get(baseURL + "/api/default-statement-of-account-list", config);
    return response.data.data;
}
export const get_clone_statement_accounts_list = async () => {
    let response = await axios.get(baseURL + "/api/clone-statement-of-account-list", config);
    return response.data.data;
}
export const sample_file_download = async (id) => {
    let response = await axios.get(baseURL + "/api/get-file/" + id, config);
    return response.data.data;
}
export const clone_file_download = async (id) => {
    let response = await axios.get(baseURL + "/api/get-clone-file/" + id, config);
    return response.data.data;
}
export const get_default_receipt_list = async () => {
    let response = await axios.get(baseURL + "/api/default-receipt-list", config);
    return response.data.data;
}
export const get_clone_receipt_list = async () => {
    let response = await axios.get(baseURL + "/api/clone-receipt-list", config);
    return response.data.data;
}
export const get_document_template = async (id) => {
    let response = await axios.get(baseURL + "/api/document-templates/" + id, config);
    return response.data.data;
}
export const delete_clone_template = async (id) => {
    let response = await axios.delete(baseURL + "/api/document-templates/" + id, config);
    return response.data.data;
}
export const get_default_invoice_list = async () => {
    let response = await axios.get(baseURL + "/api/default-invoice-list", config);
    return response.data.data;
}
export const get_clone_invoice_list = async () => {
    let response = await axios.get(baseURL + "/api/clone-invoice-list", config);
    return response.data.data;
}
export const get_clone_payment_schedule_list = async () => {
    let response = await axios.get(baseURL + "/api/clone-payment-schedule-list", config);
    return response.data.data;
}
export const get_default_payment_schedule_templates_list = async () => {
    let response = await axios.get(baseURL + "/api/default-payment-schedule-list", config);
    return response.data.data;
}
export const get_all_welcome_letter_logs = async () => {
    let response = await axios.post(baseURL + "/api/document-template-activity-log", {
        'doc_sub_type': 'Welcome Letter'
    }, config);
    return response.data.data;
}
export const get_all_payment_summary_logs = async () => {
    let response = await axios.post(baseURL + "/api/document-template-activity-log", {
        'doc_sub_type': 'Payment Summary'
    }, config);
    return response.data.data;
}
export const get_all_statement_accounts_logs = async () => {
    let response = await axios.post(baseURL + "/api/document-template-activity-log", {
        'doc_sub_type': 'Statement Of Accounts'
    }, config);
    return response.data.data;
}
export const get_all_payment_schedule_logs = async () => {
    let response = await axios.post(baseURL + "/api/document-template-activity-log", {
        'doc_sub_type': 'Payment Schedule'
    }, config);
    return response.data.data;
}
export const get_all_invoice_logs = async () => {
    let response = await axios.post(baseURL + "/api/document-template-activity-log", {
        'doc_sub_type': 'Invoices'
    }, config);
    return response.data.data;
}
export const get_all_receipt_logs = async () => {
    let response = await axios.post(baseURL + "/api/document-template-activity-log", {
        'doc_sub_type': 'Receipt'
    }, config);
    return response.data.data;
}
export const get_all_templates_documents_logs = async () => {
    let response = await axios.get(baseURL + "/api/all-document-template-activity-log", config);
    return response.data.data;
}
export const template_variable_list = async (email_type) => {
    let response = await axios.post(baseURL + "/api/email-template-variable-list", {
        "email_type": email_type
    }, config);
    return response.data.data;
}
export const get_default_email_template_list = async (email_type) => {
    let response = await axios.post(baseURL + "/api/default-email-template", {
        "email_type": email_type
    }, config);
    return response.data.data;
}
export const get_default_email_template = async (id) => {
    let response = await axios.get(baseURL + "/api/default-email-template/" + id, config);
    return response.data.data;
}
export const subject_variable_list = async () => {
    let response = await axios.get(baseURL + "/api/email-template-subject-variable-list", config);
    return response.data.data;
}
export const get_clone_email_template_list = async (email_type) => {
    let response = await axios.post(baseURL + "/api/clone-email-template-list", {
        "email_type": email_type
    }, config);
    return response.data.data;
}
export const delete_clone_email_template = async (id) => {
    let response = await axios.delete(baseURL + "/api/email-templates/" + id, config);
    return response.data.data;
}
export const get_email_template = async (id) => {
    let response = await axios.get(baseURL + "/api/email-templates/" + id, config);
    return response.data.data;
}
export const email_template_test_email = async (email_type, id) => {
    let response = await axios.post(baseURL + "/api/email-template-test-email", {
        "email_type": email_type,
        "id": id
    }, config);
    return response.data.data;
}
export const get_all_email_template_activity_log = async (email_type) => {
    let response = await axios.post(baseURL + "/api/email-template-activity-log", {
        'email_type': email_type
    }, config);
    return response.data.data;
}
export const get_all_templates_email_logs = async () => {
    let response = await axios.get(baseURL + "/api/all-email-template-activity-log", config);
    return response.data.data;
}