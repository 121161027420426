import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Modal,
    FormGroup,
    Input,
    Label,
    Row,
    InputGroup,
    ModalBody,
    ModalFooter, UncontrolledAlert, UncontrolledTooltip
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Dropzone from "react-dropzone"
import axios from "axios";
import { showToast } from '../../UI/ui-toast'

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"
import { get_project_sizes, get_project_phases_blocks, get_blocks_against_phases, get_project_floors } from "../../Projects/api/projectApi"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import AddCustomerForm from "../../Customers/child.components/AddCustomerForm";
import AddPaymentPlanForm from "../../PaymentPlans/child.components/AddPaymentPlanForm";

import { get_all_property_features_for_organisation } from "../../Catalogue/PropertyFeatures/api/propertyFeaturesApi";

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
var currency_id = 0;
var selected_property_id = null;
var paymentAmount = null;
var calculatedCountTotal = 0;
import { get_user_permissions } from "../../../helpers/custom_helper"
import { numberWithCommas } from "pages/UI/numeric-helper";
var addSalePermission;
/**
 * A class component for Add Sales module
 */
class AddSale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "",
            optionGroupProjects: [],
            optionGroupSizes: [],
            optionGroupPhases: [],
            optionGroupBlocks: [],
            optionGroupFloors: [],
            optionGroupFeatures: [],
            optionGroupPropertyTypes: [],
            optionGroupProperties: [],
            optionGroupCustomers: [],
            optionGroupSalesAgent: [],
            optionGroupProjectPaymentPlans: [],
            currencies: [],
            payment_types: {},
            propertyPrice: '',
            saleDate: [new Date()],
            paymentDate: [new Date()],
            todayDate: '',
            totalPrice: '',
            discountPrice: '',
            customers: [],
            features: [],
            selectedFiles: [],
            totalCounter: 0,
            status: '',
            property_id: null,
            sales_agent_id: null,
            currency_id: 0,
            customer_id: null,
            project_payment_plan_id: 0,
            grandTotal: 0,
            paymentPlanTableIsShown: null,
            planMsgDisplay: 'none',
            paymentPlanDisplay: 'none',
            planMsgText: '',
            due_installment_date: `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`,
            statusArray: [
                {
                    "value": 'Confirmed',
                    "label": 'Confirmed'
                },
                {
                    "value": 'Cancelled',
                    "label": 'Cancelled'
                }
            ],
            redirect: null,
            visible: false,
            modal_standard: false,
            modal_customer: false,
            modal_backdrop: false,
            togModal: false,
            varyingModal: false,
            customerSelected: -1,
            saleagentSelected: -1,
            projectSelected: -1,
            propertySelected: -1,
            currencySelected: -1,
            paymentplanSelected: -1,
            templateCloneMessage: null,
            propertyData: null,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.onChangeValidation = this.onChangeValidation.bind(this);
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)

        this.tog_standard = this.tog_standard.bind(this)
        this.tog_customer_modal = this.tog_customer_modal.bind(this)
        this.tog_toggleModal = this.tog_toggleModal.bind(this)
        this.tog_varyingModal = this.tog_varyingModal.bind(this)
    }

    tog_standard() {
        if (get_user_permissions("payment-plan-create")) {
            this.setState(prevState => ({
                modal_standard: !prevState.modal_standard,
            }))
            this.removeBodyCss()
        } else {
            showToast("Failed", "You don't have permission to create a new payment plan.", "error")
        }

    }

    tog_toggleModal() {
        this.setState(prevState => ({
            togModal: !prevState.togModal,
            togModal1: false
        }))
        this.removeBodyCss()
    }

    tog_customer_modal() {
        if (get_user_permissions("customers-create")) {
            this.setState(prevState => ({
                modal_customer: !prevState.modal_customer,
            }))
            this.removeBodyCss()
        } else {
            showToast("Failed", "You don't have permission to create a new customer.", "error")
        }
    }

    removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    tog_varyingModal() {
        this.setState(prevState => ({
            varyingModal: !prevState.varyingModal,
        }))
        this.removeBodyCss()
    }

    show() {
        this.setState({ visible: true })
    }

    hide() {
        this.setState({ visible: false })
    }

    async getOptionsProjects() {
        const res = await axios.get(baseURL + "/api/project-list", config);
        const projData = res.data.data;

        const options = projData.map(d => ({
            "value": d.id,
            "label": d.name,
            "doc_template_message": d.doc_template_message
        }))
        this.setState({ optionGroupProjects: options })
    }
    async getOptionsSalesAgent() {
        const res = await axios.get(baseURL + "/api/sales-agent-list", config);
        const salesAgentData = res.data.data;

        const options = salesAgentData.map(d => ({
            "value": d.id,
            "label": d.full_name
        }))
        this.setState({ optionGroupSalesAgent: options })
    }
    async getOptionsCustomers() {
        const res = await axios.get(baseURL + "/api/customer-list-without-images", config);
        const customersData = res.data.data;

        const options = customersData.map(d => ({
            "value": d.id,
            "label": d.full_name
        }))
        this.setState({ optionGroupCustomers: options })
    }
    async getOptionsFeatures() {
        Promise.all([get_all_property_features_for_organisation()]).then((values) => {
            const options = values[0].map(d => ({
                "value": d.id,
                "label": d.feature_name
            }));

            this.setState({ optionGroupFeatures: options });
        });
    }

    async getProjectDetail(project_id) {
        this.setState({ paymentPlanDisplay: 'none' });
        const properties = await axios.get(baseURL + "/api/get-available-properties-against-project/" + project_id, config);
        const propertiesData = properties.data.data;
        const optionsProperties = propertiesData.map(d => ({
            "value": d.id,
            "label": d.property_name
        }))
        this.setState({ optionGroupProperties: optionsProperties })
    }
    async getProjectPaymentPlans(project_id) {
        this.setState({ optionGroupProjectPaymentPlans: [] });
        var selectedCurrency = this.state.currency_id;
        if (selectedCurrency == 0) {
            return false;
        }
        const res = await axios.get(baseURL + "/api/project-payment-plan/" + project_id, config);
        if (res.status === 200) {
            var paymentPlans = res.data.data;
            if (paymentPlans.length !== undefined) {
                const options = paymentPlans.map(d => ({
                    "value": d.id,
                    "label": d.name
                }));
                this.setState({ optionGroupProjectPaymentPlans: options })
                this.setState({ planMsgDisplay: 'none' });
                this.setState({ paymentPlanDisplay: 'block' });
                this.setState({ planMsgText: '' });
            }
            else {
                this.setState({ planMsgDisplay: 'block' });
                this.setState({ paymentPlanDisplay: 'none' });
                this.setState({ planMsgText: 'No payment plan found for this project.' });
                showToast("", 'No payment plan found for this project.', "info")
            }
        }
    }
    async getCurrenciesDataFromProperties(properties_id) {
        const currencies = await axios.get(baseURL + "/api/currencies-with-properties/" + properties_id, config);
        const currenciesData = currencies.data.data;
        const optionsCurrencies = currenciesData.map(d => ({
            "value": d.id,
            "label": d.currency_name
        }))
        this.setState({ currencies: optionsCurrencies })
    }
    async getPaymentPlanDetails(payment_plan_id) {
        const res = await axios.get(baseURL + "/api/payment-plan-details/" + payment_plan_id, config);
        var paymenTypes = res.data.data;

        this.setState({ payment_types: paymenTypes })
        this.setState({ totalCounter: paymenTypes.payment_types.length })
        if (res.status === 200) {
            setTimeout(() => {
                this.setGrandTotal()
            }, 2000);
        }
    }
    async getCurrencyPrice(c_id) {
        this.setState({ paymentPlanDisplay: 'none' });

        if (currency_id == null) {
            // console.log("EMPTY");
        } else {
            const price = await axios.get(baseURL + "/api/get-property-price/" + selected_property_id + "/" + c_id, config);
            if (price.status == 200) {
                const priceData = price.data.data;
                console.log(priceData);
                this.setState({ propertyData: priceData })
                this.setState({ propertyPrice: priceData?.with_feature_price })
                this.setState({ totalPrice: priceData?.with_feature_price });
                this.getProjectPaymentPlans(this.state.project_id);
            }
        }
    }
    getOptionsFloors(floors) {
        const options = floors.map(d => ({
            "value": d.id,
            "label": d.name
        }))
        this.setState({ optionGroupFloors: options })
    }
    getOptionsSizes(sizes) {
        const options = sizes.map(d => ({
            "value": d.id,
            "label": d.name
        }))
        this.setState({ optionGroupSizes: options })
    }
    getOptionsPhases(phases) {
        const options = phases.map(d => ({
            "value": d.id,
            "label": d.name
        }))
        this.setState({ optionGroupPhases: options })
    }
    getBlocksAgainstPhases(phase_id) {
        Promise.all([get_blocks_against_phases(phase_id)]).then((values) => {
            let data = values[0];
            const options = data.map(d => ({
                "value": d.id,
                "label": d.name
            }))
            this.setState({ optionGroupBlocks: options })
        });

    }
    componentDidMount() {
        this.getOptionsSalesAgent();
        this.getOptionsCustomers();
        this.getOptionsProjects();
        this.getOptionsFeatures();

        this.setState({ todayDate: new Date() });
    }
    checkValidation = () => {
        if (this.state.saleagentSelected != 1) {
            this.setState({ saleagentSelected: 0 })
        }
        if (this.state.customerSelected != 1) {
            this.setState({ customerSelected: 0 })
        }
        if (this.state.projectSelected != 1) {
            this.setState({ projectSelected: 0 })
        }
        if (this.state.projectSelected != 1) {
            this.setState({ projectSelected: 0 })
        }
        if (this.state.currencySelected != 1) {
            this.setState({ currencySelected: 0 })
        }
        if (this.state.paymentplanSelected != 1) {
            this.setState({ paymentplanSelected: 0 })
        }
    }

    handleSelectGroupSalesAgent = selectedGroupSalesAgent => {
        this.setState({ saleagentSelected: 1 })
        this.setState({ selectedGroupSalesAgent })
        this.setState({ sales_agent_id: selectedGroupSalesAgent.value })
    }
    handleSelectGroupCustomers = selectedGroupCustomers => {
        this.setState({ customerSelected: 1 });
        this.setState({ selectedGroupCustomers });
        var customers = [];
        for (var i = 0; i < selectedGroupCustomers.length; i++) {
            selectedGroupCustomers[i].customer_id = selectedGroupCustomers[i].value;
            customers.push({ "customer_id": selectedGroupCustomers[i].customer_id })
        }
        this.setState({ customers: customers });
    }
    handleSelectGroupProject = selectedGroupProject => {
        this.setState({ paymentPlanDisplay: 'none' })
        this.setState({ templateCloneMessage: selectedGroupProject.doc_template_message })
        addSalePermission = selectedGroupProject.doc_template_message;
        this.setState({ projectSelected: 1 })
        this.setState({ selectedGroupProject })
        this.getProjectDetail(selectedGroupProject.value)
        this.getProjectPaymentPlans(selectedGroupProject.value)
        this.setState({ project_id: selectedGroupProject.value })
        this.setState({ selectedGroupProperties: null })
        this.setState({ selectedGroupProjectCurrencies: null })
        this.setState({ selectedGroupSaleStatus: null })
        this.setState({ selectedGroupProjectPaymentPlans: null })
        this.setState({ paymentPlanTableIsShown: null })
        this.setState({ totalPrice: 0 })
        this.setState({ discountPrice: 0 })
        this.setState({ propertyPrice: 0 })
        currency_id = null;
    }
    handleSelectGroupProjectPaymentPlans = selectedGroupProjectPaymentPlans => {
        this.setState({ paymentplanSelected: 1 });
        this.setState({ selectedGroupProjectPaymentPlans });
        this.getPaymentPlanDetails(selectedGroupProjectPaymentPlans.value);
        this.setState({ project_payment_plan_id: selectedGroupProjectPaymentPlans.value });
        this.setState({ grandTotal: 0 });
        this.setState({ paymentPlanTableIsShown: 1 });
    }
    handleSelectGroupProperties = selectedGroupProperties => {
        this.setState({ propertySelected: 1 });
        this.setState({ selectedGroupProperties });
        selected_property_id = selectedGroupProperties.value;
        this.getCurrenciesDataFromProperties(selectedGroupProperties.value)
        this.getCurrencyPrice(currency_id);
        this.setState({ discountPrice: 0 });
        this.setState({ totalPrice: 0 });
        this.setState({ property_id: selectedGroupProperties.value })
    }
    handleSelectGroupProjectCurrencies = selectedGroupProjectCurrencies => {
        this.setState({ currencySelected: 1 });
        this.setState({ selectedGroupProjectCurrencies });
        currency_id = selectedGroupProjectCurrencies.value;
        this.getCurrencyPrice(selectedGroupProjectCurrencies.value);
        this.setState({ paymentPlanTableIsShown: null });
        this.setState({ selectedGroupProjectPaymentPlans: null });
        this.setState({ currency_id: selectedGroupProjectCurrencies.value });
        this.setState({ discountPrice: 0 });
        this.setState({ totalPrice: 0 });
        this.setState({ paymentPlanDisplay: 'block' });
    }
    /*
    handleSelectGroupSaleStatus = selectedGroupSaleStatus => {
        this.setState({ selectedGroupSaleStatus })
        console.log(selectedGroupSaleStatus);
        this.setState({ status: selectedGroupSaleStatus.value })
    }
    */

    handleAmountChange(evt, rowCounter) {
        var occurance_val = document.getElementById('occurance-' + rowCounter).value;
        var amt_val = document.getElementById('amount-' + rowCounter).value;
        var total_val = (parseFloat(occurance_val) * parseFloat(amt_val)).toFixed(2);
        document.getElementById('total-' + rowCounter).innerHTML = total_val;
        //var total = document.getElementById('total');
        var nTotalCount = document.getElementById('totalCount').value;
        var newVal = 0;
        for (var i = 0; i < nTotalCount; i++) {
            var occurance_val = document.getElementById('occurance-' + i).value;
            var amt_val = document.getElementById('amount-' + i).value;
            newVal = newVal + (occurance_val * amt_val);
        }
        this.setState({ grandTotal: (newVal).toFixed(2) });
    }

    setGrandTotal() {

        var nTotalCount = document.getElementById('totalCount').value;
        var newVal = 0;
        for (var i = 0; i < nTotalCount; i++) {
            var occurance_val = document.getElementById('occurance-' + i).value;
            var amt_val = document.getElementById('amount-' + i).value;
            newVal = newVal + (occurance_val * amt_val);
        }
        this.setState({ grandTotal: newVal.toFixed(2) });
    }

    priceHandler(e) {
        var price = e.target.value;
        var total = Number(price - this.state.discountPrice).toFixed(2);
        this.setState({ totalPrice: total })
        this.setState({ propertyPrice: price })
    }

    discountHandler(e) {
        var discount = e.target.value;
        var total = Number(this.state.propertyPrice - discount).toFixed(2);
        this.setState({ totalPrice: total })
        this.setState({ discountPrice: discount })
    }

    picks = () => {
        this.setState({ pick: new Date().toLocaleString() + "" })
    }
    getDateFormateMethod = (myDate) => {
        var today = myDate
        const dd = today.getDate().toString()
        const mm = today.getMonth() + 1
        const yyyy = today.getFullYear()
        const fromate_date = (today = yyyy + "-" + mm + "-" + dd)
        return fromate_date
    }

    callDelayedFunc = () => {
        this.getOptionsCustomers();
        this.tog_customer_modal();
    }
    handleSubmit(e) {
        var validationMsg = '';
        var validationFlag = 1;
        e.preventDefault();

        if (e.target.chkCustomerForm != undefined) {
            if (e.target.chkCustomerForm.value == 0) {
                return false;
            }
            //setTimeout(this.callDelayedFunc(), 5000);
            setTimeout(() => {
                this.getOptionsCustomers();
                this.tog_customer_modal();
            }, 2000);
        }
        if (e.target.chkPaymentPlanForm != undefined) {
            if (e.target.chkPaymentPlanForm.value == 0) {
                return false;
            }
            //setTimeout(this.callDelayedFunc(), 5000);
            var currentProjId = this.state.project_id;
            setTimeout(() => {
                this.getProjectPaymentPlans(currentProjId);
                this.tog_standard();
            }, 2000);
            return false;
        }
        if (e.target.chkSaleForm == undefined) {
            return false;
        }

        var payment_type_array = []
        var nTotalCount = document.getElementById('totalCount').value;
        var newVal = 0;

        for (var i = 0; i < nTotalCount; i++) {
            var occurance_val = document.getElementById('occurance-' + i).value;
            var payment_type_id = document.getElementById('payment-type-id-' + i).value;
            var currency_id = document.getElementById('currency-' + i).value;
            var amt_val = document.getElementById('amount-' + i).value;

            var date_val = document.getElementById('date-' + i).value;
            // Split the date string by '/'
            var dateParts = date_val.split('/');

            // Format the date as "DD-MM-YYYY"
            var formattedDate = dateParts[0] + '-' + dateParts[1] + '-' + dateParts[2];

            console.log(formattedDate);
            payment_type_array.push({ "payment_type_id": payment_type_id, "currency_id": currency_id, "no_of_recurrences": occurance_val, "amount": amt_val, "due_date": formattedDate })
        }

        var formatedDate = this.getDateFormateMethod(this.state.saleDate[0]);

        if (this.state.payment_types.payment_types) {
            for (let i = 0; i < this.state.payment_types.payment_types.length; i++) {
                var date = document.getElementById('date-' + i).value;
                if (date == '') {
                    showToast("Failed", "Please fill the due date fields", "error");
                    return false;
                }
            }
        }
        if (this.state.project_id == undefined) {
            return false;
        }
        if (this.state.property_id == "") {
            return false;
        }
        if (this.state.currency_id == "") {
            return false;
        }
        if (this.state.currencySelected == 0 || this.state.paymentplanSelected == 0 || this.state.customerSelected == 0 || this.state.projectSelected == 0 || this.state.propertySelected == 0) {
            showToast("Failed", "Please fill the required fields.", "error");
        }
        else {
            axios.post(baseURL + "/api/sales", {
                "status": "Confirmed",
                'sale_date': formatedDate,
                'sale_price': this.state.propertyPrice,
                'sale_discount': this.state.discountPrice == "" ? 0.00 : this.state.discountPrice,
                'sale_total': this.state.totalPrice,
                'offer_detail': e.target.offerDetails.value,
                'notes': e.target.notes.value,
                'optional_extras':e.target.optionalExtras.value,
                'property_id': this.state.property_id,
                'sales_agent_id': this.state.sales_agent_id,
                'currency_id': this.state.currency_id,
                'customer_id': this.state.customers,
                'payment_plan_id': this.state.project_payment_plan_id,
                'payment_types': payment_type_array
            }, config)
                .then((response) => {
                    showToast("Success", "Sales Added Successfully", "success");
                    this.setState({ redirect: "/sales/list" });
                    console.log(response);
                })
                .catch((error) => {
                    showToast("Failed", error.response.data.message, "error")
                    console.log(error);
                });
        }
    }

    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        );
        this.setState({ selectedFiles: files });
    }

    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }
    // Add a method to calculate the minimum date for selected payment plans
    calculateMinDate(selectedDate) {
        if (!selectedDate) {
            return null;
        }
        const minDate = new Date(selectedDate);
        minDate.setDate(minDate.getDate() + 15); // Add 15 days to the selected date
        return minDate;
    }
    render() {
        //meta title
        document.title = "Add Sale";
        const { validation, propertyData } = this.state;
        const { selectedGroupSizes } = this.state;
        const { selectedGroupProject } = this.state;
        const { selectedGroupPhases } = this.state;
        const { selectedGroupBlocks } = this.state;
        const { selectedGroupFloors } = this.state;
        const { selectedGroupFeatures } = this.state;
        const { selectedGroupPropertyTypes } = this.state;
        const { selectedGroupSalesAgent } = this.state;
        const { selectedGroupCustomers } = this.state;
        const { selectedGroupProperties } = this.state;
        const { selectedGroupProjectCurrencies } = this.state;
        const { selectedGroupProjectPaymentPlans } = this.state;
        const { getOptionsCustomers } = this.getOptionsCustomers;
        const { tog_standard } = this.tog_standard;
        const { tog_customer_modal } = this.tog_customer_modal;
        const { pick } = this.state
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Sales" breadcrumbItem="Add Sale" parentLink="/sales/list" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                // firstName: (this.state && this.state.firstName) || "",
                                            }}
                                            validationSchema={Yup.object().shape({
                                                // firstName: Yup.string().required(
                                                //     "User first name is required!"
                                                // ),
                                            })}
                                        // onSubmit={values => {
                                        //     console.log(values);
                                        //     var formatedDate = this.getDateFormateMethod(this.state.saleDate[0])
                                        //     console.log(formatedDate);
                                        //     console.log(this.state.saleDate);
                                        //     console.log(selectedGroupSalesAgent.value);
                                        //     console.log(selectedGroupProject.value);
                                        //     console.log(selectedGroupProperties.value);
                                        //     console.log(selectedGroupProjectCurrencies.value);
                                        //     console.log(this.state.propertyPrice);
                                        //     console.log(this.state.discountPrice);
                                        //     console.log(this.state.totalPrice);
                                        //     console.log(this.state.customers);

                                        //     // axios.post(baseURL + "/api/sales", {
                                        //     //     "status": selectedGroupSaleStatus.value,
                                        //     //     'sale_date': formatedDate,
                                        //     //     'sale_price': this.state.propertyPrice,
                                        //     //     'sale_discount': this.state.discountPrice,
                                        //     //     'sale_total': this.state.totalPrice,
                                        //     //     'offer_detail': values.offerDetails,
                                        //     //     'notes': values.notes,
                                        //     //     'property_id': selectedGroupProperties.value,
                                        //     //     'sales_agent_id': selectedGroupSalesAgent.value,
                                        //     //     'currency_id': selectedGroupProjectCurrencies.value,
                                        //     //     'customer_id': this.state.customers
                                        //     // }, config)
                                        //     //     .then((response) => {
                                        //     //         showToast("Sales", "Sales Added Successfully", "success");
                                        //     //         this.setState({ redirect: "/sales/list" });
                                        //     //         console.log(response);
                                        //     //     })
                                        //     //     .catch((error) => {
                                        //     //         showToast("Sales", "Sales Not Added Successfully", "error")
                                        //     //         console.log(error);
                                        //     //     });
                                        // }}
                                        >
                                            {({ errors, status, touched }) => (
                                                <Form className="needs-validation" onSubmit={this.handleSubmit} id="frmAddSale">
                                                    {this.state.templateCloneMessage ?
                                                        <UncontrolledAlert color="danger" role="alert">
                                                            {this.state.templateCloneMessage} &nbsp;&nbsp;&nbsp;
                                                            <Link
                                                                to="/templates/documents-templates/sales-documents-tabs-view"
                                                                className="btn btn-sm btn-info"
                                                            >
                                                                <i className="mdi mdi-arrow-right" id="templatetooltip" />
                                                                <UncontrolledTooltip placement="top" target="templatetooltip">
                                                                    Clone Templates
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                        </UncontrolledAlert>
                                                        :
                                                        null
                                                    }
                                                    <Field name="chkSaleForm" value="1" type="hidden" />
                                                    <Row>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex ">
                                                                <Label className="col-md-2 col-form-label" htmlFor="selectDate">Date*</Label>

                                                                <div className="docs-datepicker col-md-10">
                                                                    <InputGroup>
                                                                        <Flatpickr
                                                                            className="form-control d-block"
                                                                            placeholder="Pick a date"
                                                                            value={this.state.todayDate}
                                                                            options={{
                                                                                // minDate: "today",
                                                                                enableTime: false,
                                                                                dateFormat: "d/m/Y",
                                                                            }}
                                                                            onChange={date => {
                                                                                this.setState({ saleDate: date })
                                                                            }}
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                disabled
                                                                            >
                                                                                <i
                                                                                    className="fa fa-calendar"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </button>
                                                                        </div>
                                                                    </InputGroup>
                                                                    <div className="docs-datepicker-container" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="selectType">Customers*</Label>
                                                                <div className="col-md-8">
                                                                    <Select
                                                                        name="selectType"
                                                                        isMulti={true}
                                                                        value={selectedGroupCustomers}
                                                                        onChange={this.handleSelectGroupCustomers}
                                                                        options={this.state.optionGroupCustomers}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                    {/* {this.state.customerSelected == 0 ? <>
                                                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Customer is not selected, please select first</p>
                                                                    </> : <></>
                                                                    } */}
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div>
                                                                        <button type="button" onClick={this.tog_customer_modal} className="btn btn-success" data-toggle="modal" data-target="#customerModal">Add New Customer</button>
                                                                        <Modal size="lg" isOpen={this.state.modal_customer} toggle={this.tog_customer_modal}>
                                                                            <div className="modal-header">
                                                                                <h5 className="modal-title mt-0" id="customerModalLabel">Add New Customer</h5>
                                                                                <button type="button"
                                                                                    onClick={() =>
                                                                                        this.setState({ modal_customer: false })
                                                                                    }
                                                                                    className="close"
                                                                                    data-dismiss="modal"
                                                                                    aria-label="Close"
                                                                                >
                                                                                    <span aria-hidden="true">&times;</span>
                                                                                </button>
                                                                            </div>
                                                                            <div className="modal-body">
                                                                                <AddCustomerForm getOptionsCustomers={this.getOptionsCustomers} tog_customer_modal={this.tog_customer_modal} />
                                                                            </div>
                                                                            <div className="modal-footer">
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={this.tog_customer_modal}
                                                                                    className="btn btn-secondary"
                                                                                    data-dismiss="modal"
                                                                                >
                                                                                    Close
                                                                                </button>
                                                                                {/*<button
                                                                              type="button"
                                                                              className="btn btn-primary"
                                                                            >
                                                                              Save changes
                                                                            </button>*/}
                                                                            </div>
                                                                        </Modal>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="salesAgent">Sales Agent</Label>
                                                                <div className="col-md-10">
                                                                    <Select
                                                                        name="salesAgent"
                                                                        value={selectedGroupSalesAgent}
                                                                        onChange={this.handleSelectGroupSalesAgent}
                                                                        options={this.state.optionGroupSalesAgent}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                    {/* {this.state.saleagentSelected == 0 ? <>
                                                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Sales Agent is not selected, please select first</p>
                                                                    </> : <></>
                                                                    } */}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="selectProject">Project*</Label>
                                                                <div className="col-md-10">
                                                                    <Select
                                                                        id="selectProject"
                                                                        name="selectProject"
                                                                        value={selectedGroupProject}
                                                                        onChange={this.handleSelectGroupProject}
                                                                        options={this.state.optionGroupProjects}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                    {this.state.projectSelected == 0 ? <>
                                                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Project is not selected, please select first</p>
                                                                    </> : <></>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="selectProperty">Property*</Label>
                                                                <div className="col-md-10">
                                                                    <Select
                                                                        name="selectProject"
                                                                        value={selectedGroupProperties}
                                                                        onChange={this.handleSelectGroupProperties}
                                                                        options={this.state.optionGroupProperties}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                    {this.state.propertySelected == 0 ? <>
                                                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Property is not selected, please select first</p>
                                                                    </> : <></>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="selectCurrency">Currency*</Label>
                                                                <div className="col-md-10">
                                                                    <Select
                                                                        name="selectCurrency"
                                                                        value={selectedGroupProjectCurrencies}
                                                                        onChange={this.handleSelectGroupProjectCurrencies}
                                                                        options={this.state.currencies}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                    {this.state.currencySelected == 0 ? <>
                                                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Currency is not selected, please select first</p>
                                                                    </> : <></>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        {false ? <></> : <>
                                                            <Col md="12">
                                                                <div className="mb-3 row d-flex select2-container">
                                                                    <Label className="col-md-2 col-form-label" htmlFor="price">Price*</Label>
                                                                    <div className="col-md-4">
                                                                        <Field
                                                                            name="price"
                                                                            placeholder="Price"
                                                                            value={this.state.propertyPrice}
                                                                            //  onChange={(e) => this.setState({ propertyPrice: e.target.value })}
                                                                            onChange={(e) => this.priceHandler(e)}
                                                                            type="number"
                                                                            required
                                                                            className={
                                                                                "form-control"
                                                                            }
                                                                            onBlur={(e) => {
                                                                                if (e.target.value.trim() === "") {
                                                                                    e.target.value = ""; // Empty value stays empty
                                                                                } else {
                                                                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                                                                    this.setState({ propertyPrice: formattedValue })
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <Label className="col-md-2 col-form-label" htmlFor="discount">Discount</Label>
                                                                    <div className="col-md-4">
                                                                        <Field
                                                                            name="discount"
                                                                            placeholder="Discount"
                                                                            type="number"

                                                                            value={this.state.discountPrice}
                                                                            onChange={(e) => this.discountHandler(e)}
                                                                            className={
                                                                                "form-control"
                                                                            }
                                                                            onBlur={(e) => {
                                                                                if (e.target.value.trim() === "") {
                                                                                    e.target.value = 0.00; // If empty or non-numeric input, set it to 0
                                                                                } else {
                                                                                    const parsedValue = parseFloat(e.target.value);
                                                                                    if (isNaN(parsedValue) || parsedValue < 0) {
                                                                                        // If not a valid number or less than 0, set it to 0
                                                                                        e.target.value = 0.00;
                                                                                    } else {
                                                                                        const formattedValue = parsedValue.toFixed(2);
                                                                                        this.setState({ discountPrice: formattedValue });
                                                                                        e.target.value = formattedValue;
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </>}
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="total">Total*</Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="total"
                                                                        placeholder="Total"
                                                                        value={this.state.totalPrice}
                                                                        type="number"
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="offerDetails">Offer Details</Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        id="offer-detail"
                                                                        name="offerDetails"
                                                                        placeholder="Offer Details"
                                                                        as="textarea"
                                                                        rows={3}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="notes">Notes</Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        id="notes"
                                                                        name="notes"
                                                                        placeholder="Write your notes..."
                                                                        as="textarea"
                                                                        rows={3}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="optionalExtras">Optional Extras</Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        id="optionalExtras"
                                                                        name="optionalExtras"
                                                                        placeholder="Write your optional extras..."
                                                                        as="textarea"
                                                                        rows={5}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12" style={{ "display": this.state.paymentPlanDisplay }}>
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="selectPaymentPlan">Project Payment Plans*</Label>
                                                                <div className="col-md-8">
                                                                    <Select
                                                                        name="selectPaymentPlan"
                                                                        value={selectedGroupProjectPaymentPlans}
                                                                        onChange={this.handleSelectGroupProjectPaymentPlans}
                                                                        options={this.state.optionGroupProjectPaymentPlans}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                    {/* {this.state.paymentplanSelected == 0 ? <>
                                                                        <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Payment Plan is not selected, please select first</p>
                                                                    </> : <></>
                                                                    } */}
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <div>
                                                                        <button type="button" onClick={this.tog_standard} className="btn btn-success" data-toggle="modal" data-target="#myModal">New Payment Plan</button>
                                                                        <Modal size="lg" isOpen={this.state.modal_standard} toggle={this.tog_standard}>
                                                                            <div className="modal-header">
                                                                                <h5 className="modal-title mt-0" id="myModalLabel">Add New Payment Plan</h5>
                                                                                <button type="button"
                                                                                    onClick={() =>
                                                                                        this.setState({ modal_standard: false })
                                                                                    }
                                                                                    className="close"
                                                                                    data-dismiss="modal"
                                                                                    aria-label="Close"
                                                                                >
                                                                                    <span aria-hidden="true">&times;</span>
                                                                                </button>
                                                                            </div>
                                                                            <div className="modal-body">
                                                                                <AddPaymentPlanForm tog_standard={this.tog_standard} />
                                                                            </div>
                                                                            <div className="modal-footer">
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={this.tog_standard}
                                                                                    className="btn btn-secondary"
                                                                                    data-dismiss="modal"
                                                                                >
                                                                                    Close
                                                                                </button>
                                                                                {/*<button
                                                                              type="button"
                                                                              className="btn btn-primary"
                                                                            >
                                                                              Save changes
                                                                            </button>*/}
                                                                            </div>
                                                                        </Modal>
                                                                    </div>
                                                                </div>
                                                                <p className="alert alert-danger" style={{ "margin": "5px 0 5px 0", "display": this.state.planMsgDisplay }}>{this.state.planMsgText}</p>
                                                            </div>
                                                        </Col>
                                                        <Field
                                                            name='totalCount'
                                                            id={'totalCount'}
                                                            value={this.state.totalCounter}
                                                            type="hidden"
                                                            required="required"
                                                        />
                                                        {this.state.paymentPlanTableIsShown != null ? <>
                                                            <Col md="12">
                                                                <div className="mb-3 row d-flex select2-container">
                                                                    {/* <Label className="col-md-2 col-form-label" htmlFor="">Label</Label> */}
                                                                    {/* {this.state.payment_types.payment_types?.map((d, i) => */}
                                                                    <div className="col-md-10 mb-2" style={{ "marginLeft": "1.5rem" }}>

                                                                        <table className="table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">Name</th>
                                                                                    <th scope="col">Occurances*</th>
                                                                                    <th scope="col">Amount* ({propertyData?.currency_symbol})</th>
                                                                                    <th scope="col">Total ({propertyData?.currency_symbol})</th>
                                                                                    <th scope="col">Due/Installment Date*</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.payment_types.payment_types?.map((d, i) =>
                                                                                    <tr key={d.id}>
                                                                                        <td scope="row">{d.payment_type_name}</td>
                                                                                        <td scope="row">
                                                                                            <div className="col-md-4 d-flex">
                                                                                                <Field
                                                                                                    name='occurance[]'
                                                                                                    id={'occurance-' + i}
                                                                                                    placeholder="0.00"
                                                                                                    defaultValue={d.no_of_recurrences}
                                                                                                    onChange={(e) => this.handleAmountChange(e, i)}
                                                                                                    type="text"
                                                                                                    // pattern="[0-9]+([\.,][0-9]+)?"
                                                                                                    style={{ textAlign: 'right' }}
                                                                                                    required="required"
                                                                                                    className={"form-control"}
                                                                                                />
                                                                                                <Field
                                                                                                    name='payment-type-id'
                                                                                                    id={'payment-type-id-' + i}
                                                                                                    defaultValue={d.payment_type_id}
                                                                                                    type="hidden"
                                                                                                    // pattern="[0-9]+([\.,][0-9]+)?"
                                                                                                    style={{ textAlign: 'right' }}
                                                                                                    className={"form-control"}
                                                                                                />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td scope="row">
                                                                                            {d.currencies?.map((currencies, j) =>
                                                                                                <>
                                                                                                    <div key={currencies.id} className="col-md-7 d-flex">
                                                                                                        {currency_id == currencies.currency_id ? <>
                                                                                                            <Field
                                                                                                                name="amount"
                                                                                                                placeholder="amount"
                                                                                                                id={"amount-" + i}
                                                                                                                defaultValue={currency_id === currencies.currency_id && !isNaN(parseFloat(currencies.amount))
                                                                                                                    ? parseFloat(currencies.amount).toFixed(2)
                                                                                                                    : ''}
                                                                                                                onChange={(e) => this.handleAmountChange(e, i)}
                                                                                                                type="number"
                                                                                                                required="required"
                                                                                                                step="0.01"
                                                                                                                className={
                                                                                                                    "form-control"
                                                                                                                }
                                                                                                                onBlur={(e) => {
                                                                                                                    if (e.target.value.trim() === "") {
                                                                                                                        e.target.value = ""; // Empty value stays empty
                                                                                                                        console.log(e.target.value);
                                                                                                                    } else {
                                                                                                                        const formattedValue = parseFloat(e.target.value).toFixed(2);
                                                                                                                        console.log(e.target.value);
                                                                                                                        console.log(formattedValue);
                                                                                                                        //   this.setState({discountPrice: formattedValue})
                                                                                                                        e.target.value = formattedValue;
                                                                                                                    }
                                                                                                                }}
                                                                                                            />

                                                                                                            <Field
                                                                                                                name="currency_id"
                                                                                                                placeholder="currency_id"
                                                                                                                id={"currency-" + i}
                                                                                                                defaultValue={currency_id == currencies.currency_id ? currencies.currency_id : ''}
                                                                                                                type="hidden"
                                                                                                                className={
                                                                                                                    "form-control"
                                                                                                                }
                                                                                                            />

                                                                                                        </>
                                                                                                            : ""}

                                                                                                    </div>
                                                                                                </>
                                                                                            )}
                                                                                        </td>
                                                                                        <td scope="row">
                                                                                            {d.currencies?.map((currencies, j) =>
                                                                                                <>
                                                                                                    <div key={currencies.id} className="col-md-7 d-flex">
                                                                                                        {currency_id == currencies.currency_id ? <>

                                                                                                            {/* <Field
                                                                                                                name="total"
                                                                                                                placeholder="total"
                                                                                                                id={"total-" + i}
                                                                                                                defaultValue={currency_id == currencies.currency_id ? (currencies.amount * d.no_of_recurrences) : ''}
                                                                                                                // onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
                                                                                                                type="number"
                                                                                                                className={
                                                                                                                    "form-control"
                                                                                                                }
                                                                                                            /> */}
                                                                                                            <Label className="col-form-label" id={"total-" + i} htmlFor="">{currency_id == currencies.currency_id ? (currencies.amount * d.no_of_recurrences).toFixed(2) : ''}</Label>

                                                                                                        </>
                                                                                                            : ""}

                                                                                                    </div>
                                                                                                </>
                                                                                            )}
                                                                                        </td>
                                                                                        <td scope="row">
                                                                                            <div className="col-md-10">
                                                                                                <Flatpickr
                                                                                                    value={pick}
                                                                                                    id={"date-" + i}
                                                                                                    className="form-control d-block"
                                                                                                    placeholder="Pick a date"
                                                                                                    options={{
                                                                                                        minDate: this.calculateMinDate(this.state.saleDate[0] ? this.state.saleDate[0] : this.state.todayDate),
                                                                                                        enableTime: false,
                                                                                                        dateFormat: "d/m/Y",
                                                                                                    }}
                                                                                                    onChange={date => {
                                                                                                        this.setState({ paymentDate: date })
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                                {
                                                                                    //this.setGrandTotal()
                                                                                }
                                                                                <tr>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                    <td>Grand Total:</td>
                                                                                    <div className="d-flex">{propertyData?.currency_symbol}{" "}&nbsp;<div id='total' htmlFor="">{numberWithCommas(this.state.grandTotal)}</div></div>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>

                                                                </div>
                                                            </Col>
                                                        </> : <></>}
                                                    </Row>
                                                    {this.state.project_payment_plan_id == 0 && this.state.currency_id != 0 ? <>
                                                        {addSalePermission != null ? <>
                                                            <Button color="primary" disabled className="float-end" >
                                                                Add Sale
                                                            </Button>
                                                        </> : <>
                                                            <Button color="primary" type="submit" onClick={this.checkValidation} className="float-end" >
                                                                Add Sale
                                                            </Button>
                                                        </>}
                                                    </> : <>
                                                    </>}
                                                    {this.state.project_payment_plan_id != 0 && this.state.totalPrice == this.state.grandTotal ? <>
                                                        {addSalePermission != null ? <>
                                                            <Button color="primary" disabled className="float-end" >
                                                                Add Sale
                                                            </Button>
                                                        </> : <>
                                                            <Button color="primary" type="submit" onClick={this.checkValidation} className="float-end" >
                                                                Add Sale
                                                            </Button>

                                                        </>}
                                                    </> : <>
                                                        {this.state.project_payment_plan_id != 0 ? <>
                                                            <div className="text-center">
                                                                <p className="text-danger">Property Total Price is not equal to Payment Plan Total.</p>
                                                            </div>
                                                        </> : <></>}
                                                    </>}
                                                </Form>
                                            )}
                                        </Formik>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

            </React.Fragment>
        );
    }
}
export default AddSale;
