import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
    GET_PROJECT_LIST,
    ADD_NEW_PROJECT_LIST,
    UPDATE_PROJECT_LIST,
    DELETE_PROJECT_LIST,
    GET_APPLY_PROJECT,
    DELETE_APPLY_PROJECT,
} from "./actionTypes"

import {
    getProjectListSuccess,
    getProjectListFail,
    addProjectListSuccess,
    addProjectListFail,
    updateProjectListSuccess,
    updateProjectListFail,
    deleteProjectListSuccess,
    deleteProjectListFail,
    getApplyProjectSuccess,
    getApplyProjectFail,
    deleteApplyProjectSuccess,
    deleteApplyProjectFail
} from "./actions"

//Include Both Helper File with needed methods
import {
    getProjectList,
    addNewProjectList,
    updateProjectList,
    deleteProjectList,
    getApplyProject,
    deleteApplyProject
} from "../../helpers/fakebackend_helper"

function* fetchProjectsList() {
    try {
        const response = yield call(getProjectList)
        yield put(getProjectListSuccess(response))
    } catch (error) {
        yield put(getProjectListFail(error))
    }
}

function* onAddNewProjectList({ payload: project }) {

    try {
        const response = yield call(addNewProjectList, project)

        yield put(addProjectListSuccess(response))
    } catch (error) {
        yield put(addProjectListFail(error))
    }
}

function* onUpdateProjectList({ payload: project }) {

    try {
        const response = yield call(updateProjectList, project)

        yield put(updateProjectListSuccess(response))
    } catch (error) {
        yield put(updateProjectListFail(error))
    }
}

function* onDeleteProjectList({ payload: project }) {
    try {
        const response = yield call(deleteProjectList, project)
        yield put(deleteProjectListSuccess(response))
    } catch (error) {
        yield put(deleteProjectListFail(error))
    }
}

function* OnGetApplyProject() {
    try {
        const response = yield call(getApplyProject)
        yield put(getApplyProjectSuccess(response))
    } catch (error) {
        yield put(getApplyProjectFail(error))
    }
}

function* OnDeleteApplyProject({ payload: data }) {
    try {
        const response = yield call(deleteApplyProject, data)
        yield put(deleteApplyProjectSuccess(response))
    } catch (error) {
        yield put(deleteApplyProjectFail(error))
    }
}

function* projectListSaga() {
    yield takeEvery(GET_PROJECT_LIST, fetchProjectsList)
    yield takeEvery(ADD_NEW_PROJECT_LIST, onAddNewProjectList)
    yield takeEvery(UPDATE_PROJECT_LIST, onUpdateProjectList)
    yield takeEvery(DELETE_PROJECT_LIST, onDeleteProjectList)
    yield takeEvery(GET_APPLY_PROJECT, OnGetApplyProject)
    yield takeEvery(DELETE_APPLY_PROJECT, OnDeleteApplyProject)
}

export default projectListSaga
