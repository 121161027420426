import React, { Component } from "react";
import PropTypes from "prop-types";
import CarouselPage from "../child.components/CarouselPage";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// import images
import logoPropertyLight from "../../../assets/images/Property-Appz-White-Logo-v1.png";
import logoPropertyDark from "../../../assets/images/Property-Appz-Logo-v1.0.png";
import user from "../../../assets/images/users/avatar-1.jpg";
import { Col, Container, Row, Alert, Label } from "reactstrap";
import { Link } from "react-router-dom";
// remove old import of secureLocalStorage "react-secure-storage";
import default_avatar from "../../../assets/images/avatar_01.png";
import axios from "axios";
import { showToast } from '../../UI/ui-toast'

const config = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
};
const baseURL_propertyAppz = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
import { fetchLogo } from '../../../store/logo/actions';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
class LockScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLoginData: [],
    };
  }
  componentDidMount() {
    this.props.fetchLogo();
    localStorage.removeItem('organisation_id');
    localStorage.removeItem('current_project_id');
    localStorage.removeItem('token');
    var personalData = secureLocalStorage.getItem("personalData")
    this.setState({ userLoginData: personalData.personalData })
  }
  async getUserData(user_id) {
    const res = await axios.get(baseURL + "/api/user-assign-permission/" + user_id, config);
    if (res.status === 200) {
      const data = res.data.data
      secureLocalStorage.setItem("userData", {
        data
      });
      // this.setState({ redirect: "/dashboard" });
      window.location.replace(secureLocalStorage.getItem('currentSession'));
      // window.location.replace('/properties/add');
    }

  }
  async getPropertyAppzUserData(user_id) {
    const res = await axios.get(baseURL_propertyAppz + "/api/propertyappz-user-assign-permission/" + user_id, config);
    if (res.status === 200) {
      const data = res.data.data
      secureLocalStorage.setItem("userData", {
        data
      });
      // this.setState({ redirect: "/dashboard" });
      window.location.replace(secureLocalStorage.getItem('currentSession'));
      // window.location.replace('/properties/add');
    }

  }
  async getAdminData(user_id) {
    const res = await axios.get(baseURL + "/api/platform-user-assign-permission/" + user_id, config);
    if (res.status === 200) {
      const data = res.data.data
      secureLocalStorage.setItem("userData", { data });
      // this.setState({ redirect: "/dashboard" });
      // window.location.replace('/platform/dashboard');
      window.location.replace(secureLocalStorage.getItem('currentSession'));
    }
  }
  render() {
    //meta title
    const { logoDark } = this.props;
    const { logoWhite } = this.props;
    document.title = "LockScreen | PropertyAppz";
    return (
      <React.Fragment>
        <div>
          <Container fluid className="p-0">
            <Row className="g-0">
              <CarouselPage />

              <Col xl={3}>
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5">
                        <Link to="dashboard" className="d-block auth-logo">
                          <img
                            src={logoDark ? logoDark : logoPropertyDark}
                            alt=""
                            height="40"
                            className="auth-logo-dark"
                          />
                          <img
                            src={logoWhite ? logoWhite : logoPropertyLight}
                            alt=""
                            height="40"
                            className="auth-logo-light"
                          />
                          {/* <img src={logoUrl} alt="" height="35" /> */}
                        </Link>
                      </div>
                      <div className="my-auto">
                        <div>
                          <h5 className="text-primary">Lock screen</h5>
                          <p className="text-muted">
                            Enter your password to unlock the screen!
                          </p>
                        </div>

                        <div className="mt-4">
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              password: (this.state && this.state.password) || "",
                            }}
                            validationSchema={Yup.object().shape({
                              password: Yup.string().required(
                                "Please Enter Your Password"
                              ),
                            })}
                            onSubmit={values => {
                              var endpoint;
                              var URL;
                              if (localStorage.getItem('adminUserLoginFlag')) {
                                endpoint = "/api/platformuserlogin"
                                URL = baseURL;
                              }
                              if (localStorage.getItem('organisationUserLoginFlag')) {
                                endpoint = "/api/login"
                                URL = baseURL;
                              }
                              if (localStorage.getItem('propertyAppzLoginFlag')) {
                                endpoint = "/api/propertyappz-user-login"
                                URL = baseURL_propertyAppz;
                              }
                              axios.post(URL + endpoint, {
                                "email": this.state.userLoginData.email,
                                "password": values.password
                              }, config)
                                .then((response) => {
                                  showToast("Success", "Proceeding to Great Things", "success");

                                  localStorage.setItem("organisation_id", response.data.data.user.organisation_id);
                                  let token = response.data.data.token;
                                  let personalData = response.data.data.user;
                                  localStorage.setItem("token", 'Bearer ' + token);
                                  secureLocalStorage.setItem("personalData", { personalData });
                                  config.headers.Authorization = 'Bearer ' + token;
                                  if (localStorage.getItem('adminUserLoginFlag')) {
                                    this.getAdminData(response.data.data.user.id);
                                  }
                                  if (localStorage.getItem('organisationUserLoginFlag')) {
                                    this.getUserData(response.data.data.user.id);
                                  }
                                  if (localStorage.getItem('propertyAppzLoginFlag')) {
                                    this.getPropertyAppzUserData(response.data.data.user.id)
                                  }
                                })
                                .catch((error) => {
                                  showToast("Failed", error.response.data.message, "error");
                                  console.error(error);
                                });


                            }}
                          >
                            {({ errors, status, touched }) => (
                              <Form className="form-horizontal">
                                {this.props.error && this.props.error ? (
                                  <Alert color="danger">
                                    {this.props.error}
                                  </Alert>
                                ) : null}

                                <div className="user-thumb text-center mb-4">
                                  <img
                                    src={this.state.userLoginData.picture ? this.state.userLoginData.picture : default_avatar}
                                    className="rounded-circle img-thumbnail avatar-md"
                                    alt="thumbnail"
                                  />
                                  <h5 className="font-size-15 mt-3">
                                    {this.state.userLoginData.full_name}
                                  </h5>
                                </div>

                                <div className="mb-3">
                                  <Label for="password" className="form-label">
                                    Password
                                  </Label>
                                  <Field
                                    name="password"
                                    placeholder="Enter password"
                                    type="password"
                                    className={
                                      "form-control" +
                                      (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>

                                <div className="text-end">
                                  {/* <Link to="/dashboard"> */}
                                  <button
                                    className="btn btn-primary w-md"
                                    type="submit"
                                  >
                                    {" "}
                                    Unlock{" "}
                                  </button>
                                  {/* </Link> */}
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>

                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()} <a href='https://propertyappz.com/' target='_blank' rel="noreferrer">Property Appz</a> + <a href='https://pentadmedia.com/' target='_blank' rel="noreferrer">Pentad Media</a>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

LockScreen.propTypes = {
  error: PropTypes.any,
  fetchLogo: PropTypes.func,
  logoUrl: PropTypes.shape({
    logo: PropTypes.string,
    icon: PropTypes.string,
    white_logo: PropTypes.string
  })
};
const mapStateToProps = (state) => {
  return {
    logoDark: state.Logo.logo.logoUrl?.logo,
    logoWhite: state.Logo.logo.logoUrl?.white_logo,
  };
};
export default connect(mapStateToProps, { fetchLogo })(withTranslation()(LockScreen));