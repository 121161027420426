import axios from "axios";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
export const get_all_lead_stages = async () => {
    let response = await axios.get(baseURL + "/api/lead-stages", config);
    return response.data.data;
}
export const update_lead_stage = async (lead_stage_id, name, order) => {
    let response = await axios.patch(baseURL + "/api/lead-stages/" + lead_stage_id, {
        "name": name,
        "order": order,
    }, config);
    return response.data.data;
}
export const delete_lead_stage = async (lead_stage_id) => {
    let response = await axios.delete(baseURL + "/api/lead-stages/" + lead_stage_id, config);
    return response.data.data;
}