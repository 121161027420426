import axios from "axios";
// import { Link, useHistory } from "react-router-dom";  
import { withRouter } from 'react-router-dom'

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

export const get_all_properties = async () => {
    let response = await axios.get(baseURL + "/api/properties", config);
    return response.data.data;
}
export const delete_property = async (property_id) => {
    let response = await axios.delete(baseURL + "/api/properties/" + property_id, config);
    return response.data.data;
}
export const get_current_property = async (property_id) => {
    let response = await axios.get(baseURL + "/api/properties/" + property_id, config);
    return response.data.data;
}
export const get_all_sales = async () => {
    let response = await axios.get(baseURL + "/api/sales", config);
    return response.data.data;
}
export const get_all_sales_list = async () => {
    let response = await axios.get(baseURL + "/api/sale-listing", config);
    return response.data.data;
}
export const delete_sale = async (sale_id) => {
    let response = await axios.delete(baseURL + "/api/sales/" + sale_id, config);
    return response.data.data;
}
export const get_current_sale = async (sale_id) => {
    let response = await axios.get(baseURL + "/api/sales/" + sale_id, config);
    return response.data.data;
}
export const get_current_sale_detail = async (sale_id) => {
    let response = await axios.get(baseURL + "/api/sale-details/" + sale_id, config);
    return response.data.data;
}
export const get_payment_against_sale = async (sale_id) => {
    let response = await axios.get(baseURL + "/api/payment-against-sale/" + sale_id, config);
    return response.data.data;
}
export const cancel_sale = async (sale_id) => {
    let response = await axios.post(baseURL + "/api/cancel-sale", {
        "sale_id": sale_id
    }, config);
    return response.data.data;
}
export const get_sale_log = async (sale_id) => {
    let response = await axios.get(baseURL + "/api/sale-activity-log/" + sale_id, config);
    return response.data.data;
}
export const get_all_sales_logs = async () => {
    let response = await axios.get(baseURL + "/api/all-sale-activity-log", config);
    return response.data.data;
}
export const get_all_cancelled_sales_logs = async () => {
    let response = await axios.get(baseURL + "/api/get-cancelled-sale", config);
    return response.data.data;
}
export const get_payments_log = async (sale_id) => {
    let response = await axios.get(baseURL + "/api/payment-activity-log-against-sale/" + sale_id, config);
    return response.data.data;
}
export const get_payment_log = async (payment_id) => {
    let response = await axios.get(baseURL + "/api/payment-activity-log/" + payment_id, config);
    return response.data.data;
}
export const sale_document_mail = async (sale_id) => {
    let response = await axios.post(baseURL + "/api/sale-document-mail/" + sale_id, {}, config);
    return response.data.data;
}
export const generate_welcome_letter_against_sale = async (sale_id) => {
    let response = await axios.post(baseURL + "/api/generate-welcome-letter/" + sale_id, {}, config);
    return response.data.data;
}
export const download_sale_document = async (sale_id, flag) => {
    let response = await axios.post(baseURL + "/api/sale-document-download/" + sale_id, {
        "type": flag
    }, config);
    return response.data.data;
}
export const generate_payment_schedule_against_sale = async (sale_id) => {
    let response = await axios.post(baseURL + "/api/generate-payment-schedule/" + sale_id, {}, config);
    return response.data.data;
}
export const generate_payment_summary_against_sale = async (sale_id) => {
    let response = await axios.post(baseURL + "/api/generate-payment-summary/" + sale_id, {}, config);
    return response.data.data;
}
export const generated_docs_detail = async (sale_id) => {
    let response = await axios.post(baseURL + "/api/get-generated-docs-details/" + sale_id, {}, config);
    return response.data.data;
}
export const generate_statement_accounts_against_sale = async (sale_id) => {
    let response = await axios.post(baseURL + "/api/generate-statement-of-accounts/" + sale_id, {}, config);
    return response.data.data;
}
export const send_payment_summary_email_against_sale = async (sale_id) => {
    let response = await axios.post(baseURL + "/api/payment-summary-mail/" + sale_id, {}, config);
    return response.data.data;
}
export const send_statement_of_account_email_against_sale = async (sale_id) => {
    let response = await axios.post(baseURL + "/api/statement-of-accounts-mail/" + sale_id, {}, config);
    return response.data.data;
}