import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
    Container, Row, Col, Card, CardBody,
    UncontrolledDropdown, DropdownToggle,
    DropdownMenu, DropdownItem, Label, FormGroup, Input, InputGroup, Button
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"
import { Formik, Field, Form, ErrorMessage } from "formik";

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
import { get_user_permissions } from "helpers/custom_helper";
const baseURL = secureLocalStorage.getItem("baseURL");
var leadsCreate, leadsView, leadsEdit, leadsDelete;
const onDragEnd = async (result, columns, setColumns, setLeadsData) => {
    var res_destination = result?.destination?.droppableId;
    var res_source = result?.draggableId
    res_destination = columns[res_destination].id
    // res_source = columns[res_source].id;
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
        const sourceColumn = columns[source.droppableId];
        const destColumn = columns[destination.droppableId];
        const sourceItems = [...sourceColumn.items];
        const destItems = [...destColumn.items];
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);

        // Update the columns state
        setColumns({
            ...columns,
            [source.droppableId]: {
                ...sourceColumn,
                items: sourceItems
            },
            [destination.droppableId]: {
                ...destColumn,
                items: destItems
            }
        });

        // Extract the lead ID and new lead stage ID from draggable and droppable IDs
        const leadId = removed.id;
        const newStageId = destination.droppableId; // Assuming the droppableId is the new lead stage ID

        // Call the changeLeadStage function
        await changeLeadStage(res_source, res_destination, setLeadsData);
    } else {
        // Your existing logic for reordering items within the same column...
    }
};
const changeLeadStage = async (leadId, newStageId, setLeadsData) => {
    try {
        const res = await axios.post(`${baseURL}/api/change-lead-stage/${leadId}`, {
            lead_stage_id: newStageId
        }, config);
        await getOptionsLeadsOutter(setLeadsData);
    } catch (error) {
        console.error('Error changing lead stage:', error);
    }
};
const getOptionsLeadsOutter = async (setLeadsData) => {
    try {
        const res = await axios.get(`${baseURL}/api/leads`, config);
        const data = res.data.data;
        setLeadsData(data);
        // Process the data here, maybe set it to state
    } catch (error) {
        console.error('Error fetching lead stages:', error);
        // Handle error gracefully
    }
}
function App() {
    const [columns, setColumns] = useState({});
    const [leadsData, setLeadsData] = useState([]);
    const [optionGroupLeadStages, setOptionGroupLeadStages] = useState([]);
    async function getOptionsLeads() {
        try {
            const res = await axios.get(`${baseURL}/api/leads`, config);
            const data = res.data.data;
            setLeadsData(data);
            // Process the data here, maybe set it to state
        } catch (error) {
            console.error('Error fetching lead stages:', error);
            // Handle error gracefully
        }
    }
    async function getOptionsLeadStages() {
        try {
            const res = await axios.get(`${baseURL}/api/lead-stage-listing`, config);
            const data = res.data.data;
            const columnsData = data.map(stage => ({
                id: stage.id.toString(),
                name: stage.name,
                items: []
            }));
            setOptionGroupLeadStages(columnsData);
        } catch (error) {
            console.error('Error fetching lead stages:', error);
            // Handle error gracefully
        }
    }

    useEffect(() => {
        getOptionsLeads();
        getOptionsCampaigns();
        getOptionsLeadStages();
        localStorage.removeItem('current_lead_id');
        leadsCreate = get_user_permissions('leads-create')
        leadsView = get_user_permissions('leads-view')
        leadsEdit = get_user_permissions('leads-edit')
        leadsDelete = get_user_permissions('leads-delete')
    }, []);
    useEffect(() => {
        if (!leadsData || leadsData.length === 0) return; // Check if leads data is available
        const columnsData = {};

        optionGroupLeadStages.forEach((stage) => {
            columnsData[stage.name.toLowerCase()] = {
                name: stage.name,
                items: [],
                id: stage.id
            };
        });

        console.log("Initial columns:", columnsData); // Debugging

        leadsData.forEach((card) => {
            if (card.lead_stage_name) { // Null check
                const status = card.lead_stage_name.toLowerCase();
                // Find the column corresponding to the current lead stage
                const columnToUpdate = columnsData[status];
                if (columnToUpdate) {
                    console.log("Updating column:", status); // Debugging
                    columnToUpdate.items.push({
                        id: card.id.toString(),
                        content: (
                            <Link to={`/lead/view`} onClick={() => localStorage.setItem('current_lead_id', card.id)}>
                                <div className="pb-1 task-list" id={`to-do-task-${card.id}`}>
                                    <div className="card task-box">
                                        <CardBody>
                                            <div>
                                                <p className="mb-1 text-muted">{card.campaign_name}</p>
                                            </div>
                                            <div>
                                                <p className="mb-1 text-muted">{card.lead_type_name}</p>
                                                <p className="mb-1 text-muted">{card.sales_agent_name}</p>
                                                <p className="mb-1 text-muted">{card.score}</p>
                                                <p className="mb-1 text-muted">{card.label}</p>
                                            </div>
                                            <div className="float-end ms-2">
                                                <span className="badge rounded-pill badge-soft-danger font-size-12" id={`task-status-${card.id}`}>{card.lead_stage_name}</span>
                                            </div>
                                        </CardBody>
                                    </div>
                                </div>
                            </Link>
                        )
                    });
                }
            }
        });
        setColumns(columnsData);
    }, [leadsData, optionGroupLeadStages]);

    function handleDateFilterChange(event) {
        if (event.target.value === 'allTime') {
            setToDate(null)
            setFromDate(null)
            setDateFlag("all")
            setDateFilter(event.target.value)
        } else {
            setDateFlag('specific')
            setDateFilter(event.target.value)
        }
    };
    function handleCampaignFilterChange(event) {
        if (event.target.value === 'allCampaigns') {
            setCampaignId(null)
            setCampaignFlag('all')
            setCampaignFilter(event.target.value);
        } else {
            setCampaignId(null)
            setCampaignSelected(-1)
            setCampaignFlag("specific")
            setCampaignFilter(event.target.value);
        }
    };
    function handleSelectGroupCampaign(event) {
        console.log(event);
        setSelectedGroupCampaign(event);
        setCampaignId(event.value);

    }
    function handleSubmitFilter(event) {
        event.preventDefault();
        var toDateFormatted = '';
        var fromDateFormatted = '';
        console.log(new Date());

        if (Array.isArray(toDate) && toDate.length > 0) {
            toDateFormatted = getDateFormateMethod(toDate[0]);
        } else {
            toDateFormatted = new Date();
            toDateFormatted = getDateFormateMethod(toDateFormatted);
        }
        if (Array.isArray(fromDate) && fromDate.length > 0) {
            fromDateFormatted = this.getDateFormateMethod(fromDate[0]);
        } else {
            fromDateFormatted = new Date();
            console.log(new Date());
            fromDateFormatted = getDateFormateMethod(fromDateFormatted);
        }
        const payload = {
            "date_flag": date_flag,
            "campaign_flag": campaign_flag,
            "from_date": fromDateFormatted,
            "to_date": toDateFormatted,
            "campaign_id": campagin_id == null ? '' : campagin_id,
        }
        console.log(payload);
        applyFilterRequest(payload);

    }
    function getDateFormateMethod(myDate) {
        console.log(myDate);
        var today = myDate
        const dd = today.getDate().toString()
        const mm = today.getMonth() + 1
        const yyyy = today.getFullYear()
        const fromate_date = (today = yyyy + "-" + mm + "-" + dd)
        return fromate_date
    }
    async function applyFilterRequest(payload) {
        await axios.post(baseURL + "/api/lead-filter", payload, config)
            .then((response) => {
                console.log(response.data.data);
                setLeadsData(response.data.data)
            })
            .catch((error) => {
            });
    }
    async function getOptionsCampaigns() {
        const res = await axios.get(baseURL + "/api/campaigns", config);
        const data = res.data.data
        const options = data.map(d => ({
            "value": d.id,
            "label": d.name
        }))
        setOptionGroupCampaigns(options)
    }
    const [selectedGroupCampaign, setSelectedGroupCampaign] = useState(null);
    const [optionGroupCampaigns, setOptionGroupCampaigns] = useState();
    const [dateFilter, setDateFilter] = useState('allTime');
    const [campaignFilter, setCampaignFilter] = useState('allCampaigns');
    const [campagin_id, setCampaignId] = useState();
    const [campaignSelected, setCampaignSelected] = useState(-1);
    const [date_flag, setDateFlag] = useState('all');
    const [campaign_flag, setCampaignFlag] = useState('all');
    const [todayDate, setTodayDate] = useState(new Date());
    const [toDate, setToDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card>
                            <CardBody>
                                <Formik
                                    enableReinitialize={true}
                                >
                                    {({ errors, status, touched }) => (
                                        <Form className="needs-validation">
                                            <Row className="m-2">
                                                <Col md="2" className="">
                                                    <Label className="mt-2" htmlFor="dateFilter">Date</Label>
                                                </Col>
                                                <Col md="10">
                                                    <div className="d-flex">
                                                        <div className="col-md-4 ">
                                                            <FormGroup check inline>
                                                                <Label className="mt-2" check>
                                                                    <Input
                                                                        type="radio"
                                                                        name="dateFilterAll"
                                                                        id="allTime"
                                                                        value="allTime"
                                                                        checked={dateFilter === 'allTime'}
                                                                        onChange={(event) => handleDateFilterChange(event)}
                                                                    />
                                                                    {' '}
                                                                    All Time
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup check inline>
                                                                <Label className="mt-2" check>
                                                                    <Input
                                                                        type="radio"
                                                                        name="dateFilterSpecific"
                                                                        id="specificTimeframe"
                                                                        value="specificTimeframe"
                                                                        checked={dateFilter === 'specificTimeframe'}
                                                                        onChange={(event) => handleDateFilterChange(event)}
                                                                    />
                                                                    {' '}
                                                                    Specific Timeframe
                                                                </Label>
                                                            </FormGroup>
                                                        </div>
                                                        {dateFilter === 'specificTimeframe' && (
                                                            <div className="col-md-8 ">
                                                                <div className="d-flex">
                                                                    <div className="col-md-6 d-flex">
                                                                        <Label className="mt-2">From:  &nbsp;&nbsp;&nbsp;</Label>{" "}
                                                                        <div className="docs-datepicker">
                                                                            <InputGroup>
                                                                                <Flatpickr
                                                                                    className="form-control d-block"
                                                                                    placeholder="Pick a date"
                                                                                    value={todayDate}
                                                                                    options={{
                                                                                        // minDate: "today",
                                                                                        enableTime: false,
                                                                                        dateFormat: "d/m/Y",
                                                                                    }}
                                                                                    onChange={date => {
                                                                                        setFromDate(date)
                                                                                    }}
                                                                                />
                                                                                <div className="input-group-append">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                        disabled
                                                                                    >
                                                                                        <i
                                                                                            className="fa fa-calendar"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                    </button>
                                                                                </div>
                                                                            </InputGroup>
                                                                            <div className="docs-datepicker-container" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 d-flex justify-content-end">
                                                                        <Label className="mt-2">&nbsp;&nbsp;&nbsp;To: &nbsp;&nbsp;&nbsp;</Label>{" "}
                                                                        <div className="docs-datepicker">
                                                                            <InputGroup>
                                                                                <Flatpickr
                                                                                    className="form-control d-block"
                                                                                    placeholder="Pick a date"
                                                                                    value={todayDate}
                                                                                    options={{
                                                                                        // maxDate: "today",
                                                                                        enableTime: false,
                                                                                        dateFormat: "d/m/Y",
                                                                                    }}
                                                                                    onChange={date => {
                                                                                        setToDate(date)
                                                                                    }}
                                                                                />
                                                                                <div className="input-group-append">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                        disabled
                                                                                    >
                                                                                        <i
                                                                                            className="fa fa-calendar"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                    </button>
                                                                                </div>
                                                                            </InputGroup>
                                                                            <div className="docs-datepicker-container" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* Campaing Dropdown */}
                                            <Row className="m-2 pt-2">
                                                <Col md="2" className="">
                                                    <Label className="mt-2" htmlFor="campaignFilter">Campaign</Label>
                                                </Col>
                                                <Col md="10">
                                                    <div className="d-flex">
                                                        <div className="col-md-6">
                                                            <FormGroup check inline>
                                                                <Label className="mt-2" check>
                                                                    <Input type="radio" name="campaignFilterAll" id="allCampaigns" value="allCampaigns"
                                                                        checked={campaignFilter === 'allCampaigns'}
                                                                        onChange={(event) => handleCampaignFilterChange(event)} />{' '}
                                                                    All Campaigns
                                                                </Label>
                                                            </FormGroup>
                                                            <FormGroup check inline>
                                                                <Label className="mt-2" check>
                                                                    <Input type="radio" name="camapignFilterSpecific" id="specificCampaign" value="specificCampaign"
                                                                        checked={campaignFilter === 'specificCampaign'}
                                                                        onChange={(event) => handleCampaignFilterChange(event)} />{' '}
                                                                    Specific Campaign
                                                                </Label>
                                                            </FormGroup>
                                                        </div>
                                                        {campaignFilter === 'specificCampaign' ? (
                                                            <>
                                                                <div className="col-md-6">
                                                                    <div className="d-flex">
                                                                        <div className="col-md-12 d-flex">
                                                                            <Label className="mt-2">Campaign: &nbsp;&nbsp;&nbsp;</Label>{" "}
                                                                            <Select
                                                                                value={selectedGroupCampaign}
                                                                                onChange={handleSelectGroupCampaign}
                                                                                options={optionGroupCampaigns}
                                                                                className="col-md-6"
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : <>
                                                        </>}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className="float-end mt-2">
                                                <Col className="d-flex" md="12">
                                                    <div className="p-2">
                                                        <Button onClick={(event) => handleSubmitFilter(event)} color="primary" className="btn btn-primary">
                                                            Apply Filter
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container className="" fluid>
                <Row className="mt-4">
                    <Col>
                        <div className="board-wrapper" style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                            <DragDropContext onDragEnd={result => onDragEnd(result, columns, setColumns, setLeadsData)}>
                                <div style={{ display: "flex" }}>
                                    {Object.entries(columns).map(([columnId, column], index) => (
                                        <div key={columnId} style={{ flex: "0 0 auto", margin: "0 8px", minWidth: "300px" }}>
                                            <h4 className="text-center">{column.name}</h4>
                                            <Droppable droppableId={columnId} key={columnId}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        style={{
                                                            // height: "300px",
                                                            // overflowY: "auto",
                                                            background: snapshot.isDraggingOver ? "rgba(85, 110, 230, 0.25)" : "rgba(255, 255, 255, 0.1)",
                                                            borderRadius: "4px",
                                                            padding: "4px"
                                                        }}
                                                    >
                                                        {column.items.map((item, index) => (
                                                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={{
                                                                            userSelect: "none",
                                                                            padding: 8,
                                                                            margin: "0 0 8px 0",
                                                                            minHeight: "50px",
                                                                            // backgroundColor: snapshot.isDragging ? "#263B4A" : "#456C86",
                                                                            // color: "white",
                                                                            ...provided.draggableProps.style
                                                                        }}
                                                                    >
                                                                        {item.content}
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </div>
                                    ))}
                                </div>
                            </DragDropContext>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default App;
