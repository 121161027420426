import axios from "axios";
// import { Link, useHistory } from "react-router-dom";  
import { withRouter } from 'react-router-dom'   

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

export const get_current_organisation = async () => {
    let response = await axios.get(baseURL + "/api/organisation-show", config);
    return response.data.data;
}
export const get_current_organisation_without_auth = async () => {
    let response = await axios.get(baseURL + "/api/org-logo");
    return response.data.data;
}