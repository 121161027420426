import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle, Button, Table, Label, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { isEmpty, size } from "lodash";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import moment from "moment";
import { receipt_view } from '../api/paymentsApi'
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { numberWithCommas } from "pages/UI/numeric-helper";
class ReceiptView extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 500,
            listData: [],
            listdata: "",
            paymentDetail: "",
            recordDeleteID: null,
            redirect: null
        };
        this.onClickView = this.onClickView.bind(this);
    }
    componentDidMount() {
        Promise.all([receipt_view(localStorage.getItem('current_sale_receipt_id'))]).then((values) => {
            console.log(values[0]);
            this.setState({ paymentDetail: values[0] })
            this.setState({ listData: values[0].sales_receipt_details });
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    };
    onClickView = arg => {
        const listdata = arg;
        // window.localStorage.setItem("current_payment_id", listdata.id);
        this.setState({ redirect: "/sales/receipt/view" });
    }
    render() {
        const listdata = this.state.listdata;
        const { paymentDetail } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        const columns = [
            {
                dataField: "due_date",
                text: "Due Date",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {listdata.payment_details.due_date}
                    </>
                ),
            },
            {
                dataField: "payment_types_name",
                text: "Payment Type",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {listdata.payment_details.payment_types_name}
                    </>
                ),
            },
            {
                dataField: "amount",
                text: `${'Amount '} (${paymentDetail.currency_name ? paymentDetail.currency_name : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {numberWithCommas(listdata.payment_details.amount)}
                    </>
                ),
            },
            {
                dataField: "total",
                text: `${'Total '} (${paymentDetail.currency_name ? paymentDetail.currency_name : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {numberWithCommas(listdata.payment_details.total)}
                    </>
                ),
            },
            {
                dataField: "previously_paid",
                text: `${'Previously Paid '} (${paymentDetail.currency_name ? paymentDetail.currency_name : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {numberWithCommas(listdata.previously_paid)}
                    </>
                ),
            },
            {
                dataField: "adjusted",
                text: `${'Adjusted '} (${paymentDetail.currency_name ? paymentDetail.currency_name : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {numberWithCommas(listdata.adjusted)}
                    </>
                ),
            },
            {
                dataField: "status",
                text: "Status",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {listdata.payment_details.status}
                    </>
                ),
            },
        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];
        const pageOptions = {
            sizePerPage: 500,
            // totalSize: productsData.length, // replace later with size(customers),
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;
        //meta title
        document.title = "Receipt Details";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState({ recordDeleteID: row.id });
            }
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Receipts" breadcrumbItem="Receipt Details" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <div className="mb-5 d-flex align-items-center">
                                            <h6 className="mb-0 card-title flex-grow-1">Received Amount Details</h6>
                                        </div>
                                        <div className="row">
                                            <Col md="12">
                                                <FormGroup className="mb-3 row d-flex">
                                                    <Label className="col-md-2 col-form-label">
                                                        <strong>Received Date</strong>
                                                    </Label>
                                                    <div className="col-md-2">
                                                        <Label className="col-md-12 col-form-label">
                                                            {paymentDetail.receipt_date}
                                                        </Label>
                                                    </div>
                                                    <div className="col-md-4"></div>
                                                    <Label className="col-md-2 col-form-label">
                                                        <strong>Received Amount</strong>
                                                    </Label>
                                                    <div className="col-md-2">
                                                        <Label className="col-md-12 col-form-label">
                                                            <strong>{paymentDetail.currency_name}</strong> {" "} {numberWithCommas(paymentDetail.received_amount)}
                                                        </Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </div>
                                        <div className="row">
                                            <Col md="12">
                                                <FormGroup className="mb-3 row d-flex">
                                                    <Label className="col-md-2 col-form-label">
                                                        <strong>Mode Of Payments</strong>
                                                    </Label>
                                                    <div className="col-md-2">
                                                        <Label className="col-md-12 col-form-label">
                                                            {paymentDetail.mode_of_payment}
                                                        </Label>
                                                    </div>
                                                    <div className="col-md-4"></div>
                                                    <Label className="col-md-2 col-form-label">
                                                        <strong>Notes</strong>
                                                    </Label>
                                                    <div className="col-md-2">
                                                        <Label className="col-md-12 col-form-label">
                                                            {paymentDetail.notes}
                                                        </Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <div className="mb-5 d-flex align-items-center">
                                            <h6 className="mb-0 card-title flex-grow-1">List Adjustment Payments</h6>
                                        </div>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.listData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.listData}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            // selectRow={selectRow}
                                                                            rowEvents={rowEvents}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            ref={this.node}
                                                                        />
                                                                    </div>
                                                                    <div className="table-responsive">
                                                                        <Table className="table mb-3 mt-0">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>{" "}</th>
                                                                                    <th>{" "}</th>
                                                                                    <th>{" "}</th>
                                                                                    <th>{" "}</th>
                                                                                    <th>{" "}</th>
                                                                                    <th>{" "}</th>
                                                                                    <th>{" "}</th>
                                                                                    <th>{" "}</th>
                                                                                    <th>{" "}</th>
                                                                                    <th>{" "}</th>
                                                                                    <th><strong>Total: </strong></th>
                                                                                    <th>{" "}</th>
                                                                                    <td><strong>{paymentDetail.currency_name}</strong> {" "} {numberWithCommas(paymentDetail.received_amount)}</td>
                                                                                    <th>{" "}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default ReceiptView;
