import React, { Component } from "react";
import { Row, Col, Table, Card, CardBody } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { isEmpty, size } from "lodash";
import moment from "moment";
import { showToast } from '../../../../../UI/ui-toast'

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../../../../../components/Common/Breadcrumb";
import { get_all_invoice_logs, clone_file_download } from '../../../../api/templatesApi';

class InvoiceLogs extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 50,
            listData: [],
            listdata: "",
            recordDeleteID: null,
            redirect: null,
        };
        this.onClickDownload = this.onClickDownload.bind(this);
    }
    componentDidMount() {
        Promise.all([get_all_invoice_logs()]).then((values) => {
            this.setState({ listData: values[0] })
            this.setState({ log_data: values[0] })
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    }
    onClickDownload = listdata => {
        Promise.all([clone_file_download(listdata.doc_id)]).then((values) => {
            const contentType = values[0].split(';')[0].split(':')[1];
            const base64Content = values[0].split(',')[1];
            const byteCharacters = atob(base64Content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const extension = values[0].split(';')[0].split('/')[1];
            const blob = new Blob([byteArray], { type: contentType });
            const fileName = `${listdata.doc_name}.${extension}`;
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.target = '_blank';
            a.rel = 'noopener noreferrer';
            a.click();
            showToast("Success", "Download Success.", "success")
        }).catch((error) => {
            showToast("Failed", "Download Failed!", "error")
        });
    };
    render() {
        const listdata = this.state.listdata;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        const { log_data } = this.state;
        const columns = [
            {
                dataField: "id",
                text: "#",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {Object.entries(listdata.properties.new).length > 0 || Object.entries(listdata.properties.old).length > 0 ? (
                            <>
                                {listdata.logsDetails.id}
                            </>
                        ) : null}
                    </>

                ),
            },
            {
                dataField: "full_name",
                text: "Description",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {Object.entries(listdata.properties.new).length > 0 || Object.entries(listdata.properties.old).length > 0 ? (
                            <>
                                {listdata.logsDetails.description.split("-").pop() == 'updated' ? <>
                                    <strong>{listdata.full_name}</strong> made the following changes in <br /><strong>{listdata.logsDetails.subject_type.split("\\").pop()}</strong> of&nbsp;
                                    <Link
                                        to="#"
                                        onClick={() => this.onClickDownload(listdata)}
                                    >
                                        <strong>{listdata.doc_name}</strong>&nbsp;</Link>

                                    at {moment(listdata.logsDetails.created_at).format('DD/MM/YYYY h:mma')}
                                </> : <></>}

                                {listdata.logsDetails.description.split("-").pop() == 'created' ? <>
                                    <strong>{listdata.full_name}</strong> added the following information in <br /><strong>{listdata.logsDetails.subject_type.split("\\").pop()}</strong> of&nbsp;
                                    <Link
                                        to="#"
                                        onClick={() => this.onClickDownload(listdata)}
                                    >
                                        <strong>{listdata.doc_name}</strong>&nbsp;</Link>

                                    at {moment(listdata.logsDetails.created_at).format('DD/MM/YYYY h:mma')}
                                </> : <></>}

                                {listdata.logsDetails.description.split("-").pop() == 'deleted' ? <>
                                    <strong>{listdata.full_name}</strong> deleted the following information in <br /><strong>{listdata.logsDetails.subject_type.split("\\").pop()}</strong> of&nbsp;
                                    <Link
                                        to="#"
                                        onClick={() => this.onClickDownload(listdata)}
                                    >
                                        <strong>{listdata.doc_name}</strong>&nbsp;</Link>

                                    at {moment(listdata.logsDetails.created_at).format('DD/MM/YYYY h:mma')}
                                </> : <></>}
                            </>
                        ) : null}
                    </>

                ),
            },
            {
                dataField: "changes",
                text: "Changes",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        <tr>
                            <td colSpan={3}>
                                <div className="table-responsive">
                                    <Table className="table table-bordered table-striped">
                                        <thead className="border border-1 border-white bg-dark text-white">
                                            <tr>
                                                <th scope="col">Column</th>
                                                <th scope="col">Old</th>
                                                <th scope="col">New</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.entries(listdata.properties.new).map(([key, value], index) => {
                                                const oldValue = listdata.properties.old[key];
                                                if (!oldValue && !value) {
                                                    return null;
                                                }
                                                return (
                                                    <tr key={index}>
                                                        <td>{key}</td>
                                                        <td>{oldValue}</td>
                                                        <td>{value}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </td>
                        </tr>
                    </>
                ),
            },

        ];
        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];
        const pageOptions = {
            sizePerPage: 50,
            custom: true,
        };
        const selectRow = {
            mode: "checkbox",
        };
        const { SearchBar } = Search;
        //meta title
        document.title = "Invoices Logs";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState({ recordDeleteID: row.id });
            }
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Financials Templates" breadcrumbItem="Invoices Logs" />
                        <Row>
                            {log_data?.length === 0 ? (
                                <Card>
                                    <CardBody>No invoices logs data available.</CardBody>
                                </Card>
                            ) : (
                                <Col className="col-12">
                                    <Card>
                                        <CardBody>
                                            <PaginationProvider
                                                pagination={paginationFactory(pageOptions)}
                                                keyField="id"
                                                columns={columns}
                                                data={this.state.listData}
                                            >
                                                {({ paginationProps, paginationTableProps }) => (
                                                    <ToolkitProvider
                                                        keyField="id"
                                                        columns={columns}
                                                        data={this.state.listData}
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row className="mb-2">
                                                                    <Col md="4">
                                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                                            <div className="position-relative">
                                                                                <SearchBar
                                                                                    {...toolkitProps.searchProps}
                                                                                />
                                                                                <i className="bx bx-search-alt search-icon" />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col xl="12">
                                                                        <div className="table-responsive">
                                                                            <BootstrapTable
                                                                                keyField={"id"}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                defaultSorted={defaultSorted}
                                                                                // selectRow={selectRow}
                                                                                rowEvents={rowEvents}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                {...paginationTableProps}
                                                                                ref={this.node}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="align-items-md-center mt-30">
                                                                    <Col className="inner-custom-pagination d-flex">
                                                                        <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                            <SizePerPageDropdownStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                        <div className="text-md-right ms-auto">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                )}
                                            </PaginationProvider>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default InvoiceLogs;
