import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../UI/ui-toast'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"
import { get_organisation_active_roles } from '../../Roles/api/rolesApi';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get_current_orginasation_user } from '../api/usersApi';
const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      optionGroupRole: [],
      u_first_name: '',
      u_last_name: '',
      u_middle_name: '',
      u_mobile_number: '',
      u_telephone: '',
      u_email: '',
      u_title: '',
      u_status: '',
      u_role: '',
      u_picture: null,
      u_picture_show: null,
      redirect: null,
      titlesArray: constant.titles_list,
      statusArray: [
        {
          "value": '1',
          "label": 'Active'
        },
        {
          "value": '0',
          "label": 'Inactive'
        }
      ],
      id: "",
      name: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  async getOptionsRoles() {
    Promise.all([get_organisation_active_roles()]).then((values) => {
      console.log(values[0]);
      // this.setState({ listData: values[0] });
      const options = values[0].map(d => ({
        "value": d.id,
        "label": d.name
      }))
      this.setState({ optionGroupRole: options });
    });
  }
  componentDidMount() {
    console.log(localStorage.getItem("current_user"));
    Promise.all([get_current_orginasation_user(localStorage.getItem("current_user"))]).then((values) => {
      this.setState({ u_first_name: values[0].first_name });
      this.setState({ u_last_name: values[0].last_name });
      this.setState({ u_middle_name: values[0].middle_name });
      this.setState({ u_mobile_number: values[0].mobile });
      this.setState({ u_telephone: values[0].telephone });
      this.setState({ u_email: values[0].email });
      this.setState({ u_title: values[0].title });
      this.setState({ u_status: values[0].status });
      this.setState({ u_role: values[0].roles[0].name });
      this.setState({ u_picture_show: values[0].picture });
    });
    this.getOptionsRoles()
  }

  handleSelectedUserTitle = selectedUserTitle => {
    this.setState({ u_title: selectedUserTitle.value });
    this.setState({ selectedUserTitle })
    console.log(selectedUserTitle);
  }
  handleSelectedUserStatus = selectedUserStatus => {
    this.setState({ u_status: selectedUserStatus.value });
    this.setState({ selectedUserStatus })
    console.log(selectedUserStatus);
  }
  handleSelectGroupRole = selectedGroupRole => {
    this.setState({ selectedGroupRole })
    this.setState({ u_role: selectedGroupRole.label });
    console.log(selectedGroupRole);
  }
  handleImageChangePicture = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log('Picture');
      console.log(e.target.files[0]);
      this.setState({ u_picture: e.target.files[0] });
    }
  };

  handleSubmit(e) {

    e.preventDefault();
    if (!this.state.u_first_name || !this.state.u_last_name || !this.state.u_mobile_number || !this.state.u_email) {
      let missingFields = '';
      if (!this.state.u_first_name) {
        missingFields += '<li>First Name</li>';
      }
      if (!this.state.u_last_name) {
        missingFields += '<li>Last Name</li>';
      }
      if (!this.state.u_mobile_number) {
        missingFields += '<li>Mobile Number</li>';
      }
      if (!this.state.u_email) {
        missingFields += '<li>Email</li>';
      }
      showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
      return false;
    }
    axios.post(baseURL + "/api/orginasation-user-update/" + localStorage.getItem("current_user"), {

      "title": this.state.u_title,
      "first_name": e.target.firstName.value,
      "middle_name": e.target.middleName.value,
      "last_name": e.target.lastName.value,
      "telephone": e.target.telephone.value,
      "organisation_id": localStorage.getItem("organisation_id"),
      "mobile": e.target.mobileNumber.value,
      "role": this.state.u_role,
      "email": e.target.email.value,
      "picture": this.state.u_picture,
      "status": this.state.u_status
    }, config)
      .then((response) => {
        showToast("Success", "The user " + e.target.firstName.value + " has been updated successfully.", "success");
        this.setState({ redirect: "/organisation/users/list" });
        localStorage.removeItem("current_user");
        // window.location.replace('/customers/list');
        console.log(response);
      })
      .catch((error) => {
        showToast("Failed", "User Not Edited Successfully", "error");
        console.log(error);
      });
  }
  render() {
    //meta title
    document.title = "Edit User";
    const { validation } = this.state;
    const { selectedUserTitle } = this.state;
    const { selectedUserStatus } = this.state;
    const { selectedGroupRole } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="User" breadcrumbItem="Edit User" parentLink="/organisation/users/list" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik
                    >
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation" onSubmit={this.handleSubmit}>
                          <Row>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectCountry">Role Select*</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={{ label: this.state.u_role, value: 0 }}
                                    onChange={this.handleSelectGroupRole}
                                    options={this.state.optionGroupRole}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="userTitle">Select title*</Label>
                                <div className="col-md-10">
                                  <Select
                                    onChange={this.handleSelectedUserTitle}
                                    options={this.state.titlesArray}
                                    value={{ label: this.state.u_title, value: 0 }}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </div>
                            </Col>

                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="firstName">
                                  First Name*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="firstName"
                                    placeholder="First Name"
                                    type="text"
                                    value={this.state.u_first_name}
                                    onChange={(e) => this.setState({ u_first_name: e.target.value })}
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="middleName">
                                  Middle Name
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="middleName"
                                    placeholder="Middle Name"
                                    type="text"
                                    value={this.state.u_middle_name}
                                    onChange={(e) => this.setState({ u_middle_name: e.target.value })}
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="lastName">
                                  Last Name*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="lastName"
                                    placeholder="Last Name"
                                    type="text"
                                    onChange={(e) => this.setState({ u_last_name: e.target.value })}
                                    value={this.state.u_last_name}
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="telephone">
                                  Telephone
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="telephone"
                                    placeholder="Telephone"
                                    type="text"
                                    onChange={(e) => this.setState({ u_telephone: e.target.value })}
                                    value={this.state.u_telephone}
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="mobileNumber">
                                  Mobile Number*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="mobileNumber"
                                    placeholder="Mobile Number"
                                    type="text"
                                    onChange={(e) => this.setState({ u_mobile_number: e.target.value })}
                                    value={this.state.u_mobile_number}
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="email">
                                  Email*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="email"
                                    placeholder="Email"
                                    type="email"
                                    onChange={(e) => this.setState({ u_email: e.target.value })}
                                    value={this.state.u_email}
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="userTitle">Select Status*</Label>
                                <div className="col-md-10">
                                  <Select
                                    onChange={this.handleSelectedUserStatus}
                                    options={this.state.statusArray}
                                    value={this.state.u_status == 1 ? { label: "Active", value: this.state.u_status } : { label: "Inactive", value: this.state.u_status }}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="picture">
                                  Picture
                                </Label>
                                <div className="col-md-10">
                                  <Input className="form-control form-control" type="file" id="inputPicture" accept="image/*"
                                    onChange={this.handleImageChangePicture} />
                                </div>
                              </div>
                            </Col>
                            {this.state.u_picture_show == null ?

                              <Col md="12">
                                <div className="mb-3 row d-flex">
                                  <Label className="col-md-2 col-form-label" htmlFor="picture">
                                    Existing Picture
                                  </Label>
                                  <div className="col-md-10 mt-2">
                                    No Picture Exists
                                  </div>
                                </div>
                              </Col>
                              :
                              <Col md="12">
                                <div className="mb-3 row d-flex">
                                  <Label className="col-md-2 col-form-label" htmlFor="picture">
                                    Existing Picture
                                  </Label>
                                  <div className="col-md-10">
                                    <img className="rounded me-2" alt="200x200" width="200" src={this.state.u_picture_show} data-holder-rendered="true" />
                                  </div>
                                </div>
                              </Col>
                            }
                          </Row>
                          <Button color="primary" type="submit" className="float-end" >
                            Update User
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default EditUser;
