import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../UI/ui-toast'

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get_current_integration } from '../api/integrationApi';
import { get_current_organisation } from '../../Platform_OrganisationSetup/api/organisationApi';

import constant from '../../../constants.json';

const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

class EditIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      redirect: null,
      integrationsArray: [
        {
          "value": 'Stripe',
          "label": 'Stripe'
        },
        {
          "value": 'Twillio',
          "label": 'Twillio'
        },
        {
          "value": 'Mailchimp',
          "label": 'Mailchimp'
        }
      ],
      id: "",
      integration_name: '',
      credentials: '',
      org_id: "",
      
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }

  handleSelectedIntegration = selectedIntegration => {
    this.setState({ integration_name: selectedIntegration.value });
    this.setState({ selectedIntegration });
  }

  handleSubmit(e) {
    e.preventDefault();

    axios.patch(baseURL + "/api/integrations/" + this.state.current_integration_id, {

      "integration_name": this.state.integration_name,
      "credentials": e.target.credentials.value,
      "organisation_id": this.state.org_id,
    }, config)
    .then((response) => {
      showToast("Success", "The integration has been updated successfully.", "success");
      this.setState({ redirect: '/integrations/list' });
      localStorage.removeItem("current_salesagent");
      console.log(response);
    })
    .catch((error) => {
      showToast("Failed", "Integration Not Edited Successfully", "error");
      console.log(error);
    });

  }

  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }

  componentDidMount() {
    
    Promise.all([get_current_organisation(), get_current_integration(localStorage.getItem("current_integration_id"))])
    .then(([response1, response2]) => {
      console.log(response1.id);
      console.log(response2.integration_name);
      console.log(response2.credentials);
      this.setState({org_id: response1.id, integration_name: response2.integration_name, credentials: response2.credentials, current_integration_id: localStorage.getItem("current_integration_id")})
    }).catch((error) => {
      console.log(error);
    });
    
  }

  render() {
    //meta title
    document.title = "Edit Integration";
    const { validation } = this.state;

    const { selectedIntegration } = this.state;

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Integration" breadcrumbItem="Edit Integration" parentLink="/integrations/list" />

            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        credentials: (this.state && this.state.credentials) || "",
                      }}
                      validationSchema={Yup.object().shape({
                        /*credentials: Yup.string().required(
                          "Please Enter Credentials."
                        ),*/
                      })}
                      /*onSubmit={values => {
                        console.log(values);
                        axios.post(baseURL + "/api/integrations", {

                          "integration_name": selectedIntegration.value,
                          "credentials": values.credentials,
                          "organisation_id": this.state.org_id,
                        }, config)
                          .then((response) => {
                            console.log(response);
                            showToast("Integration", "Integration Added Successfully", "success");
                            this.setState({ redirect: "/integrations/list" });
                          })
                          .catch((error) => {
                            showToast("Integration", "Integration Not Added Successfully", "error");
                            console.log(error);
                          });

                      }}*/
                    >
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation" onSubmit={this.handleSubmit}>
                          <Row>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectedIntegration">Name *</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={{ label: this.state.integration_name, value: this.state.integration_name }}
                                    onChange={this.handleSelectedIntegration}
                                    options={this.state.integrationsArray}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="credentials">
                                  Credentials *
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="credentials"
                                    placeholder="Credentials"
                                    as="textarea"
                                    rows={5}
                                    value={this.state.credentials}
                                    onChange={(e) => this.setState({ credentials: e.target.value })}
                                    className={
                                      "form-control" +
                                      (errors.credentials && touched.credentials
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="credentials"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            
                            
                          </Row>
                          <Button color="primary" type="submit" className="float-end">
                            Update Integration
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default EditIntegration
