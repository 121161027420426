import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { get_project_floors } from '../../api/projectApi';
let renderCount = 0;
let floorCount = 1;
export default function Fields({ control, register, setValue, getValues }) {
    const { fields, append, remove, prepend, swap } = useFieldArray({
        control,
        name: "floors"
    });

    renderCount++;
    useEffect(() => {
        Promise.all([get_project_floors(localStorage.getItem('current_project_id'))]).then((values) => {
            console.log(values[0]);
            setValue("floors", values[0]);
            floorCount = values[0].length
        });
    }, []);
    return (
        <>
            <ul style={{ listStyle: "none" }}>
                {fields.map((item, index) => {
                    const isEditable = item.has_floor_property
                    return (
                        <li key={item.id}>
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                Floors
                            </label>
                            <input placeholder="Enter Floor" disabled={isEditable} className="form-control" {...register(`floors.${index}.name`)} />

                            <button type="button" color="danger"
                                className="btn btn-danger mt-3" disabled={isEditable} onClick={() => {
                                    floorCount--;
                                    remove(index)
                                }}>
                                Delete
                            </button>
                            {index + 1 >= floorCount ?
                                <>
                                    {index + 1 == floorCount ? <>
                                        {index >= 1 ?
                                            <button color="primary"
                                                className="btn btn-primary ms-1 mt-3" type="button" onClick={() => swap(index - 1, index)}>
                                                Up
                                            </button>
                                            :
                                            <></>
                                        }


                                    </>
                                        :
                                        <>
                                            <button color="primary"
                                                className="btn btn-primary ms-1 mt-3" type="button" onClick={() => swap(index, index + 1)}>
                                                Down
                                            </button>
                                        </>
                                    }
                                </>
                                :
                                <>
                                    {index >= 1 ?
                                        <button color="primary"
                                            className="btn btn-primary ms-1 mt-3" type="button" onClick={() => swap(index - 1, index)}>
                                            Up
                                        </button>
                                        :
                                        <></>
                                    }

                                    <button color="primary"
                                        className="btn btn-primary ms-1 mt-3" type="button" onClick={() => swap(index, index + 1)}>
                                        Down
                                    </button>
                                </>
                            }
                        </li>
                    );
                })}
                <button
                    type="button" color="success"
                    className="btn btn-success mt-2 mb-2"
                    onClick={() => {
                        append({ name: "", id:0 });
                        floorCount++;
                    }}
                >
                    Add Floor
                </button>
            </ul>
        </>
    );
}
