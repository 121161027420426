import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row, InputGroup, Form
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../UI/ui-toast'
import * as Yup from "yup";
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

class AddBank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
            statusArray: [
                {
                    "value": '1',
                    "label": 'Active'
                },
                {
                    "value": '0',
                    "label": 'Inactive'
                }
            ],
            selectedStatus: '',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleSubmit(e) {
        e.preventDefault();
        var endpoint;
        const bank_name = document.getElementById('bank_name').value;
        const account_name = document.getElementById('account_name').value;
        const account_number = document.getElementById('account_number').value;
        const sort_code = document.getElementById('sort_code').value;
        const iban_number = document.getElementById('iban_number').value;
        const swift_code = document.getElementById('swift_code').value;
        if (!bank_name ||
            !account_name ||
            !account_number ||
            !sort_code ||
            !iban_number ||
            !swift_code) {
            let missingFields = '';
            if (!bank_name) {
                missingFields += '<li>Bank Name</li>';
            }
            if (!account_name) {
                missingFields += '<li>Account Name</li>';
            }
            if (!account_number) {
                missingFields += '<li>Account Number</li>';
            }
            if (!sort_code) {
                missingFields += '<li>Sort Code</li>';
            }
            if (!iban_number) {
                missingFields += '<li>IBAN Number</li>';
            }
            if (!swift_code) {
                missingFields += '<li>Swift Code</li>';
            }
            showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
            return false;
        }
        if (localStorage.getItem("adminUserLoginFlag")) {
            endpoint = "/api/platform-bank-details"
        } else {
            endpoint = "/api/organisation-bank-details"
        }
        axios.post(baseURL + endpoint, {
            "account_name": account_name,
            "bank_name": bank_name,
            "account_number": account_number,
            "sort_code": sort_code,
            "iban_number": iban_number,
            "swift_code": swift_code,
            "status": this.state.selectedStatus?.value,
            "organisation_id": localStorage.getItem("organisation_id")
        }, config)
            .then((response) => {
                showToast("Success", "The bank added successfully.", "success");
                this.setState({ redirect: "/organisation/bank/list" });
            })
            .catch((error) => {
                showToast("Failed", error.response.data.message, "error")
            });
    }
    handleSelectedStatus = selectedStatus => {
        this.setState({ selectedStatus: selectedStatus })
    }
    render() {
        //meta title
        document.title = "Add Bank";
        const { selectedStatus } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Banks" breadcrumbItem="Add Bank" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Form onSubmit={this.handleSubmit}>
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="account_name">
                                                            Account Name*
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="account_name"
                                                                name="account_name"
                                                                placeholder="Account Name"
                                                                type="text"
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="bank_name">
                                                            Bank Name*
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="bank_name"
                                                                name="bank_name"
                                                                placeholder="Bank Name"
                                                                type="text"
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="account_number">
                                                            Account Number*
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="account_number"
                                                                name="account_number"
                                                                placeholder="Account Number"
                                                                type="text"
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="sort_code">
                                                            Sort Code*
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="sort_code"
                                                                name="sort_code"
                                                                placeholder="Sort Code"
                                                                type="text"
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="iban_number">
                                                            IBAN Number*
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="iban_number"
                                                                name="iban_number"
                                                                placeholder="IBAN Number"
                                                                type="text"
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="swift_code">
                                                            Swift Code*
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="swift_code"
                                                                name="swift_code"
                                                                placeholder="Swift Code"
                                                                type="text"
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <div className="mb-3 row d-flex select2-container">
                                                        <Label className="col-md-2 col-form-label" htmlFor="selectedStatus">Status*</Label>
                                                        <div className="col-md-10">
                                                            <Select
                                                                value={selectedStatus}
                                                                onChange={this.handleSelectedStatus}
                                                                options={this.state.statusArray}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                            <div className="invalid-feedback" id="status-select-msg" style={{ display: this.state.status_select_display }}>Please Select Role Status.</div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Button color="primary" type="submit" className="float-end">
                                                Add Bank
                                            </Button>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default AddBank;