import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../UI/ui-toast'

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"
import { get_organisation_active_roles} from '../../Roles/api/rolesApi';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      optionGroupRole: [],
      u_picture: null,
      redirect: null,
      titlesArray: constant.titles_list,
      statusArray: [
        {
          "value": '1',
          "label": 'Active'
        },
        {
          "value": '0',
          "label": 'Inactive'
        }
      ],
      id: "",
      name: '',
      roleSelected: -1,
      titleSelected: -1,
      statusSelected: -1,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }

  async getOptionsRoles() {
    
    Promise.all([get_organisation_active_roles()]).then((values) => {
      console.log(values[0]);
      // this.setState({ listData: values[0] });
      const options = values[0].map(d => ({
        "value": d.id,
        "label": d.name
      }))
      this.setState({ optionGroupRole: options });
    });
  }
  componentDidMount() {
    this.getOptionsRoles()
  }
  checkValidation = () => {
    if (this.state.titleSelected != 1) {
      this.setState({ titleSelected: 0 })
    }
    if (this.state.roleSelected != 1) {
      this.setState({ roleSelected: 0 })
    }
    if (this.state.statusSelected != 1) {
      this.setState({ statusSelected: 0 })
    }
  }
  handleSelectedUserTitle = selectedUserTitle => {
    this.setState({titleSelected:1})
    this.setState({ selectedUserTitle })
    console.log(selectedUserTitle);
  }
  handleSelectGroupRole = selectedGroupRole => {
    this.setState({roleSelected:1})
    this.setState({ selectedGroupRole })
    console.log(selectedGroupRole);
  }
  handleImageChangePicture = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log('Picture');
      console.log(e.target.files[0]);
      this.setState({ u_picture: e.target.files[0] });
    }
  };
  handleSelectGroupStatus = selectedUserStatus => {
    this.setState({statusSelected:1})
    this.setState({ selectedUserStatus })
    console.log(selectedUserStatus);
}

  handleSubmit(e) {
    e.preventDefault();

    const modifiedV = { ...this.state.validation };
    var fnm = document.getElementById("validationTooltip01").value;
    var lnm = document.getElementById("validationTooltip02").value;
    var unm = document.getElementById("validationTooltipUsername").value;
    var city = document.getElementById("validationTooltip03").value;
    var stateV = document.getElementById("validationTooltip04").value;

    if (fnm === "") {
      modifiedV["fnm"] = false;
    } else {
      modifiedV["fnm"] = true;
    }

    if (lnm === "") {
      modifiedV["lnm"] = false;
    } else {
      modifiedV["lnm"] = true;
    }

    if (unm === "") {
      modifiedV["unm"] = false;
    } else {
      modifiedV["unm"] = true;
    }

    if (city === "") {
      modifiedV["city"] = false;
    } else {
      modifiedV["city"] = true;
    }

    if (stateV === "") {
      modifiedV["stateV"] = false;
    } else {
      modifiedV["stateV"] = true;
    }

    this.setState({ validation: modifiedV });
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }

  render() {
    //meta title
    document.title = "Add User";
    const { validation } = this.state;
    const { selectedUserTitle } = this.state;
    const { selectedGroupRole } = this.state;
    const { selectedUserStatus } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="User" breadcrumbItem="Add User" parentLink="/organisation/users/list" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        firstName: (this.state && this.state.firstName) || "",
                        lastName: (this.state && this.state.lastName) || "",
                        mobileNumber: (this.state && this.state.mobileNumber) || "",
                        email: (this.state && this.state.email) || "",
                        password: (this.state && this.state.password) || "",
                        password_confirm: (this.state && this.state.password_confirm) || "",
                      }}
                      validationSchema={Yup.object().shape({
                        firstName: Yup.string().required(
                          "User first name is required!"
                        ),
                        lastName: Yup.string().required(
                          "User last name is required!"
                        ),
                        mobileNumber: Yup.string().required(
                          "User mobile number is required!"
                        ),
                        email: Yup.string().required(
                          "User email is required!"
                        ),
                        password: Yup.string().required(
                          "User password is required!"
                        ),
                        password_confirm: Yup.string().required(
                          "User confirm password is required!"
                        ),
                      })}
                      onSubmit={values => {
                        console.log(values);
                        if(this.state.titleSelected == 0 || this.state.roleSelected == 0 || this.state.statusSelected == 0){
                          showToast("Failed", "Please fill the required fields.", "error");
                        }else{
                          axios.post(baseURL + "/api/orginasation-user", {
                            "title": selectedUserTitle.value,
                            "first_name": values.firstName,
                            "middle_name": values.middleName,
                            "last_name": values.lastName,
                            "role": selectedGroupRole.label,
                            "organisation_id": localStorage.getItem("organisation_id"),
                            "password": values.password,
                            "password_confirmation": values.password_confirm,
                            "telephone": values.telephone,
                            "mobile": values.mobileNumber,
                            "email": values.email,
                            "picture": this.state.u_picture,
                            "status": selectedUserStatus.value
                          }, config)
                            .then((response) => {
                              showToast("Success", "The user "+values.firstName+" has been created successfully.", "success");
                              this.setState({ redirect: "/organisation/users/list" });
                              console.log(response);
                            })
                            .catch((error) => {
                              /*showToast("User", "User Not Added Successfully", "error")
                              console.log(error.response);*/
                              var responseErrors = error.response.data.errors;
                              var errorMessage = "The user could not be added.";
                              Object.entries(responseErrors).forEach(([key, value]) => 
                                {
                                  errorMessage = errorMessage + "<br />&#10003; " + value;
                                }
                              );
                              showToast("Failed", errorMessage, "error");
                              console.log(error);
                              console.log(error.response.data.errors);
                            });
                        }
                      }}
                    >
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation">
                          <Row>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectCountry">Role Select*</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={selectedGroupRole}
                                    onChange={this.handleSelectGroupRole}
                                    options={this.state.optionGroupRole}
                                    classNamePrefix="select2-selection"
                                  // className={
                                  //   "" +
                                  //   (errors.selectCountry && touched.selectCountry
                                  //     ? " is-invalid"
                                  //     : "")
                                  // }
                                  />
                                  {this.state.roleSelected == 0 ? <>
                                    <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Role is not selected, please select first</p>
                                  </> : <></>
                                  }
                                  {/* <ErrorMessage
                                    name="selectCountry"
                                    component="div"
                                    className="invalid-feedback"
                                  /> */}
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="customerTitle">Select title*</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={selectedUserTitle}
                                    onChange={this.handleSelectedUserTitle}
                                    options={this.state.titlesArray}
                                    classNamePrefix="select2-selection"
                                  // className={
                                  //   "" +
                                  //   (errors.customerTitle && touched.customerTitle
                                  //     ? " is-invalid"
                                  //     : "")
                                  // }
                                  />
                                  {this.state.titleSelected == 0 ? <>
                                    <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Title is not selected, please select first</p>
                                  </> : <></>
                                  }
                                  { /*<ErrorMessage
                                    name="customerTitle"
                                    component="div"
                                    className="invalid-feedback"
                                  />*/ }
                                </div>
                              </div>
                            </Col>

                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="firstName">
                                  First Name*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="firstName"
                                    placeholder="First Name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.firstName && touched.firstName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="firstName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="middleName">
                                  Middle Name
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="middleName"
                                    placeholder="Middle Name"
                                    type="text"
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="lastName">
                                  Last Name*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="lastName"
                                    placeholder="Last Name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.lastName && touched.lastName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="lastName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="telephone">
                                  Telephone
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="telephone"
                                    placeholder="Telephone"
                                    type="text"
                                    className={
                                      "form-control"
                                    }
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="mobileNumber">
                                  Mobile Number*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="mobileNumber"
                                    placeholder="Mobile Number"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.mobileNumber && touched.mobileNumber
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="mobileNumber"
                                    component="div"
                                    className="invalid-feedback"
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="email">
                                  Email*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="email"
                                    placeholder="Email"
                                    type="email"
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="password">
                                  Password*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="password"
                                    placeholder="Password"
                                    type="password"
                                    className={
                                      "form-control" +
                                      (errors.password && touched.password
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="invalid-feedback"
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="password_confirm">
                                  Confirm Password*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="password_confirm"
                                    placeholder="Confirm Password"
                                    type="password"
                                    className={
                                      "form-control" +
                                      (errors.password_confirm && touched.password_confirm
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="password_confirm"
                                    component="div"
                                    className="invalid-feedback"
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectFeatures">Status*</Label>
                                <div className="col-md-10">
                                  <Select
                                    name="selectFeatures"
                                    value={selectedUserStatus}
                                    onChange={this.handleSelectGroupStatus}
                                    options={this.state.statusArray}
                                    classNamePrefix="select2-selection"
                                  />
                                  {this.state.statusSelected == 0 ? <>
                                    <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Status is not selected, please select first</p>
                                  </> : <></>
                                  }
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="picture">
                                  Picture
                                </Label>
                                <div className="col-md-10">
                                  <Input className="form-control form-control" type="file" id="inputPicture" accept="image/*"
                                    onChange={this.handleImageChangePicture} />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Button color="primary" type="submit" onClick={this.checkValidation} className="float-end" >
                            Add User
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default AddUser;
