import React, { Component } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Redirect } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row, InputGroup, Form
} from "reactstrap";
import axios from "axios";
import Flatpickr from 'react-flatpickr'
import * as Yup from "yup";
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { showToast } from "pages/UI/ui-toast";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
class AddLead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "",
            redirect: null,
            optionGroupCampaigns: [],
            optionGroupSalesAgents: [],
            optionGroupLeadTypes: [],
            optionGroupLeadStages: [],
            optionGroupLeadPipelines: [],
            id: "",
            name: '',
            campaign_ID: '',
            sales_agent_ID: '',
            lead_type_ID: '',
            lead_stage_ID: '',
            lead_pipeline_ID: '',
            statusArray: [
                {
                    "value": '1',
                    "label": 'Yes'
                },
                {
                    "value": '0',
                    "label": 'No'
                }
            ],
            selectedStatus: '',
            conversionDate: [new Date()],
        };
    }

    async getOptionsCampaigns() {
        const res = await axios.get(baseURL + "/api/campaigns", config);
        const data = res.data.data
        const options = data.map(d => ({
            "value": d.id,
            "label": d.name
        }))
        this.setState({ optionGroupCampaigns: options })
    }
    async getOptionsSalesAgents() {
        const res = await axios.get(baseURL + "/api/sales-agent-list", config);
        const salesAgentData = res.data.data;

        const options = salesAgentData.map(d => ({
            "value": d.id,
            "label": d.full_name
        }))
        this.setState({ optionGroupSalesAgents: options })
    }
    async getOptionsLeadTypes() {
        const res = await axios.get(baseURL + "/api/lead-type-listing", config);
        const data = res.data.data
        const options = data.map(d => ({
            "value": d.id,
            "label": d.name
        }))
        this.setState({ optionGroupLeadTypes: options })
    }
    async getOptionsLeadStages() {
        const res = await axios.get(baseURL + "/api/lead-stage-listing", config);
        const data = res.data.data
        const options = data.map(d => ({
            "value": d.id,
            "label": d.name
        }))
        this.setState({ optionGroupLeadStages: options })
    }
    async getOptionsLeadPipelines() {
        const res = await axios.get(baseURL + "/api/lead-pipeline-listing", config);
        const data = res.data.data
        const options = data.map(d => ({
            "value": d.id,
            "label": d.name
        }))
        this.setState({ optionGroupLeadPipelines: options })
    }
    componentDidMount() {
        this.getOptionsCampaigns()
        this.getOptionsSalesAgents()
        this.getOptionsLeadTypes()
        this.getOptionsLeadStages()
        this.getOptionsLeadPipelines()
        this.setState({ todayDate: new Date() });
    }
    handleSelectGroupCampaign = selectedGroupCampaign => {
        this.setState({ campaign_ID: selectedGroupCampaign })
        this.setState({ selectedGroupCampaign })
    }
    handleSelectGroupSalesAgents = selectedGroupSalesAgents => {
        this.setState({ sales_agent_ID: selectedGroupSalesAgents })
        this.setState({ selectedGroupSalesAgents })
    }
    handleSelectGroupLeadTypes = selectedGroupLeadTypes => {
        this.setState({ lead_type_ID: selectedGroupLeadTypes })
        this.setState({ selectedGroupLeadTypes })
    }
    handleSelectGroupLeadStages = selectedGroupLeadStages => {
        this.setState({ lead_stage_ID: selectedGroupLeadStages })
        this.setState({ selectedGroupLeadStages })
    }
    handleSelectGroupLeadPipeLines = selectedGroupLeadPipeLines => {
        this.setState({ lead_pipeline_ID: selectedGroupLeadPipeLines })
        this.setState({ selectedGroupLeadPipeLines })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const first_name = document.getElementById('first_name').value;
        const last_name = document.getElementById('last_name').value;
        const mobile = document.getElementById('mobile').value;
        const email = document.getElementById('email').value;
        const lead_score = document.getElementById('lead_score').value;
        const lead_amount = document.getElementById('lead_amount').value;
        const lead_label = document.getElementById('lead_label').value;
        var conversionDateFormatted = this.getDateFormateMethod(this.state.conversionDate[0]);
        if (!first_name ||
            !last_name ||
            !mobile ||
            !conversionDateFormatted || 
            !email || !lead_score || !lead_amount || !lead_label ||
            !this.state.campaign_ID?.value || !this.state.sales_agent_ID?.value || 
            !this.state.lead_type_ID?.value || !this.state.lead_stage_ID?.value || !this.state.lead_pipeline_ID?.value) {
            let missingFields = '';
            if (!first_name) {
                missingFields += '<li>First Name</li>';
            }
            if (!last_name) {
                missingFields += '<li>Last Name</li>';
            }
            if (!mobile) {
                missingFields += '<li>Mobile</li>';
            }
            if (!email) {
                missingFields += '<li>Email</li>';
            }
            if (!conversionDateFormatted) {
                missingFields += '<li>Conversion Date</li>';
            }
            if (!this.state.campaign_ID?.value) {
                missingFields += '<li>Campagin Type</li>';
            }
            if (!this.state.sales_agent_ID?.value) {
                missingFields += '<li>Sales Agent</li>';
            }
            if (!this.state.lead_type_ID?.value) {
                missingFields += '<li>Lead Type</li>';
            }
            if (!this.state.lead_stage_ID?.value) {
                missingFields += '<li>Lead Stage</li>';
            }
            if (!this.state.lead_pipeline_ID?.value) {
                missingFields += '<li>Lead Stage</li>';
            }
            if (!lead_score) {
                missingFields += '<li>Lead Score</li>';
            }
            if (!lead_amount) {
                missingFields += '<li>Lead Amount</li>';
            }
            if (!lead_label) {
                missingFields += '<li>Lead Label</li>';
            }
            showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
            return false;
        }
       
        axios.post(baseURL + "/api/leads", {
            "customer_first_name": first_name,
            "campaign_id": this.state.campaign_ID?.value,
            "customer_last_name": last_name,
            "conversion_date": conversionDateFormatted,
            "customer_mobile": email,
            "customer_email": mobile,
            "score": lead_score,
            "amount": lead_amount,
            "label": lead_label,
            "lead_type_id": this.state.lead_type_ID?.value,
            "lead_stage_id": this.state.lead_stage_ID?.value,
            "lead_pipeline_id": this.state.lead_pipeline_ID?.value,
            "sales_agent_id": this.state.sales_agent_ID?.value,
        }, config)
            .then((response) => {
                showToast("Success", "The lead added successfully.", "success");
                this.setState({ redirect: "/lead/list" });
            })
            .catch((error) => {
                showToast("Failed", error.response.data.message, "error")
            });
    }

    getDateFormateMethod = (myDate) => {
        var today = myDate
        const dd = today.getDate().toString()
        const mm = today.getMonth() + 1
        const yyyy = today.getFullYear()
        const fromate_date = (today = yyyy + "-" + mm + "-" + dd)
        return fromate_date
    }
    showToast(title, message, toastType) {
        //position class
        var positionClass = "toast-top-right";
        toastr.options = {
            positionClass: positionClass,
            timeOut: this.state.timeOut,
            extendedTimeOut: this.state.extendedTimeOut,
            showEasing: this.state.showEasing,
            hideEasing: this.state.hideEasing,
            showMethod: this.state.showMethod,
            hideMethod: this.state.hideMethod,
            showDuration: this.state.showDuration,
            hideDuration: this.state.hideDuration,
        };

        // setTimeout(() => toastr.success(`Settings updated `), 300)
        //Toaster Types
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
    }
    handleSelectedStatus = selectedStatus => {
        this.setState({ selectedStatus: selectedStatus })
    }

    render() {
        //meta title
        document.title = "Add Lead";
        const { selectedStatus } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        const { selectedGroupCampaign, selectedGroupSalesAgents, selectedGroupLeadPipeLines, selectedGroupLeadStages, selectedGroupLeadTypes } = this.state;
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Leads" breadcrumbItem="Add Lead" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Form onSubmit={this.handleSubmit}>
                                            <Row>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="first_name">
                                                            First Name*
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="first_name"
                                                                name="first_name"
                                                                placeholder="First Name"
                                                                type="text"
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="last_name">
                                                            Last Name*
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="last_name"
                                                                name="last_name"
                                                                placeholder="Last Name"
                                                                type="text"
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="mobile">
                                                            Mobile*
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="mobile"
                                                                name="mobile"
                                                                placeholder="Mobile"
                                                                type="text"
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="email">
                                                            Email*
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="email"
                                                                name="email"
                                                                placeholder="Email"
                                                                type="email"
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <div className="mb-3 row d-flex select2-container">
                                                        <Label className="col-md-2 col-form-label" htmlFor="campaignType">Campaigns*</Label>
                                                        <div className="col-md-10">
                                                            <Select
                                                                value={selectedGroupCampaign}
                                                                onChange={this.handleSelectGroupCampaign}
                                                                options={this.state.optionGroupCampaigns}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="12">
                                                    <div className="mb-3 row d-flex select2-container">
                                                        <Label className="col-md-2 col-form-label" htmlFor="campaignType">Sales Agents*</Label>
                                                        <div className="col-md-10">
                                                            <Select
                                                                value={selectedGroupSalesAgents}
                                                                onChange={this.handleSelectGroupSalesAgents}
                                                                options={this.state.optionGroupSalesAgents}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="12">
                                                    <div className="mb-3 row d-flex select2-container">
                                                        <Label className="col-md-2 col-form-label" htmlFor="campaignType">Lead Types*</Label>
                                                        <div className="col-md-10">
                                                            <Select
                                                                value={selectedGroupLeadTypes}
                                                                onChange={this.handleSelectGroupLeadTypes}
                                                                options={this.state.optionGroupLeadTypes}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="12">
                                                    <div className="mb-3 row d-flex select2-container">
                                                        <Label className="col-md-2 col-form-label" htmlFor="campaignType">Lead Stages*</Label>
                                                        <div className="col-md-10">
                                                            <Select
                                                                value={selectedGroupLeadStages}
                                                                onChange={this.handleSelectGroupLeadStages}
                                                                options={this.state.optionGroupLeadStages}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="12">
                                                    <div className="mb-3 row d-flex select2-container">
                                                        <Label className="col-md-2 col-form-label" htmlFor="campaignType">Lead Pipelines*</Label>
                                                        <div className="col-md-10">
                                                            <Select
                                                                value={selectedGroupLeadPipeLines}
                                                                onChange={this.handleSelectGroupLeadPipeLines}
                                                                options={this.state.optionGroupLeadPipelines}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="12">
                                                    <div className="mb-3 row d-flex ">
                                                        <Label className="col-md-2 col-form-label" htmlFor="selectDate">Conversion Date*</Label>
                                                        <div className="docs-datepicker col-md-10">
                                                            <InputGroup>
                                                                <Flatpickr
                                                                    className="form-control d-block"
                                                                    placeholder="Pick a start date"
                                                                    options={{
                                                                        // minDate: "today",
                                                                        enableTime: false,
                                                                        dateFormat: "d/m/Y",
                                                                    }}
                                                                    onChange={date => {
                                                                        this.setState({ conversionDate: date })
                                                                    }}
                                                                />
                                                                <div className="input-group-append">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                        disabled
                                                                    >
                                                                        <i
                                                                            className="fa fa-calendar"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </button>
                                                                </div>
                                                            </InputGroup>
                                                            <div className="docs-datepicker-container" />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="lead_label">
                                                            Label
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="lead_label"
                                                                name="lead_label"
                                                                placeholder="Label"
                                                                type="text"
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="lead_score">
                                                            Score
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="lead_score"
                                                                name="lead_score"
                                                                placeholder="Score"
                                                                type="text"
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="12">
                                                    <FormGroup className="mb-3 row d-flex">
                                                        <Label className="col-md-2 col-form-label" htmlFor="lead_amount">
                                                            Amount
                                                        </Label>
                                                        <div className="col-md-10">
                                                            <Input
                                                                id="lead_amount"
                                                                name="lead_amount"
                                                                placeholder="Amount"
                                                                type="number"
                                                                className={
                                                                    "form-control"
                                                                }
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Button color="primary" type="submit" className="float-end">
                                                Add Lead
                                            </Button>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default AddLead;