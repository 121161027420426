import React, { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { get_project_sizes, get_project_phases_blocks } from '../../api/projectApi';
let renderCount = 0;
let floorCount = 1;
export default function Fields({ control, register, setValue, getValues }) {
    const { fields, append, remove, prepend, swap } = useFieldArray({
        control,
        name: "sizes"
    });
    renderCount++;
    useEffect(() => {
        Promise.all([get_project_sizes(localStorage.getItem('current_project_id'))]).then((values) => {
            setValue("sizes", values[0]);
            floorCount = values[0].length
        });
    }, []);

    return (
        <>
            <ul style={{ listStyle: "none" }}>
                {fields.map((item, index) => {
                    const isEditable = item.has_size_property 
                    return (
                        <li key={item.id}>
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                Size
                            </label>
                            <input placeholder="Enter Size" disabled={isEditable} className="form-control" {...register(`sizes.${index}.name`)} />

                            <button type="button" color="danger"
                                className="btn btn-danger mt-3" onClick={() => {
                                    floorCount--;
                                    remove(index)
                                }}
                                disabled={isEditable}

                            >
                                Delete
                            </button>
                            {index + 1 >= floorCount ?
                                <>
                                    {index + 1 == floorCount ? <>
                                        {index >= 1 ?
                                            <button color="primary"
                                                className="btn btn-primary ms-1 mt-3" type="button" onClick={() => swap(index - 1, index)}>
                                                Up
                                            </button>
                                            :
                                            <></>
                                        }


                                    </>
                                        :
                                        <>
                                            <button color="primary" 
                                                className="btn btn-primary ms-1 mt-3" type="button" onClick={() => swap(index, index + 1)}>
                                                Down
                                            </button>
                                        </>
                                    }
                                </>
                                :
                                <>
                                    {index >= 1 ?
                                        <button color="primary"
                                            className="btn btn-primary ms-1 mt-3" type="button" onClick={() => swap(index - 1, index)}>
                                            Up
                                        </button>
                                        :
                                        <></>
                                    }

                                    <button color="primary"
                                        className="btn btn-primary ms-1 mt-3" type="button" onClick={() => swap(index, index + 1)}>
                                        Down
                                    </button>
                                </>
                            }
                        </li>
                    );
                })}
                <button
                    type="button" color="success"
                    className="btn btn-success mb-2 mt-2"
                    onClick={() => {
                        append({ name: "", id: 0 });
                        floorCount++;
                    }}
                >
                    Add Size
                </button>
            </ul>
        </>
    );
}
